import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ShadowCard from "../../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
// import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import {
  // clearNewDate,
  clearSalaryDetails,
  decreaseActiveStep,
  setApplyFromDate,
  setApplyFromDateSelector,
  // setApplyFromDateSelector,
} from "../employeeSlice";
import FormTextField from "../../../components/formFields/FormTextField";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import FormDatePicker from "../../../components/formFields/FormDateField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { ISalaryDate } from "../employeeModel";
import {
  getAllSalaryDateByEmployeeID,
  getSalaryStructureByDateAndEmpID,
  updateEmployeeSalaryStructure,
} from "../services/employee.services";
import moment from "moment";
import { LoadingPanel } from "../../../components/layout/Loading";
import { ErrorToast } from "../../../components/toast/Toasts";

interface SalaryChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const DateChangeWatcher: React.FC<SalaryChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const EmployeeID = location.state?.EmployeeID;
  const APPLY_DATE = formRenderProps.valueGetter("apply_from_date_selector");
  const NewDate = formRenderProps.valueGetter("new_date");
  const NewFormatedDate = NewDate ? moment(NewDate).format("YYYY-MM-DD") : "";
  useEffect(() => {
    dispatch(setApplyFromDate(NewFormatedDate));
  }, [NewDate]);

  useEffect(() => {
    const payload = {
      employee_id: EmployeeID,
      apply_from_date: moment(APPLY_DATE, "DD/MM/YYYY").format("YYYY-MM-DD"),
    };
    if (APPLY_DATE) {
      dispatch(getSalaryStructureByDateAndEmpID(payload));
      formRenderProps.onChange("apply_from_date_selector", {
        value: APPLY_DATE,
      });
    }
  }, [APPLY_DATE]);

  return null;
};
const SalaryChangeWatcher: React.FC<SalaryChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const Basic = +formRenderProps.valueGetter("basic") || 0;
  const DAPercent = +formRenderProps.valueGetter("da_per") || 0;
  const HRAPercent = +formRenderProps.valueGetter("hra_per") || 0;
  const PFPercent = +formRenderProps.valueGetter("pf_per") || 0;
  const ESIPercent = +formRenderProps.valueGetter("esi_per") || 0;

  const DA = +formRenderProps.valueGetter("da") || 0;
  const HRA = +formRenderProps.valueGetter("hra") || 0;
  const Wash = +formRenderProps.valueGetter("wash") || 0;
  const Bonus = +formRenderProps.valueGetter("adv_bonus") || 0;
  const Leave = +formRenderProps.valueGetter("adv_leave") || 0;
  const Food = +formRenderProps.valueGetter("food") || 0;
  const TotalEarning = +formRenderProps.valueGetter("total_earning") || 0;
  const TotalDeduction = +formRenderProps.valueGetter("total_deduction") || 0;

  const PF = +formRenderProps.valueGetter("pf") || 0;
  const ESI = +formRenderProps.valueGetter("esi") || 0;
  const PT = +formRenderProps.valueGetter("pt") || 0;
  const IT = +formRenderProps.valueGetter("it") || 0;
  const LWF = +formRenderProps.valueGetter("lwf") || 0;
  const Advance = +formRenderProps.valueGetter("advance") || 0;
  const Loan = +formRenderProps.valueGetter("loan") || 0;
  const Other = +formRenderProps.valueGetter("other") || 0;
  useEffect(() => {
    // const TotalEarning = new Intl.NumberFormat("en-IN", {
    //   style: "currency",
    //   currency: "INR",
    // }).format(+(Basic + DA + HRA + Wash + Bonus + Leave + Food).toFixed(2));
    formRenderProps.onChange("da", {
      value: (Basic * (DAPercent / 100))?.toFixed(2),
    });
    formRenderProps.onChange("hra", {
      value: ((Basic + DA) * (HRAPercent / 100))?.toFixed(2),
    });
    formRenderProps.onChange("pf", {
      value: ((Basic + DA) * (PFPercent / 100))?.toFixed(2),
    });
    // formRenderProps.onChange("esi", {
    //   value: ((Basic + DA) * (ESIPercent / 100)).toFixed(2),
    // });
    formRenderProps.onChange("total_earning", {
      value: (Basic + DA + HRA + Wash + Bonus + Leave + Food).toFixed(2),
    });
  }, [
    Basic,
    DAPercent,
    HRAPercent,
    DA,
    PFPercent,
    ESIPercent,
    HRA,
    Wash,
    Bonus,
    Leave,
    Food,
  ]);

  useEffect(() => {
    // const TotalDeduction = new Intl.NumberFormat("en-IN", {
    //   style: "currency",
    //   currency: "INR",
    // }).format(+(PF + ESI + PT + IT + LWF + Advance + Loan + Other).toFixed(2));

    formRenderProps.onChange("total_deduction", {
      value: (PF + ESI + PT + IT + LWF + Advance + Loan + Other).toFixed(2),
    });
  }, [PF, ESI, PT, IT, LWF, Advance, Loan, Other]);

  useEffect(() => {
    const PTCal = TotalEarning > 12000 ? 200 : 0;
    formRenderProps.onChange("pt", {
      value: PTCal,
    });
  }, [TotalEarning]);

  useEffect(() => {
    const TotalSalary = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(+(TotalEarning - TotalDeduction).toFixed());
    formRenderProps.onChange("total_salary", {
      value: TotalSalary,
    });
  }, [TotalEarning, TotalDeduction]);
  return null;
};

const SalaryStructure = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const EmployeeGUID = location.state?.EmployeeGUID;
  const EmployeeID = location.state?.EmployeeID;
  const formloading = useAppSelector((state) => state.employee.formloading);
  const gridRef = useRef<any>(null);
  const SalaryDetails = useAppSelector(
    (state) => state?.employee?.SalaryDetails
  );
  const DateFilter = useAppSelector((state) => state?.employee?.DateFilter);
  const SalaryDateList = useAppSelector(
    (state) => state?.employee?.SalaryDateList
  );
  const [formKey, setFormKey] = useState(1);
  const [dateFormKey, setDateFormKey] = useState(new Date().getTime());
  const [editMode, setEditMode] = useState<Boolean>(false);
  const editModeRef = useRef<Boolean>(false);
  useEffect(() => {
    dispatch(getAllSalaryDateByEmployeeID(EmployeeID));
  }, [EmployeeID]);

  useEffect(() => {
    if (SalaryDateList?.length > 0) {
      const payload = {
        employee_id: EmployeeID,
        apply_from_date: moment(
          SalaryDateList[0]?.apply_from_date,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
      };
      dispatch(getSalaryStructureByDateAndEmpID(payload));
      setDateFormKey(dateFormKey + 5);
      dispatch(setApplyFromDateSelector(SalaryDateList[0]?.apply_from_date));
    }
  }, [SalaryDateList]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [SalaryDetails]);
  const handleToggel = () => {
    editModeRef.current = !editModeRef.current;
    setEditMode((prev) => !prev);
    if (editModeRef.current) {
      setFormKey(formKey + 1);
      dispatch(clearSalaryDetails());
    } else {
      // const payload = {
      //   employee_id: EmployeeID,
      //   apply_from_date: moment(
      //     SalaryDateList[0]?.apply_from_date,
      //     "DD/MM/YYYY"
      //   ).format("YYYY-MM-DD"),
      // };
      // dispatch(getSalaryStructureByDateAndEmpID(payload));
      // setDateFormKey(dateFormKey + 1);
      setDateFormKey(dateFormKey + 5);
      dispatch(setApplyFromDateSelector(""));
      // dispatch(clearNewDate());
    }
  };
  const handleSubmit = async (values: any) => {
    const formdata = {
      employee_id: EmployeeID,
      basic: +values?.basic,
      da_per: +values?.da_per,
      hra_per: +values?.hra_per,
      wash: +values?.wash,
      adv_bonus: +values?.adv_bonus,
      adv_leave: +values?.adv_leave,
      food: +values?.food,
      pf_per: +values?.pf_per,
      esi_per: +values?.esi_per,
      pt: +values?.pt,
      it: +values?.it,
      lwf: +values?.lwf,
      advance: +values?.advance,
      loan: +values?.loan,
      other: +values?.other,
      apply_from_date: values?.apply_from_date,
    };
    try {
      if (!values?.salary_guid) {
        if (!formdata?.apply_from_date) {
          ErrorToast("Date Field is required!");
          return;
        }
        const response = await dispatch(
          updateEmployeeSalaryStructure(formdata)
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          setFormKey(formKey + 1);
          dispatch(clearSalaryDetails());
          dispatch(getAllSalaryDateByEmployeeID(EmployeeID));
          // const payload = {
          //   employee_id: EmployeeID,
          //   apply_from_date: values?.apply_from_date,
          // };
          // dispatch(getSalaryStructureByDateAndEmpID(payload));
          editModeRef.current = false;
          setEditMode(false);
        }
      } else {
        const response = await dispatch(
          updateEmployeeSalaryStructure({
            ...formdata,
            salary_guid: values?.salary_guid,
            apply_from_date: formdata?.apply_from_date
              ? moment(formdata?.apply_from_date).format("YYYY-MM-DD")
              : null,
          })
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          setFormKey(formKey + 1);
          dispatch(clearSalaryDetails());
          dispatch(getAllSalaryDateByEmployeeID(EmployeeID));
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };
  return (
    <>
      {formloading && <LoadingPanel gridRef={gridRef} />}
      <ShadowCard style={{ padding: 10 }}>
        <Form
          onSubmit={handleSubmit}
          key={dateFormKey}
          initialValues={DateFilter}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <GridLayout
                style={{ marginRight: 30, marginBottom: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[{ width: "10%" }, { width: "5%" }, { width: "85%" }]}
              >
                <DateChangeWatcher formRenderProps={formRenderProps} />
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>{"Salary Structure"}</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  {!editMode && (
                    <Field
                      name="apply_from_date_selector"
                      label="Date"
                      placeholder="--SELECT DATE--"
                      component={FormSelectionField}
                      // validator={requiredValidator}
                      options={SalaryDateList?.map((date: ISalaryDate) => {
                        return {
                          value: date?.apply_from_date,
                          label: date?.apply_from_date,
                        };
                      })}
                    />
                  )}
                  {editMode && (
                    <Field
                      name="new_date"
                      label="Date"
                      component={FormDatePicker}
                      wrapperStyle={{ marginBottom: 5 }}
                      validator={requiredValidator}
                    />
                  )}
                </GridLayoutItem>
                <GridLayoutItem style={{ position: "relative" }}>
                  <Button
                    fillMode="solid"
                    themeColor="primary"
                    type="button"
                    style={{
                      marginLeft: 10,
                      position: "absolute",
                      top: "35px",
                    }}
                    onClick={handleToggel}
                  >
                    {editMode ? "Cancel" : "Add"}
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </FormElement>
          )}
        />
        <Form
          onSubmit={handleSubmit}
          key={formKey}
          initialValues={SalaryDetails}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <SalaryChangeWatcher formRenderProps={formRenderProps} />
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 10, cols: 10 }}
                cols={[{ width: "50%" }, { width: "50%" }]}
              >
                <GridLayoutItem>
                  <GridLayout
                    style={{
                      marginRight: 50,
                    }}
                    gap={{ rows: 0, cols: 10 }}
                    cols={[{ width: "50%" }, { width: "50%" }]}
                  >
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem>
                      <Typography.h5 style={{ textDecoration: "underline" }}>
                        {"Earnings"}
                      </Typography.h5>
                    </GridLayoutItem>
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="basic"
                        label="Basic"
                        component={FormTextField}
                        placeholder="Basic"
                        //   validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem style={{ display: "flex", width: "100%" }}>
                      <Field
                        name="da_per"
                        label="% DA"
                        max={2}
                        type="number"
                        placeholder="i.e. 5"
                        style={{ width: "60%" }}
                        component={FormTextField}
                        //   validator={requiredValidator}
                      />
                      <Field
                        name="da"
                        style={{ width: "100%" }}
                        label="DA"
                        format="n2"
                        type="number"
                        placeholder="DA"
                        component={FormTextField}
                        //   validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem style={{ display: "flex", width: "100%" }}>
                      <Field
                        name="hra_per"
                        label="% HRA"
                        max={2}
                        type="number"
                        placeholder="i.e. 5"
                        style={{ width: "60%" }}
                        component={FormTextField}
                        //   validator={requiredValidator}
                      />
                      <Field
                        name="hra"
                        label="House Rent Allowance"
                        type="number"
                        format="n2"
                        placeholder="House Rent Allowance"
                        component={FormTextField}
                        //   validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="wash"
                        label="Wash"
                        type="number"
                        placeholder="Wash"
                        component={FormTextField}
                        //   validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="adv_bonus"
                        label="Bonus"
                        type="number"
                        placeholder="Bonus"
                        component={FormTextField}
                        //   validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="adv_leave"
                        label="Advance Leave"
                        type="number"
                        placeholder="Leave"
                        component={FormTextField}
                        //   validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="food"
                        label="Food"
                        type="number"
                        placeholder="Food"
                        component={FormTextField}
                        //   validator={requiredValidator}
                      />
                    </GridLayoutItem>
                  </GridLayout>
                </GridLayoutItem>
                <GridLayoutItem>
                  <GridLayout
                    style={{
                      marginRight: 30,
                      borderLeft: "2px solid #000",
                      paddingLeft: 40,
                    }}
                    gap={{ rows: 0, cols: 10 }}
                    cols={[{ width: "50%" }, { width: "50%" }]}
                  >
                    <GridLayoutItem>
                      <Typography.h5 style={{ textDecoration: "underline" }}>
                        {"Deduction"}
                      </Typography.h5>
                    </GridLayoutItem>
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem style={{ display: "flex", width: "100%" }}>
                      <Field
                        name="pf_per"
                        label="% PF"
                        type="number"
                        placeholder="i.e. 5"
                        style={{ width: "60%" }}
                        component={FormTextField}
                        //   validator={requiredValidator}
                      />
                      <Field
                        name="pf"
                        label="PF"
                        type="number"
                        placeholder="PF"
                        component={FormTextField}
                        //   validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem style={{ display: "flex", width: "100%" }}>
                      <Field
                        name="esi_per"
                        label="% ESI"
                        type="number"
                        placeholder="i.e. 5"
                        style={{ width: "60%" }}
                        component={FormTextField}
                        //   validator={requiredValidator}
                      />
                      <Field
                        name="esi"
                        label="ESI"
                        type="number"
                        placeholder="ESI"
                        component={FormTextField}
                        //   validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="pt"
                        label="PT"
                        type="number"
                        placeholder="PT"
                        component={FormTextField}
                        //   validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="it"
                        label="IT"
                        type="number"
                        placeholder="IT"
                        component={FormTextField}
                        //   validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="lwf"
                        label="L.W.F"
                        type="number"
                        placeholder="LWF"
                        component={FormTextField}
                        //   validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="advance"
                        label="Advance"
                        type="number"
                        placeholder="Advance"
                        component={FormTextField}
                        //   validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="loan"
                        label="Loan"
                        type="number"
                        placeholder="Loan"
                        component={FormTextField}
                        //   validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="other"
                        label="Other"
                        type="number"
                        placeholder="Other"
                        component={FormTextField}
                        //   validator={requiredValidator}
                      />
                    </GridLayoutItem>
                  </GridLayout>
                </GridLayoutItem>
                <GridLayoutItem>
                  <GridLayout
                    style={{
                      marginRight: 30,
                    }}
                    gap={{ rows: 0, cols: 0 }}
                    cols={[{ width: "50%" }, { width: "50%" }]}
                  >
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem>
                      {/* <Field
                      name="total_earning"
                      label="Total Earning"
                      placeholder="Total Earning"
                      type="number"
                      disabled
                      component={FormTextField}
                      validator={requiredValidator}
                    /> */}
                      <Typography.h4>
                        Total Earning :
                        {new Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                        }).format(formRenderProps.valueGetter("total_earning"))}
                      </Typography.h4>
                    </GridLayoutItem>
                  </GridLayout>
                </GridLayoutItem>
                <GridLayoutItem>
                  <GridLayout
                    style={{
                      marginRight: 30,
                      paddingLeft: 40,
                    }}
                    gap={{ rows: 0, cols: 10 }}
                    cols={[{ width: "50%" }, { width: "50%" }]}
                  >
                    <GridLayoutItem>
                      {/* <Field
                      name="total_deduction"
                      label="Total Deduction"
                      placeholder="Total Deduction"
                      type="number"
                      disabled
                      component={FormTextField}
                         validator={requiredValidator}
                    /> */}
                      <Typography.h4>
                        Total Deduction :
                        {new Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                        }).format(
                          formRenderProps.valueGetter("total_deduction")
                        )}
                      </Typography.h4>
                    </GridLayoutItem>

                    <GridLayoutItem>
                      <Typography.h4 style={{ color: "green" }}>
                        Total Salary :
                        {formRenderProps.valueGetter("total_salary")}
                      </Typography.h4>
                    </GridLayoutItem>
                  </GridLayout>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ textAlign: "end", marginTop: 20 }}
                  colSpan={2}
                >
                  <Button
                    type="button"
                    fillMode="outline"
                    themeColor="primary"
                    style={{ marginRight: 10 }}
                    onClick={() => dispatch(decreaseActiveStep())}
                  >
                    Previous
                  </Button>

                  <ButtonWithLoading
                    label={SalaryDetails.salary_guid ? "Update" : "Save"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || formloading}
                    loading={formloading}
                  />
                  <Button
                    fillMode="solid"
                    themeColor="primary"
                    style={{ marginLeft: 10 }}
                    onClick={() => navigate("/employee")}
                  >
                    Go to Employee List
                  </Button>
                </GridLayoutItem>
              </GridLayout>
              {/* <DayChangeWatcher formRenderProps={formRenderProps} /> */}
            </FormElement>
          )}
        />
      </ShadowCard>
    </>
  );
};

export default SalaryStructure;
