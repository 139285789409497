import React, { useCallback } from "react";
import {
  Upload as KendoUpload,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Label, Error, Hint } from "@progress/kendo-react-labels";
import { Avatar } from "@progress/kendo-react-layout";

import userAvatar from "../../assets/Images/useravatar.jpg";
import { SiMicrosoftexcel } from "react-icons/si";
import { FaFilePdf, FaRegFileWord } from "react-icons/fa";
import { FcDocument } from "react-icons/fc";
import { CgDanger } from "react-icons/cg";

const FormAttachmentUpload = (fieldRenderProps: FieldRenderProps) => {
  const {
    valid,
    value,
    layout = "horizontal",
    id,
    optional,
    label,
    hint,
    validationMessage,
    touched,
    ...others
  } = fieldRenderProps;
  const imgRef = React.useRef<HTMLImageElement | null>(null);
  const hasImage = value && value.length > 0;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";

  const onChangeHandler = (event: UploadOnAddEvent) => {
    fieldRenderProps.onChange({ value: event.newState });
  };
  const onRemoveHandler = (event: UploadOnRemoveEvent) => {
    fieldRenderProps.onChange({ value: event.newState });
  };

  React.useEffect(() => {
    if (hasImage && imgRef.current) {
      if (Array.isArray(value) && value.length > 0) {
        var reader = new FileReader();

        reader.onload = function (e: ProgressEvent<FileReader>) {
          const target = e.target as FileReader;
          if (target && imgRef.current) {
            imgRef.current.setAttribute("src", target.result as string);
          }
        };

        reader.readAsDataURL(value[0].getRawFile());
      } else if (typeof value === "string") {
        imgRef.current.setAttribute("src", value);
      }
    }
  }, [value, hasImage]);

  const imageFileExtensions = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "tiff",
    "svg",
    "webp",
    "heic",
    "ico",
    "psd",
    "ai",
    "eps",
    "raw",
  ];
  const filename = Array.isArray(value) ? value[0]?.name : value;
  const isImage = imageFileExtensions.includes(
    filename?.split(".").pop()?.toLowerCase() || ""
  );

  const checkIcon = useCallback((filename: string) => {
    const ext = filename?.split(".").pop()?.toLowerCase();
    switch (ext) {
      case "xlsx":
      case "xls":
      case "xlsm":
      case "xltx":
      case "xltm":
      case "csv":
        return (
          <SiMicrosoftexcel
            style={{ fontSize: "50px", color: "green", margin: "auto" }}
          />
        );
      case "docx":
      case "doc":
      case "dotx":
      case "dotm":
      case "rtf":
      case "odt":
        return <FaRegFileWord style={{ fontSize: "50px", color: "blue" }} />;
      case "pdf":
        return (
          <FaFilePdf
            style={{ fontSize: "50px", color: "red", margin: "auto" }}
          />
        );
      default:
        return <FcDocument style={{ fontSize: "50px", margin: "auto" }} />;
    }
  }, []);
  return (
    <FieldWrapper
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        flexDirection: layout === "horizontal" ? "row" : "column",
        width: "100%",
        margin: 0,
      }}
    >
      <Avatar
        style={{
          width: 90,
          height: 90,
          flexBasis: 90,
          backgroundColor: "transparent",
        }}
        rounded={"full"}
        type={"image"}
      >
        {hasImage ? (
          isImage ? (
            <img
              style={{ width: 90, height: 90 }}
              ref={imgRef}
              src={"#"}
              alt={"User Avatar"}
            />
          ) : (
            <div
              style={{
                height: "100%",
              }}
              className="d-flex justify-content-center  align-items-center"
            >
              {checkIcon(filename)}
            </div>
          )
        ) : (
          <img
            style={{ width: 90, height: 90 }}
            src={userAvatar}
            alt="user-avatar"
          />
        )}
      </Avatar>
      <div
        className={"k-form-field-wrap"}
        style={{ width: "100%", maxWidth: "350px" }}
      >
        <Label
          id={labelId}
          editorId={id}
          editorValid={valid}
          optional={optional}
          className="field-label"
          style={{color:"#000"}}
        >
          {label}
        </Label>
        <KendoUpload
          id={id}
          autoUpload={false}
          showActionButtons={false}
          multiple={false}
          files={Array.isArray(value) ? value : []}
          onAdd={onChangeHandler}
          onRemove={onRemoveHandler}
          ariaDescribedBy={`${hintId} ${errorId}`}
          ariaLabelledBy={labelId}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error className="d-flex justify-content-end" id={errorId}>
            <span
              className="d-flex align-items-center"
              style={{
                background: "#DF4957",
                color: "white",
                padding: "1px 8px 1px 5px",
                borderRadius: 10,
                gap: 5,
              }}
            >
              <CgDanger /> {validationMessage}
            </span>
          </Error>
        )}
      </div>
    </FieldWrapper>
  );
};

export default FormAttachmentUpload;
