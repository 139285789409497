import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { FiEdit } from "react-icons/fi";
import AlertBox from "../../components/common/AlertBox";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { MdDelete } from "react-icons/md";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import { checkAcessRights } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
  PRINT_ACCESS,
} from "../../_contstants/common";
import {
  deleteVisitor,
  getAllVisitors,
  updateVisitorOutTime,
} from "./services/visitor.services";
import { clearVisitorID, setVisitorID } from "./visitorSlice";
import moment from "moment";
import { IoPrint } from "react-icons/io5";
import { TimePicker } from "@progress/kendo-react-dateinputs";

const Visitor: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  useEffect(() => {
    return () => {
      dispatch(setCurrentPage(0));
    };
  }, []);

  const handleCreate = () => {
    navigate("/visitor/create");
  };

  return (
    <>
      <DeleteVisitorDialog />
      <EditVisitorOutTimeDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Visitor List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              {"Create Visitor"}
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <VisitorGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const VisitorGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);

  const VisitorList = useAppSelector((state) => state.visitor.VisitorList);
  const loading = useAppSelector((state) => state.visitor.loading);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  useEffect(() => {
    const payload = {
      name: "",
      financial_year: 202425,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(getAllVisitors(payload));
  }, [currentPage, pageLimit]);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (visitor_guid: any) => {
      navigate("/visitor/edit", {
        state: { visitor_guid: visitor_guid },
      });
    };

    const handleOpenDeleteDialog = (visitor_guid: any) => {
      dispatch(openDialog("deleteVisitor"));
      dispatch(setVisitorID(visitor_guid));
    };

    const handlePrint = (visitor_guid: any) => {
      window.open(`/visitorprint/${visitor_guid}`);
    };

    return (
      <td
        className={`action-td ${props?.className}`}
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() => handleEdit(props.dataItem?.visitor_guid)}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem?.visitor_guid)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
        {isPrintAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Print"
            onClick={() => handlePrint(props.dataItem?.visitor_guid)}
          >
            <IoPrint
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 9 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const handleOpenTimeEditDialog = (visitor_guid: any) => {
    dispatch(openDialog("editTimeVisitor"));
    dispatch(setVisitorID(visitor_guid));
    // dispatch(setVisitorOutTime(out_time));
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {VisitorList && VisitorList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={filter ? filterBy(VisitorList, filter) : VisitorList}
          onFilterChange={handleFilterChange}
          total={VisitorList[0]?.itemcount || VisitorList.length}
          skip={currentPage * pageLimit}
          take={pageLimit}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 50, 100, 300, 500],
            pageSizeValue: pageLimit,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="visitor_no"
            title="Visitor No."
            width={100}
            cell={(props: any) => (
              <td>
                <div
                  className={isEditAccess ? "hoverableName" : "normalName"}
                  onClick={() =>
                    isEditAccess &&
                    navigate("/visitor/edit", {
                      state: {
                        visitor_guid: props.dataItem?.visitor_guid,
                      },
                    })
                  }
                >
                  {props.dataItem?.visitor_no}
                </div>
              </td>
            )}
          />
          <Column field="visitor_date" title="Visitor Date" width={200} />
          <Column field="in_time" title="In Time" width={150} />
          <Column
            field="out_time"
            title="Out Time"
            width={150}
            cell={(props: any) => (
              <td>
                {!props.dataItem?.out_time ? (
                  <div>
                    <a
                      style={{
                        color: "red",
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                      onClick={() =>
                        handleOpenTimeEditDialog(props.dataItem?.visitor_guid)
                      }
                    >
                      Set Out Time
                    </a>
                  </div>
                ) : (
                  <div>{props.dataItem?.out_time}</div>
                )}
              </td>
            )}
          />
          <Column field="name" title="Name" width={250} />
          <Column field="mobile_no" title="Mobile No" width={150} />
          <Column field="email_id" title="Email" width={300} />
          <Column field="user_name" title="To Meet Person" width={250} />
          <Column field="reason" title="Reason" width={300} />
          {(isEditAccess || isDeleteAccess || isPrintAccess) && (
            <Column
              field="id"
              title="Actions"
              cell={MyEditCommandCell}
              width={"140px"}
              locked={true}
              filterable={false}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const EditVisitorOutTimeDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const VisitorID = useAppSelector((state) => state.visitor.VisitorID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const [outTime, setOutTime] = React.useState<string | Date>(
    moment().toDate() || ""
  );

  useEffect(() => {
    setOutTime(moment().toDate());
  }, [VisitorID]);

  const handleSubmit = async () => {
    if (VisitorID) {
      const updatePayload = {
        visitor_guid: VisitorID,
        out_time: outTime ? moment(outTime).format("HH:mm:ss") : "",
      };
      const response = await dispatch(updateVisitorOutTime(updatePayload));
      dispatch(setCurrentPage(0));
      const payload = {
        name: "",
        financial_year: 202425,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response.meta.requestStatus === "fulfilled") {
        dispatch(getAllVisitors(payload));
        dispatch(closeDialog());
        dispatch(clearVisitorID());
      } else {
        dispatch(getAllVisitors(payload));
        dispatch(closeDialog());
        dispatch(clearVisitorID());
      }
    }
  };

  return (
    <>
      {dialogName === "editTimeVisitor" && (
        <AppDialog>
          <>
            <form className="k-form" onSubmit={handleSubmit}>
              <fieldset>
                <Typography.h5>{"Edit Visitor Out Time"}</Typography.h5>
                <GridLayout>
                  <GridLayoutItem>
                    <TimePicker
                      name="out_time"
                      label="Out Time"
                      required={true}
                      defaultValue={moment().toDate() || ""}
                      onChange={(e: any) => {
                        setOutTime(e?.target?.value);
                      }}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                      marginTop: 20,
                    }}
                  >
                    <div>
                      <Button
                        type="button"
                        fillMode={"solid"}
                        themeColor={"primary"}
                        onClick={handleSubmit}
                      >
                        Update
                      </Button>
                      <Button
                        type="button"
                        fillMode={"outline"}
                        themeColor={"primary"}
                        style={{ marginLeft: 4 }}
                        onClick={() => {
                          setOutTime("");
                          dispatch(clearVisitorID());
                          dispatch(closeDialog());
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </fieldset>
            </form>
          </>
        </AppDialog>
      )}
    </>
  );
};

const DeleteVisitorDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const VisitorID = useAppSelector((state) => state.visitor.VisitorID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  const handleDeleteAction = async (ID: any) => {
    if (ID) {
      const response = await dispatch(deleteVisitor(ID));
      dispatch(setCurrentPage(0));
      const payload = {
        name: "",
        financial_year: 202425,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response.payload.Data.Status === 200) {
        dispatch(getAllVisitors(payload));
        dispatch(closeDialog());
        dispatch(clearVisitorID());
      } else {
        dispatch(getAllVisitors(payload));
        dispatch(closeDialog());
        dispatch(clearVisitorID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteVisitor" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Visitor"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Visitor?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(VisitorID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default Visitor;
