import React, { useEffect, useRef, useState } from "react";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { MdDelete } from "react-icons/md";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { LoadingPanel } from "../../components/layout/Loading";
import { Switch } from "@progress/kendo-react-inputs";
import AlertBox from "../../components/common/AlertBox";
import IconButton from "../../components/common/IconButton";
// import CreateItemCategory from "./CreateItemCategory";
// import { clearItemCategoryID, setItemCategoryID } from "./itemCategorySlice";
import {
  ItemGroupActiveInactive,
  deleteItemGroup,
  getAllItemGroup,
} from "./services/itemgroup.services";
import { clearItemGroupID, setItemGroupID } from "./itemgroupSlice";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { checkAcessRights } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";

const ItemGroup: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  useEffect(() => {
    dispatch(getAllItemGroup());
    return () => {
      dispatch(closeDialog());
      dispatch(closed());
    };
  }, []);

  const handleItemGroupCreate = () => {
    navigate("/itemgroup/create");
  };

  return (
    <>
      <DeleteStateDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Item Group List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleItemGroupCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              Create Item Group
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <ItemGroupGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const ItemGroupGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);
  const ItemGroupList = useAppSelector(
    (state) => state.itemgroup.ItemGroupList
  );
  const loading = useAppSelector((state) => state.itemgroup.loading);
  const [showFullRemarks, setShowFullRemarks] = useState(false);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? ItemGroupList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };
  const truncateRemarks = (remarks: string) => {
    if (!remarks) return "";
    const maxLength = 100;
    if (remarks.length <= maxLength) return remarks;
    return remarks.substring(0, maxLength) + "...";
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditItem = (item_group_guid: any) => {
      navigate("/itemgroup/edit", {
        state: { item_group_guid: item_group_guid },
      });
    };

    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteitemGroup"));
      dispatch(setItemGroupID(ID));
    };
    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {/* {isEditAccess && (
          <ButtonEdit
            ID={props.dataItem.item_group_guid}
            name="itemGroupForm"
            tooltipTitle="Edit"
          />
        )} */}
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() => handleEditItem(props.dataItem.item_group_guid)}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() =>
              handleOpenDeleteDialog(props.dataItem.item_group_guid)
            }
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
    const [IsActive, setIsActive] = useState<boolean>(props.dataItem.isactive);

    const handleActiveInActive = () => {
      setIsActive(!IsActive);
      const data = {
        item_group_guid: props.dataItem.item_group_guid,
        isactive: !IsActive,
      };
      dispatch(ItemGroupActiveInactive(data)).then((response: any) => {
        if (response.payload?.data?.Status === 200) {
          dispatch(getAllItemGroup());
        } else {
          dispatch(getAllItemGroup());
        }
      });
    };

    return (
      <td style={{ textAlign: "center" }}>
        <Switch
          disabled={isEditAccess ? false : true}
          name="isactive"
          checked={IsActive}
          onChange={handleActiveInActive}
          size="small"
        />
      </td>
    );
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {ItemGroupList && ItemGroupList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(ItemGroupList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : ItemGroupList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={ItemGroupList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="item_group"
            title="Item Group"
            cell={(props: any) => (
              <td>
                <div
                  className={isEditAccess ? "hoverableName" : "normalName"}
                  onClick={() =>
                    isEditAccess &&
                    navigate("/itemgroup/edit", {
                      state: {
                        item_group_guid: props.dataItem?.item_group_guid,
                      },
                    })
                  }
                >
                  {props.dataItem?.item_group}
                </div>
              </td>
            )}
          />
          <Column
            field="remarks"
            title="Remarks"
            cell={(props: any) => (
              <td>
                <div
                  className="remarks-content"
                  dangerouslySetInnerHTML={{
                    __html: showFullRemarks
                      ? props.dataItem?.remarks
                      : truncateRemarks(props.dataItem?.remarks),
                  }}
                />
                {props.dataItem?.remarks.length > 100 && (
                  <span onClick={() => setShowFullRemarks(!showFullRemarks)}>
                    {showFullRemarks ? "Read Less" : "Read More"}
                  </span>
                )}
              </td>
            )}
          />
          <Column
            field="isactive"
            title="Status"
            cell={MyActiveInActtiveCommandCell}
            width={"90px"}
            filterable={false}
            locked={true}
          />
          {(isEditAccess || isDeleteAccess) && (
            <Column
              field="item_group_guid"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteStateDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const ItemGroupID = useAppSelector((state) => state.itemgroup.ItemGroupID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteItemGroup(ID));
      if (response.payload?.Data.Status === 200) {
        dispatch(getAllItemGroup());
        dispatch(closeDialog());
        dispatch(clearItemGroupID());
      } else {
        dispatch(getAllItemGroup());
        dispatch(closeDialog());
        dispatch(clearItemGroupID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteitemGroup" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Item Group?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Item Group?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => {
                    handleDeleteAction(ItemGroupID);
                  }}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default ItemGroup;
