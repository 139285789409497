import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useCallback, useEffect, useRef } from "react";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { LoadingPanel } from "../../components/layout/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createMagnetCleaning,
  getMagnetCleaningById,
  updateMagnetCleaning,
} from "./services/magnetcleaning.services";
import { clearMagnetCleaningDetails } from "./magnetcleaningSlice";
import FormDatePicker from "../../components/formFields/FormDateField";
import moment from "moment";
import { findAllActiveMachine } from "../machine/services/machine.services";
import { IUser } from "../user/userModel";
import { getAllActiveUsers } from "../user/services/user.services";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import { getAllItemIncremental } from "../Item/services/item.services";
import { clearItemList } from "../Item/itemSlice";
import AppDrawer from "../../components/muidrawer/Drawer";
import CreateMachine from "../machine/CreateMachine";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { getLocalStorageItem } from "../../_helper/helper";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const QtyPercentageWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const item_qty = formRenderProps.valueGetter("item_qty");
  const metal_qty = formRenderProps.valueGetter("metal_qty");

  useEffect(() => {
    const metal_percentage = (metal_qty / item_qty) * 100;
    formRenderProps.onChange("metal_qty_percentage", {
      value: `${metal_percentage ? metal_percentage?.toFixed(2) : 0} %`,
    });
  }, [item_qty, metal_qty]);

  return null;
};

const UserChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const UserID = getLocalStorageItem("UserID");
  const magnet_cleaning_guid = location.state?.magnet_cleaning_guid;

  useEffect(() => {
    if (!magnet_cleaning_guid) {
      formRenderProps.onChange("supervisor_by", {
        value: UserID ? +UserID : null,
      });
      formRenderProps.onChange("verify_by", {
        value: UserID ? +UserID : null,
      });
    }
  }, [UserID]);

  return null;
};

const CreateMagnetCleaning: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const magnetcleaningDetail = useAppSelector(
    (state) => state.magnetcleaning.magnetcleaningDetail
  );
  const loading = useAppSelector((state) => state.magnetcleaning.loading);
  const MachineList = useAppSelector((state) => state.machine.MachineList);
  const magnet_cleaning_guid = location.state?.magnet_cleaning_guid;
  const UserList = useAppSelector((state) => state.user.UserList);
  const isDrawerOpen = useAppSelector((state) => state.muidrawer.open);
  const drawerName = useAppSelector((state) => state.muidrawer.name);

  const gridRef = useRef<any>(null);

  useEffect(() => {
    if (magnet_cleaning_guid) {
      const payload = {
        magnet_cleaning_guid: magnet_cleaning_guid,
      };
      dispatch(getMagnetCleaningById(payload));
    }
  }, [magnet_cleaning_guid]);

  useEffect(() => {
    dispatch(findAllActiveMachine());
    dispatch(getAllActiveUsers());
    return () => {
      dispatch(clearMagnetCleaningDetails());
      dispatch(clearItemList());
      dispatch(closed());
    };
  }, []);

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (magnet_cleaning_guid) {
      try {
        const editPayload = {
          magnet_cleaning_guid: magnetcleaningDetail?.magnet_cleaning_guid,
          cleaning_no: values.cleaning_no,
          machine_no: values.machine_no ? +values.machine_no : null,
          item_id: values.item_id,
          metal_qty: values.metal_qty ? +values.metal_qty : 0,
          item_qty: values.item_qty ? +values.item_qty : 0,
          lot_no: values.lot_no ? +values.lot_no : null,
          metal_qty_percentage: values.metal_qty_percentage
            ? +values.metal_qty_percentage?.replace("%", "")
            : null,
          m_c_cleaning: values.m_c_cleaning,
          sieve_condition: values.sieve_condition,
          magnet_cleaning_date: values.magnet_cleaning_date
            ? moment(values.magnet_cleaning_date).format("YYYY-MM-DD")
            : "",
          financial_year: 202425,
          supervisor_by: values.supervisor_by,
          verify_by: values.verify_by,
          remarks: values.remarks,
        };
        const response = await dispatch(updateMagnetCleaning(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
          dispatch(closed());
          dispatch(clearMagnetCleaningDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
      }
    } else {
      try {
        const insertPayload = {
          item_id: values.item_id,
          metal_qty: values.metal_qty ? +values.metal_qty : 0,
          item_qty: values.item_qty ? +values.item_qty : 0,
          lot_no: values.lot_no ? +values.lot_no : null,
          metal_qty_percentage: values.metal_qty_percentage
            ? +values.metal_qty_percentage?.replace("%", "")
            : null,
          m_c_cleaning: values.m_c_cleaning,
          sieve_condition: values.sieve_condition,
          machine_no: values.machine_no ? +values.machine_no : null,
          magnet_cleaning_date: values.magnet_cleaning_date
            ? moment(values.magnet_cleaning_date).format("YYYY-MM-DD")
            : "",
          financial_year: 202425,
          supervisor_by: values.supervisor_by,
          verify_by: values.verify_by,
          remarks: values.remarks,
        };
        const response = await dispatch(createMagnetCleaning(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
          dispatch(closed());
          dispatch(clearMagnetCleaningDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleMagnetCleaningNoChange = (
    ev: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (ev.key === "Enter" || ev.key === "Tab") {
      ev.preventDefault();
      if ((ev.target as HTMLInputElement).value) {
        const payload = {
          cleaning_no: (ev.target as HTMLInputElement).value,
          financial_year: 202425,
        };
        dispatch(getMagnetCleaningById(payload));
      }
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
    navigate("/magnetcleaning");
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {isDrawerOpen && drawerName === "machineForm" && (
        <AppDrawer>
          <CreateMachine />
        </AppDrawer>
      )}
      <Form
        onSubmit={handleSubmit}
        initialValues={magnetcleaningDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {magnet_cleaning_guid
                      ? "Update Magnet Cleaning"
                      : "Create Magnet Cleaning"}
                  </Typography.h4>
                </GridLayoutItem>
                {magnet_cleaning_guid && (
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="w-50 right-alighned-field"
                      name="cleaning_no"
                      onKeyDown={handleMagnetCleaningNoChange}
                      label="Magnet Cleaning No"
                      placeholder="0"
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                )}
                {magnet_cleaning_guid && (
                  <GridLayoutItem colSpan={3}></GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="magnet_cleaning_date"
                    label="Date"
                    format={"dd/MM/yyyy"}
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="machine_no"
                    label="Machine No"
                    placeholder="Machine No"
                    isAddNew={true}
                    isIndirect={true}
                    drawerName="machineForm"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={MachineList?.map((machine: any) => {
                      return {
                        value: machine.id,
                        label: machine.machine_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="item_id"
                    label="Product Name"
                    placeholder="Product Name"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    fetchIncrementalData={(search: any) =>
                      handleItemSearchChange(
                        search,
                        `item_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("item_options")
                        ?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item.product_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ display: "flex", gap: 10 }}>
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="lot_no"
                    label="Lot No"
                    type="number"
                    placeholder="0"
                    component={FormNumericTextField}
                    validator={requiredValidator}
                  />
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="item_qty"
                    label="Item Qty"
                    type="number"
                    placeholder="0"
                    component={FormNumericTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ display: "flex", gap: 10 }}>
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="metal_qty"
                    label="Metal Qty In GM"
                    type="number"
                    placeholder="0"
                    min={0}
                    max={formRenderProps.valueGetter("item_qty")}
                    component={FormNumericTextField}
                    validator={requiredValidator}
                  />
                  <div className="w-100">
                    <Field
                      wrapperClassName="red-highlight-field center-alighned-field d-flex flex-wrap justify-content-center"
                      name="metal_qty_percentage"
                      label="Metal Qty (%)"
                      placeholder="0"
                      disabled={true}
                      component={FormTextField}
                    />
                  </div>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="m_c_cleaning"
                    label="Machine Cleaning"
                    placeholder="Machine Cleaning"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="sieve_condition"
                    label="Sieve Condition"
                    placeholder="Sieve Condition"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="supervisor_by"
                    label="Supervisor By"
                    placeholder="Supervisor By"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={UserList?.map((user: IUser) => {
                      return {
                        value: user?.id,
                        label: `${user?.first_name} ${user?.last_name}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="verify_by"
                    label="Verify By"
                    placeholder="Verify By"
                    component={FormSelectionField}
                    options={UserList?.map((user: IUser) => {
                      return {
                        value: user?.id,
                        label: `${user?.first_name} ${user?.last_name}`,
                      };
                    })}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={!magnet_cleaning_guid ? "Create" : "Update"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={handleFormClose}
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
                <QtyPercentageWatcher formRenderProps={formRenderProps} />
                <UserChangeWatcher formRenderProps={formRenderProps} />
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateMagnetCleaning;
