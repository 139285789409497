import { Field, FormRenderProps } from "@progress/kendo-react-form";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
  GridCellProps,
} from "@progress/kendo-react-grid";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import { Button } from "@progress/kendo-react-buttons";
import FormDatePicker from "../../components/formFields/FormDateField";
import ButtonForAll from "../../components/common/Button";
import { formatIndianNumber } from "../../_helper/helper";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import React from "react";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import IconButton from "../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import moment from "moment";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { MdDelete } from "react-icons/md";
import { openDialog } from "../../components/dialog/dialogSlice";
import { setItemMarginRateID } from "./itemSlice";

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const MarginStructureDetails = ({
  formRenderProps,
  setSubmitAction,
}: {
  formRenderProps: FormRenderProps;
  setSubmitAction: any;
}) => {
  const dispatch = useAppDispatch();
  const ItemDetail = useAppSelector((state) => state.item.ItemDetail);

  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? ItemDetail?.item_margin_structure?.length || event.page.take
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 12 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const MyEditCommandCell = (
    props: GridCellProps,
    formRenderProps: FormRenderProps
  ) => {
    const handleEdit = (item: any) => {
      formRenderProps.onChange("item_margin_structure_id", {
        value: item?.id,
      });
      formRenderProps.onChange("item_group_marginstructure_id", {
        value: item?.item_group_marginstructure_id,
      });
      formRenderProps.onChange("from_date", {
        value: item?.from_date
          ? moment(item?.from_date, "DD/MM/YYYY").toDate()
          : "",
      });
      formRenderProps.onChange("to_date", {
        value: item?.to_date
          ? moment(item?.to_date, "DD/MM/YYYY").toDate()
          : "",
      });
      formRenderProps.onChange("agent_margin", {
        value: item?.agent_margin,
      });
      formRenderProps.onChange("agent_marginvalue1", {
        value: item?.agent_marginvalue1 || "0",
      });
      formRenderProps.onChange("agent_marginvalue2", {
        value: item?.agent_marginvalue2 || "0",
      });
      formRenderProps.onChange("retailer_margin", {
        value: item?.retailer_margin,
      });
      formRenderProps.onChange("retailer_value", {
        value: item?.retailer_value || "0",
      });
      formRenderProps.onChange("conversion_unit", {
        value: item?.conversion_unit,
      });
    };

    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteItemMarginRate"));
      dispatch(setItemMarginRateID(ID));
    };

    return (
      <td
        className={props.className}
        style={{
          ...props.style,
          textAlign: "center",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEdit(props.dataItem)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem?.id)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const handleClearMarginstructure = (formRenderProps: FormRenderProps) => {
    formRenderProps.onChange("item_margin_structure_id", {
      value: null,
    });
    formRenderProps.onChange("item_group_marginstructure_id", {
      value: null,
    });
    formRenderProps.onChange("from_date", {
      value: "",
    });
    formRenderProps.onChange("to_date", {
      value: "",
    });
    formRenderProps.onChange("agent_margin", {
      value: null,
    });
    formRenderProps.onChange("agent_marginvalue1", {
      value: "",
    });
    formRenderProps.onChange("agent_marginvalue2", {
      value: "",
    });
    formRenderProps.onChange("retailer_margin", {
      value: null,
    });
    formRenderProps.onChange("retailer_value", {
      value: "",
    });
    formRenderProps.onChange("conversion_unit", {
      value: 1,
    });
  };

  return (
    <>
      <Card style={{ padding: 10, border: "1px solid goldenrod" }}>
        <GridLayout cols={[{ width: "100%" }]}>
          <GridLayoutItem>
            <Typography.h4>{"Margin Structure"}</Typography.h4>
          </GridLayoutItem>
          <GridLayoutItem
            className="bg-dark text-white fw-600"
            style={{ width: "100%" }}
          >
            <GridLayout
              style={{
                marginRight: 70,
                padding: "10px 0",
              }}
              gap={{ rows: 0, cols: "0.5%" }}
              cols={[
                { width: "13.5%" },
                { width: "13.5%" },
                { width: "13.5%" },
                { width: "10%" },
                { width: "12%" },
                { width: "12%" },
                { width: "10%" },
                { width: "12%" },
              ]}
            >
              <GridLayoutItem className="ps-2">From date</GridLayoutItem>
              <GridLayoutItem className="ps-2">To Date</GridLayoutItem>
              <GridLayoutItem className="ps-2">Conversion Unit</GridLayoutItem>
              <GridLayoutItem className="ps-2" colSpan={3}>
                Agent Margin
              </GridLayoutItem>
              <GridLayoutItem className="ps-2" colSpan={2}>
                Retailer Margin
              </GridLayoutItem>
            </GridLayout>
          </GridLayoutItem>
          <GridLayoutItem>
            <GridLayout
              gap={{ rows: 0, cols: "0.5%" }}
              cols={[
                { width: "13.5%" },
                { width: "13.5%" },
                { width: "13.5%" },
                { width: "10%" },
                { width: "12%" },
                { width: "12%" },
                { width: "10%" },
                { width: "12%" },
              ]}
            >
              <GridLayoutItem>
                <Field
                  name="from_date"
                  format="dd/MM/yyyy"
                  component={FormDatePicker}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="to_date"
                  format="dd/MM/yyyy"
                  component={FormDatePicker}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`conversion_unit`}
                  placeholder="Unit"
                  component={FormSelectionField}
                  options={[
                    { value: 1, label: "KG" },
                    { value: 2, label: "PKT" },
                  ]}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`agent_margin`}
                  placeholder="% / ₹"
                  component={FormSelectionField}
                  options={[
                    { value: 1, label: "%" },
                    { value: 2, label: "₹" },
                  ]}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`agent_marginvalue1`}
                  placeholder="Value 1"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`agent_marginvalue2`}
                  placeholder="Value 2"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`retailer_margin`}
                  placeholder="% / ₹"
                  component={FormSelectionField}
                  options={[
                    { value: 1, label: "%" },
                    { value: 2, label: "₹" },
                  ]}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`retailer_value`}
                  placeholder="Value 1"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem
                colSpan={8}
                style={{ textAlign: "end", marginTop: 20 }}
              >
                <ButtonForAll
                  label={
                    formRenderProps.valueGetter("item_margin_structure_id")
                      ? "Update"
                      : "Add"
                  }
                  disabled={
                    formRenderProps.valueGetter("from_date") &&
                    formRenderProps.valueGetter("to_date") &&
                    formRenderProps.valueGetter("conversion_unit")
                      ? // formRenderProps.valueGetter("agent_margin") &&
                        // formRenderProps.valueGetter("agent_marginvalue1") &&
                        // formRenderProps.valueGetter("agent_marginvalue2") &&
                        // formRenderProps.valueGetter("retailer_margin") &&
                        // formRenderProps.valueGetter("retailer_value")
                        false
                      : true
                  }
                  type="submit"
                  onClick={() => setSubmitAction("marginstructure")}
                  // loading={loading}
                />
                <Button
                  style={{ marginLeft: 4 }}
                  fillMode={"outline"}
                  themeColor={"primary"}
                  type="button"
                  onClick={() => {
                    handleClearMarginstructure(formRenderProps);
                  }}
                >
                  Clear
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </GridLayoutItem>
        </GridLayout>
      </Card>
      {ItemDetail &&
        ItemDetail?.item_margin_structure &&
        ItemDetail?.item_margin_structure?.length > 0 && (
          <>
            <GridLayoutItem
              style={{
                marginTop: 30,
              }}
            >
              <Typography.h4 className="m-0 text-dark">
                {"Margin Structure List"}
              </Typography.h4>
              {/* <ButtonForAll
                label={showMarginRate ? "Hide" : "Add"}
                type="button"
                onClick={() => setShowMarginRate(!showMarginRate)}
              /> */}
            </GridLayoutItem>
            <KendoGrid
              style={{ marginTop: 14 }}
              data={
                ItemDetail?.item_margin_structure
                  ? ItemDetail?.item_margin_structure?.slice(
                      page.skip,
                      page.take + page.skip
                    )
                  : []
              }
              skip={page.skip}
              take={page.take}
              total={ItemDetail?.item_margin_structure?.length}
              pageable={{
                buttonCount: 5,
                pageSizes: [5, 10, 15, "All"],
                pageSizeValue: pageSizeValue,
                type: "input",
              }}
              onPageChange={pageChange}
              cells={{
                headerCell: HeaderCustomCell,
                data: MyDataCustomCell,
              }}
            >
              <Column field="from_date" title="From Date" />
              <Column field="to_date" title="To Date" />
              <Column
                field="conversion_unit"
                title="Conversion Unit"
                cell={(props: any) => (
                  <td>
                    {props.dataItem?.conversion_unit === 1
                      ? "KG"
                      : props.dataItem?.conversion_unit === 2
                      ? "PKT"
                      : ""}
                  </td>
                )}
              />
              <Column
                field="agent_marginvalue1"
                title="Agent Margin value 1"
                cell={(props: any) => (
                  <td className="text-center">
                    {props.dataItem?.agent_marginvalue1
                      ? formatIndianNumber(props.dataItem?.agent_marginvalue1)
                      : 0}{" "}
                    {props.dataItem?.agent_marginvalue1 &&
                      (props.dataItem?.agent_margin === 1 ? "%" : "₹")}
                  </td>
                )}
              />
              <Column
                field="agent_marginvalue2"
                title="Agent Margin Value 2"
                cell={(props: any) => (
                  <td className="text-center">
                    {props.dataItem?.agent_marginvalue2
                      ? formatIndianNumber(props.dataItem?.agent_marginvalue2)
                      : 0}{" "}
                    {props.dataItem?.agent_marginvalue2 &&
                      (props.dataItem?.agent_margin === 1 ? "%" : "₹")}
                  </td>
                )}
              />
              <Column
                field="retailer_value"
                title="Retailer Value"
                cell={(props: any) => (
                  <td className="text-center">
                    {props.dataItem?.retailer_value
                      ? formatIndianNumber(props.dataItem?.retailer_value)
                      : 0}{" "}
                    {props.dataItem?.retailer_value &&
                      (props.dataItem?.retailer_margin === 1 ? "%" : "₹")}
                  </td>
                )}
              />
              <Column
                field="ID"
                title="Actions"
                cell={(props: any) => MyEditCommandCell(props, formRenderProps)}
                width={"110px"}
                filterable={false}
                locked={true}
              />
            </KendoGrid>
          </>
        )}
    </>
  );
};

export default MarginStructureDetails;
