import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { IVisitor, IVisitorInitialState } from "./visitorModel";
import {
  createVisitor,
  deleteVisitor,
  getAllActiveVisitors,
  getAllVisitors,
  getVisitorByID,
  updateVisitor,
  updateVisitorOutTime,
} from "./services/visitor.services";

const initialState: IVisitorInitialState = {
  loading: false,
  error: "",
  VisitorID: null,
  // VisitorOutTime: "",
  VisitorList: [],
  VisitorDetail: {
    visitor_date: moment().toDate(),
    user_id: null,
    name: "",
    mobile_no: "",
    email_id: "",
    id_proof_no: "",
    from_company: "",
    reason: "",
    in_time: moment().toDate(),
    out_time: "",
    financial_year: null,
  },
};

const visitorSlice = createSlice({
  name: "visitor",
  initialState,
  reducers: {
    clearVisitorDetails: (state) => {
      state.VisitorDetail = initialState.VisitorDetail;
    },
    setVisitorID: (state, action) => {
      state.VisitorID = action.payload;
    },
    clearVisitorID: (state) => {
      state.VisitorID = initialState.VisitorID;
    },
    // setVisitorOutTime: (state, action) => {
    //   state.VisitorOutTime = action.payload;
    // },
    // clearVisitorOutTime: (state) => {
    //   state.VisitorOutTime = initialState.VisitorOutTime;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllVisitors.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllVisitors.fulfilled,
      (state, action: PayloadAction<IVisitor[]>) => {
        state.loading = false;
        state.VisitorList = action.payload || [];
      }
    );
    builder.addCase(getAllVisitors.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.VisitorList = [];
    });

    builder.addCase(getAllActiveVisitors.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveVisitors.fulfilled,
      (state, action: PayloadAction<IVisitor[]>) => {
        state.loading = false;
        state.VisitorList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveVisitors.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.VisitorList = [];
    });

    builder.addCase(createVisitor.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createVisitor.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(createVisitor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateVisitor.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateVisitor.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateVisitor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteVisitor.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteVisitor.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteVisitor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getVisitorByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getVisitorByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.VisitorDetail = action.payload;
    });
    builder.addCase(getVisitorByID.rejected, (state, action) => {
      state.loading = false;
      state.VisitorDetail = initialState.VisitorDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateVisitorOutTime.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateVisitorOutTime.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateVisitorOutTime.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearVisitorDetails,
  clearVisitorID,
  setVisitorID,
  // clearVisitorOutTime,
  // setVisitorOutTime,
} = visitorSlice.actions;
export default visitorSlice.reducer;
