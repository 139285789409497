import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IProfessionTaxSlabDetail } from "../professionTaxSlabModel";
import moment from "moment";

// export const getAllAccounts = createAsyncThunk(
//   "Account/FindAllAccount",
//   async () => {
//     try {
//       const response = await axiosApiInstance.post(
//         `${API_URL}/Account/FindAllAccount`,
//         {
//           account_name: "",
//         }
//       );
//       return response.data?.Data;
//     } catch (error: any) {
//       // ErrorToast(error?.response?.data?.Details || "Something went wrong");
//       console.error("Error Fetching Accounts:", error);
//       return Promise.reject({
//         message: error?.response?.data?.Details || "Something went wrong",
//       });
//     }
//   }
// );

export const createProfessionTaxSlab = createAsyncThunk(
  "ProfessionalTaxLabontroller/InsertProfessionTaxSlab",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ProfessionalTaxLabontroller/InsertProfessionTaxSlab`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Profession tax Slab:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateProfessionTaxSlab = createAsyncThunk(
  "/ProfessionalTaxLabontroller/UpdateProfessionTaxSlab",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ProfessionalTaxLabontroller/UpdateProfessionTaxSlab`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Profession Tax Slab:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteProfessionTaxSlab = createAsyncThunk(
  "ProfessionalTaxLabontroller/DeleteProfessionTaxSlab",
  async (GUID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ProfessionalTaxLabontroller/DeleteProfessionTaxSlab`,
        { profession_tax_guid: GUID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Profession Tax Slab:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getProfessionTaxSlabByDate = createAsyncThunk(
  "ProfessionalTaxLabontroller/FindByIDProfessionTaxSlab",
  async (effective_from_date: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ProfessionalTaxLabontroller/FindByIDProfessionTaxSlab`,
        { effective_from_date }
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        profession_tax_guid: result?.profession_tax_guid,
        effective_from_date: result?.effective_from_date
          ? moment(result?.effective_from_date, "YYYY-MM-DD").toDate()
          : "",
        proffestional_tax: result?.proffestional_tax,
      } as IProfessionTaxSlabDetail;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Profession Tax Slab:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveProfessionTaxDate = createAsyncThunk(
  "ProfessionalTaxLabontroller/FindAllActiveprofessiontaxdatelist",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/ProfessionalTaxLabontroller/FindAllActiveprofessiontaxdatelist`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Profession Date:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
