import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useCallback, useEffect, useRef } from "react";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
// import { STATUSARRAY } from "../../_contstants/common";
import { LoadingPanel } from "../../components/layout/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import FormDatePicker from "../../components/formFields/FormDateField";
import moment from "moment";
import { findAllActiveMachine } from "../machine/services/machine.services";
import {
  createMachineCleaning,
  getMachineCleaningById,
  updateMachineCleaning,
} from "./services/machinecleaning.services";
import { clearMachineCleaningDetails } from "./machinecleaningSlice";
import { getAllActiveUsers } from "../user/services/user.services";
import { IUser } from "../user/userModel";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import { getAllItemIncremental } from "../Item/services/item.services";
import { clearItemList } from "../Item/itemSlice";
import AppDrawer from "../../components/muidrawer/Drawer";
import CreateMachine from "../machine/CreateMachine";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { getLocalStorageItem } from "../../_helper/helper";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const UserChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const UserID = getLocalStorageItem("UserID");
  const machine_cleaning_guid = location.state?.machine_cleaning_guid;

  useEffect(() => {
    if (!machine_cleaning_guid) {
      formRenderProps.onChange("qc_by", {
        value: UserID ? +UserID : null,
      });
      formRenderProps.onChange("production_by", {
        value: UserID ? +UserID : null,
      });
    }
  }, [UserID]);

  return null;
};

const CreateMachineCleaning: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const machinecleaningDetail = useAppSelector(
    (state) => state.machinecleaning.machinecleaningDetail
  );
  const loading = useAppSelector((state) => state.machinecleaning.loading);
  const MachineList = useAppSelector((state) => state.machine.MachineList);
  const UserList = useAppSelector((state) => state.user.UserList);
  const isDrawerOpen = useAppSelector((state) => state.muidrawer.open);
  const drawerName = useAppSelector((state) => state.muidrawer.name);

  const machine_cleaning_guid = location.state?.machine_cleaning_guid;
  // const id = location.state?.id;
  const gridRef = useRef<any>(null);

  useEffect(() => {
    if (machine_cleaning_guid) {
      const payload = {
        machine_cleaning_guid: machine_cleaning_guid,
      };
      dispatch(getMachineCleaningById(payload));
    }
  }, [machine_cleaning_guid]);

  useEffect(() => {
    dispatch(getAllActiveUsers());
    dispatch(findAllActiveMachine());
    return () => {
      dispatch(clearMachineCleaningDetails());
      dispatch(clearItemList());
      dispatch(closed());
    };
  }, []);

  
  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (machine_cleaning_guid) {
      try {
        const editPayload = {
          machine_cleaning_guid: machinecleaningDetail?.machine_cleaning_guid,
          cleaning_no: values.cleaning_no,
          machine_no: values.machine_no,
          item_id: values.item_id,
          grinding_lot_no: values.grinding_lot_no
            ? `${values.grinding_lot_no}`
            : "",
          production_qty: values?.production_qty
            ? `${values?.production_qty}`
            : "0",
          machine_cleaning_date: values.machine_cleaning_date
            ? moment(values.machine_cleaning_date).format("YYYY-MM-DD")
            : "",
          maintenance_time: values.maintenance_time
            ? values.maintenance_time
            : "",
          qc_by: values.qc_by ? +values.qc_by : null,
          production_by: values.production_by ? +values.production_by : null,
          financial_year: 202425,
          remarks: values.remarks,
        };
        const response = await dispatch(updateMachineCleaning(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
          dispatch(closed());
          dispatch(clearMachineCleaningDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
      }
    } else {
      try {
        const insertPayload = {
          machine_no: values.machine_no,
          item_id: values.item_id,
          grinding_lot_no: values.grinding_lot_no
            ? `${values.grinding_lot_no}`
            : "",
          production_qty: values?.production_qty
            ? `${values?.production_qty}`
            : "0",
          machine_cleaning_date: values.machine_cleaning_date
            ? moment(values.machine_cleaning_date).format("YYYY-MM-DD")
            : "",
          maintenance_time: values.maintenance_time
            ? values.maintenance_time
            : "",
          qc_by: values.qc_by ? +values.qc_by : null,
          production_by: values.production_by ? +values.production_by : null,
          financial_year: 202425,
          remarks: values.remarks,
        };
        const response = await dispatch(createMachineCleaning(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
          dispatch(closed());
          dispatch(clearMachineCleaningDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleMachineCleaningNoChange = (
    ev: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (ev.key === "Enter" || ev.key === "Tab") {
      ev.preventDefault();
      if ((ev.target as HTMLInputElement).value) {
        const payload = {
          cleaning_no: (ev.target as HTMLInputElement).value,
        };
        dispatch(getMachineCleaningById(payload));
      }
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
    navigate("/machinecleaning");
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {isDrawerOpen && drawerName === "machineForm" && (
        <AppDrawer>
          <CreateMachine />
        </AppDrawer>
      )}
      <Form
        onSubmit={handleSubmit}
        initialValues={machinecleaningDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {machine_cleaning_guid
                      ? "Update Machine Cleaning"
                      : "Create Machine Cleaning"}
                  </Typography.h4>
                </GridLayoutItem>
                {machine_cleaning_guid && (
                  <GridLayoutItem>
                    <Field
                      name="cleaning_no"
                      onKeyDown={handleMachineCleaningNoChange}
                      label="Machine Cleaning No"
                      placeholder="Machine Cleaning No"
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                )}
                {machine_cleaning_guid && (
                  <GridLayoutItem colSpan={3}></GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="machine_cleaning_date"
                    label="Date"
                    format={"dd/MM/yyyy"}
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="item_id"
                    label="Product Name"
                    placeholder="Product Name"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    fetchIncrementalData={(search: any) =>
                      handleItemSearchChange(
                        search,
                        `item_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("item_options")
                        ?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item.product_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="machine_no"
                    label="Machine No"
                    placeholder="Machine No"
                    isAddNew={true}
                    isIndirect={true}
                    drawerName="machineForm"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={MachineList?.map((machine: any) => {
                      return {
                        value: machine.id,
                        label: machine.machine_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="grinding_lot_no"
                    label="Grinding Lot No"
                    placeholder="0"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="production_qty"
                    label="Production Qty"
                    type="number"
                    placeholder="0"
                    component={FormNumericTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="maintenance_time"
                    label="Maintenance Time"
                    placeholder="Maintenance Time"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="qc_by"
                    label="QC By"
                    placeholder="QC By"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={UserList?.map((user: IUser) => {
                      return {
                        value: user?.id,
                        label: `${user?.first_name} ${user?.last_name}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem rowSpan={4} colSpan={2}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="production_by"
                    label="Production By"
                    placeholder="Production By"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={UserList?.map((user: IUser) => {
                      return {
                        value: user?.id,
                        label: `${user?.first_name} ${user?.last_name}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem></GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <ButtonWithLoading
                    label={!machine_cleaning_guid ? "Create" : "Update"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginLeft: 4 }}
                    onClick={handleFormClose}
                  >
                    Cancel
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <UserChangeWatcher formRenderProps={formRenderProps} />
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateMachineCleaning;
