import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import {
  IProductCleaning,
  IProductCleaningInitialState,
} from "./productCleaningModel";
import {
  createProductCleaning,
  deleteProductCleaning,
  getAllActiveProductCleanings,
  getAllProductCleanings,
  getProductCleaningByID,
  updateProductCleaning,
} from "./services/productCleaning.services";

const initialState: IProductCleaningInitialState = {
  loading: false,
  error: "",
  ProductCleaningID: null,
  ProductCleaningList: [],
  ProductCleaningDetail: {
    current_stock: 1000,
    cleaning_date: moment().toDate(),
    cleaning_no: null,
    item_id: null,
    grn_no: null,
    no_of_workers: null,
    machine_id: null,
    start_time: "",
    end_time: "",
    total_qty: null,
    received_qty: null,
    rejection_qty: null,
    cleaning_checker_by: null,
    financial_year: null,
    department_id: null,
    process_id: null,
    remarks: "",
  },
};

const productCleaningSlice = createSlice({
  name: "productCleaning",
  initialState,
  reducers: {
    clearProductCleaningDetails: (state) => {
      state.ProductCleaningDetail = initialState.ProductCleaningDetail;
    },
    setProductCleaningID: (state, action) => {
      state.ProductCleaningID = action.payload;
    },
    clearProductCleaningID: (state) => {
      state.ProductCleaningID = initialState.ProductCleaningID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllProductCleanings.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllProductCleanings.fulfilled,
      (state, action: PayloadAction<IProductCleaning[]>) => {
        state.loading = false;
        state.ProductCleaningList = action.payload || [];
      }
    );
    builder.addCase(getAllProductCleanings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ProductCleaningList = [];
    });
    builder.addCase(getAllActiveProductCleanings.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveProductCleanings.fulfilled,
      (state, action: PayloadAction<IProductCleaning[]>) => {
        state.loading = false;
        state.ProductCleaningList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveProductCleanings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ProductCleaningList = [];
    });
    builder.addCase(createProductCleaning.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createProductCleaning.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(createProductCleaning.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateProductCleaning.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateProductCleaning.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateProductCleaning.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteProductCleaning.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteProductCleaning.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteProductCleaning.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getProductCleaningByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getProductCleaningByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.ProductCleaningDetail = action.payload;
    });
    builder.addCase(getProductCleaningByID.rejected, (state, action) => {
      state.loading = false;
      state.ProductCleaningDetail = initialState.ProductCleaningDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearProductCleaningDetails,
  clearProductCleaningID,
  setProductCleaningID,
} = productCleaningSlice.actions;
export default productCleaningSlice.reducer;
