import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import {
  emailWOValueValidator,
  MobileNoWOValueValidator,
  requiredValidator,
} from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import {
  createPurchaseOrder,
  getPurchaseNoPurchaseOrderByID,
  getPurchaseOrderByID,
  updatePurchaseOrder,
} from "./services/purchaseOrder.services";
import {
  clearPurchaseOrderDetails,
  clearStateForGST,
  setStateForGST,
} from "./purchaseOrderSlice";
import moment from "moment";
import FormDatePicker from "../../components/formFields/FormDateField";
import {
  getAccountByID,
  getAllAccountIncremental,
} from "../account/services/account.services";
import POItemDetailsArray from "./POItemDetailsArray";
import { findAllActiveUnit } from "../unit/services/unit.services";
import { getAllActiveGST } from "../gst/services/gst.services";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { findAllActiveWarehouse } from "../warehouse/services/warehouse.services";
import { findAllActiveItemCategory } from "../Item Category/services/Itemcategory.services";
import { GUJARATIDFORGST } from "../../_contstants/common";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import { clearAccountList } from "../account/accountSlice";
import { getAllItemIncremental } from "../Item/services/item.services";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog from "../../components/dialog/Dialog";
import { formatIndianNumber } from "../../_helper/helper";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const QtychangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const purchase_order_items = formRenderProps.valueGetter(
    "purchase_order_items"
  );
  const taxable_amount = formRenderProps.valueGetter("taxable_amount");
  const gst = formRenderProps.valueGetter("gst");
  const sub_total = formRenderProps.valueGetter("sub_total");
  const round_off = formRenderProps.valueGetter("round_off");
  const stateForGST = useAppSelector(
    (state) => state.purchaseOrder.stateForGST
  );

  useEffect(() => {
    let totalGSTamount = 0;
    let totalGST5 = 0;
    let totalGST12 = 0;
    let totalGST18 = 0;
    let totalGST28 = 0;
    purchase_order_items?.map((item: any, index: number) => {
      const gst_id = item?.gst_id;
      const amount = item?.amount;
      const gstid = gst_id ? parseInt(gst_id.split("--")[1]) : 0;
      if (gstid === 5) {
        totalGST5 += (+amount * +gstid) / 100;
      } else if (gstid === 12) {
        totalGST12 += (+amount * +gstid) / 100;
      } else if (gstid === 18) {
        totalGST18 += (+amount * +gstid) / 100;
      } else if (gstid === 28) {
        totalGST28 += (+amount * +gstid) / 100;
      }
      totalGSTamount += (+amount * +gstid) / 100;
      if (!gst_id) {
        formRenderProps.onChange(`purchase_order_items.${index}.gst_id`, {
          value: null,
        });
      }
    });
    formRenderProps.onChange("gst", {
      value: totalGSTamount || 0,
    });
    if (stateForGST === GUJARATIDFORGST) {
      formRenderProps.onChange("cgst", {
        value: totalGSTamount / 2 || 0,
      });
      formRenderProps.onChange("sgst", {
        value: totalGSTamount / 2 || 0,
      });
    } else {
      formRenderProps.onChange("igst", {
        value: totalGSTamount || 0,
      });
    }
    formRenderProps.onChange("gst5", {
      value: totalGST5 || 0,
    });
    formRenderProps.onChange("gst12", {
      value: totalGST12 || 0,
    });
    formRenderProps.onChange("gst18", {
      value: totalGST18 || 0,
    });
    formRenderProps.onChange("gst28", {
      value: totalGST28 || 0,
    });
  }, [
    stateForGST,
    purchase_order_items?.map((item: any) => item?.gst_id).join("-"),
    purchase_order_items?.map((item: any) => item?.amount).join("-"),
  ]);

  let totalamount = 0;
  let totalQty = 0;

  useEffect(() => {
    if (purchase_order_items && purchase_order_items.length > 0) {
      purchase_order_items?.map((item: any) => {
        totalQty += +item?.qty || 0;
        totalamount += +item?.amount || 0;
      });
    }
    formRenderProps.onChange("totalQty", {
      value: totalQty,
    });
    formRenderProps.onChange("taxable_amount", {
      value: totalamount,
    });
  }, [
    purchase_order_items.map((item: any) => item?.qty).join("-"),
    purchase_order_items.map((item: any) => item?.amount).join("-"),
  ]);

  useEffect(() => {
    if (purchase_order_items && purchase_order_items.length > 0) {
      purchase_order_items?.map((item: any, index: number) => {
        const qty = item?.qty || 0;
        const rate = item?.rate || 0;
        const discount = item?.discount || 0;
        const discount_type = item?.discount_type || "₹";
        if (discount_type && discount_type === "₹") {
          formRenderProps.onChange(`purchase_order_items.${index}.amount`, {
            value: +qty * +rate > +discount ? +qty * +rate - +discount : 0,
          });
        } else if (discount_type && discount_type === "%") {
          let total = +qty * +rate;
          formRenderProps.onChange(`purchase_order_items.${index}.amount`, {
            value:
              +qty * +rate > (+total * +discount) / 100
                ? +qty * +rate - (+total * +discount) / 100
                : 0,
          });
        } else {
          formRenderProps.onChange(`purchase_order_items.${index}.amount`, {
            value: +qty * +rate,
          });
        }
      });
    }
  }, [
    purchase_order_items.map((item: any) => item?.qty).join("-"),
    purchase_order_items.map((item: any) => item?.rate).join("-"),
    purchase_order_items.map((item: any) => item?.discount).join("-"),
    purchase_order_items.map((item: any) => item?.discount_type).join("-"),
  ]);

  useEffect(() => {
    formRenderProps.onChange("sub_total", {
      value: +taxable_amount + +gst || 0,
    });
    formRenderProps.onChange("round_off", {
      value: (Math.round(+sub_total) - +sub_total)?.toFixed(2),
    });
    formRenderProps.onChange("ground_total", {
      value: +sub_total + +round_off || 0,
    });
  }, [taxable_amount, gst, sub_total, round_off]);

  return null;
};

const VendorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const purchase_order_guid = location.state?.purchase_order_guid;
  const vendor_id = formRenderProps.valueGetter("vendor_id");
  const isVendorRef = useRef(true);

  useEffect(() => {
    if (vendor_id) {
      const handleVendorChange = async () => {
        const payload = {
          id: vendor_id,
        };
        const response = await dispatch(getAccountByID(payload));
        if (response.meta.requestStatus === "fulfilled") {
          dispatch(setStateForGST(response.payload?.state_id));
          // if (!isVendorRef.current) {
          formRenderProps.onChange("mobile_no", {
            value: response.payload?.mobile_number,
          });
          formRenderProps.onChange("email_id", {
            value: response.payload?.email,
          });
          // } else {
          //   isVendorRef.current = false;
          // }
          formRenderProps.onChange("state_id", {
            value: response.payload?.state_id,
          });
        } else {
          formRenderProps.onChange("mobile_no", {
            value: "",
          });
          formRenderProps.onChange("email_id", {
            value: "",
          });
          formRenderProps.onChange("state_id", {
            value: null,
          });
        }
      };
      if (!isVendorRef.current) {
        handleVendorChange();
      } else {
        if (!purchase_order_guid) {
          handleVendorChange();
        }
        isVendorRef.current = false;
      }
    } else {
      dispatch(setStateForGST(null));
      formRenderProps.onChange("mobile_no", {
        value: "",
      });
      formRenderProps.onChange("email_id", {
        value: "",
      });
      formRenderProps.onChange("state_id", {
        value: null,
      });
    }
  }, [vendor_id]);

  return null;
};

// const ItemChangeWatcher: React.FC<FormChangeWatcherProps> = ({
//   formRenderProps,
// }) => {
//   const dispatch = useAppDispatch();
//   const location = useLocation();
//   const purchase_order_guid = location.state?.purchase_order_guid;
//   const purchase_order_items = formRenderProps.valueGetter(
//     "purchase_order_items"
//   );
//   const isPOItemRef = useRef(true);

//   useEffect(() => {
//     purchase_order_items &&
//       purchase_order_items.length > 0 &&
//       purchase_order_items?.map((item: any, index: number) => {
//         const item_id = item?.item_id;
//         if (item_id) {
//           const handleItemChange = async () => {
//             const payload = {
//               id: item_id,
//             };
//             const response: any = await dispatch(getItemById(payload));
//             if (response.meta.requestStatus === "fulfilled") {
//               formRenderProps.onChange(`purchase_order_items.${index}.gst_id`, {
//                 value: `${response.payload?.gst_id}--${response.payload?.gst}`,
//               });
//               formRenderProps.onChange(
//                 `purchase_order_items.${index}.unit_id`,
//                 {
//                   value: response.payload?.unit_id,
//                 }
//               );
//               formRenderProps.onChange(
//                 `purchase_order_items.${index}.item_Category_id`,
//                 {
//                   value: response.payload?.item_category_id,
//                 }
//               );
//             }
//           };
//           if (!isPOItemRef.current) {
//             handleItemChange();
//           } else {
//             if (!purchase_order_guid) {
//               handleItemChange();
//             }
//             isPOItemRef.current = false;
//           }
//         } else {
//           formRenderProps.onChange(`purchase_order_items.${index}.gst_id`, {
//             value: null,
//           });
//           formRenderProps.onChange(`purchase_order_items.${index}.unit_id`, {
//             value: null,
//           });
//           formRenderProps.onChange(
//             `purchase_order_items.${index}.item_Category_id`,
//             {
//               value: null,
//             }
//           );
//         }
//       });
//   }, [purchase_order_items?.map((item: any) => item?.item_id).join(",")]);

//   return null;
// };

const CreatePurchaseOrder: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const purchase_order_guid = location.state?.purchase_order_guid;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.purchaseOrder.loading);
  const stateForGST = useAppSelector(
    (state) => state.purchaseOrder.stateForGST
  );
  const PurchaseOrderDetail = useAppSelector(
    (state) => state.purchaseOrder.PurchaseOrderDetail
  );
  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [PurchaseOrderDetail, purchase_order_guid]);

  useEffect(() => {
    if (purchase_order_guid) {
      dispatch(getPurchaseOrderByID(purchase_order_guid));
    }
  }, [purchase_order_guid]);

  useEffect(() => {
    dispatch(findAllActiveUnit());
    dispatch(getAllActiveGST());
    dispatch(findAllActiveWarehouse());
    dispatch(findAllActiveItemCategory());

    return () => {
      dispatch(clearPurchaseOrderDetails());
      dispatch(clearStateForGST());
      dispatch(clearAccountList());
      dispatch(closeDialog());
    };
  }, []);

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (purchase_order_guid) {
      try {
        const updatePayload: any = {
          purchase_order_guid: PurchaseOrderDetail?.purchase_order_guid,
          po_no_string: values?.po_no_string ? values?.po_no_string : "",
          po_date: values?.po_date
            ? moment(values?.po_date).format("YYYY-MM-DD")
            : "",
          delivery_date: values?.delivery_date
            ? moment(values?.delivery_date).format("YYYY-MM-DD")
            : "",
          quotation_no: values?.quotation_no ? values?.quotation_no : "",
          quotation_ref: values?.quotation_ref ? values?.quotation_ref : "",
          vendor_id: values?.vendor_id ? +values?.vendor_id : null,
          mobile_no: values?.mobile_no ? values?.mobile_no : "",
          email_id: values?.email_id ? values?.email_id : "",
          // address1: values?.address1 ? values?.address1 : "",
          // address2: values?.address2 ? values?.address2 : "",
          // pincode: values?.pincode ? values?.pincode.toString() : "",
          // state_id: values?.state_id ? +values?.state_id : null,
          // city_id: values?.city_id ? +values?.city_id : null,
          freight: values?.freight ? values?.freight : "",
          p_and_f_charge: values?.p_and_f_charge ? values?.p_and_f_charge : "",
          payment_terms: values?.payment_terms ? values?.payment_terms : "",
          mode_of_transport: values?.mode_of_transport
            ? values?.mode_of_transport
            : "",
          remarks: values?.remarks ? values?.remarks : "",
          taxable_amount: values?.taxable_amount
            ? +values?.taxable_amount
            : null,
          gst: values?.gst ? +values?.gst : null,
          sub_total: values?.sub_total ? +values?.sub_total : null,
          round_off: values?.round_off ? +values?.round_off : null,
          ground_total: values?.ground_total ? +values?.ground_total : null,
          financial_account_year: values?.financial_account_year
            ? +values?.financial_account_year
            : 202425,
          purchase_order_items: values?.purchase_order_items
            ? values?.purchase_order_items.map((poitems: any) => {
                return {
                  id: poitems?.id ? poitems?.id : 0,
                  purchase_order_id: poitems?.purchase_order_id
                    ? poitems?.purchase_order_id
                    : PurchaseOrderDetail?.po_no
                    ? PurchaseOrderDetail?.po_no
                    : null,
                  item_id: poitems?.item_id ? poitems?.item_id : null,
                  item_Category_id: poitems?.item_Category_id
                    ? poitems?.item_Category_id
                    : null,
                  mrp: poitems?.mrp ? +poitems?.mrp : 0,
                  qty: poitems?.qty ? +poitems?.qty : 0,
                  rate: poitems?.rate ? +poitems?.rate : null,
                  discount: poitems?.discount ? +poitems?.discount : 0,
                  discount_type: poitems?.discount_type === "₹" ? 1 : 2,
                  amount: poitems?.amount ? +poitems?.amount : null,
                  unit_id: poitems?.unit_id ? poitems?.unit_id : null,
                  gst_id: poitems?.gst_id
                    ? parseInt(poitems?.gst_id.split("--")[0])
                    : null,
                  cold_storage_id: poitems?.cold_storage_id
                    ? poitems?.cold_storage_id
                    : null,
                };
              })
            : [],
          isactive: values?.isactive ? values?.isactive : true,
        };

        if (stateForGST === GUJARATIDFORGST) {
          updatePayload.cgst = values?.cgst ? values?.cgst : null;
          updatePayload.sgst = values?.sgst ? values?.sgst : null;
          updatePayload.igst = null;
        } else {
          updatePayload.igst = values?.igst ? values?.igst : null;
          updatePayload.cgst = null;
          updatePayload.sgst = null;
        }

        // if (values?.confirm_total === values?.ground_total) {
        const response = await dispatch(updatePurchaseOrder(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/purchaseorder");
        }
        // } else {
        //   ErrorToast("Please Confirm Total.");
        // }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const insertPayload: any = {
          po_no_string: values?.po_no_string ? values?.po_no_string : "",
          po_date: values?.po_date
            ? moment(values?.po_date).format("YYYY-MM-DD")
            : "",
          delivery_date: values?.delivery_date
            ? moment(values?.delivery_date).format("YYYY-MM-DD")
            : "",
          quotation_no: values?.quotation_no ? values?.quotation_no : "",
          quotation_ref: values?.quotation_ref ? values?.quotation_ref : "",
          vendor_id: values?.vendor_id ? +values?.vendor_id : null,
          mobile_no: values?.mobile_no ? values?.mobile_no : "",
          email_id: values?.email_id ? values?.email_id : "",
          // address1: values?.address1 ? values?.address1 : "",
          // address2: values?.address2 ? values?.address2 : "",
          // pincode: values?.pincode ? values?.pincode.toString() : "",
          // state_id: values?.state_id ? +values?.state_id : null,
          // city_id: values?.city_id ? +values?.city_id : null,
          remarks: values?.remarks ? values?.remarks : "",
          taxable_amount: values?.taxable_amount
            ? +values?.taxable_amount
            : null,
          gst: values?.gst ? +values?.gst : null,
          sub_total: values?.sub_total ? +values?.sub_total : null,
          round_off: values?.round_off ? +values?.round_off : null,
          ground_total: values?.ground_total ? +values?.ground_total : null,
          financial_account_year: values?.financial_account_year
            ? +values?.financial_account_year
            : 202425,
          freight: values?.freight ? values?.freight : "",
          p_and_f_charge: values?.p_and_f_charge ? values?.p_and_f_charge : "",
          payment_terms: values?.payment_terms ? values?.payment_terms : "",
          mode_of_transport: values?.mode_of_transport
            ? values?.mode_of_transport
            : "",
          purchase_order_items: values?.purchase_order_items
            ? values?.purchase_order_items.map((poitems: any) => {
                return {
                  // purchase_order_id: poitems?.purchase_order_id
                  //   ? poitems?.purchase_order_id
                  //   : null,
                  item_id: poitems?.item_id ? poitems?.item_id : null,
                  item_Category_id: poitems?.item_Category_id
                    ? poitems?.item_Category_id
                    : null,
                  mrp: poitems?.mrp ? +poitems?.mrp : 0,
                  qty: poitems?.qty ? +poitems?.qty : 0,
                  rate: poitems?.rate ? +poitems?.rate : null,
                  discount: poitems?.discount ? +poitems?.discount : 0,
                  discount_type: poitems?.discount_type === "%" ? 2 : 1,
                  amount: poitems?.amount ? +poitems?.amount : null,
                  unit_id: poitems?.unit_id ? poitems?.unit_id : null,
                  gst_id: poitems?.gst_id
                    ? parseInt(poitems?.gst_id.split("--")[0])
                    : null,
                  cold_storage_id: poitems?.cold_storage_id
                    ? poitems?.cold_storage_id
                    : null,
                };
              })
            : [],
          isactive: values?.isactive ? values?.isactive : true,
        };
        if (stateForGST === GUJARATIDFORGST) {
          insertPayload.cgst = values?.cgst ? values?.cgst : null;
          insertPayload.sgst = values?.sgst ? values?.sgst : null;
          insertPayload.igst = null;
        } else {
          insertPayload.igst = values?.igst ? values?.igst : null;
          insertPayload.cgst = null;
          insertPayload.sgst = null;
        }

        // if (values?.confirm_total === values?.ground_total) {
        const response = await dispatch(createPurchaseOrder(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
        }
        // } else {
        //   ErrorToast("Please Confirm Total.");
        // }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handlePONoChange = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Enter" || ev.key === "Tab") {
      ev.preventDefault();
      if ((ev.target as HTMLInputElement).value) {
        const payload = {
          po_no: (ev.target as HTMLInputElement).value,
          financial_account_year: 202425,
        };
        dispatch(getPurchaseNoPurchaseOrderByID(payload));
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={PurchaseOrderDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <PurchaseOrderTaxSummary formRenderProps={formRenderProps} />
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 10, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {purchase_order_guid
                      ? "Update Purchase Order"
                      : "Create Purchase Order"}
                  </Typography.h4>
                </GridLayoutItem>
                {purchase_order_guid && (
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      name="po_no"
                      onKeyDown={handlePONoChange}
                      label="Purchase Order No"
                      placeholder="0"
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                )}
                {purchase_order_guid && (
                  <GridLayoutItem colSpan={3}></GridLayoutItem>
                )}

                {/* <GridLayoutItem>
                  <Field
                    name="po_no_string"
                    label="Purchase Order No"
                    placeholder="Purchase Order No"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem> */}
                <GridLayoutItem>
                  <Field
                    name="po_date"
                    label="Purchase Order Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vendor_id"
                    label="Vendor"
                    placeholder="Party Name"
                    // onChange={(e: any) =>
                    //   handleVendorChange(e, formRenderProps)
                    // }
                    isAddNew={true}
                    addNewLink="account/create"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    fetchIncrementalData={(search: any) =>
                      handleVendorSearchChange(
                        search,
                        `vendor_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("vendor_options")
                        ?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item.account_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="mobile_no"
                    type="number"
                    label="Mobile No"
                    placeholder="91********01"
                    max="10"
                    component={FormTextField}
                    validator={MobileNoWOValueValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="email_id"
                    label="Email"
                    placeholder="example@example.com"
                    component={FormTextField}
                    validator={emailWOValueValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="quotation_no"
                    label="Quotation No"
                    placeholder="Quotation No"
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="quotation_ref"
                    label="Quotation Ref."
                    placeholder="Quotation Ref."
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="delivery_date"
                    label="Delivery Date"
                    format="dd/MM/yyyy"
                    minDate={moment(
                      formRenderProps.valueGetter("po_date")
                    ).toDate()}
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ display: "flex", gap: 10 }}>
                  <Field
                    wrapperClassName="w-100"
                    name="freight"
                    label="Freight"
                    placeholder="0"
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                  <Field
                    wrapperClassName="w-100"
                    name="p_and_f_charge"
                    label="P&F Charge"
                    placeholder="0"
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2} rowSpan={2}>
                  <Field
                    wrapperStyle={{ margin: 0 }}
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="payment_terms"
                    label="Payment Terms"
                    placeholder="Payment Terms"
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="mode_of_transport"
                    label="Mode Of Transport"
                    placeholder="Mode Of Transport"
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  style={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "end",
                  }}
                >
                  <a
                    className="tax-summary"
                    onClick={() =>
                      dispatch(openDialog("showPurchaseOrderTaxSummary"))
                    }
                  >
                    Tax Summary
                  </a>
                  <Field
                    wrapperClassName="stock-label center-alighned-field"
                    wrapperStyle={{
                      margin: 0,
                      width: "50%",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                    label="Grand Total"
                    name="ground_total"
                    placeholder="0"
                    disabled={true}
                    format="n2"
                    component={FormNumericTextField}
                  />
                  {/* <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td colSpan={3}>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "60%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Grand Total
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>:</td>
                        <td style={{ width: "10%", textAlign: "center" }}></td>
                        <td style={{ width: "30%" }}>
                        </td>
                      </tr> 
                      <tr>
                        <td
                          style={{
                            width: "60%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Confirm Total
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>:</td>
                        <td style={{ width: "10%", textAlign: "center" }}></td>
                        <td style={{ width: "30%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="confirm_total"
                            placeholder="0.00"
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr> 
                       </table>
                  </div> */}
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <QtychangeWatcher formRenderProps={formRenderProps} />
            <VendorChangeWatcher formRenderProps={formRenderProps} />
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>Item Details</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    handleItemSearchChange={handleItemSearchChange}
                    component={POItemDetailsArray}
                    name="purchase_order_items"
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <ButtonWithLoading
                    label={purchase_order_guid ? "Update" : "Create"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginLeft: 4 }}
                    onClick={() => navigate("/purchaseorder")}
                  >
                    Cancel
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

const PurchaseOrderTaxSummary: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const stateForGST = useAppSelector(
    (state) => state.purchaseOrder.stateForGST
  );
  const purchase_order_guid = location.state?.purchase_order_guid;
  const PurchaseOrderDetail = useAppSelector(
    (state) => state.purchaseOrder.PurchaseOrderDetail
  );
  const isVendorRef = useRef(true);
  const [isIGST, setIsIgst] = useState<boolean>(false);

  const taxable_amount = formRenderProps.valueGetter("taxable_amount");
  const sub_total = formRenderProps.valueGetter("sub_total");
  const round_off = formRenderProps.valueGetter("round_off");
  const ground_total = formRenderProps.valueGetter("ground_total");
  const totalGST5 = formRenderProps.valueGetter("gst5");
  const totalGST12 = formRenderProps.valueGetter("gst12");
  const totalGST18 = formRenderProps.valueGetter("gst18");
  const totalGST28 = formRenderProps.valueGetter("gst28");
  // let isIGST = stateForGST !== GUJARATIDFORGST;

  useEffect(() => {
    if (purchase_order_guid) {
      if (!isVendorRef.current) {
        setIsIgst(stateForGST !== GUJARATIDFORGST);
      } else {
        if (PurchaseOrderDetail?.igst && PurchaseOrderDetail?.igst !== null) {
          setIsIgst(true);
        } else {
          setIsIgst(false);
        }
        isVendorRef.current = false;
      }
    } else {
      setIsIgst(stateForGST !== GUJARATIDFORGST);
    }
  }, [stateForGST]);

  return (
    <>
      {dialogName === "showPurchaseOrderTaxSummary" && (
        <AppDialog className="potaxsummary-dialog">
          <>
            <GridLayout>
              <GridLayoutItem style={{ position: "relative" }}>
                <Typography.h5
                  style={{ background: "red", padding: 10, color: "white" }}
                >
                  {"Tax Summary"}
                </Typography.h5>
                <span
                  style={{ cursor: "pointer" }}
                  className="close-btn"
                  onClick={() => dispatch(closeDialog())}
                >
                  X
                </span>
              </GridLayoutItem>
              <GridLayoutItem>
                <div>
                  <table
                    style={{ width: "100%" }}
                    className="potaxsummary-table"
                  >
                    <tr>
                      <td
                        style={{
                          width: "30%",
                          fontWeight: 600,
                        }}
                      >
                        Total
                      </td>
                      <td style={{ width: "10%", textAlign: "center" }}>:</td>
                      <td style={{ width: "10%", textAlign: "center" }}></td>
                      <td
                        style={{
                          width: "50%",
                          textAlign: "right",
                          paddingRight: 45,
                        }}
                      >
                        {formatIndianNumber(taxable_amount)}
                      </td>
                    </tr>
                    {isIGST && totalGST5 > 0 && (
                      <tr>
                        <td
                          style={{
                            width: "30%",
                            fontWeight: 600,
                          }}
                        >
                          IGST 5%
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>:</td>
                        <td style={{ width: "10%", textAlign: "center" }}>
                          5 %
                        </td>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "right",
                            paddingRight: 45,
                          }}
                        >
                          {formatIndianNumber(totalGST5)}
                        </td>
                      </tr>
                    )}
                    {isIGST && totalGST12 > 0 && (
                      <tr>
                        <td
                          style={{
                            width: "30%",
                            fontWeight: 600,
                          }}
                        >
                          IGST 12%
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>:</td>
                        <td style={{ width: "10%", textAlign: "center" }}>
                          12 %
                        </td>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "right",
                            paddingRight: 45,
                          }}
                        >
                          {formatIndianNumber(totalGST12)}
                        </td>
                      </tr>
                    )}
                    {isIGST && totalGST18 > 0 && (
                      <tr>
                        <td
                          style={{
                            width: "30%",
                            fontWeight: 600,
                          }}
                        >
                          IGST 18%
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>:</td>
                        <td style={{ width: "10%", textAlign: "center" }}>
                          18 %
                        </td>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "right",
                            paddingRight: 45,
                          }}
                        >
                          {formatIndianNumber(totalGST18)}
                        </td>
                      </tr>
                    )}
                    {isIGST && totalGST28 > 0 && (
                      <tr>
                        <td
                          style={{
                            width: "30%",
                            fontWeight: 600,
                          }}
                        >
                          IGST 28%
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>:</td>
                        <td style={{ width: "10%", textAlign: "center" }}>
                          28 %
                        </td>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "right",
                            paddingRight: 45,
                          }}
                        >
                          {formatIndianNumber(totalGST28)}
                        </td>
                      </tr>
                    )}
                    {!isIGST && totalGST5 > 0 && (
                      <>
                        <tr>
                          <td
                            style={{
                              width: "30%",
                              fontWeight: 600,
                            }}
                          >
                            CGST 2.5%
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            2.5 %
                          </td>
                          <td
                            style={{
                              width: "50%",
                              textAlign: "right",
                              paddingRight: 45,
                            }}
                          >
                            {formatIndianNumber(totalGST5 / 2)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: "30%",
                              fontWeight: 600,
                            }}
                          >
                            SGST 2.5%
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            2.5 %
                          </td>
                          <td
                            style={{
                              width: "50%",
                              textAlign: "right",
                              paddingRight: 45,
                            }}
                          >
                            {formatIndianNumber(totalGST5 / 2)}
                          </td>
                        </tr>
                      </>
                    )}
                    {!isIGST && totalGST12 > 0 && (
                      <>
                        <tr>
                          <td
                            style={{
                              width: "30%",
                              fontWeight: 600,
                            }}
                          >
                            CGST 6%
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            6 %
                          </td>
                          <td
                            style={{
                              width: "50%",
                              textAlign: "right",
                              paddingRight: 45,
                            }}
                          >
                            {formatIndianNumber(totalGST12 / 2)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: "30%",
                              fontWeight: 600,
                            }}
                          >
                            SGST 6%
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            6 %
                          </td>
                          <td
                            style={{
                              width: "50%",
                              textAlign: "right",
                              paddingRight: 45,
                            }}
                          >
                            {formatIndianNumber(totalGST12 / 2)}
                          </td>
                        </tr>
                      </>
                    )}
                    {!isIGST && totalGST18 > 0 && (
                      <>
                        <tr>
                          <td
                            style={{
                              width: "30%",
                              fontWeight: 600,
                            }}
                          >
                            CGST 9%
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            9 %
                          </td>
                          <td
                            style={{
                              width: "50%",
                              textAlign: "right",
                              paddingRight: 45,
                            }}
                          >
                            {formatIndianNumber(totalGST18 / 2)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: "30%",
                              fontWeight: 600,
                            }}
                          >
                            SGST 9%
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            9 %
                          </td>
                          <td
                            style={{
                              width: "50%",
                              textAlign: "right",
                              paddingRight: 45,
                            }}
                          >
                            {formatIndianNumber(totalGST18 / 2)}
                          </td>
                        </tr>
                      </>
                    )}
                    {!isIGST && totalGST28 > 0 && (
                      <>
                        <tr>
                          <td
                            style={{
                              width: "30%",
                              fontWeight: 600,
                            }}
                          >
                            CGST 14%
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            14 %
                          </td>
                          <td
                            style={{
                              width: "50%",
                              textAlign: "right",
                              paddingRight: 45,
                            }}
                          >
                            {formatIndianNumber(totalGST28 / 2)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: "30%",
                              fontWeight: 600,
                            }}
                          >
                            SGST 14%
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            14 %
                          </td>
                          <td
                            style={{
                              width: "50%",
                              textAlign: "right",
                              paddingRight: 45,
                            }}
                          >
                            {formatIndianNumber(totalGST28 / 2)}
                          </td>
                        </tr>
                      </>
                    )}
                    <tr>
                      <td
                        style={{
                          fontWeight: 600,
                          width: "30%",
                        }}
                      >
                        Sub Total
                      </td>
                      <td style={{ width: "10%", textAlign: "center" }}>:</td>
                      <td style={{ width: "10%", textAlign: "center" }}></td>
                      <td
                        style={{
                          width: "50%",
                          textAlign: "right",
                          paddingRight: 45,
                        }}
                      >
                        {formatIndianNumber(sub_total)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: 600,
                          width: "30%",
                        }}
                      >
                        Round Off
                      </td>
                      <td style={{ width: "10%", textAlign: "center" }}>:</td>
                      <td style={{ width: "10%", textAlign: "center" }}></td>
                      <td
                        style={{
                          width: "50%",
                          textAlign: "right",
                          paddingRight: 45,
                        }}
                      >
                        {round_off}
                      </td>
                    </tr>
                    <tr
                      style={{
                        // border: "1px solid lightgray",
                        // borderRight: "none",
                        // borderLeft: "none",
                        background: "#e9e6e6",
                        fontSize: 18,
                        fontWeight: 600,
                      }}
                    >
                      <td
                        style={{
                          width: "30%",
                        }}
                      >
                        Grand Total
                      </td>
                      <td style={{ width: "10%", textAlign: "center" }}>:</td>
                      <td style={{ width: "10%", textAlign: "center" }}></td>
                      <td
                        style={{
                          width: "50%",
                          textAlign: "right",
                          paddingRight: 45,
                        }}
                      >
                        {formatIndianNumber(ground_total)}
                      </td>
                    </tr>
                  </table>
                </div>
              </GridLayoutItem>
              {/* <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: 20,
                }}
              >
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => dispatch(closeDialog())}
                  type="button"
                >
                  Cancel
                </Button>
              </GridLayoutItem> */}
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default CreatePurchaseOrder;
