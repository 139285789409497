import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import FormCheckbox from "../../components/formFields/FormCheckbox";
import moment from "moment";
import ButtonForAll from "../../components/common/Button";
import {
  convertToWords,
  formatNumber,
  // getLocalStorageItem,
} from "../../_helper/helper";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  createOrder,
  getAllItemMarginRates,
  getOrderByID,
  updateOrder,
} from "./services/order.services";
import { clearOrderDetails } from "./orderSlice";
import FixSchemeCardArray from "./FixSchemeCardArray";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const SchemeChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const FreeScheme = formRenderProps.valueGetter("free_scheme");
  const CardScheme = formRenderProps.valueGetter("card_scheme");

  useEffect(() => {
    if (FreeScheme) {
      formRenderProps.onChange("card_scheme", {
        value: false,
      });
    }
  }, [FreeScheme]);

  useEffect(() => {
    if (CardScheme) {
      formRenderProps.onChange("free_scheme", {
        value: false,
      });
    }
  }, [CardScheme]);

  return null;
};
const OrderForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const id = location.state?.id;
  // const UserID = getLocalStorageItem("UserID");
  const AccountID = useAppSelector(
    (state) => state.login.loginDetails.AccountID
  );
  const UserTypeID = localStorage.getItem("UserTypeID");
  const StateIDString = useAppSelector(
    (state) => state.login.loginDetails.StateID
  );
  const StateID = parseInt(StateIDString);

  const loading = useAppSelector((state) => state.order.loading);
  const MarginRateList = useAppSelector((state) => state.order.MarginRateList);
  const OrderDetail = useAppSelector((state) => state.order.OrderDetail);

  const [initialValues, setInitialValues] = useState({});
  const [formKey, setFormKey] = useState(1);
  const [showPrint, setShowPrint] = useState(false);
  const [submitAction, setSubmitAction] = useState("value");
  const [printData, setPrintData] = useState([]);
  const [RateData, setRateData] = useState<any[]>(
    JSON.parse(JSON.stringify(MarginRateList))
  );

  useEffect(() => {
    if (UserTypeID) {
      dispatch(getAllItemMarginRates(+UserTypeID));
    }
  }, [UserTypeID]);

  useEffect(() => {
    dispatch(clearOrderDetails());
    setInitialValues({});
  }, [location?.pathname]);

  useEffect(() => {
    if (id) {
      dispatch(getOrderByID(+id));
    }
  }, [id]);

  useEffect(() => {
    const updatedMarginRatelist = JSON.parse(JSON.stringify(MarginRateList));
    const updatedData = updatedMarginRatelist.map((itemGroup: any) => {
      const updatedItems = itemGroup.item_margin_rate.map((item: any) => {
        OrderDetail?.place_order_items?.forEach((printGroup: any) => {
          if (item.item_group_id === printGroup.item_group_id) {
            printGroup.item_margin_rate.forEach((printItem: any) => {
              if (item.item_id === printItem.item_id) {
                item.quantity = +printItem.quantity;
                item.amount = +printItem.amount;
                item.id = +printItem.id;
              }
            });
          }
        });
        return item;
      });
      return {
        ...itemGroup,
        item_margin_rate: updatedItems,
      };
    });

    setRateData(updatedData);
  }, [MarginRateList, OrderDetail]);

  useEffect(() => {
    const newInitialValues =
      OrderDetail &&
      OrderDetail?.place_order_items &&
      OrderDetail?.place_order_items
        ?.map((item: any) => {
          return item?.item_margin_rate?.map((subitem: any) => {
            return {
              [`qty_${item?.item_group_id}_${subitem?.item_id}`]:
                subitem?.quantity,
              [`amount_${item?.item_group_id}_${subitem?.item_id}`]:
                subitem?.amount,
            };
          });
        })
        ?.reduce((acc: any, curr: any) => {
          return {
            ...OrderDetail,
            ...acc,
            ...curr.reduce((subAcc: any, subCurr: any) => {
              return { ...subAcc, ...subCurr };
            }, {}),
          };
        }, {});

    if (JSON.stringify(newInitialValues) !== JSON.stringify(initialValues)) {
      setInitialValues(newInitialValues);
      setFormKey((prevKey) => prevKey + 1);
    }
  }, [OrderDetail]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, []);

  const handleQtyChange = (
    formRenderProps: FormRenderProps,
    mainIndex: number,
    subIndex: number,
    itemGroupId: number,
    itemId: number
  ) => {
    const qty = formRenderProps.valueGetter(`qty_${itemGroupId}_${itemId}`);

    const rate = RateData[mainIndex]?.item_margin_rate[subIndex]?.rate;
    if (qty === 0) {
      formRenderProps.onChange(`qty_${itemGroupId}_${itemId}`, {
        value: null,
      });
    }

    formRenderProps.onChange(`amount_${itemGroupId}_${itemId}`, {
      value: +rate * +qty || null,
    });

    RateData[mainIndex].item_margin_rate[subIndex].amount = +rate * +qty || 0;
    RateData[mainIndex].item_margin_rate[subIndex].quantity = +qty || 0;
  };

  const handlePrevious = () => {
    setShowPrint(!showPrint);
    RateData.forEach((itemGroup) => {
      itemGroup.item_margin_rate.forEach((item: any) => {
        printData.forEach((printGroup: any) => {
          if (item.item_group_id === printGroup.item_group_id) {
            printGroup.item_margin_rate.forEach((printItem: any) => {
              if (item.item_id === printItem.item_id) {
                item.quantity = printItem.quantity;
                item.amount = printItem.amount;
              }
            });
          }
        });
      });
    });
  };

  const handleSubmit = async (values: any) => {
    if (submitAction === "value") {
      const updatedValue: any = RateData?.map((item: any) => ({
        ...item,
        item_margin_rate: item?.item_margin_rate?.filter(
          (subItem: any) => subItem.quantity > 0
        ),
      }))?.filter((item: any) => item?.item_margin_rate?.length > 0);

      setPrintData(updatedValue);
      setShowPrint(!showPrint);
    } else {
      const place_order_items =
        printData &&
        printData.length > 0 &&
        printData?.flatMap(
          (salesorderitem: any) =>
            salesorderitem?.item_margin_rate &&
            salesorderitem?.item_margin_rate.length > 0 &&
            salesorderitem?.item_margin_rate?.map((item: any) => {
              return {
                id: item?.id ? +item?.id : 0,
                item_id: item?.item_id ? +item?.item_id : null,
                item_group_id: item?.item_group_id
                  ? +item?.item_group_id
                  : null,
                mrp: item?.mrp ? +item?.mrp : null,
                quantity: item?.quantity ? +item?.quantity : null,
                unit_id: item?.unit_id ? +item?.unit_id : null,
                rate: item?.rate ? +item?.rate : null,
                gst_id: item?.gst_id ? +item?.gst_id : null,
                amount: item?.amount ? +item?.amount : null,
              };
            })
        );

      if (id) {
        const editPayload: any = {
          sale_order_guid: OrderDetail?.sale_order_guid
            ? OrderDetail?.sale_order_guid
            : "",
          sales_date: OrderDetail?.sales_date
            ? moment(OrderDetail?.sales_date).format("YYYY-MM-DD")
            : "",
          dealer_id: AccountID ? +AccountID : null,
          financial_year: 202425,
          total_amount: Math.round(
            +totalAmount + +totalGST5 + +totalGST12 + +totalGST18 + +totalGST28
          ),
          gst: +(+totalGST5 + +totalGST12 + +totalGST18 + +totalGST28)?.toFixed(
            2
          ),
          free_scheme: values?.free_scheme ? values?.free_scheme : false,
          card_scheme: values?.card_scheme ? values?.card_scheme : false,
          place_order_items: place_order_items || [],
        };
        if (values?.free_scheme) {
          editPayload.free_scheme_offers = values?.scheme
            ? values?.scheme?.map((scheme: any) => {
                return {
                  free_good: scheme?.free_good ? scheme?.free_good : "",
                  article: scheme?.article ? scheme?.article : "",
                };
              })
            : [];
        }
        if (StateID === 1) {
          editPayload.cgst = +(
            (+totalGST5 + +totalGST12 + +totalGST18 + +totalGST28) /
            2
          )?.toFixed(2);
          editPayload.sgst = +(
            (+totalGST5 + +totalGST12 + +totalGST18 + +totalGST28) /
            2
          )?.toFixed(2);
          editPayload.igst = 0.0;
        } else {
          editPayload.igst = +(
            +totalGST5 +
            +totalGST12 +
            +totalGST18 +
            +totalGST28
          )?.toFixed(2);
          editPayload.cgst = null;
          editPayload.sgst = null;
        }
        const response = await dispatch(updateOrder(editPayload));
        if (response.meta.requestStatus === "fulfilled") {
          navigate("/orderhistory");
        }
      } else {
        const insertPayload: any = {
          sales_order_id: 0,
          sales_date: moment().format("YYYY-MM-DD") || "",
          dealer_id: AccountID ? +AccountID : null,
          financial_year: 202425,
          total_amount: Math.round(
            +totalAmount + +totalGST5 + +totalGST12 + +totalGST18 + +totalGST28
          ),
          gst: +(+totalGST5 + +totalGST12 + +totalGST18 + +totalGST28)?.toFixed(
            2
          ),
          free_scheme: values?.free_scheme ? values?.free_scheme : false,
          card_scheme: values?.card_scheme ? values?.card_scheme : false,
          place_order_items: place_order_items || [],
        };
        if (values?.free_scheme) {
          insertPayload.free_scheme_offers = values?.scheme
            ? values?.scheme?.map((scheme: any) => {
                return {
                  free_good: scheme?.free_good ? scheme?.free_good : "",
                  article: scheme?.article ? scheme?.article : "",
                };
              })
            : [];
        }
        if (StateID === 1) {
          insertPayload.cgst = +(
            (+totalGST5 + +totalGST12 + +totalGST18 + +totalGST28) /
            2
          )?.toFixed(2);
          insertPayload.sgst = +(
            (+totalGST5 + +totalGST12 + +totalGST18 + +totalGST28) /
            2
          )?.toFixed(2);
          insertPayload.igst = null;
        } else {
          insertPayload.igst = +(
            +totalGST5 +
            +totalGST12 +
            +totalGST18 +
            +totalGST28
          )?.toFixed(2);
          insertPayload.cgst = null;
          insertPayload.sgst = null;
        }
        const response = await dispatch(createOrder(insertPayload));
        if (response.meta.requestStatus === "fulfilled") {
          navigate("/orderhistory");
        }
      }
    }
  };

  let totalAmount = 0;
  let totalAmountGST5 = 0;
  let totalAmountGST12 = 0;
  let totalAmountGST18 = 0;
  let totalAmountGST28 = 0;
  let totalGST5 = 0;
  let totalGST12 = 0;
  let totalGST18 = 0;
  let totalGST28 = 0;

  printData &&
    printData.length > 0 &&
    printData?.map(
      (salesorderitem: any) =>
        salesorderitem?.item_margin_rate &&
        salesorderitem?.item_margin_rate.length > 0 &&
        salesorderitem?.item_margin_rate?.map((item: any) => {
          totalAmount += item?.amount;
          if (item?.gst === 5) {
            totalGST5 += (item?.amount * item?.gst) / 100;
            totalAmountGST5 += item?.amount;
          }
          if (item?.gst === 12) {
            totalGST12 += (item?.amount * item?.gst) / 100;
            totalAmountGST12 += item?.amount;
          }
          if (item?.gst === 18) {
            totalGST18 += (item?.amount * item?.gst) / 100;
            totalAmountGST18 += item?.amount;
          }
          if (item?.gst === 28) {
            totalGST28 += (item?.amount * item?.gst) / 100;
            totalAmountGST28 += item?.amount;
          }
        })
    );

  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement style={{ overflow: "scroll", scrollbarWidth: "none" }}>
            {!showPrint ? (
              <div
                className="container orderform"
                style={{ padding: 12, minWidth: 800 }}
              >
                <div className="row">
                  <div className="col-12">
                    <Typography.h4>Place Order</Typography.h4>
                  </div>
                  <div className="col-12">
                    <table
                      cellSpacing={1}
                      cellPadding={2}
                      className="table table-bordered w-100  salesorder-print-table"
                      style={{ marginTop: 1 }}
                    >
                      <tr
                        className="text-center fw-600 text-white bg-dark"
                        style={{ fontSize: 12 }}
                      >
                        <th className="pb-3 pt-3" style={{ width: "5%" }}>
                          No.
                        </th>
                        <th style={{ width: "45%" }}>Description Of Goods</th>
                        <th style={{ width: "10%" }}>MRP</th>
                        <th colSpan={2} style={{ width: "20%" }}>
                          Quantity / Per
                        </th>
                        {/* <th style={{ width: "10%" }}>Per</th> */}
                        <th style={{ width: "10%" }}>Rate</th>
                        <th style={{ width: "10%" }}>Amount</th>
                      </tr>
                      {RateData && RateData.length > 0 ? (
                        RateData?.map((item: any, mainIndex: number) => (
                          <tr key={mainIndex}>
                            <td colSpan={7} className="p-0">
                              <div
                                className="accordion p-0 "
                                id="accordionExample"
                              >
                                <div
                                  className="accordion-item"
                                  style={{ borderTop: 0 }}
                                >
                                  <h2 className="accordion-header">
                                    <button
                                      className={`accordion-button ${
                                        mainIndex === 0 ? "" : "collapsed"
                                      }`}
                                      style={{ borderRadius: 0 }}
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#collapse${mainIndex}`}
                                      aria-expanded={
                                        mainIndex === 0 ? "true" : "false"
                                      }
                                      aria-controls={`collapse${mainIndex}`}
                                    >
                                      <div
                                        className="fw-600"
                                        style={{ fontSize: 12 }}
                                      >
                                        {item?.item_group}
                                      </div>
                                    </button>
                                  </h2>
                                  {item?.item_margin_rate &&
                                    item?.item_margin_rate?.length > 0 &&
                                    item?.item_margin_rate?.map(
                                      (subitem: any, subIndex: number) => (
                                        <div
                                          key={subIndex}
                                          id={`collapse${mainIndex}`}
                                          className={`accordion-collapse collapse ${
                                            mainIndex === 0 ? "show" : ""
                                          }`}
                                          data-bs-parent="#accordionExample"
                                        >
                                          <div
                                            className="accordion-body"
                                            style={{ padding: "10px 0" }}
                                          >
                                            <table style={{ width: "100%" }}>
                                              <tr>
                                                <td
                                                  style={{ width: "5%" }}
                                                  className="text-center"
                                                >
                                                  {subIndex + 1}
                                                </td>
                                                <td style={{ width: "45%" }}>
                                                  {subitem?.product_name}
                                                </td>
                                                <td
                                                  style={{
                                                    width: "10%",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Rs. {subitem?.mrp || 0}
                                                </td>
                                                <td style={{ width: "10%" }}>
                                                  <Field
                                                    wrapperClassName="right-alighned-field"
                                                    wrapperStyle={{
                                                      margin: 0,
                                                    }}
                                                    name={`qty_${item?.item_group_id}_${subitem?.item_id}`}
                                                    placeholder="0"
                                                    onChange={() =>
                                                      handleQtyChange(
                                                        formRenderProps,
                                                        mainIndex,
                                                        subIndex,
                                                        item?.item_group_id,
                                                        subitem?.item_id
                                                      )
                                                    }
                                                    format="n2"
                                                    component={
                                                      FormNumericTextField
                                                    }
                                                  />
                                                </td>
                                                <td
                                                  style={{
                                                    width: "10%",
                                                  }}
                                                >
                                                  {subitem?.unit_name}
                                                </td>
                                                <td
                                                  style={{
                                                    width: "10%",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Rs. {subitem?.rate || 0}
                                                </td>
                                                <td
                                                  style={{
                                                    width: "10%",
                                                    padding: "0 5px",
                                                  }}
                                                >
                                                  <Field
                                                    wrapperClassName="right-alighned-field orderform-amount"
                                                    wrapperStyle={{
                                                      margin: 0,
                                                    }}
                                                    name={`amount_${item?.item_group_id}_${subitem?.item_id}`}
                                                    placeholder="0"
                                                    format="n2"
                                                    disabled={true}
                                                    component={
                                                      FormNumericTextField
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                            </table>
                                          </div>
                                        </div>
                                      )
                                    )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7} className="text-center p-2">
                            No Records Found
                          </td>
                        </tr>
                      )}
                    </table>
                  </div>
                  <div
                    className="col-12"
                    style={{
                      textAlign: "end",
                      marginTop: "20px",
                      width: "100%",
                    }}
                  >
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginRight: 4 }}
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                    <ButtonForAll
                      label="Verify"
                      type="submit"
                      onClick={() => setSubmitAction("value")}
                      disabled={
                        !formRenderProps.allowSubmit
                        // || loading
                      }
                      // loading={loading}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="order-container"
                style={{ padding: 12, minWidth: 800 }}
              >
                <div className="row">
                  <div className="col-12">
                    <Typography.h4 className="text-center">
                      Place Order
                    </Typography.h4>
                  </div>
                  <div className="col-12 order-print-page">
                    <table
                      cellSpacing={1}
                      cellPadding={2}
                      className="table table-bordered w-100  order-print-table mb-0"
                      style={{ marginTop: 1 }}
                    >
                      <tr
                        className="text-center fw-600"
                        style={{ fontSize: 12 }}
                      >
                        <th className="order-print-th" style={{ width: "10%" }}>
                          Sr No.
                        </th>
                        <th className="order-print-th" style={{ width: "40%" }}>
                          Description Of Goods
                        </th>
                        <th className="order-print-th" style={{ width: "10%" }}>
                          MRP
                        </th>
                        <th className="order-print-th" style={{ width: "10%" }}>
                          Quantity
                        </th>
                        <th className="order-print-th" style={{ width: "10%" }}>
                          Per
                        </th>
                        <th className="order-print-th" style={{ width: "10%" }}>
                          Rate
                        </th>
                        <th className="order-print-th" style={{ width: "10%" }}>
                          Amount
                        </th>
                      </tr>
                      {printData &&
                        printData?.length > 0 &&
                        printData?.map((item: any) => (
                          <>
                            <tr>
                              <td
                                className="fw-600"
                                colSpan={7}
                                style={{ fontSize: 12 }}
                              >
                                {item?.item_group}
                              </td>
                            </tr>
                            {item?.item_margin_rate &&
                              item?.item_margin_rate?.length > 0 &&
                              item?.item_margin_rate?.map(
                                (subitem: any, index: number) => (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{subitem?.product_name}</td>
                                    <td>Rs. {subitem?.mrp || 0}</td>
                                    <td className="text-end">
                                      {subitem?.quantity}
                                    </td>
                                    <td>{subitem?.unit_name}</td>
                                    <td>Rs. {subitem?.rate || 0}</td>
                                    <td className=" text-end fw-500">
                                      {formatNumber(subitem?.amount)}
                                    </td>
                                  </tr>
                                )
                              )}
                          </>
                        ))}
                      <tr>
                        <td colSpan={6}></td>
                        <td
                          className="text-end fw-600"
                          style={{ fontSize: 14 }}
                        >
                          {formatNumber(totalAmount)}
                        </td>
                      </tr>
                      {StateID === 1 ? (
                        <>
                          {totalGST5 > 0 && (
                            <>
                              <tr>
                                <td></td>
                                <td className=" text-end">CGST 2.5%</td>
                                <td></td>
                                <td></td>
                                <td className=" text-end">2.5</td>
                                <td>%</td>
                                <td className=" text-end fw-500">
                                  {(+totalGST5 / 2).toFixed(2)}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td className=" text-end">SGST 2.5%</td>
                                <td></td>
                                <td></td>
                                <td className=" text-end">2.5</td>
                                <td>%</td>
                                <td className=" text-end fw-500">
                                  {(+totalGST5 / 2).toFixed(2)}
                                </td>
                              </tr>
                            </>
                          )}
                          {totalGST12 > 0 && (
                            <>
                              <tr>
                                <td></td>
                                <td className=" text-end">CGST 6%</td>
                                <td></td>
                                <td></td>
                                <td className=" text-end">6</td>
                                <td>%</td>
                                <td className=" text-end fw-500">
                                  {(+totalGST12 / 2).toFixed(2)}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td className=" text-end">SGST 6%</td>
                                <td></td>
                                <td></td>
                                <td className=" text-end">6</td>
                                <td>%</td>
                                <td className=" text-end fw-500">
                                  {(+totalGST12 / 2).toFixed(2)}
                                </td>
                              </tr>
                            </>
                          )}
                          {totalGST18 > 0 && (
                            <>
                              <tr>
                                <td></td>
                                <td className=" text-end">CGST 9%</td>
                                <td></td>
                                <td></td>
                                <td className=" text-end">9</td>
                                <td>%</td>
                                <td className=" text-end fw-500">
                                  {(+totalGST18 / 2).toFixed(2)}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td className=" text-end">SGST 9%</td>
                                <td></td>
                                <td></td>
                                <td className=" text-end">9</td>
                                <td>%</td>
                                <td className=" text-end fw-500">
                                  {(+totalGST18 / 2).toFixed(2)}
                                </td>
                              </tr>
                            </>
                          )}
                          {totalGST28 > 0 && (
                            <>
                              <tr>
                                <td></td>
                                <td className=" text-end">CGST 14%</td>
                                <td></td>
                                <td></td>
                                <td className=" text-end">14</td>
                                <td>%</td>
                                <td className=" text-end fw-500">
                                  {(+totalGST28 / 2).toFixed(2)}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td className=" text-end">SGST 14%</td>
                                <td></td>
                                <td></td>
                                <td className=" text-end">14</td>
                                <td>%</td>
                                <td className=" text-end fw-500">
                                  {(+totalGST28 / 2).toFixed(2)}
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {totalGST5 > 0 && (
                            <tr>
                              <td></td>
                              <td className=" text-end">IGST 5%</td>
                              <td></td>
                              <td></td>
                              <td className=" text-end">5</td>
                              <td>%</td>
                              <td className=" text-end fw-500">
                                {(+totalGST5).toFixed(2)}
                              </td>
                            </tr>
                          )}
                          {totalGST12 > 0 && (
                            <tr>
                              <td></td>
                              <td className=" text-end">IGST 12%</td>
                              <td></td>
                              <td></td>
                              <td className=" text-end">12</td>
                              <td>%</td>
                              <td className=" text-end fw-500">
                                {(+totalGST12).toFixed(2)}
                              </td>
                            </tr>
                          )}
                          {totalGST18 > 0 && (
                            <tr>
                              <td></td>
                              <td className=" text-end">IGST 18%</td>
                              <td></td>
                              <td></td>
                              <td className=" text-end">18</td>
                              <td>%</td>
                              <td className=" text-end fw-500">
                                {(+totalGST18).toFixed(2)}
                              </td>
                            </tr>
                          )}
                          {totalGST28 > 0 && (
                            <tr>
                              <td></td>
                              <td className=" text-end">IGST 28%</td>
                              <td></td>
                              <td></td>
                              <td className=" text-end">28</td>
                              <td>%</td>
                              <td className=" text-end fw-500">
                                {(+totalGST28).toFixed(2)}
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                      <tr style={{ fontSize: 12 }}>
                        <td
                          colSpan={6}
                          className=" text-end fw-600"
                          style={{ fontSize: 14 }}
                        >
                          {" "}
                          Total Invoice Amount:
                        </td>
                        <td
                          className="text-end fw-600"
                          style={{ fontSize: 14 }}
                        >
                          {formatNumber(
                            Math.round(
                              +totalAmount +
                                +totalGST5 +
                                +totalGST12 +
                                +totalGST18 +
                                +totalGST28
                            )
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <span className="d-block p-0" style={{ fontSize: 8 }}>
                            Amount Chargeable (in words)
                          </span>
                          <span
                            className="d-block fw-600 p-0"
                            style={{ fontSize: 12, textTransform: "uppercase" }}
                          >
                            {convertToWords(
                              Math.round(
                                +totalAmount +
                                  +totalGST5 +
                                  +totalGST12 +
                                  +totalGST18 +
                                  +totalGST28
                              )
                            )}
                          </span>
                        </td>
                        <td className="text-end">E & O.E</td>
                      </tr>
                      {StateID === 1 ? (
                        <>
                          <tr
                            className="text-center fw-600"
                            style={{ fontSize: 13 }}
                          >
                            <th rowSpan={2}>Taxable Value</th>
                            <th colSpan={2}>Central Tax</th>
                            <th colSpan={2}>State Tax</th>
                            <th rowSpan={2} colSpan={2}>
                              Total Tax Amount
                            </th>
                          </tr>
                          <tr className="text-center" style={{ fontSize: 13 }}>
                            <th>Rate</th>
                            <th>Amount</th>
                            <th>Rate</th>
                            <th>Amount</th>
                          </tr>
                        </>
                      ) : (
                        <>
                          <tr className="text-center" style={{ fontSize: 13 }}>
                            <th rowSpan={2}>Taxable Value</th>
                            <th colSpan={4}>Integrated Tax</th>
                            <th rowSpan={2} colSpan={2}>
                              Total Tax Amount
                            </th>
                          </tr>
                          <tr className="text-center" style={{ fontSize: 13 }}>
                            <th>Rate</th>
                            <th colSpan={3}>Amount</th>
                          </tr>
                        </>
                      )}
                      {StateID === 1 ? (
                        <>
                          {totalGST5 > 0 && (
                            <tr className="text-end">
                              <td>{totalAmountGST5}</td>
                              <td>2.5 %</td>
                              <td className=" ">
                                {(+totalGST5 / 2).toFixed(2)}
                              </td>
                              <td>2.5 %</td>
                              <td>{(+totalGST5 / 2).toFixed(2)}</td>
                              <td colSpan={2}>{(+totalGST5).toFixed(2)}</td>
                            </tr>
                          )}
                          {totalGST12 > 0 && (
                            <tr className="text-end">
                              <td>{totalAmountGST12}</td>
                              <td>6 %</td>
                              <td className=" ">
                                {(+totalGST12 / 2).toFixed(2)}
                              </td>
                              <td>6 %</td>
                              <td>{(+totalGST12 / 2).toFixed(2)}</td>
                              <td colSpan={2}>{(+totalGST12).toFixed(2)}</td>
                            </tr>
                          )}
                          {totalGST18 > 0 && (
                            <tr className="text-end">
                              <td>{totalAmountGST18}</td>
                              <td>9 %</td>
                              <td className=" ">
                                {(+totalGST18 / 2).toFixed(2)}
                              </td>
                              <td>9 %</td>
                              <td>{(+totalGST18 / 2).toFixed(2)}</td>
                              <td colSpan={2}>{(+totalGST18).toFixed(2)}</td>
                            </tr>
                          )}
                          {totalGST28 > 0 && (
                            <tr className="text-end">
                              <td>{totalAmountGST28}</td>
                              <td>14 %</td>
                              <td className=" ">
                                {(+totalGST28 / 2).toFixed(2)}
                              </td>
                              <td>14 %</td>
                              <td>{(+totalGST28 / 2).toFixed(2)}</td>
                              <td colSpan={2}>{(+totalGST28).toFixed(2)}</td>
                            </tr>
                          )}
                        </>
                      ) : (
                        <>
                          {totalGST5 && (
                            <tr className="text-end">
                              <td>{totalAmountGST5}</td>
                              <td>5 %</td>
                              <td colSpan={3}>{(+totalGST5).toFixed(2)}</td>
                              <td colSpan={2}>{(+totalGST5).toFixed(2)}</td>
                            </tr>
                          )}
                          {totalGST12 && (
                            <tr className="text-end">
                              <td>{totalAmountGST12}</td>
                              <td>12 %</td>
                              <td colSpan={3}>{(+totalGST12).toFixed(2)}</td>
                              <td colSpan={2}>{(+totalGST12).toFixed(2)}</td>
                            </tr>
                          )}
                          {totalGST18 && (
                            <tr className="text-end">
                              <td>{totalAmountGST18}</td>
                              <td>18 %</td>
                              <td colSpan={3}>{(+totalGST18).toFixed(2)}</td>
                              <td colSpan={2}>{(+totalGST18).toFixed(2)}</td>
                            </tr>
                          )}
                          {totalGST28 && (
                            <tr className="text-end">
                              <td>{totalAmountGST28}</td>
                              <td>28 %</td>
                              <td colSpan={3}>{(+totalGST28).toFixed(2)}</td>
                              <td colSpan={2}>{(+totalGST28).toFixed(2)}</td>
                            </tr>
                          )}
                        </>
                      )}
                      {StateID === 1 ? (
                        <tr
                          className="text-end fw-600"
                          style={{ fontSize: 13 }}
                        >
                          <td>{formatNumber(totalAmount)}</td>
                          <td></td>
                          <td>
                            {formatNumber(
                              +(
                                (+totalGST5 +
                                  +totalGST12 +
                                  +totalGST18 +
                                  +totalGST28) /
                                2
                              )?.toFixed(2)
                            )}
                          </td>
                          <td></td>
                          <td>
                            {formatNumber(
                              +(
                                (+totalGST5 +
                                  +totalGST12 +
                                  +totalGST18 +
                                  +totalGST28) /
                                2
                              )?.toFixed(2)
                            )}
                          </td>
                          <td colSpan={2}>
                            {formatNumber(
                              +(
                                +totalGST5 +
                                +totalGST12 +
                                +totalGST18 +
                                +totalGST28
                              )?.toFixed(2)
                            )}
                          </td>
                        </tr>
                      ) : (
                        <tr
                          className="text-end fw-600"
                          style={{ fontSize: 13 }}
                        >
                          <td>{formatNumber(totalAmount)}</td>
                          <td></td>
                          <td colSpan={3}>
                            {formatNumber(
                              +(
                                +totalGST5 +
                                +totalGST12 +
                                +totalGST18 +
                                +totalGST28
                              )?.toFixed(2)
                            )}
                          </td>
                          <td colSpan={2}>
                            {formatNumber(
                              +(
                                +totalGST5 +
                                +totalGST12 +
                                +totalGST18 +
                                +totalGST28
                              )?.toFixed(2)
                            )}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td colSpan={7} style={{ textAlign: "end" }}>
                          <span className="d-block p-0" style={{ fontSize: 8 }}>
                            Tax Amount (in words)
                          </span>
                          <span
                            className="d-block fw-600 p-0"
                            style={{ fontSize: 12, textTransform: "uppercase" }}
                          >
                            {convertToWords(
                              +(
                                +totalGST5 +
                                +totalGST12 +
                                +totalGST18 +
                                +totalGST28
                              )?.toFixed(2)
                            )}
                          </span>
                        </td>
                      </tr>
                    </table>
                    <table
                      cellSpacing={1}
                      cellPadding={2}
                      className="table table-bordered w-100  order-print-table mb-0"
                      style={{ marginTop: 1 }}
                    >
                      <tr>
                        <td
                          colSpan={
                            formRenderProps.valueGetter("free_scheme") ? 3 : 2
                          }
                        >
                          <Field
                            wrapperClassName="m-0 d-flex justify-content-center fw-600"
                            name="free_scheme"
                            label="Fix/ Free Scheme"
                            maxLength="100"
                            component={FormCheckbox}
                            // onChange={() => handleIsMenuCheck(formRenderProps)}
                          />
                        </td>
                        <td colSpan={2}>
                          <Field
                            wrapperClassName="m-0 d-flex justify-content-center fw-600"
                            name="card_scheme"
                            label="Card Scheme"
                            maxLength="100"
                            component={FormCheckbox}
                            // onChange={() => handleIsMenuCheck(formRenderProps)}
                          />
                        </td>
                      </tr>
                      {formRenderProps.valueGetter("free_scheme") && (
                        <FieldArray
                          formRenderProps={formRenderProps}
                          component={FixSchemeCardArray}
                          name="scheme"
                        />
                      )}
                    </table>
                  </div>
                  <div
                    className="col-12"
                    style={{
                      textAlign: "end",
                      marginTop: "20px",
                      width: "100%",
                    }}
                  >
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginRight: 4 }}
                      onClick={() =>
                        // setShowPrint(!showPrint)
                        handlePrevious()
                      }
                    >
                      Cancel
                    </Button>
                    <ButtonWithLoading
                      label="Place Order"
                      type="submit"
                      onClick={() => setSubmitAction("submit")}
                      loading={submitAction === "submit" && loading}
                      disabled={submitAction === "submit" && loading}
                    />
                  </div>
                </div>
              </div>
            )}
            <SchemeChangeWatcher formRenderProps={formRenderProps} />
          </FormElement>
        )}
      />
    </>
  );
};

export default OrderForm;
