import React, { useEffect, useRef } from "react";
import WEBBANNER from "../../assets/Images/webbanner.jpg";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { tokenCheck } from "./services/dashboard.services";
import { LoadingPanel } from "../../components/layout/Loading";

const AccountDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const loading = useAppSelector((state) => state.dashboard.loading);

  useEffect(() => {
    dispatch(tokenCheck());
  }, []);

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <div className="position-relative">
      <img src={WEBBANNER} alt="" style={{ width: "100%" }} />
      <div className="account-dashboard">
        <h2 className="account-dashboard-label">
          Welcome to the Shree Ganesh Masala
        </h2>
      </div>
    </div>
  );
};

export default AccountDashboard;
