import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import FormDatePicker from "../../components/formFields/FormDateField";
import { getAllAccountIncremental } from "../account/services/account.services";
import {
  getAllItemIncremental,
} from "../Item/services/item.services";
import { findAllActiveUnit } from "../unit/services/unit.services";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import {
  createInwardPackingMaterial,
  getInwardPackingMaterialByID,
  updateInwardPackingMaterial,
} from "./services/inwardPackingMaterial.services";
import { clearInwardPackingDetails } from "./inwardPackingMaterialSlice";
import InwardPackingMaterialArray from "./InwardPackingMaterialArray";
import moment from "moment";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import { clearAccountList } from "../account/accountSlice";

const CreateInwardPackingMaterial: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const inward_packing_material_guid =
    location.state?.inward_packing_material_guid;
  const gridRef = useRef<any>(null);

  const loading = useAppSelector(
    (state) => state.inwardPackingMaterial.loading
  );
  const InwardPackingDetail = useAppSelector(
    (state) => state.inwardPackingMaterial.InwardPackingDetail
  );
  const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [InwardPackingDetail]);

  useEffect(() => {
    if (inward_packing_material_guid) {
      const payload = {
        inward_packing_material_guid: inward_packing_material_guid,
      };
      dispatch(getInwardPackingMaterialByID(payload));
    }
  }, [inward_packing_material_guid]);

  useEffect(() => {
    dispatch(findAllActiveUnit());

    return () => {
      dispatch(clearInwardPackingDetails());
      dispatch(clearAccountList());
    };
  }, []);

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (inward_packing_material_guid) {
      try {
        const updatePayload = {
          inward_packing_material_guid:
            InwardPackingDetail?.inward_packing_material_guid,
          vendor_id: values?.vendor_id ? values?.vendor_id : null,
          inward_packing_material_date: values?.inward_packing_material_date
            ? moment(values?.inward_packing_material_date).format("YYYY-MM-DD")
            : "",
          remarks: values?.remarks ? values?.remarks : "",
          financial_year: 202425,
          InwardPackingMaterialDetails: values?.InwardPackingMaterialDetails
            ? values?.InwardPackingMaterialDetails?.map((inwarditem: any) => {
                return {
                  id: inwarditem?.id ? +inwarditem?.id : 0,
                  item_id: inwarditem?.item_id ? +inwarditem?.item_id : null,
                  inward_item_qty: inwarditem?.inward_item_qty
                    ? +inwarditem?.inward_item_qty
                    : 0,
                  unit_id: inwarditem?.unit_id ? +inwarditem?.unit_id : null,
                  roll: inwarditem?.roll ? +inwarditem?.roll : null,
                };
              })
            : [],
        };
        const response = await dispatch(
          updateInwardPackingMaterial(updatePayload)
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/inwardpackingmaterial");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const insertPayload = {
          vendor_id: values?.vendor_id ? values?.vendor_id : null,
          inward_packing_material_date: values?.inward_packing_material_date
            ? moment(values?.inward_packing_material_date).format("YYYY-MM-DD")
            : "",
          remarks: values?.remarks ? values?.remarks : "",
          financial_year: 202425,
          InwardPackingMaterialDetails: values?.InwardPackingMaterialDetails
            ? values?.InwardPackingMaterialDetails?.map((inwarditem: any) => {
                return {
                  item_id: inwarditem?.item_id ? +inwarditem?.item_id : null,
                  inward_item_qty: inwarditem?.inward_item_qty
                    ? +inwarditem?.inward_item_qty
                    : 0,
                  unit_id: inwarditem?.unit_id ? +inwarditem?.unit_id : null,
                  roll: inwarditem?.roll ? +inwarditem?.roll : null,
                };
              })
            : [],
        };
        const response = await dispatch(
          createInwardPackingMaterial(insertPayload)
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleInwardPackingNoChange = (
    ev: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (ev.key === "Enter" || ev.key === "Tab") {
      ev.preventDefault();
      if ((ev.target as HTMLInputElement).value) {
        const payload = {
          inward_packing_no: (ev.target as HTMLInputElement).value,
        };
        dispatch(getInwardPackingMaterialByID(payload));
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={InwardPackingDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {inward_packing_material_guid
                      ? "Update Inward Packing Material"
                      : "Create Inward Packing Material"}
                  </Typography.h4>
                </GridLayoutItem>
                {inward_packing_material_guid && (
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      name="inward_packing_no"
                      onKeyDown={handleInwardPackingNoChange}
                      label="Inward Packing No"
                      placeholder="Inward Packing No"
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                )}
                {inward_packing_material_guid && (
                  <GridLayoutItem colSpan={3}></GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="inward_packing_material_date"
                    label="Inward Packing Material Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vendor_id"
                    label="Party Name"
                    placeholder="Party Name"
                    isAddNew={true}
                    addNewLink="account/create"
                    component={FormIncrementalSearch}
                    fetchIncrementalData={(search: any) =>
                      handleVendorSearchChange(
                        search,
                        `vendor_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("vendor_options")
                        ?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item.account_name,
                          };
                        }) || []
                    }
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}></GridLayoutItem>
                <GridLayoutItem colSpan={2} rowSpan={3}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                gap={{ rows: 0, cols: 10 }}
                cols={[{ width: "100%" }]}
              >
                <GridLayoutItem>
                  <Typography.h4>Item Details</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem style={{ borderBottom: "1px solid lightgray" }}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    handleItemSearchChange={handleItemSearchChange}
                    component={InwardPackingMaterialArray}
                    name="InwardPackingMaterialDetails"
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 15,
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={inward_packing_material_guid ? "Update" : "Create"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={() => navigate("/inwardpackingmaterial")}
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateInwardPackingMaterial;
