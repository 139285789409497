import React, { useEffect, useRef } from "react";
import {
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useLocation } from "react-router-dom";
import { LoadingPanel } from "../../../components/layout/Loading";
import {
  clearEmployeeAttachments,
  decreaseActiveStep,
  increaseActiveStep,
} from "../employeeSlice";
import { closeDialog } from "../../../components/dialog/dialogSlice";
import ShadowCard from "../../../components/common/ShadowCard";
import DocumentAttachmentsArray from "./DocumentAttachmentsArray";
import { Button } from "@progress/kendo-react-buttons";
import { getAllActiveDocuments } from "../../document/services/document.services";
import { getDocumentAttachmentByUserID } from "../services/employee.services";
interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const DocumentAttachmentsChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const EmployeeAttachments = useAppSelector(
    (state) => state.employee?.EmployeeAttachments
  );
  useEffect(() => {
    const EmployeeAttachmentsCopy = JSON.parse(
      JSON.stringify(EmployeeAttachments)
    );
    formRenderProps.onChange("EmployeeAttachments", {
      value: EmployeeAttachmentsCopy || [],
    });
  }, [EmployeeAttachments]);
  return null;
};

const DocumentAttachments: React.FC = () => {
  const location = useLocation();
  const EmployeeID = location.state?.EmployeeID;
  // console.log("EmployeeID", EmployeeID);
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const formloading = useAppSelector((state) => state.employee.formloading);
  const EmployeeAttachments = useAppSelector(
    (state) => state.employee?.EmployeeAttachments
  );

  useEffect(() => {
    dispatch(getAllActiveDocuments());
    return () => {
      dispatch(closeDialog());
      dispatch(clearEmployeeAttachments());
    };
  }, []);

  const handleSubmit = () => {
    dispatch(increaseActiveStep());
  };

  useEffect(() => {
    if (EmployeeID) {
      dispatch(getDocumentAttachmentByUserID(EmployeeID));
    }
  }, [EmployeeID]);

  if (formloading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={EmployeeAttachments}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <DocumentAttachmentsChangeWatcher
              formRenderProps={formRenderProps}
            />
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem style={{ marginTop: 10 }} colSpan={3}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={DocumentAttachmentsArray}
                    name="EmployeeAttachments"
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <Button
                    type="button"
                    fillMode="outline"
                    themeColor="primary"
                    style={{ marginRight: 4 }}
                    onClick={() => dispatch(decreaseActiveStep())}
                  >
                    Previous
                  </Button>
                  <ButtonWithLoading
                    label={"Next"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || formloading}
                    loading={formloading}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default DocumentAttachments;
