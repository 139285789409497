import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import {
  clearProductionReportList,
  setProductionReport,
} from "../reportsSlice";
import { IItem } from "../../Item/itemModel";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import { setCurrentPage, setPageLimit } from "../../pagination/paginationSlice";
import { getProductionReport } from "../services/reports.services";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import IconButton from "../../../components/common/IconButton";
import { IoPrint } from "react-icons/io5";
import Logo from "../../../assets/Images/logo.png";
import { getProductionByID } from "../../production/services/production.services";
import { checkAcessRights } from "../../../_helper/helper";
import { PRINT_ACCESS } from "../../../_contstants/common";
import { clearItemList } from "../../Item/itemSlice";
import FormIncrementalSearch from "../../../components/formFields/FormIncrementalSearch";
import { getAllItemIncremental } from "../../Item/services/item.services";

const ProductionReportForm: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const itemLoading = useAppSelector((state) => state.item.loading);
  const ItemList = useAppSelector((state) => state.item.ItemList);
  const ProductionReportList = useAppSelector(
    (state) => state.reports.ProductionReportList
  );
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  useEffect(() => {
    return () => {
      dispatch(clearProductionReportList());
      dispatch(setCurrentPage(0));
      dispatch(clearItemList());
    };
  }, []);

  const handleSubmit = (values: any) => {
    const payload = {
      item_id: values?.item_id ? values?.item_id : null,
      from_date: values?.from_date
        ? moment(values?.from_date).format("YYYY-MM-DD")
        : null,
      to_date: values?.to_date
        ? moment(values?.to_date).format("YYYY-MM-DD")
        : null,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(setProductionReport(payload));
    dispatch(getProductionReport(payload));
  };
  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 10 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[{ width: "40%" }, { width: "60%" }]}
                >
                  <GridLayoutItem colSpan={2}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Production Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem style={{ display: "flex", gap: 10 }}>
                    <Field
                      wrapperStyle={{ width: "100%" }}
                      name="from_date"
                      label="From Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                    />
                    <Field
                      wrapperStyle={{ width: "100%" }}
                      name="to_date"
                      label="To Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem></GridLayoutItem>
                  <GridLayoutItem
                    style={{ display: "flex", alignItems: "end", gap: 10 }}
                  >
                    <Field
                      wrapperClassName="w-100"
                      name="item_id"
                      label="Product Name"
                      // component={FormSelectionField}
                      // options={ItemList?.map((item: IItem) => {
                      //   return {
                      //     value: item?.id,
                      //     label: item?.product_name,
                      //   };
                      // })}
                      component={FormIncrementalSearch}
                      fetchIncrementalData={(search: any) =>
                        dispatch(getAllItemIncremental({ search }))
                      }
                      loading={itemLoading}
                      options={ItemList?.map((item: IItem) => {
                        return {
                          value: item?.id,
                          label: item?.product_name,
                        };
                      })}
                    />
                    <div style={{ marginBottom: 1 }}>
                      <ButtonWithLoading
                        label={"View"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          ProductionReportList &&
          ProductionReportList.length > 0 && (
            <ShadowCard style={{ marginTop: 10 }}>
              <ProductionGridCommponent />
            </ShadowCard>
          )
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};

const ProductionGridCommponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);

  const ProductionReportList = useAppSelector(
    (state) => state.reports.ProductionReportList
  );
  const ProductionReportDetails = useAppSelector(
    (state) => state.reports.ProductionReportDetails
  );
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const isProductionRef = React.useRef(true);

  useEffect(() => {
    if (!isProductionRef.current) {
      const payload = {
        item_id: ProductionReportDetails?.item_id
          ? ProductionReportDetails?.item_id
          : null,
        from_date: ProductionReportDetails?.from_date
          ? ProductionReportDetails?.from_date
          : "",
        to_date: ProductionReportDetails?.to_date
          ? ProductionReportDetails?.to_date
          : "",
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      dispatch(getProductionReport(payload));
    } else {
      isProductionRef.current = false;
    }
  }, [currentPage, pageLimit]);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const printTable = async (Production_guid: any) => {
    const response = await dispatch(getProductionByID(Production_guid));
    if (response.meta.requestStatus === "fulfilled") {
      const ProductionDetail = response.payload;
      let printbuttons = document.querySelector(
        ".printbuttons"
      ) as HTMLElement | null;
      let report_container = document.querySelector(
        ".report-container"
      ) as HTMLElement | null;

      if (printbuttons) printbuttons.style.display = "none";
      if (report_container) report_container.style.padding = "20px 0";
      const data =
        ProductionDetail?.ProductionItemDetails &&
        ProductionDetail?.ProductionItemDetails.length > 0
          ? ProductionDetail?.ProductionItemDetails
          : [];

      let tableRows = ProductionDetail?.ProductionItemDetails.length;
      let maxRows = tableRows;
      let pageCount = Math.ceil(tableRows / maxRows);
      let content = "";

      for (let i = 0; i < pageCount; i++) {
        content += '<div class="print-page">';
        //   content += `<div class="page-header">Page ${i + 1}</div>`;
        content +=
          '<table class="table table-bordered" style="font-size:10px;margin-bottom:10px;">';
        content += "<tr>";
        content += `<td rowSpan="4" style="width:15%" ><img src="${Logo}" width="100%" /></td>`;
        content +=
          '<td colSpan="4" style="text-align: center;font-size: 14px;font-weight: 600;color: gray;">Harihar Foods Pvt Ltd.</td>';
        content += "</tr>";
        content += "<tr>";
        content += "<td>Document Name:</td>";
        content += '<td colSpan="3">Production Report</td>';
        content += "</tr>";
        content += "<tr>";
        content += "<td>Document Nnumber:</td>";
        content += "<td>PRD/F/03:</td>";
        content += "<td>Page</td>";
        content += `<td>${i + 1}</td>`;
        content += "</tr>";
        content += "<tr>";
        content += "<td>Date Of Issue:</td>";
        content += "<td>01/01/2020</td>";
        content += "<td>Version</td>";
        content += "<td>2.00</td>";
        content += "</tr>";

        content += "</table>";
        content += '<table style="width: 100%; padding: 10px 30px 20px;">';
        content += "<tr>";
        content += `<td style="width: 50%;"><b>Product Name:</b> <u> ${ProductionDetail?.product_name} </u></td>`;
        content += `<td><b>Date:</b> <u> ${
          ProductionDetail?.production_date
            ? moment(ProductionDetail?.production_date).format("DD/MM/YYYY")
            : ""
        } </u></td>`;
        content += "</tr>";
        content += "<tr>";
        content += `<td><b>Lot No.:</b>  <u> ${ProductionDetail?.lot_no} </u></td>`;
        content += "<td></td>";
        content += "</tr>";
        content += "</table>";

        content +=
          '<table class="table table-bordered" style="font-size:10px;">';
        content += '<thead style="background:lightgray;">';
        content += "<tr>";
        content += '<th style="width:12%;">Sr. No.</th>';
        content += '<th style="width:40%;">Particular</th>';
        content += "<th>Lot No./ GRN</th>";
        content += '<th style="width: 28%;">Quantity of Kg</th>';
        content += "</tr>";
        content += "</thead>";
        content += "<tbody>";

        let startRow = i * maxRows;
        let endRow = Math.min((i + 1) * maxRows, tableRows);

        for (let j = startRow; j < endRow; j++) {
          content += "<tr>";
          content += `<td style="text-align:center;">${j + 1}</td>`;
          content += `<td>${data[j].product_name}</td>`;
          content += `<td style="text-align:center;">${data[j].grn_no}</td>`;
          content += `<td style="text-align:center;">${data[j].used_qty}</td>`;
          content += "</tr>";
        }
        for (let j = tableRows + 1; j <= 22; j++) {
          content += "<tr>";
          content += `<td style="text-align:center;">${j}</td>`;
          content += `<td></td>`;
          content += `<td></td>`;
          content += `<td></td>`;
          content += "</tr>";
        }

        let totalQty = 0;
        for (let j = startRow; j < endRow; j++) {
          totalQty += data[j]?.used_qty || 0;
        }

        content += "<tr>";
        content += `<td colSpan="3" style="text-align:end;">Total Quantity (Before Gringing) in Kg</td>`;
        content += `<td style="text-align:center;">${totalQty}</td>`;
        content += "</tr>";
        content += "<tr>";
        content += `<td colSpan="2">(Old Material Add) Lot No: - ${
          ProductionDetail?.old_material_lot_no !== null
            ? ProductionDetail?.old_material_lot_no
            : ""
        }</td>`;
        content += `<td style="text-align:end;">Quantity: -</td>`;
        content += `<td style="text-align:center;">${ProductionDetail.quantity}</td>`;
        content += "</tr>";
        content += "<tr>";
        content += `<td colSpan="3" style="text-align:end;">Total Quantity Kg</td>`;
        content += `<td style="text-align:center;">${ProductionDetail.total_qty}</td>`;
        content += "</tr>";
        content += "<tr>";
        content += "<tr>";
        content += `<td colSpan="3" style="font-weight:bold;">Total Quantity (After Grinding) Received in Kg</td>`;
        content += `<td style="text-align:center;">${ProductionDetail.received_qty}</td>`;
        content += "</tr>";
        content += "<tr>";
        content += "<tr>";
        content += `<td colSpan="3" style="text-align:end;">Wastage / Loss Total in Kg.</td>`;
        content += `<td style="text-align:center;">${ProductionDetail.loss_qty}</td>`;
        content += "</tr>";
        content += "<tr>";

        content += "</tbody>";
        content += "</table>";

        content +=
          '<table class="table table-bordered" style="font-size:10px;margin-top:30px;text-align:left">';
        content += "<tr >";
        content += '<th style="width:26%;">No. of Worker</th>';
        content += '<th style="width:26%;">Starting Time</th>';
        content += '<th style="width:18%;">Ending Time</th>';
        content += '<th style="width:30%;">Roasting Duration</th>';
        content += "</tr>";
        content += "<tr>";
        content += `<td>${ProductionDetail?.no_of_workers}</td>`;
        content += `<td>${
          ProductionDetail?.start_time
            ? moment(ProductionDetail?.start_time).format("hh:mm A")
            : ""
        }</td>`;
        content += `<td>${
          ProductionDetail?.end_time
            ? moment(ProductionDetail?.end_time).format("hh:mm A")
            : ""
        }</td>`;
        content += `<td>${ProductionDetail?.roasting_duration}</td>`;
        content += "</tr>";
        content += "<tr>";
        content += `<td colSpan="2" style="font-weight:bold;">Production Supervisor Name & Sign</td>`;
        content += `<td colSpan="2">${ProductionDetail?.supervisor_name}</td>`;
        content += "</tr>";
        content += "</table>";

        content +=
          '<table class="table table-bordered" style="font-size:11px; width:100%; margin:40px 0; text-align:center">';
        content += "<tr>";
        content += `<td>Prepared By: </br>Mr. GAURANG MEHTA</td>`;
        content += `<td>Document Status: </br>CONTROL COPY</td>`;
        content += "<td>Approved By: </br>Mr. PARAG SHAH</td>";
        content += "</tr>";
        content += "</table>";

        content += "</div>";
      }

      // Create a new window for the print preview
      let printWindow = window.open("", "_blank");
      if (!printWindow) {
        console.error("Failed to open print preview window.");
        return;
      }
      printWindow.document.write(`
        <html>
        <head>
          <title>Print Preview</title>
          <style>
            @page { margin: 1cm; }
            .print-page { page-break-after: always; }
            .table-bordered { width: 100%; border-collapse: collapse; }
            .table-bordered th, .table-bordered td { border: 1px solid black; padding: 5px 8px; }
            body { font-family: Arial, sans-serif; }
          </style>
        </head>
        <body>
          ${content}
          <script>
            window.onload = function() {
              window.print();
            };
          </script>
        </body>
        </html>
      `);
      printWindow.document.close();

      if (printbuttons) printbuttons.style.display = "block";
      if (report_container) report_container.style.padding = "20px 10px";
    }
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    return (
      <td
        className={`${props?.className}`}
        style={{
          ...props.style,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Print"
          onClick={() => printTable(props.dataItem?.Production_guid)}
        >
          <IoPrint className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
      </td>
    );
  };

  return (
    <>
      {ProductionReportList && ProductionReportList.length ? (
        <GridLayout gap={{ rows: 10, cols: 10 }} cols={[{ width: "100%" }]}>
          <GridLayoutItem className="d-flex justify-content-between align-items-center">
            <Typography.h4 className="m-0">
              Production List{" "}
              {(ProductionReportDetails?.from_date ||
                ProductionReportDetails?.to_date) && (
                <span style={{ fontSize: 13 }}>
                  {"("}
                  {ProductionReportDetails?.from_date
                    ? moment(
                        ProductionReportDetails?.from_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {ProductionReportDetails?.from_date &&
                    ProductionReportDetails?.to_date &&
                    " - "}
                  {ProductionReportDetails?.to_date
                    ? moment(
                        ProductionReportDetails?.to_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {")"}
                </span>
              )}
            </Typography.h4>
            {/* <Button
              style={{ marginRight: 5 }}
              type="button"
              fillMode={"solid"}
              themeColor={"primary"}
              //   onClick={() => printTable(ProductionReportList)}
            >
              Print
            </Button> */}
          </GridLayoutItem>
          <GridLayoutItem>
            <Grid
              className="table-bordered"
              style={{ maxHeight: "calc(100vh - 152px)" }}
              filterable={true}
              filter={filter}
              data={
                filter
                  ? filterBy(ProductionReportList, filter)
                  : ProductionReportList
              }
              onFilterChange={handleFilterChange}
              total={
                ProductionReportList[0]?.itemcount ||
                ProductionReportList.length
              }
              skip={currentPage * pageLimit}
              take={pageLimit}
              pageable={{
                buttonCount: 5,
                pageSizes: [10, 50, 100, 300, 500],
                pageSizeValue: pageLimit,
                type: "input",
              }}
              onPageChange={pageChange}
              cells={{
                headerCell: HeaderCustomCell,
                data: MyDataCustomCell,
              }}
            >
              <GridColumn field="UniqueNo" title="Sr. No." width={80} />
              <GridColumn
                field="start_date"
                title="Date"
                cell={(props) => (
                  <td>
                    {props.dataItem?.start_date
                      ? moment(props.dataItem?.start_date, "DD/MM/YYYY").format(
                          "DD MMM YYYY"
                        )
                      : ""}
                    {props.dataItem?.start_date &&
                      props.dataItem?.end_date &&
                      " - "}
                    <p>
                      {props.dataItem?.end_date
                        ? moment(props.dataItem?.end_date, "DD/MM/YYYY").format(
                            "DD MMM YYYY"
                          )
                        : ""}
                    </p>
                  </td>
                )}
              />
              <GridColumn field="product_name" title="Product Name" />
              <GridColumn field="lot_no" title="Lot No" />
              <GridColumn field="total_qty" title="Total Qty" />
              <GridColumn field="loss_qty" title="Loss Qty" />
              <GridColumn
                field="loss_percentage"
                title="Loss Qty (%)"
                cell={(props) => <td>{props.dataItem?.loss_percentage} %</td>}
              />
              <GridColumn field="supervisor_name" title="Supervisor Name" />
              {isPrintAccess && (
                <GridColumn
                  field="ID"
                  title="Actions"
                  cell={MyEditCommandCell}
                  width={"90px"}
                  filterable={false}
                />
              )}
            </Grid>
          </GridLayoutItem>
        </GridLayout>
      ) : (
        <AlertBox />
      )}
    </>
  );
};
export default ProductionReportForm;
