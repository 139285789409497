import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IRetailerSalesOrder,
  IRetailerSalesOrderInitialState,
} from "./retailerSalesOrderModel";
import {
  createRetailerSalesOrder,
  deleteRetailerSalesOrder,
  getAllRetailerSalesOrders,
  getRetailerSalesOrderByID,
  updateRetailerSalesOrder,
} from "./services/retailerSalesOrder.services";

const initialState: IRetailerSalesOrderInitialState = {
  loading: false,
  error: "",
  RetailerSalesOrderID: null,
  RetailerSalesOrderList: [],
  MarginRateList: [],
  RetailerSalesOrderDetail: {
    accountid: null,
    date: "",
    open_stock_list: [],
  },
};

const retailerSalesOrderSlice = createSlice({
  name: "retailerSalesOrder",
  initialState,
  reducers: {
    clearRetailerSalesOrderDetails: (state) => {
      state.RetailerSalesOrderDetail = initialState.RetailerSalesOrderDetail;
    },
    setRetailerSalesOrderID: (state, action) => {
      state.RetailerSalesOrderID = action.payload;
    },
    clearRetailerSalesOrderID: (state) => {
      state.RetailerSalesOrderID = initialState.RetailerSalesOrderID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRetailerSalesOrders.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllRetailerSalesOrders.fulfilled,
      (state, action: PayloadAction<IRetailerSalesOrder[]>) => {
        state.loading = false;
        state.RetailerSalesOrderList = action.payload || [];
      }
    );
    builder.addCase(getAllRetailerSalesOrders.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RetailerSalesOrderList = [];
    });

    builder.addCase(createRetailerSalesOrder.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createRetailerSalesOrder.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createRetailerSalesOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateRetailerSalesOrder.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateRetailerSalesOrder.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateRetailerSalesOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteRetailerSalesOrder.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteRetailerSalesOrder.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteRetailerSalesOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getRetailerSalesOrderByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getRetailerSalesOrderByID.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.RetailerSalesOrderDetail = action.payload;
      }
    );
    builder.addCase(getRetailerSalesOrderByID.rejected, (state, action) => {
      state.loading = false;
      state.RetailerSalesOrderDetail = initialState.RetailerSalesOrderDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearRetailerSalesOrderDetails,
  clearRetailerSalesOrderID,
  setRetailerSalesOrderID,
} = retailerSalesOrderSlice.actions;
export default retailerSalesOrderSlice.reducer;
