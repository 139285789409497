import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";

export const tokenCheck = createAsyncThunk("UserLogin/TokenCheck", async () => {
  try {
    const response = await axiosApiInstance.get(
      `${API_URL}/UserLogin/TokenCheck`
    );  
    return response.data?.Data;
  } catch (error: any) {
    console.error("Error Token Check:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});
