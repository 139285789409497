import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { FiEdit } from "react-icons/fi";
import { FaEye } from "react-icons/fa";
import AlertBox from "../../components/common/AlertBox";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { MdDelete } from "react-icons/md";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import {
  deleteInward,
  getAllInwards,
  getInwardByID,
} from "./services/inward.services";
import { clearInwardDetails, clearInwardID, setInwardID } from "./inwardSlice";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import { checkAcessRights, formatIndianNumber } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import { clearItemList } from "../Item/itemSlice";

const Inward: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  const handleCreate = () => {
    navigate("/inward/create");
  };

  useEffect(() => {
    return () => {
      dispatch(clearItemList());
      dispatch(clearInwardDetails());
      dispatch(setCurrentPage(0));
    };
  }, []);

  return (
    <>
      <DeleteInwardDialog />
      <InwardChildItemDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Inward List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              {"Create Inward"}
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <InwardOrderGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const InwardOrderGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const InwardList = useAppSelector((state) => state.inward.InwardList);
  const loading = useAppSelector((state) => state.inward.loading);
  const { currentPage, pageLimit } = useAppSelector(
    (state) => state.pagination
  );

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  useEffect(() => {
    const payload = {
      account_name: "",
      vehicle_no: "",
      chalan_no: "",
      financial_year: 202425,
      page_no: +currentPage + 1,
      page_size: +pageLimit,
    };
    dispatch(getAllInwards(payload));
  }, [currentPage, pageLimit]);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (inward_guid: any) => {
      navigate("/inward/edit", {
        state: { inward_guid: inward_guid },
      });
    };
    const handleOpenDeleteDialog = (inward_guid: any) => {
      dispatch(openDialog("deleteInward"));
      dispatch(setInwardID(inward_guid));
    };
    const handleItemView = (inward_guid: any) => {
      dispatch(openDialog("inwardItemView"));
      dispatch(setInwardID(inward_guid));
    };

    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() => handleEdit(props.dataItem?.inward_guid)}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem?.inward_guid)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="View"
          onClick={() => handleItemView(props.dataItem?.inward_guid)}
        >
          <FaEye className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {InwardList && InwardList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={filter ? filterBy(InwardList, filter) : InwardList}
          onFilterChange={handleFilterChange}
          total={InwardList[0]?.total_item_count || InwardList.length}
          skip={currentPage * pageLimit}
          take={pageLimit}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 50, 100, 300, 500],
            pageSizeValue: pageLimit,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="inward_no"
            title="Inward No"
            filterable={false}
            cell={(props: any) => (
              <td>
                <div
                  className={isEditAccess ? "hoverableName" : "normalName"}
                  onClick={() =>
                    isEditAccess &&
                    navigate("/inward/edit", {
                      state: {
                        inward_guid: props.dataItem?.inward_guid,
                      },
                    })
                  }
                >
                  {props.dataItem?.inward_no}
                </div>
              </td>
            )}
          />
          <Column field="inward_date" title="Inward Date" />
          <Column
            field="po_no"
            title="PO No"
            cell={(props: any) => (
              <td className="text-end">{props.dataItem?.po_no}</td>
            )}
          />
          <Column field="account_name" title="Vendor" />
          <Column field="chalan_no" title="Chalan No" />
          <Column field="bill_no" title="Bill No" />
          <Column field="bill_date" title="Bill Date" />
          <Column field="vehicle_no" title="Vehicle No" />
          {(isEditAccess || isDeleteAccess) && (
            <Column
              field="id"
              title="Actions"
              cell={MyEditCommandCell}
              width={"140px"}
              filterable={false}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteInwardDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const InwardID = useAppSelector((state) => state.inward.InwardID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteInward(ID));
      dispatch(setCurrentPage(0));
      const payload = {
        account_name: "",
        vehicle_no: "",
        chalan_no: "",
        financial_year: 202425,
        page_no: +currentPage + 1,
        page_size: +pageLimit,
      };
      if (response.payload.Data.Status === 200) {
        dispatch(getAllInwards(payload));
        dispatch(closeDialog());
        dispatch(clearInwardID());
      } else {
        dispatch(getAllInwards(payload));
        dispatch(closeDialog());
        dispatch(clearInwardID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteInward" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Inward"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Inward?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(InwardID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

const InwardChildItemDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const InwardID = useAppSelector((state) => state.inward.InwardID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const InwardDetail = useAppSelector((state) => state.inward.InwardDetail);
  const loading = useAppSelector((state) => state.inward.loading);

  useEffect(() => {
    if (InwardID) {
      const payload = {
        inward_guid: InwardID,
      };
      dispatch(getInwardByID(payload));
    }
  }, [InwardID]);

  useEffect(() => {
    return () => {
      dispatch(clearInwardDetails());
    };
  }, []);

  let totalDagina = 0;
  let totalAmount = 0;

  InwardDetail &&
    InwardDetail?.inward_items &&
    InwardDetail?.inward_items.length > 0 &&
    InwardDetail?.inward_items?.forEach((item: any) => {
      totalDagina += item?.dagina || 0;
      totalAmount += item?.total || 0;
    });

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {dialogName === "inwardItemView" && (
        <AppDialog>
          <>
            <Typography.h5>{"Inward Items"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                {InwardDetail &&
                InwardDetail?.inward_items &&
                InwardDetail?.inward_items.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{ background: "black", color: "white" }}>
                            No
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Item
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Weight (Qty)
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Rate
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Unit
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Dagina
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Total
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Warehouse
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Warehouse Receipt No
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {InwardDetail?.inward_items?.map(
                          (item: any, index: number) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item?.item_name}</td>
                              <td>{item?.weight}</td>
                              <td className="text-end">{item?.rate}</td>
                              <td>{item?.unit_name}</td>
                              <td className="text-end">{item?.dagina}</td>
                              <td className="text-end">
                                {item?.total
                                  ? formatIndianNumber(item?.total)
                                  : ""}
                              </td>
                              <td>{item?.warehouse_name}</td>
                              <td>{item?.receipt_no}</td>
                            </tr>
                          )
                        )}
                        <tr className="fw-bold">
                          <td
                            colSpan={5}
                            className="text-end pe-5"
                            style={{
                              background: "lightgray",
                              color: "black",
                            }}
                          >
                            Total
                          </td>
                          <td
                            style={{
                              textAlign: "end",
                              background: "lightgray",
                              color: "black",
                            }}
                          >
                            {totalDagina}
                          </td>
                          <td
                            style={{
                              textAlign: "end",
                              background: "lightgray",
                              color: "black",
                            }}
                          >
                            {formatIndianNumber(Math.round(totalAmount))}
                          </td>
                          <td
                            style={{
                              background: "lightgray",
                              color: "black",
                            }}
                          ></td>
                          <td
                            style={{
                              background: "lightgray",
                              color: "black",
                            }}
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <AlertBox style={{ marginBottom: 10 }} />
                )}
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default Inward;
