import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AccountActiveInactive,
  createAccount,
  deleteAccount,
  getAccountByID,
  getAllAccountIncremental,
  getAllAccounts,
  getAllActiveAccounts,
  getAllActiveAccountsUnderGroup,
  updateAccount,
} from "./services/account.services";
import { IAccountInitialState, IAccountUnderGroup } from "./accountModel";

const initialState: IAccountInitialState = {
  formLoading: false,
  loading: false,
  error: "",
  AccountList: [],
  AccountUnderGroupList: [],
  AccountID: null,
  AccountDetail: {
    user_type_id: 1,
    under_group_id: null,
    user_name: "",
    password: "",
    account_name: "",
    short_alias_name: "",
    contact_person_name: "",
    email: "",
    mobile_number: "",
    address1: "",
    address2: "",
    pincode: null,
    country_id: null,
    state_id: null,
    city_id: null,
    payment_due_days: null,
    provide_bank_details: false,
    account_no: "",
    ifsc_code: "",
    bank_name: "",
    enable_credit_limit: false,
    limit_set_based_on_no_of_invoice: null,
    openingbalance_credit_debit: "credit",
    limit_set_based_on_outstanding_amount: null,
    opening_balance: "0",
    pan_no: "",
    gst_no: "",
    isactive: 1,
  },
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    clearAccountDetails: (state) => {
      state.AccountDetail = initialState.AccountDetail;
    },
    setAccountID: (state, action) => {
      state.AccountID = action.payload;
    },
    clearAccountID: (state) => {
      state.AccountID = initialState.AccountID;
    },
    clearAccountList: (state) => {
      state.AccountList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAccounts.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllAccounts.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.AccountList = action.payload || [];
      }
    );
    builder.addCase(getAllAccounts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.AccountList = [];
    });
    builder.addCase(createAccount.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createAccount.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createAccount.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateAccount.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateAccount.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateAccount.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteAccount.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteAccount.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteAccount.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAccountByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getAccountByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.AccountDetail = action.payload;
    });
    builder.addCase(getAccountByID.rejected, (state, action) => {
      state.loading = false;
      state.AccountDetail = initialState.AccountDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveAccounts.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveAccounts.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.AccountList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveAccounts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.AccountList = [];
    });

    builder.addCase(getAllActiveAccountsUnderGroup.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveAccountsUnderGroup.fulfilled,
      (state, action: PayloadAction<IAccountUnderGroup[]>) => {
        state.loading = false;
        state.AccountUnderGroupList = action.payload || [];
      }
    );
    builder.addCase(
      getAllActiveAccountsUnderGroup.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.AccountUnderGroupList = [];
      }
    );
    builder.addCase(AccountActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(AccountActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(AccountActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getAllAccountIncremental.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllAccountIncremental.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.AccountList = action.payload || [];
      }
    );
    builder.addCase(getAllAccountIncremental.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.AccountList = [];
    });
  },
});

export const {
  clearAccountDetails,
  setAccountID,
  clearAccountID,
  clearAccountList,
} = accountSlice.actions;
export default accountSlice.reducer;
