import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useRef } from "react";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { STATUSARRAY } from "../../_contstants/common";
import { LoadingPanel } from "../../components/layout/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import FormRadioGroup from "../../components/formFields/FormRadioGroup";
// import FormCheckbox from "../../components/formFields/FormCheckbox";
import {
  createAccount,
  // createAccount,
  getAccountByID,
  getAllActiveAccountsUnderGroup,
  updateAccount,
} from "./services/account.services";
import { clearAccountDetails } from "./accountSlice";
import { ICountry } from "../country/countryModel";
import { IState } from "../state/stateModel";
import { ICity } from "../city/cityModel";
import { getAllActiveCountries } from "../country/services/country.services";
import { getAllActiveCities } from "../city/services/city.services";
import { getAllActiveStates } from "../state/services/state.services";
import { IAccountUnderGroup } from "./accountModel";
import {
  emailWOValueValidator,
  gstWOValueValidator,
  MobileNoWOValueValidator,
  panWOValueValidator,
  passwordValidator,
  pincodeWOValueValidator,
  requiredValidator,
} from "../../components/formFields/CommonValidator";
import AppDrawer from "../../components/muidrawer/Drawer";
import CreateCity from "../city/CreateCity";
import CreateState from "../state/CreateState";
import CreateCountry from "../country/CreateCountry";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import FormPasswordField from "../../components/formFields/FormPasswordField";

const CreateAccount: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const AccountDetail = useAppSelector((state) => state.account.AccountDetail);
  const formLoading = useAppSelector((state) => state.account.formLoading);
  const AccountUnderGroupList = useAppSelector(
    (state) => state.account.AccountUnderGroupList
  );
  const CountryList = useAppSelector((state) => state.country.CountryList);
  const StateList = useAppSelector((state) => state.state.StateList);
  const CityList = useAppSelector((state) => state.city.CityList);
  const isDrawerOpen = useAppSelector((state) => state.muidrawer.open);
  const drawerName = useAppSelector((state) => state.muidrawer.name);

  const [formKey, setFormKey] = React.useState(1);

  const account_guid = location.state?.account_guid;
  const gridRef = useRef<any>(null);

  useEffect(() => {
    if (account_guid) {
      const payload = {
        account_guid: account_guid,
      };
      dispatch(getAccountByID(payload));
    }
  }, [account_guid]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [AccountDetail]);

  useEffect(() => {
    dispatch(getAllActiveAccountsUnderGroup());
    dispatch(getAllActiveCountries());
    dispatch(getAllActiveCities());
    dispatch(getAllActiveStates());

    return () => {
      dispatch(clearAccountDetails());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    if (account_guid) {
      try {
        const editPayload = {
          account_guid: account_guid,
          under_group_id: values.under_group_id ? +values.under_group_id : null,
          user_type_id: values.user_type_id ? +values.user_type_id : null,
          user_name: values.user_name ? values.user_name : "",
          account_name: values.account_name,
          short_alias_name: values.short_alias_name,
          contact_person_name: values.contact_person_name,
          email: values.email,
          mobile_number: values.mobile_number,
          address1: values.address1,
          address2: values.address2,
          pincode: values.pincode ? +values.pincode : null,
          country_id: values.country_id ? +values.country_id : null,
          state_id: values.state_id ? +values.state_id : null,
          city_id: values.city_id ? +values.city_id : null,
          payment_due_days: values.payment_due_days
            ? +values.payment_due_days
            : null,
          provide_bank_details: values.provide_bank_details,
          account_no: values.account_no,
          bank_account_name: values.bank_account_name,
          bank_name: values.bank_name,
          ifsc_code: values.ifsc_code,
          pan_no: values.pan_no,
          gst_no: values.gst_no,
          enable_credit_limit: values.enable_credit_limit,
          limit_set_based_on_no_of_invoice:
            values.limit_set_based_on_no_of_invoice
              ? +values.limit_set_based_on_no_of_invoice
              : null,
          limit_set_based_on_outstanding_amount:
            values.limit_set_based_on_outstanding_amount
              ? +values.limit_set_based_on_outstanding_amount
              : null,
          opening_balance: values.opening_balance
            ? values.opening_balance
            : "0",
          openingbalance_credit_debit: values.openingbalance_credit_debit,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(updateAccount(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/account");
          dispatch(closed());
          dispatch(clearAccountDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
      }
    } else {
      try {
        const insertPayload = {
          under_group_id: values.under_group_id ? +values.under_group_id : null,
          account_name: values.account_name,
          user_type_id: values.user_type_id ? +values.user_type_id : null,
          user_name: values.user_name ? values.user_name : "",
          password: values.password ? values.password : "",
          short_alias_name: values.short_alias_name,
          contact_person_name: values.contact_person_name,
          email: values.email,
          mobile_number: values.mobile_number,
          address1: values.address1,
          address2: values.address2,
          pincode: values.pincode ? +values.pincode : null,
          country_id: values.country_id ? +values.country_id : null,
          state_id: values.state_id ? +values.state_id : null,
          city_id: values.city_id ? +values.city_id : null,
          payment_due_days: values.payment_due_days
            ? +values.payment_due_days
            : null,
          provide_bank_details: values.provide_bank_details,
          account_no: values.account_no,
          bank_account_name: values.bank_account_name,
          bank_name: values.bank_name,
          ifsc_code: values.ifsc_code,
          pan_no: values.pan_no,
          gst_no: values.gst_no,
          enable_credit_limit: values.enable_credit_limit,
          limit_set_based_on_no_of_invoice:
            values.limit_set_based_on_no_of_invoice
              ? +values.limit_set_based_on_no_of_invoice
              : null,
          limit_set_based_on_outstanding_amount:
            values.limit_set_based_on_outstanding_amount
              ? +values.limit_set_based_on_outstanding_amount
              : null,
          opening_balance: values.opening_balance
            ? values.opening_balance
            : "0",
          openingbalance_credit_debit: values.openingbalance_credit_debit,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(createAccount(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/account");
          dispatch(closed());
          dispatch(clearAccountDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
    navigate("/account");
  };

  const CREDIT_DEBIT = [
    { label: "Credit", value: "credit" },
    { label: "Debit", value: "debit" },
  ];
  const BANK_DETAIL = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  const ENABLE_CREDIT_LIMIT = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {isDrawerOpen && drawerName === "cityForm" && (
        <AppDrawer>
          <CreateCity />
        </AppDrawer>
      )}
      {isDrawerOpen && drawerName === "stateForm" && (
        <AppDrawer>
          <CreateState />
        </AppDrawer>
      )}
      {isDrawerOpen && drawerName === "countryForm" && (
        <AppDrawer>
          <CreateCountry />
        </AppDrawer>
      )}
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={AccountDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {account_guid ? "Update Account" : "Create Account"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="user_type_id"
                    label="User Type"
                    placeholder="User Type"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={[
                      { label: "Distributor", value: 1 },
                      { label: "Retailer", value: 2 },
                    ]}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="user_name"
                    label="User Name"
                    placeholder="User Name"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                {!account_guid ? (
                  <GridLayoutItem>
                    <Field
                      name={"password"}
                      type={"password"}
                      component={FormPasswordField}
                      label={"Password"}
                      validator={passwordValidator}
                    />
                  </GridLayoutItem>
                ) : (
                  <GridLayoutItem></GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="under_group_id"
                    label="Under Group"
                    placeholder="Under Group"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={AccountUnderGroupList?.map(
                      (account: IAccountUnderGroup) => {
                        return {
                          value: account?.id,
                          label: account?.under_group_name,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="account_name"
                    label="Account Name"
                    placeholder="Account Name"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="short_alias_name"
                    label="Short/Alise Name"
                    placeholder="Short/Alise Name"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="contact_person_name"
                    label="Contect Person Name"
                    placeholder="Contect Person Name"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ width: "100%", display: "flex", gap: 10 }}
                >
                  <Field
                    wrapperClassName="w-100"
                    name="email"
                    label="Email"
                    placeholder="Email"
                    component={FormTextField}
                    validator={emailWOValueValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="w-100"
                    name="mobile_number"
                    label="Mobile Number"
                    placeholder="Mobile Number"
                    type="number"
                    max={16}
                    component={FormTextField}
                    validator={MobileNoWOValueValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  <Typography.h5
                    style={{ marginTop: "25px", marginBottom: "3px" }}
                  >
                    Address Details
                  </Typography.h5>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="address1"
                    label="Address 1"
                    placeholder="Address 1"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="address2"
                    label="Address 2"
                    placeholder="Address 2"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="city_id"
                    label="City"
                    placeholder="City"
                    isAddNew={true}
                    isIndirect={true}
                    drawerName="cityForm"
                    component={FormSelectionField}
                    options={CityList?.map((city: ICity) => {
                      return {
                        value: city?.id,
                        label: city?.city_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="state_id"
                    label="State"
                    placeholder="State"
                    isAddNew={true}
                    isIndirect={true}
                    drawerName="stateForm"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={StateList?.map((state: IState) => {
                      return {
                        value: state?.id,
                        label: state?.state_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="country_id"
                    label="Country"
                    placeholder="Country"
                    isAddNew={true}
                    isIndirect={true}
                    drawerName="countryForm"
                    component={FormSelectionField}
                    options={CountryList?.map((country: ICountry) => {
                      return {
                        value: country?.id,
                        label: country?.country_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="pincode"
                    label="Pincode"
                    type="number"
                    placeholder="Pincode"
                    minNumber="0"
                    component={FormTextField}
                    validator={pincodeWOValueValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="pan_no"
                    label="PAN No"
                    placeholder="PAN No"
                    component={FormTextField}
                    validator={panWOValueValidator}
                    onChange={(e) =>
                      formRenderProps.onChange("pan_no", {
                        value: e?.value?.toUpperCase(),
                      })
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="gst_no"
                    label="GST No"
                    placeholder="GST No"
                    component={FormTextField}
                    onChange={(e) =>
                      formRenderProps.onChange("gst_no", {
                        value: e?.value?.toUpperCase(),
                      })
                    }
                    validator={gstWOValueValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem></GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    label="Provide Bank Details"
                    component={FormRadioGroup}
                    name={`provide_bank_details`}
                    layout="horizontal"
                    options={BANK_DETAIL}
                  />
                </GridLayoutItem>
                {formRenderProps.valueGetter("provide_bank_details") ? (
                  <>
                    <GridLayoutItem
                      style={{ width: "100%", display: "flex", gap: 10 }}
                    >
                      <Field
                        wrapperClassName="w-100"
                        label="A/C No"
                        name="account_no"
                        placeholder="A/C No"
                        type="number"
                        component={FormTextField}
                      />
                      <Field
                        wrapperClassName="w-100"
                        name="bank_account_name"
                        label="Bank Account Name"
                        placeholder="Bank Account Name"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem
                      style={{ width: "100%", display: "flex", gap: 10 }}
                    >
                      <Field
                        wrapperClassName="w-100"
                        label="Bank Name"
                        name="bank_name"
                        placeholder="Bank Name"
                        component={FormTextField}
                      />
                      <Field
                        label="IFSC Code"
                        name="ifsc_code"
                        placeholder="IFSC Code"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                  </>
                ) : (
                  <GridLayoutItem colSpan={2}></GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    label="Enable Credit Limit"
                    component={FormRadioGroup}
                    name={`enable_credit_limit`}
                    layout="horizontal"
                    options={ENABLE_CREDIT_LIMIT}
                  />
                </GridLayoutItem>
                {formRenderProps.valueGetter("enable_credit_limit") ? (
                  <>
                    <GridLayoutItem>
                      <Field
                        style={{ width: "100%" }}
                        label="Limit set based on no of Invoice"
                        name="limit_set_based_on_no_of_invoice"
                        type="number"
                        placeholder="Limit set based on no of Invoice"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        style={{ width: "100%" }}
                        label="Limit set based on outstanding amount"
                        name="limit_set_based_on_outstanding_amount"
                        type="number"
                        placeholder="Limit set based on outstanding amount"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                  </>
                ) : (
                  <GridLayoutItem colSpan={3}></GridLayoutItem>
                )}
                <GridLayoutItem
                  style={{ width: "100%", display: "flex", gap: 10 }}
                >
                  <Field
                    wrapperClassName="w-100"
                    name="opening_balance"
                    label="Opening Balance"
                    placeholder="Opening Balance"
                    type={"number"}
                    min="0"
                    component={FormTextField}
                  />
                  <Field
                    // wrapperClassName="w-100"
                    name="openingbalance_credit_debit"
                    label="Cr/Dr"
                    component={FormSelectionField}
                    options={CREDIT_DEBIT?.map((cr_dr: any) => {
                      return {
                        value: cr_dr.value,
                        label: cr_dr.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="payment_due_days"
                    label="Payment Due Days"
                    placeholder="0"
                    type={"number"}
                    min="0"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"isactive"}
                    name={"isactive"}
                    component={FormSelectionField}
                    label={"Status"}
                    options={STATUSARRAY?.map((status: any) => {
                      return {
                        value: status.value,
                        label: status.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <ButtonWithLoading
                    label={!account_guid ? "Create" : "Update"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || formLoading}
                    loading={formLoading}
                  />
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginLeft: 4 }}
                    onClick={handleFormClose}
                  >
                    Cancel
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateAccount;
