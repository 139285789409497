import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import moment from "moment";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const getAllProductCleanings = createAsyncThunk(
  "ProductCleaning/FindAllProductCleaning",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ProductCleaning/FindAllProductCleaning`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Product cleanings:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createProductCleaning = createAsyncThunk(
  "ProductCleaning/InsertProductCleaning",
  async (ProductCleaningData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ProductCleaning/InsertProductCleaning`,
        ProductCleaningData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating product cleaning:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateProductCleaning = createAsyncThunk(
  "ProductCleaning/UpdateProductCleaning",
  async (ProductCleaningData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ProductCleaning/UpdateProductCleaning`,
        ProductCleaningData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating product cleaning:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteProductCleaning = createAsyncThunk(
  "ProductCleaning/DeleteProductCleaning",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ProductCleaning/DeleteProductCleaning`,
        { product_cleaning_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting product cleaning:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getProductCleaningByID = createAsyncThunk(
  "ProductCleaning/FindByIDProductCleaning",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ProductCleaning/FindByIDProductCleaning`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        product_cleaning_guid: result?.product_cleaning_guid,
        cleaning_date: result?.cleaning_date
          ? moment(result?.cleaning_date, "YYYY-MM-DD").toDate()
          : "",
        cleaning_no: result?.cleaning_no,
        item_id: result?.item_id,
        product_name: result?.product_name,
        grn_no: result?.grn_no,
        no_of_workers: result?.no_of_workers,
        machine_id: result?.machine_id,
        machine_name: result?.machine_name,
        start_time: result?.start_time
          ? moment(result?.start_time, "HH:mm:ss").toDate()
          : "",
        end_time: result?.end_time
          ? moment(result?.end_time, "HH:mm:ss").toDate()
          : "",
        total_qty: result?.total_qty,
        received_qty: result?.received_qty,
        rejection_qty: result?.rejection_qty,
        cleaning_checker_by: +result?.cleaning_checker_by,
        financial_year: result?.financial_year,
        department_id: result?.department_id,
        process_id: result?.process_id,
        item_options: result?.item_options || [],
        remarks: result?.remarks,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching product cleaning:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveProductCleanings = createAsyncThunk(
  "ProductCleaning/FindAllActiveProductCleaning",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/ProductCleaning/FindAllActiveProductCleaning`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching vendor types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
