import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import React from "react";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import { setFamilyDeleteIndex } from "../employeeSlice";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import IconButton from "../../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import FormDatePicker from "../../../components/formFields/FormDateField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { ErrorToast } from "../../../components/toast/Toasts";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { BLOOD_GROUPS, GENDER } from "../../../_contstants/common";
import FormTextField from "../../../components/formFields/FormTextField";
import { IRelation } from "../../relation/relationModel";
import moment from "moment";

const FamilyDetailsArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const FamilyDetailsDeleteIndex = useAppSelector(
    (state) => state.employee.FamilyDeleteIndex
  );
  const RelationList = useAppSelector((state) => state.relation?.RelationList);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("familyDetailsDeleteDialog"));
    dispatch(setFamilyDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      try {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setFamilyDeleteIndex(-1));
      } catch (error: any) {
        ErrorToast(error?.response?.data?.Details || "Something went wrong");
        console.error("Error deleting Family Member:", error);
        return Promise.reject({
          message: error?.response?.data?.Details || "Something went wrong",
        });
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        name: "",
        gender: "",
        blood_group: "",
        relationship_id: null,
        date: "",
      },
    });
  }, [fieldArrayRenderProps]);

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {/* {!fieldArrayRenderProps.value ? pushElementInarray() : null} */}
      {/* {fieldArrayRenderProps?.value?.length < 1 ? pushElementInarray() : null} */}
      {dialogName === "familyDetailsDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Family Member"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Family Member?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(FamilyDetailsDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map(
          (FamilyMemberDetails: any, index: number) => (
            <GridLayoutItem key={index}>
              <Card className="fieldarray-card">
                <GridLayout
                  style={{ marginRight: 40, marginTop: 10 }}
                  gap={{ rows: 0, cols: 10 }}
                  cols={[
                    { width: "20%" },
                    { width: "20%" },
                    { width: "20%" },
                    { width: "20%" },
                    { width: "20%" },
                  ]}
                >
                  <GridLayoutItem colSpan={4}>
                    <Typography.p
                      style={{
                        background: "#f85252",
                        padding: "2px 10px",
                        width: "fit-content",
                        margin: 0,
                        color: "#fff",
                        borderRadius: "15px",
                      }}
                    >
                      Member {index + 1}
                    </Typography.p>
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      display: "flex",
                      width: "100%",
                      gap: 10,
                      justifyContent: "end",
                    }}
                  >
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(index)}
                      //  disabled={fieldArrayRenderProps.value.length === 1}
                      themeColor={"error"}
                      size={"small"}
                      fillMode={"solid"}
                      type="button"
                      style={{ height: 24, width: 24 }}
                    >
                      <MdDelete
                        className="absolute-position"
                        style={{ fontSize: "16px" }}
                      />
                    </IconButton>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`FamilyMemberDetails.${index}.name`}
                      label="Name"
                      placeholder={"Name"}
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`FamilyMemberDetails.${index}.relationship_id`}
                      label="Relation"
                      validator={requiredValidator}
                      placeholder="Select Relation"
                      component={FormSelectionField}
                      options={RelationList?.map((state: IRelation) => {
                        return {
                          value: state?.id,
                          label: state?.relation,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`FamilyMemberDetails.${index}.date`}
                      label="Birth Date"
                      placeholder="Birth Date"
                      format={"dd/MM/yyyy"}
                      maxDate={moment().toDate()}
                      component={FormDatePicker}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem style={{ width: "100%" }}>
                    <Field
                      name={`FamilyMemberDetails.${index}.gender`}
                      label="Gender"
                      placeholder="Select Gender"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={GENDER?.map((gender: any) => {
                        return {
                          value: gender?.value,
                          label: gender?.label,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem style={{ width: "100%" }}>
                    <Field
                      name={`FamilyMemberDetails.${index}.blood_group`}
                      label="Blood Group"
                      component={FormSelectionField}
                      placeholder="Select Blood Group"
                      // validator={requiredValidator}
                      options={BLOOD_GROUPS?.map((group: any) => {
                        return {
                          value: group?.value,
                          label: group?.label,
                        };
                      })}
                    />
                  </GridLayoutItem>
                </GridLayout>
              </Card>
            </GridLayoutItem>
          )
        )}
      <GridLayoutItem style={{ marginTop: 10 }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Member
        </Button>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default FamilyDetailsArray;
