import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useCallback, useEffect, useRef } from "react";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { closed } from "../../components/drawer/drawerSlice";
import { LoadingPanel } from "../../components/layout/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import FormDatePicker from "../../components/formFields/FormDateField";
import moment from "moment";
import FormTimePicker from "../../components/formFields/FormTimeField";
import {
  createProductMiliing,
  getProductMiliingByID,
  updateProductMiliing,
} from "./services/productmilling.services";
import { clearProductMillingDetails } from "./productmillingSlice";
import { getAllItemIncremental } from "../Item/services/item.services";
import { findAllActiveDepartment } from "../department/services/department.services";
import { getGRNByItemID } from "../inwardreturn/services/inwardReturn.services";
import { findAllActiveProcess } from "../process/services/process.services";
import { findAllActiveMachine } from "../machine/services/machine.services";
import { getAllActiveUsers } from "../user/services/user.services";
import { IUser } from "../user/userModel";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { getCurrentStock } from "../stocktransfer/services/stockTransfer.services";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import CreateMachine from "../machine/CreateMachine";
import AppDrawer from "../../components/muidrawer/Drawer";
import { clearItemList } from "../Item/itemSlice";
import { getLocalStorageItem } from "../../_helper/helper";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const QtychangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const total_qty = formRenderProps.valueGetter("total_qty");
  const received_qty = formRenderProps.valueGetter("received_qty");
  const production_loss_qty = formRenderProps.valueGetter(
    "production_loss_qty"
  );

  useEffect(() => {
    formRenderProps.onChange("production_loss_qty", {
      value: +total_qty - +received_qty,
    });
  }, [total_qty, received_qty]);

  if (production_loss_qty < 0) {
    formRenderProps.onChange("production_loss_qty", {
      value: 0,
    });
  }

  return null;
};

const ItemChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const item_id = formRenderProps.valueGetter("item_id");
  const location = useLocation();
  const product_milling_guid = location.state?.product_milling_guid;
  const isItemRef = React.useRef(true);

  useEffect(() => {
    const fetchGRNOptions = async () => {
      if (product_milling_guid) {
        if (!isItemRef.current) {
          formRenderProps.onChange("grn_no", {
            value: null,
          });
        } else {
          isItemRef.current = false;
        }
      } else {
        formRenderProps.onChange("grn_no", {
          value: null,
        });
      }
      if (item_id) {
        const response: any = await dispatch(getGRNByItemID(item_id));
        if (response && response.payload) {
          const result = response.payload
            .filter((grn: any) => grn?.item_id === item_id)
            .map((grnno: any) => ({
              value: `${grnno?.grn_no}`,
              label: `${grnno?.grn_no}`,
            }));

          formRenderProps.onChange("grnNoOptions", {
            value: result?.length > 0 && result,
          });
        } else {
          formRenderProps.onChange("grnNoOptions", { value: [] });
          formRenderProps.onChange("grn_no", {
            value: null,
          });
        }
      } else {
        formRenderProps.onChange("grnNoOptions", { value: [] });
        formRenderProps.onChange("grn_no", {
          value: null,
        });
      }
    };

    fetchGRNOptions();
  }, [item_id]);

  return null;
};

const GRNChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const product_milling_guid = location.state?.product_milling_guid;
  const previous_process_id = formRenderProps.valueGetter(
    "previous_process_id"
  );
  const item_id = formRenderProps.valueGetter("item_id");
  const grn_no = formRenderProps.valueGetter("grn_no");
  const current_stock = formRenderProps.valueGetter("current_stock");

  useEffect(() => {
    if (grn_no && previous_process_id) {
      const fetchStock = async (grn_no: any) => {
        const payload = {
          item_id: item_id ? item_id : null,
          grn_no: grn_no ? grn_no : "",
          process_id: previous_process_id ? previous_process_id : null,
          warehouse_id: 1,
        };
        const res = await dispatch(getCurrentStock(payload));
        formRenderProps.onChange("current_stock", {
          value: res?.payload?.actual_stock || 0,
        });
      };

      fetchStock(grn_no);
    } else {
      formRenderProps.onChange("current_stock", {
        value: 0,
      });
    }
  }, [grn_no, previous_process_id]);

  useEffect(() => {
    if (
      (current_stock === null || current_stock < 1) &&
      !product_milling_guid
    ) {
      formRenderProps.onChange("total_qty", {
        value: 0,
      });
      formRenderProps.onChange("received_qty", {
        value: 0,
      });
    }
  }, [current_stock]);

  return null;
};

const UserChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const UserID = getLocalStorageItem("UserID");
  const product_milling_guid = location.state?.product_milling_guid;

  useEffect(() => {
    if (!product_milling_guid) {
      formRenderProps.onChange("checker_by", {
        value: UserID ? +UserID : null,
      });
    }
  }, [UserID]);

  return null;
};

const CreateProductMilling: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const ProductMillingDetail = useAppSelector(
    (state) => state.productmilling.ProductMillingDetail
  );
  const loading = useAppSelector((state) => state.productmilling.loading);
  // const ItemList = useAppSelector((state) => state.item.ItemList);
  // const itemLoading = useAppSelector((state) => state.item.loading);
  const ProcessList = useAppSelector((state) => state.process.ProcessList);
  const MachineList = useAppSelector((state) => state.machine.MachineList);
  const UserList = useAppSelector((state) => state.user.UserList);
  const product_milling_guid = location.state?.product_milling_guid;
  const [formKey, setFormKey] = React.useState(1);
  const isDrawerOpen = useAppSelector((state) => state.muidrawer.open);
  const drawerName = useAppSelector((state) => state.muidrawer.name);
  const gridRef = useRef<any>(null);

  useEffect(() => {
    if (product_milling_guid) {
      const payload = {
        product_milling_guid: product_milling_guid,
      };
      dispatch(getProductMiliingByID(payload));
    }
  }, [product_milling_guid]);

  useEffect(() => {
    dispatch(getAllActiveUsers());
    dispatch(findAllActiveDepartment());
    dispatch(findAllActiveProcess());
    dispatch(findAllActiveMachine());
    return () => {
      dispatch(clearProductMillingDetails());
      dispatch(clearItemList());
    };
  }, []);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [ProductMillingDetail]);

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (product_milling_guid) {
      try {
        const editPayload = {
          product_milling_guid: ProductMillingDetail?.product_milling_guid,
          milling_date: values.milling_date
            ? moment(values.milling_date).format("YYYY-MM-DD")
            : "",
          item_id: values.item_id ? +values.item_id : null,
          milling_no: values.milling_no ? +values.milling_no : null,
          grn_no: values.grn_no ? values.grn_no : "",
          no_of_workers: values.no_of_workers ? +values.no_of_workers : null,
          machine_id: values.machine_id ? +values.machine_id : null,
          start_time: values.start_time
            ? moment(values.start_time).format("HH:mm:ss")
            : "",
          end_time: values.end_time
            ? moment(values.end_time).format("HH:mm:ss")
            : "",
          total_qty: values.total_qty ? +values.total_qty : 0,
          received_qty: values.received_qty ? +values.received_qty : 0,
          production_loss_qty: values.production_loss_qty
            ? +values.production_loss_qty
            : 0,
          katta: values.katta ? +values.katta : null,
          lot_no: values.lot_no ? values.lot_no : "",
          checker_by: values.checker_by ? +values.checker_by : null,
          // department_id: values.department_id ? +values.department_id : null,
          previous_process_id: values.previous_process_id
            ? +values.previous_process_id
            : null,
          process_id: values.process_id ? +values.process_id : null,
          financial_year: 202425,
          remarks: values.remarks,
        };
        const response = await dispatch(updateProductMiliing(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
          dispatch(closed());
          dispatch(clearProductMillingDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
      }
    } else {
      try {
        const insertPayload = {
          milling_date: values.milling_date
            ? moment(values.milling_date).format("YYYY-MM-DD")
            : "",
          item_id: values.item_id ? +values.item_id : null,
          grn_no: values.grn_no ? values.grn_no : "",
          no_of_workers: values.no_of_workers ? +values.no_of_workers : null,
          machine_id: values.machine_id ? +values.machine_id : null,
          start_time: values.start_time
            ? moment(values.start_time).format("HH:mm:ss")
            : "",
          end_time: values.end_time
            ? moment(values.end_time).format("HH:mm:ss")
            : "",
          total_qty: values.total_qty ? +values.total_qty : 0,
          received_qty: values.received_qty ? +values.received_qty : 0,
          production_loss_qty: values.production_loss_qty
            ? +values.production_loss_qty
            : 0,
          katta: values.katta ? +values.katta : null,
          lot_no: values.lot_no ? values.lot_no : "",
          checker_by: values.checker_by,
          // department_id: values.department_id ? +values.department_id : null,
          previous_process_id: values.previous_process_id
            ? +values.previous_process_id
            : null,
          process_id: values.process_id ? +values.process_id : null,
          financial_year: 202425,
          remarks: values.remarks,
        };
        const response = await dispatch(createProductMiliing(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
          dispatch(closed());
          dispatch(clearProductMillingDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleProductMillingNoChange = (
    ev: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (ev.key === "Enter" || ev.key === "Tab") {
      ev.preventDefault();
      if ((ev.target as HTMLInputElement).value) {
        const payload = {
          milling_no: (ev.target as HTMLInputElement).value,
          financial_year: 202425,
        };
        dispatch(getProductMiliingByID(payload));
      }
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
    navigate("/productmilling");
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {isDrawerOpen && drawerName === "machineForm" && (
        <AppDrawer>
          <CreateMachine />
        </AppDrawer>
      )}
      <Form
        onSubmit={handleSubmit}
        initialValues={ProductMillingDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 40 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {product_milling_guid
                      ? "Update Product Milling"
                      : "Create Product Milling"}
                  </Typography.h4>
                </GridLayoutItem>
                {product_milling_guid && (
                  <GridLayoutItem>
                    <Field
                      name="milling_no"
                      onKeyDown={handleProductMillingNoChange}
                      label="Product Milling No"
                      placeholder="Product Milling No"
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="milling_date"
                    label="Milling Date"
                    format={"dd/MM/yyyy"}
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={product_milling_guid ? 2 : 3}
                ></GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="w-100"
                    name="previous_process_id"
                    label="Material Taken From Department"
                    placeholder="Material Taken From Department"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={ProcessList?.map((process: any) => {
                      return {
                        value: process.id,
                        label: process.process_name,
                      };
                    })}
                  />
                </GridLayoutItem>

                <GridLayoutItem>
                  <Field
                    name="item_id"
                    label="Product Name"
                    placeholder="Product Name"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    fetchIncrementalData={(search: any) =>
                      handleItemSearchChange(
                        search,
                        `item_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("item_options")
                        ?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item.product_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem
                // style={{ display: "flex", gap: 10, alignItems: "start" }}
                >
                  <Field
                    name="grn_no"
                    label="GRN No"
                    placeholder="GRN No"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={formRenderProps.valueGetter("grnNoOptions") || []}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="stock-label center-alighned-field d-flex flex-wrap justify-content-center"
                    wrapperStyle={{ color: "red" }}
                    name="current_stock"
                    label="Current Stock"
                    disabled={true}
                    placeholder="0"
                    component={FormNumericTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>

                <GridLayoutItem>
                  <Field
                    wrapperClassName="w-100"
                    name="process_id"
                    label="Material Issue To Department"
                    placeholder="Material Issue To Department"
                    component={FormSelectionField}
                    // validator={requiredValidator}
                    options={ProcessList?.map((process: any) => {
                      return {
                        value: process.id,
                        label: process.process_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="machine_id"
                    label="Machine No"
                    placeholder="Machine No"
                    isAddNew={true}
                    drawerName="machineForm"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={MachineList?.map((machine: any) => {
                      return {
                        value: machine.id,
                        label: machine.machine_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                // style={{ display: "flex", gap: 10 }}
                >
                  <Field
                    wrapperStyle={{ width: "100%" }}
                    name="start_time"
                    label="Start Time"
                    placeholder="Start Time"
                    component={FormTimePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperStyle={{ width: "100%" }}
                    name="end_time"
                    label="End Time"
                    placeholder="End Time"
                    component={FormTimePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem></GridLayoutItem> */}
                <GridLayoutItem>
                  <Field
                    wrapperClassName="w-100"
                    name="lot_no"
                    label="Lot No."
                    placeholder="0"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                // colSpan={2}
                // style={{ display: "flex", gap: 10 }}
                >
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="total_qty"
                    label="Total Quantity (KG)"
                    min={0}
                    max={
                      product_milling_guid
                        ? ProductMillingDetail.total_qty +
                          formRenderProps.valueGetter("current_stock")
                        : formRenderProps.valueGetter("current_stock")
                    }
                    placeholder="0"
                    component={FormNumericTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="received_qty"
                    label="Received Quantity (KG)"
                    placeholder="0"
                    min={0}
                    max={formRenderProps.valueGetter("total_qty")}
                    component={FormNumericTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="w-100 center-alighned-field red-highlight-field d-flex flex-wrap justify-content-center"
                    name="production_loss_qty"
                    label="Production Loss Quantity (KG)"
                    placeholder="0"
                    disabled={true}
                    component={FormNumericTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="no_of_workers"
                    label="No Of Workers"
                    placeholder="0"
                    type="number"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="katta"
                    label="Katta"
                    placeholder="Katta"
                    component={FormNumericTextField}
                    min={0}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem>
                  <Field
                    name="department_id"
                    label="Material issue to Department"
                    placeholder="Material issue to Department"
                    component={FormSelectionField}
                    options={DepartmentList?.map((department: any) => {
                      return {
                        value: department.id,
                        label: department.department_name,
                      };
                    })}
                  />
                  </GridLayoutItem> */}
                <GridLayoutItem>
                  <Field
                    name="checker_by"
                    label="Checked By"
                    placeholder="Checked By"
                    component={FormSelectionField}
                    options={UserList?.map((user: IUser) => {
                      return {
                        value: user.id,
                        label: `${user?.first_name} ${user?.last_name}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    // height={164}
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  // colSpan={3}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <ButtonWithLoading
                    label={!product_milling_guid ? "Create" : "Update"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginLeft: 4 }}
                    onClick={handleFormClose}
                  >
                    Cancel
                  </Button>
                </GridLayoutItem>
                <QtychangeWatcher formRenderProps={formRenderProps} />
                <ItemChangeWatcher formRenderProps={formRenderProps} />
                <GRNChangeWatcher formRenderProps={formRenderProps} />
                <UserChangeWatcher formRenderProps={formRenderProps} />
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateProductMilling;
