import * as React from "react";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import {
  InputSuffix,
  NumericTextBox,
  NumericTextBoxProps,
} from "@progress/kendo-react-inputs";
import { Hint, Error, Label } from "@progress/kendo-react-labels";
import { warningTriangleIcon } from "@progress/kendo-svg-icons";
import { Button } from "@progress/kendo-react-buttons";
import { CgDanger } from "react-icons/cg";

const FormNumericTextField = (
  props: NumericTextBoxProps & FieldRenderProps
) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    optional,
    placeholder,
    format,
    max,
    min,
    step = 1,
    spinners = false,
    wrapperClassName,
    wrapperStyle,
    onBlur,
    ...other
  } = props;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";

  return (
    <FieldWrapper className={wrapperClassName} style={wrapperStyle}>
      <Label
        // label={label}
        editorId={id}
        // editorValue={other.value}
        editorValid={valid}
        editorDisabled={disabled}
        optional={optional}
      >
        {label}
      </Label>
      <NumericTextBox
        valid={valid}
        rounded={"large"}
        size={"large"}
        format={format}
        step={step}
        max={max}
        min={min}
        spinners={spinners}
        placeholder={placeholder}
        suffix={() =>
          touched ? (
            <InputSuffix>
              {!valid && (
                <Button
                  tabIndex={-1}
                  style={{ height: "100%" }}
                  themeColor={"error"}
                  fillMode={"flat"}
                  svgIcon={warningTriangleIcon}
                />
              )}
            </InputSuffix>
          ) : null
        }
        id={id}
        disabled={disabled}
        aria-describedby={`${hintId} ${errorId}`}
        onBlur={onBlur}
        {...other}
      />
      {/* )} */}
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && (
        <Error className="d-flex justify-content-end" id={errorId}>
          <span
            className="d-flex align-items-center"
            style={{
              background: "#DF4957",
              color: "white",
              padding: "1px 8px 1px 5px",
              borderRadius: 10,
              gap: 5,
            }}
          >
            <CgDanger /> {validationMessage}
          </span>
        </Error>
      )}
    </FieldWrapper>
  );
};

export default FormNumericTextField;
