import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { FiEdit } from "react-icons/fi";
import AlertBox from "../../components/common/AlertBox";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { MdDelete } from "react-icons/md";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import { clearInwardReturnID, setInwardReturnID } from "./inwardReturnSlice";
import {
  deleteInwardReturn,
  getAllInwardReturns,
} from "./services/inwardReturn.services";
import { checkAcessRights } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import { clearItemList } from "../Item/itemSlice";

const InwardReturn: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  const handleCreate = () => {
    navigate("/inwardreturn/create");
  };

  useEffect(() => {
    return () => {
      dispatch(clearItemList());
      dispatch(setCurrentPage(0));
    };
  }, []);

  return (
    <>
      <DeleteInwardReturnDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Inward Return List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              {"Create Inward Return"}
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <InwardReturnOrderGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const InwardReturnOrderGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const InwardReturnList = useAppSelector(
    (state) => state.inwardReturn.InwardReturnList
  );
  const loading = useAppSelector((state) => state.inwardReturn.loading);
  const { currentPage, pageLimit } = useAppSelector(
    (state) => state.pagination
  );

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  useEffect(() => {
    const payload = {
      account_name: "",
      vehicle_no: "",
      chalan_no: "",
      financial_year: 202425,
      page_no: +currentPage + 1,
      page_size: +pageLimit,
    };
    dispatch(getAllInwardReturns(payload));
  }, [currentPage, pageLimit]);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (inward_return_guid: number) => {
      navigate("/inwardreturn/edit", {
        state: { inward_return_guid: inward_return_guid },
      });
    };
    const handleOpenDeleteDialog = (inward_return_guid: number) => {
      dispatch(openDialog("deleteInwardReturn"));
      dispatch(setInwardReturnID(inward_return_guid));
    };

    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() => handleEdit(props.dataItem?.inward_return_guid)}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() =>
              handleOpenDeleteDialog(props.dataItem?.inward_return_guid)
            }
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {InwardReturnList && InwardReturnList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={filter ? filterBy(InwardReturnList, filter) : InwardReturnList}
          onFilterChange={handleFilterChange}
          total={
            InwardReturnList[0]?.total_item_count || InwardReturnList.length
          }
          skip={currentPage * pageLimit}
          take={pageLimit}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 50, 100, 300, 500],
            pageSizeValue: pageLimit,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="inward_return_no"
            title="Inward Return No"
            filterable={false}
            cell={(props: any) => (
              <td>
                <div
                  className={isEditAccess ? "hoverableName" : "normalName"}
                  onClick={() =>
                    isEditAccess &&
                    navigate("/inwardreturn/edit", {
                      state: {
                        inward_return_guid: props.dataItem?.inward_return_guid,
                      },
                    })
                  }
                >
                  {props.dataItem?.inward_return_no}
                </div>
              </td>
            )}
          />
          <Column field="inward_return_date" title="Inward Return Date" />
          <Column field="account_name" title="Vendor" />
          <Column
            field="bill_no"
            title="Inward No"
            cell={(props: any) => (
              <td className="text-end">{props.dataItem?.bill_no}</td>
            )}
          />

          <Column field="chalan_no" title="Chalan No" />
          <Column field="vehicle_no" title="Vehicle No" />
          {(isEditAccess || isDeleteAccess) && (
            <Column
              field="id"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteInwardReturnDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const InwardReturnID = useAppSelector(
    (state) => state.inwardReturn.InwardReturnID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteInwardReturn(ID));
      dispatch(setCurrentPage(0));
      const payload = {
        account_name: "",
        vehicle_no: "",
        chalan_no: "",
        financial_year: 202425,
        page_no: +currentPage + 1,
        page_size: +pageLimit,
      };
      if (response.payload.Data.Status === 200) {
        dispatch(getAllInwardReturns(payload));
        dispatch(closeDialog());
        dispatch(clearInwardReturnID());
      } else {
        dispatch(getAllInwardReturns(payload));
        dispatch(closeDialog());
        dispatch(clearInwardReturnID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteInwardReturn" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Inward Return"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Inward Return?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(InwardReturnID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default InwardReturn;
