import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import moment from "moment";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const getAllPurchaseOrders = createAsyncThunk(
  "PurchaseOrder/FindAllPurchaseOrder",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PurchaseOrder/FindAllPurchaseOrder`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Purchase Orders:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createPurchaseOrder = createAsyncThunk(
  "PurchaseOrder/InsertPurchaseOrder",
  async (PurchaseOrderData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PurchaseOrder/InsertPurchaseOrder`,
        PurchaseOrderData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating purchase order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updatePurchaseOrder = createAsyncThunk(
  "PurchaseOrder/UpdatePurchaseOrder",
  async (PurchaseOrderData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PurchaseOrder/UpdatePurchaseOrder`,
        PurchaseOrderData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Purchase Order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deletePurchaseOrder = createAsyncThunk(
  "PurchaseOrder/DeletePurchaseOrder",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PurchaseOrder/DeletePurchaseOrder`,
        { purchase_order_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Purchase Order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPurchaseOrderByID = createAsyncThunk(
  "PurchaseOrder/FindByIDPurchaseOrder",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PurchaseOrder/FindByIDPurchaseOrder`,
        { purchase_order_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        purchase_order_guid: result?.purchase_order_guid,
        po_no: result?.po_no,
        po_no_string: result?.po_no_string,
        po_date: result?.po_date
          ? moment(result?.po_date, "YYYY-MM-DD").toDate()
          : "",
        delivery_date: result?.delivery_date
          ? moment(result?.delivery_date, "YYYY-MM-DD").toDate()
          : "",
        quotation_no: result?.quotation_no,
        quotation_ref: result?.quotation_ref,
        vendor_id: result?.vendor_id,
        vendor_options: result?.vendor_options,
        mobile_no: result?.mobile_no,
        email_id: result?.email_id,
        // address1: result.address1,
        // address2: result.address2,
        // pincode: result.pincode,
        // state_id: result.state_id,
        // city_id: result.city_id,
        remarks: result?.remarks,
        taxable_amount: result?.taxable_amount,
        gst: result?.gst,
        cgst: result?.cgst,
        sgst: result?.sgst,
        igst: result?.igst,
        gst_no: result?.gst_no,
        sub_total: result?.sub_total,
        round_off: result?.round_off,
        ground_total: result?.ground_total,
        financial_account_year: result?.financial_account_year,
        freight: result?.freight,
        p_and_f_charge: result?.p_and_f_charge,
        payment_terms: result?.payment_terms,
        mode_of_transport: result?.mode_of_transport,
        purchase_order_items: result?.purchase_order_items
          ? result?.purchase_order_items.map((poitems: any) => {
              return {
                id: poitems?.id ? poitems?.id : null,
                purchase_order_id: poitems?.purchase_order_id
                  ? poitems?.purchase_order_id
                  : null,
                item_id: poitems?.item_id ? poitems?.item_id : null,
                item_Category_id: poitems?.item_Category_id
                  ? poitems?.item_Category_id
                  : null,
                mrp: poitems?.mrp ? poitems?.mrp : null,
                qty: poitems?.qty ? poitems?.qty : null,
                rate: poitems?.rate ? poitems?.rate : null,
                discount: poitems?.discount ? poitems?.discount : null,
                discount_type: poitems?.discount_type === 1 ? "₹" : "%",
                // amount: poitems?.amount ? poitems?.amount : null,
                gst_id: poitems?.gst_id
                  ? `${poitems?.gst_id}--${poitems?.gst}`
                  : null,
                unit_id: poitems?.unit_id ? poitems?.unit_id : null,
                item_options: poitems?.item_options
                  ? poitems?.item_options
                  : [],
                cold_storage_id: poitems?.cold_storage_id
                  ? poitems?.cold_storage_id
                  : null,
              };
            })
          : [],
        isactive: result.isactive,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Purchase Order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

// export const deletePurchaseOrderItem = createAsyncThunk(
//   "PurchaseOrder/DeletePurchaseOrderItem",
//   async (ID: number) => {
//     try {
//       const response = await axiosApiInstance.post(
//         `${API_URL}/PurchaseOrder/DeletePurchaseOrderItem`,
//         { ID }
//       );
//       SuccessToast(response.data?.Details || "Success");
//       return response.data;
//     } catch (error: any) {
//       ErrorToast(error?.response?.data?.Details || "Something went wrong");
//       console.error("Error deleting purchase order item:", error);
//       return Promise.reject({
//         message: error?.response?.data?.Details || "Something went wrong",
//       });
//     }
//   }
// );

export const getPurchaseNoPurchaseOrderByID = createAsyncThunk(
  "PurchaseOrder/FindByPurchaseNoPurchaseOrder",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PurchaseOrder/FindByPurchaseNoPurchaseOrder`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        purchase_order_guid: result.purchase_order_guid,
        po_no: result.po_no,
        po_no_string: result.po_no_string,
        po_date: result.po_date
          ? moment(result.po_date, "YYYY-MM-DD").toDate()
          : "",
        delivery_date: result.delivery_date
          ? moment(result.delivery_date, "YYYY-MM-DD").toDate()
          : "",
        quotation_no: result.quotation_no,
        quotation_ref: result.quotation_ref,
        vendor_id: result.vendor_id,
        vendor_options: result.vendor_options,
        mobile_no: result.mobile_no,
        email_id: result.email_id,
        // address1: result.address1,
        // address2: result.address2,
        // pincode: result.pincode,
        // state_id: result.state_id,
        // city_id: result.city_id,
        remarks: result.remarks,
        taxable_amount: result.taxable_amount,
        gst: result.gst,
        cgst: result?.cgst,
        sgst: result?.sgst,
        igst: result?.igst,
        gst_no: result?.gst_no,
        sub_total: result.sub_total,
        round_off: result.round_off,
        ground_total: result.ground_total,
        financial_account_year: result.financial_account_year,
        freight: result?.freight,
        p_and_f_charge: result?.p_and_f_charge,
        payment_terms: result?.payment_terms,
        mode_of_transport: result?.mode_of_transport,
        purchase_order_items: result?.purchase_order_items
          ? result?.purchase_order_items.map((poitems: any) => {
              return {
                id: poitems?.id ? poitems?.id : null,
                purchase_order_id: poitems?.purchase_order_id
                  ? poitems?.purchase_order_id
                  : null,
                item_id: poitems?.item_id ? poitems?.item_id : null,
                item_Category_id: poitems?.item_Category_id
                  ? poitems?.item_Category_id
                  : null,
                mrp: poitems?.mrp ? poitems?.mrp : null,
                qty: poitems?.qty ? poitems?.qty : null,
                rate: poitems?.rate ? poitems?.rate : null,
                discount: poitems?.discount ? poitems?.discount : null,
                discount_type: poitems?.discount_type === 1 ? "₹" : "%",
                // amount: poitems?.amount ? poitems?.amount : null,
                gst_id: poitems?.gst_id
                  ? `${poitems?.gst_id}--${poitems?.gst}`
                  : null,
                unit_id: poitems?.unit_id ? poitems?.unit_id : null,
                item_options: poitems?.item_options
                  ? poitems?.item_options
                  : [],
                cold_storage_id: poitems?.cold_storage_id
                  ? poitems?.cold_storage_id
                  : null,
              };
            })
          : [],
        isactive: result.isactive,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Purchase Order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
