import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormTextField from "../../components/formFields/FormTextField";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ShadowCard from "../../components/common/ShadowCard";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { LoadingPanel } from "../../components/layout/Loading";
import FormTimePicker from "../../components/formFields/FormTimeField";
import { IUser } from "../user/userModel";
import { getAllActiveUsers } from "../user/services/user.services";
import FormTextArea from "../../components/formFields/FormTextArea";
import moment from "moment";
import { clearOfficeStaffOutDetails } from "./officeStaffOutSlice";
import {
  createOfficeStaffOut,
  getOfficeStaffOutByID,
  updateOfficeStaffOut,
} from "./services/officeStaffOut.services";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const OutTimeChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const staff_out_guid = location.state?.staff_out_guid;

  useEffect(() => {
    if (!staff_out_guid) {
      formRenderProps.onChange("out_time", {
        value: moment().toDate() || "",
      });
    }
  }, []);

  return null;
};

const CreateOfficeStaffOut: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const staff_out_guid = location.state?.staff_out_guid;
  const loading = useAppSelector((state) => state.officeStaffOut.loading);
  const OfficeStaffOutDetail = useAppSelector(
    (state) => state.officeStaffOut.OfficeStaffOutDetail
  );
  const UserList = useAppSelector((state) => state.user.UserList);

  useEffect(() => {
    dispatch(getAllActiveUsers());

    return () => {
      dispatch(clearOfficeStaffOutDetails());
    };
  }, []);

  useEffect(() => {
    if (staff_out_guid) {
      dispatch(getOfficeStaffOutByID(staff_out_guid));
    }
  }, [staff_out_guid]);

  const handleSubmit = async (values: any) => {
    if (staff_out_guid) {
      try {
        const updatePayload = {
          staff_out_guid: staff_out_guid,
          staff_out_date: values?.staff_out_date
            ? moment(values?.staff_out_date).format("YYYY-MM-DD")
            : "",
          user_id: values?.user_id ? +values?.user_id : null,
          reason_for_out: values?.reason_for_out ? values?.reason_for_out : "",
          approved_by: values?.approved_by ? +values?.approved_by : null,
          in_time: values?.in_time
            ? moment(values?.in_time).format("HH:mm:ss")
            : "",
          out_time: values?.out_time
            ? moment(values?.out_time).format("HH:mm:ss")
            : "",
          financial_year: 202425,
        };
        const response = await dispatch(updateOfficeStaffOut(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/officestaffout");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const insertPayload = {
          staff_out_date: values?.staff_out_date
            ? moment(values?.staff_out_date).format("YYYY-MM-DD")
            : "",
          user_id: values?.user_id ? +values?.user_id : null,
          reason_for_out: values?.reason_for_out ? values?.reason_for_out : "",
          approved_by: values?.approved_by ? +values?.approved_by : null,
          in_time: values?.in_time
            ? moment(values?.in_time).format("HH:mm:ss")
            : "",
          out_time: values?.out_time
            ? moment(values?.out_time).format("HH:mm:ss")
            : "",
          financial_year: 202425,
        };
        const response = await dispatch(createOfficeStaffOut(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/officestaffout");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={OfficeStaffOutDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {staff_out_guid
                      ? "Update Office Staff Out"
                      : "Create Office Staff Out"}
                  </Typography.h4>
                </GridLayoutItem>
                {staff_out_guid && (
                  <GridLayoutItem>
                    <Field
                      name="staff_out_no"
                      label="Staff Out No"
                      disabled={true}
                      placeholder="Staff Out No"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="staff_out_date"
                    label="Staff Out Date"
                    format="dd/MM/yyyy"
                    disabled={true}
                    component={FormDatePicker}
                  />
                </GridLayoutItem>
                {staff_out_guid ? (
                  <GridLayoutItem colSpan={2}></GridLayoutItem>
                ) : (
                  <GridLayoutItem colSpan={3}></GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="user_id"
                    label="Employee Name"
                    placeholder="Employee Name"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={UserList?.map((user: IUser) => {
                      return {
                        value: user?.id,
                        label: `${user?.first_name} ${user?.last_name}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperStyle={{ width: "100%" }}
                    name="out_time"
                    label="Out Time"
                    component={FormTimePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperStyle={{ width: "100%" }}
                    name="in_time"
                    label="In Time"
                    component={FormTimePicker}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="approved_by"
                    label="Approved By Name"
                    placeholder="Approved By Name"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={UserList?.map((user: IUser) => {
                      return {
                        value: user?.id,
                        label: `${user?.first_name} ${user?.last_name}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name="reason_for_out"
                    label="Reason For Out"
                    placeholder="Reason"
                    component={FormTextArea}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 10,
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={staff_out_guid ? "Update" : "Create"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={() => navigate("/officestaffout")}
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <OutTimeChangeWatcher formRenderProps={formRenderProps} />
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateOfficeStaffOut;
