import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import {
  emailValidator,
  MobileNoValidator,
  requiredValidator,
} from "../../../components/formFields/CommonValidator";
import FormTextField from "../../../components/formFields/FormTextField";
import {
  BLOOD_GROUPS,
  GENDER,
  MARITAL_STATUS,
  NATIONALITY,
  WEEK_DAYS,
} from "../../../_contstants/common";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import {
  createEmployee,
  getAllActiveEmployee,
  getEmployeeByID,
  updateEmployee,
  uploadEmployeeImages,
} from "../services/employee.services";
import { clearEmployeeDetails, increaseActiveStep } from "../employeeSlice";
import ShadowCard from "../../../components/common/ShadowCard";
import FormDatePicker from "../../../components/formFields/FormDateField";
import { Button } from "@progress/kendo-react-buttons";
// import FormImageUpload from "../../../components/formFields/FormImageUpload";
import { LoadingPanel } from "../../../components/layout/Loading";
import moment from "moment";
import FormTimePicker from "../../../components/formFields/FormTimeField";
import { getAllActiveStates } from "../../state/services/state.services";
import { IState } from "../../state/stateModel";
import FormCheckbox from "../../../components/formFields/FormCheckbox";
import { IDepartment } from "../../department/departmentModel";
import { findAllActiveDepartment } from "../../department/services/department.services";
import FormImageUpload from "../../../components/formFields/FormImageUpload";
import { getAllActiveDesignations } from "../../designation/services/designation.services";
import { IDesignation } from "../../designation/designationModel";
import { SuccessToast } from "../../../components/toast/Toasts";
import { IRelation } from "../../relation/relationModel";
import { IEmployee } from "../employeeModel";
import { getAllActiveRelations } from "../../relation/services/relation.services";

const EmployeeInformation: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const gridRef = useRef<any>(null);
  const EmployeeID = location.state?.EmployeeID;
  const EmployeeGUID = location.state?.EmployeeGUID;
  const StateList = useAppSelector((state) => state?.state?.StateList);
  const RelationList = useAppSelector((state) => state?.relation?.RelationList);
  const DepartmentList = useAppSelector(
    (state) => state?.department?.DepartmentList
  );
  const EmployeeList = useAppSelector((state) => state?.employee?.EmployeeList);
  const DesignationList = useAppSelector(
    (state) => state?.designation?.DesignationList
  );
  // const CityList = useAppSelector((state) => state?.city?.CityList);
  // console.log("EmployeeID", EmployeeID);
  const formloading = useAppSelector((state) => state.employee.formloading);
  const EmployeeDetails = useAppSelector(
    (state) => state.employee.EmployeeDetails
  );
  const [formKey, setFormKey] = useState(1);
  useEffect(() => {
    dispatch(getAllActiveStates());
    dispatch(findAllActiveDepartment());
    dispatch(getAllActiveDesignations());
    dispatch(getAllActiveEmployee());
    dispatch(getAllActiveRelations());
    return () => {
      dispatch(clearEmployeeDetails());
    };
  }, []);
  useEffect(() => {
    if (EmployeeGUID) {
      dispatch(getEmployeeByID(EmployeeGUID));
    }
  }, [EmployeeGUID]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [EmployeeDetails]);

  // console.log("location", location);
  // console.log("User_GUID", EmployeeGUID);

  const handleSubmit = async (values: any) => {
    const formdata = {
      employee_code: values?.employee_code,
      employee_code_string: values?.employee_code_string,
      first_name: values?.first_name,
      middle_name: values?.middle_name,
      last_name: values?.last_name,
      gender: values?.gender,
      mobile_no: values?.mobile_no,
      email: values?.email,
      department_id: values?.department_id,
      designation_id: values?.designation_id,
      official_email: values?.official_email,
      date_of_joining: values?.date_of_joining
        ? moment(values?.date_of_joining).format("YYYY-MM-DD")
        : null,
      work_shift_time: values?.work_shift_time
        ? moment(values?.work_shift_time).format("HH:mm")
        : "",
      weekly_off: values?.weekly_off,
      left_date: values?.left_date
        ? moment(values?.left_date).format("YYYY-MM-DD")
        : null,
      location: values?.location,
      reporting_person:values?.reporting_person,
      dob: values?.dob ? moment(values?.dob).format("YYYY-MM-DD") : null,
      blood_group: values?.blood_group,
      personal_email: values?.personal_email,
      marital_status: values?.marital_status,
      date_of_marrige: values?.date_of_marrige
        ? moment(values?.date_of_marrige).format("YYYY-MM-DD")
        : null,
      spouse_name: values?.spouse_name,
      nationality: values?.nationality,
      address: values?.address,
      street: values?.street,
      area: values?.area,
      city: values?.city,
      pincode: values?.pincode,
      state_id: values?.state_id,
      is_permanent_address_same: values?.is_permanent_address_same,
      permenent_address: values?.permenent_address,
      permenent_street: values?.permenent_street,
      permenent_area: values?.permenent_area,
      permenent_city: values?.permenent_city,
      permenent_pincode: values?.permenent_pincode,
      permenent_state_id: values?.permenent_state_id,
      emergency_name: values?.emergency_name,
      emergency_mobile: values?.emergency_mobile,
      emergency_relationship_id: values?.emergency_relationship_id,
      isactive: values?.isactive === 1,
    };
    try {
      if (!EmployeeGUID) {
        const response = await dispatch(createEmployee(formdata));
        console.log("response", response);
        if (
          response.payload?.Status === 201 &&
          (response.payload?.user_guid || response.payload?.id)
        ) {
          if (
            typeof values?.user_image !== "string" ||
            values?.user_image.length > 0
          ) {
            const files = new FormData();
            files.append("user_guid", response.payload?.user_guid);
            const UserImage = values?.user_image[0].getRawFile();
            files.append("user_image", UserImage);
            const res = await dispatch(uploadEmployeeImages(files));
            if (res.payload?.Data?.Status === 200) {
              SuccessToast(
                res.payload?.Data?.Details || "Image Upload Success!"
              );
            }
          }
          navigate("/employee/edit", {
            state: {
              EmployeeGUID: response.payload?.user_guid,
              EmployeeID: response.payload?.id,
            },
          });
          dispatch(increaseActiveStep());
        }
      } else {
        const editPayload = {
          user_guid: EmployeeGUID,
          ...formdata,
        };
        const response = await dispatch(updateEmployee(editPayload));
        // console.log("response", response);
        if (response?.meta?.requestStatus === "fulfilled") {
          if (
            typeof values?.user_image !== "string" &&
            values?.user_image?.length > 0
          ) {
            const files = new FormData();
            files.append("user_guid", EmployeeGUID);
            const UserImage = values?.user_image[0].getRawFile();
            files.append("user_image", UserImage);
            const res = await dispatch(uploadEmployeeImages(files));
            //  console.log("res", res);
            if (res.payload?.Data?.Status === 200) {
              SuccessToast(
                res.payload?.Data?.Details || "Image Upload Success!"
              );
            }
          }
          navigate("/employee/edit", {
            state: {
              EmployeeGUID: EmployeeGUID,
              EmployeeID: EmployeeID,
            },
          });
          dispatch(increaseActiveStep());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };
  if (formloading) return <LoadingPanel gridRef={gridRef} />;
  // console.log("EmployeeDetail", EmployeeDetails);
  //console.log("DepartmentList",DepartmentList);

  return (
    <Form
      onSubmit={handleSubmit}
      key={formKey}
      initialValues={EmployeeDetails}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 10 }}>
            <GridLayout
              style={{ marginRight: 30 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem colSpan={3}>
                <Typography.h4>{"Employee Information"}</Typography.h4>
              </GridLayoutItem>
              {EmployeeGUID && (
                <GridLayoutItem colSpan={3}>
                  <Typography.h5>
                    Employee Code : {EmployeeDetails?.employee_code_string}
                  </Typography.h5>
                </GridLayoutItem>
              )}

              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="first_name"
                  label="First Name"
                  placeholder="First Name"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="middle_name"
                  label="Middle Name"
                  placeholder="Last Name"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="last_name"
                  label="Last Name"
                  placeholder={"Last Name"}
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="gender"
                  label="Gender"
                  placeholder="Select Gender"
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={GENDER?.map((gender: any) => {
                    return {
                      value: gender?.value,
                      label: gender?.label,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="mobile_no"
                  label="Mobile No"
                  placeholder="Mobile No"
                  type="number"
                  max={10}
                  component={FormTextField}
                  validator={MobileNoValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="email"
                  label="Email"
                  placeholder="i.e.example@gmail.com"
                  component={FormTextField}
                  validator={emailValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"department_id"}
                  label={"Department"}
                  placeholder="Select Department"
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={DepartmentList?.map((department: IDepartment) => {
                    return {
                      value: department?.id,
                      label: department?.department_name,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"designation_id"}
                  label={"Designation"}
                  placeholder="Select Designation"
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={DesignationList?.map((designation: IDesignation) => {
                    return {
                      value: designation?.id,
                      label: designation?.designation_name,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="official_email"
                  label="Official Email"
                  placeholder="i.e.example@example.com"
                  component={FormTextField}
                  // validator={emailValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="date_of_joining"
                  label="Date Of Joining"
                  format={"dd/MM/yyyy"}
                  maxDate={moment().toDate()}
                  component={FormDatePicker}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="left_date"
                  label="Left Date"
                  format={"dd/MM/yyyy"}
                  // placeholder="L Date"
                  maxDate={moment().toDate()}
                  component={FormDatePicker}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="work_shift_time"
                  label="Work Shift Time"
                  component={FormTimePicker}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"weekly_off"}
                  label={"Weekly Off"}
                  placeholder="Select Day"
                  component={FormSelectionField}
                  //  validator={requiredValidator}
                  options={WEEK_DAYS?.map((day: any) => {
                    return {
                      value: day?.value,
                      label: day?.label,
                    };
                  })}
                />
              </GridLayoutItem>

              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="location"
                  label="Location"
                  placeholder="Location"
                  component={FormTextField}
                  //  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"reporting_person"}
                  label={"Reporting Person"}
                  placeholder="Select Person"
                  component={FormSelectionField}
                  //  validator={requiredValidator}
                  options={EmployeeList?.map((emp: IEmployee) => {
                    return {
                      value: emp?.id,
                      label: `${emp?.name}`,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={"user_image"}
                  label={"Employee Images"}
                  type={"file"}
                  accept="image/*"
                  component={FormImageUpload}
                  //  validator={requiredValidator}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
          <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem colSpan={3}>
                <Typography.h4>{"Personal Information"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="dob"
                  label="Birth Date"
                  placeholder="Birth Date"
                  format={"dd/MM/yyyy"}
                  maxDate={moment().toDate()}
                  component={FormDatePicker}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="blood_group"
                  label="Blood Group"
                  component={FormSelectionField}
                  placeholder="Select Blood Group"
                  // validator={requiredValidator}
                  options={BLOOD_GROUPS?.map((group: any) => {
                    return {
                      value: group?.value,
                      label: group?.label,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="personal_email"
                  label="Personal Email"
                  placeholder="i.e.example@gmail.com"
                  component={FormTextField}
                  // validator={emailValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="marital_status"
                  label="Marital Status"
                  component={FormSelectionField}
                  placeholder="Select Marital Status"
                  // validator={requiredValidator}
                  options={MARITAL_STATUS?.map((mstatus: any) => {
                    return {
                      value: mstatus?.value,
                      label: mstatus?.label,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="date_of_marrige"
                  label="Marriage Date"
                  format={"dd/MM/yyyy"}
                  maxDate={moment().toDate()}
                  component={FormDatePicker}
                  disabled={
                    formRenderProps.valueGetter("marital_status") !== "Married"
                  }
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="spouse_name"
                  label="Spouse Name"
                  placeholder="Spouse Name"
                  disabled={
                    formRenderProps.valueGetter("marital_status") !== "Married"
                  }
                  component={FormTextField}
                  // validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"nationality"}
                  label={"Nationality"}
                  component={FormSelectionField}
                  placeholder="Select Nationality"
                  // validator={requiredValidator}
                  options={NATIONALITY?.map((origin: any) => {
                    return {
                      value: origin?.value,
                      label: origin?.label,
                    };
                  })}
                />
              </GridLayoutItem>
              {/* <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"isactive"}
                  name={"isactive"}
                  label={"Status"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={STATUSARRAY?.map((status: any) => {
                    return {
                      value: status?.value,
                      label: status?.label,
                    };
                  })}
                />
              </GridLayoutItem> */}
            </GridLayout>
          </ShadowCard>
          <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem colSpan={3}>
                <Typography.h4>{"Address Information"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem colSpan={3}>
                <Typography.h5 style={{ textDecoration: "underline" }}>
                  {"Present Address"}
                </Typography.h5>
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="address"
                  label="Address"
                  placeholder="Address"
                  component={FormTextField}
                  // validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="street"
                  label="Street"
                  placeholder="Street"
                  component={FormTextField}
                  // validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="area"
                  label="Area"
                  placeholder="Area"
                  component={FormTextField}
                  // validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="city"
                  label="City"
                  placeholder="i.e. Ahmedabad"
                  component={FormTextField}
                  // validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="pincode"
                  label="Pincode"
                  placeholder="i.e. 382408"
                  max={6}
                  type="number"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="state_id"
                  label="State"
                  placeholder="Select State"
                  component={FormSelectionField}
                  options={StateList?.map((state: IState) => {
                    return {
                      value: state?.id,
                      label: state?.state_name,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem
                colSpan={3}
                className="d-flex align-items-end gap-3"
              >
                <Typography.h5
                  style={{ textDecoration: "underline", margin: 0 }}
                >
                  {"Permanent Address"}
                </Typography.h5>
                <Field
                  name="is_permanent_address_same"
                  label="Same as Above"
                  component={FormCheckbox}
                  size={"large"}
                  labelClassName={"fw-bold"}
                />
              </GridLayoutItem>
              {!formRenderProps.valueGetter("is_permanent_address_same") && (
                <>
                  <GridLayoutItem style={{ width: "100%" }}>
                    <Field
                      name="permenent_address"
                      label="Address"
                      placeholder="Address"
                      component={FormTextField}
                      // validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem style={{ width: "100%" }}>
                    <Field
                      name="permenent_street"
                      label="Street"
                      placeholder="Street"
                      component={FormTextField}
                      // validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem style={{ width: "100%" }}>
                    <Field
                      name="permenent_area"
                      label="Area"
                      placeholder="Area"
                      component={FormTextField}
                      // validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem style={{ width: "100%" }}>
                    <Field
                      name="permenent_city"
                      label="City"
                      placeholder="i.e. Ahmedabad"
                      component={FormTextField}
                      // validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem style={{ width: "100%" }}>
                    <Field
                      name="permenent_pincode"
                      label="Pincode"
                      placeholder="i.e. 382408"
                      max={6}
                      type="number"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="permenent_state_id"
                      label="State"
                      placeholder="Select State"
                      component={FormSelectionField}
                      options={StateList?.map((state: IState) => {
                        return {
                          value: state?.id,
                          label: state?.state_name,
                        };
                      })}
                    />
                  </GridLayoutItem>
                </>
              )}
            </GridLayout>
          </ShadowCard>
          <ShadowCard style={{ padding: 10, margin: "10px 0" }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem colSpan={3}>
                <Typography.h4>{"Emergency Contact"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="emergency_name"
                  label="Name"
                  placeholder="Name"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="emergency_mobile"
                  label="Mobile No"
                  placeholder="Mobile No"
                  type="number"
                  max={10}
                  component={FormTextField}
                  // validator={MobileNoValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="emergency_relationship_id"
                  label="Relationship"
                  placeholder="Select Relation"
                  component={FormSelectionField}
                  options={RelationList?.map((relation: IRelation) => {
                    return {
                      value: relation?.id,
                      label: relation?.relation,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem
                colSpan={3}
                style={{
                  textAlign: "end",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <Button
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 4 }}
                  onClick={() => navigate("/employee")}
                >
                  Cancel
                </Button>
                <ButtonWithLoading
                  label={EmployeeGUID ? "Update" : "Add"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || formloading}
                  loading={formloading}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
        </FormElement>
      )}
    />
  );
};

export default EmployeeInformation;
