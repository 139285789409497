import React, { useEffect, useRef, useState } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { Button } from "@progress/kendo-react-buttons";
import { LoadingPanel } from "../../components/layout/Loading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { STATUSARRAY } from "../../_contstants/common";
import {
  clearItemGroupDetails,
  clearItemGroupMarginRateID,
  setItemGroupMarginRateID,
} from "./itemgroupSlice";
import {
  createItemGroup,
  createItemGroupMarginRate,
  deleteItemGroupMarginRate,
  getAllItemGroup,
  getItemGroupById,
  updateItemGroup,
  updateItemGroupMarginRate,
} from "./services/itemgroup.services";
import { Typography } from "@progress/kendo-react-common";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import { useLocation, useNavigate } from "react-router-dom";
import ShadowCard from "../../components/common/ShadowCard";
import FormDatePicker from "../../components/formFields/FormDateField";
import ButtonForAll from "../../components/common/Button";
import moment from "moment";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { formatIndianNumber } from "../../_helper/helper";

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const CreateItemGroup: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const location = useLocation();
  const item_group_guid = location.state?.item_group_guid;
  const formLoading = useAppSelector((state) => state.itemgroup.formLoading);
  const ItemGroupDetails = useAppSelector(
    (state) => state.itemgroup.ItemGroupDetail
  );
  const [submitAction, setSubmitAction] = useState("value");
  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    return () => {
      dispatch(clearItemGroupDetails());
    };
  }, []);

  useEffect(() => {
    if (item_group_guid) {
      dispatch(getItemGroupById(item_group_guid));
    }
  }, [item_group_guid]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [ItemGroupDetails]);

  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? ItemGroupDetails?.item_group_margin_structure?.length ||
          event.page.take
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (
    props: GridCellProps,
    formRenderProps: FormRenderProps
  ) => {
    const handleEdit = (item: any) => {
      formRenderProps.onChange("item_group_margin_structure_id", {
        value: item?.id,
      });
      formRenderProps.onChange("from_date", {
        value: item?.from_date
          ? moment(item?.from_date, "DD/MM/YYYY").toDate()
          : "",
      });
      formRenderProps.onChange("to_date", {
        value: item?.to_date
          ? moment(item?.to_date, "DD/MM/YYYY").toDate()
          : "",
      });
      formRenderProps.onChange("agent_margin", {
        value: item?.agent_margin,
      });
      formRenderProps.onChange("agent_marginvalue1", {
        value: item?.agent_marginvalue1 || "0",
      });
      formRenderProps.onChange("agent_marginvalue2", {
        value: item?.agent_marginvalue2 || "0",
      });
      formRenderProps.onChange("retailer_margin", {
        value: item?.retailer_margin,
      });
      formRenderProps.onChange("retailer_value", {
        value: item?.retailer_value || "0",
      });
      formRenderProps.onChange("conversion_unit", {
        value: item?.conversion_unit,
      });
    };

    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteItemGroupMarginRate"));
      dispatch(setItemGroupMarginRateID(ID));
    };

    return (
      <td
        className={props.className}
        style={{
          ...props.style,
          textAlign: "center",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEdit(props.dataItem)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem?.id)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 14 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const handleClearMarginrate = (formRenderProps: FormRenderProps) => {
    formRenderProps.onChange("item_group_margin_structure_id", {
      value: null,
    });
    formRenderProps.onChange("from_date", {
      value: "",
    });
    formRenderProps.onChange("to_date", {
      value: "",
    });
    formRenderProps.onChange("agent_margin", {
      value: null,
    });
    formRenderProps.onChange("agent_marginvalue1", {
      value: "",
    });
    formRenderProps.onChange("agent_marginvalue2", {
      value: "",
    });
    formRenderProps.onChange("retailer_margin", {
      value: null,
    });
    formRenderProps.onChange("retailer_value", {
      value: "",
    });
    formRenderProps.onChange("conversion_unit", {
      value: 1,
    });
  };

  const handleSubmit = async (values: any) => {
    if (submitAction === "value") {
      try {
        if (!item_group_guid) {
          const payload = {
            item_group: values.item_group,
            remarks: values.remarks,
            isactive: values.isactive === 1,
          };
          const response = await dispatch(createItemGroup(payload));
          if (response?.meta?.requestStatus === "fulfilled") {
            dispatch(clearItemGroupDetails());
            navigate("/itemgroup/edit", {
              state: {
                item_group_guid: response.payload?.item_group_guid,
              },
            });
          }
        } else {
          const payload = {
            item_group_guid: item_group_guid,
            item_group: values.item_group,
            remarks: values.remarks,
            isactive: values.isactive === 1,
          };
          const response = await dispatch(updateItemGroup(payload));
          if (response?.meta?.requestStatus === "fulfilled") {
            navigate("/itemgroup");
            dispatch(clearItemGroupDetails());
            dispatch(getAllItemGroup());
          }
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        if (!values?.item_group_margin_structure_id) {
          const insertPayload = {
            item_group_id: ItemGroupDetails?.id ? ItemGroupDetails?.id : null,
            from_date: values?.from_date
              ? moment(values?.from_date).format("YYYY-MM-DD")
              : "",
            to_date: values?.to_date
              ? moment(values?.to_date).format("YYYY-MM-DD")
              : "",
            agent_margin: values?.agent_margin ? +values?.agent_margin : null,
            agent_marginvalue1: values?.agent_marginvalue1
              ? +values?.agent_marginvalue1
              : 0,
            agent_marginvalue2: values?.agent_marginvalue2
              ? +values?.agent_marginvalue2
              : 0,
            retailer_margin: values?.retailer_margin
              ? +values?.retailer_margin
              : null,
            retailer_value: values?.retailer_value
              ? +values?.retailer_value
              : 0,
            conversion_unit: values?.conversion_unit
              ? +values?.conversion_unit
              : 0,
          };
          const response = await dispatch(
            createItemGroupMarginRate(insertPayload)
          );
          if (response?.meta?.requestStatus === "fulfilled") {
            dispatch(getItemGroupById(item_group_guid));
          }
        } else {
          const updatePayload = {
            item_group_margin_structure_id:
              values?.item_group_margin_structure_id
                ? values?.item_group_margin_structure_id
                : null,
            item_group_id: ItemGroupDetails?.id ? ItemGroupDetails?.id : null,
            from_date: values?.from_date
              ? moment(values?.from_date).format("YYYY-MM-DD")
              : "",
            to_date: values?.to_date
              ? moment(values?.to_date).format("YYYY-MM-DD")
              : "",
            agent_margin: values?.agent_margin ? +values?.agent_margin : null,
            agent_marginvalue1: values?.agent_marginvalue1
              ? +values?.agent_marginvalue1
              : 0,
            agent_marginvalue2: values?.agent_marginvalue2
              ? +values?.agent_marginvalue2
              : 0,
            retailer_margin: values?.retailer_margin
              ? +values?.retailer_margin
              : null,
            retailer_value: values?.retailer_value
              ? +values?.retailer_value
              : 0,
            conversion_unit: values?.conversion_unit
              ? +values?.conversion_unit
              : 0,
          };
          const response = await dispatch(
            updateItemGroupMarginRate(updatePayload)
          );
          if (response?.meta?.requestStatus === "fulfilled") {
            dispatch(getItemGroupById(item_group_guid));
          }
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <DeleteMarginRateDialog />
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={ItemGroupDetails}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement style={{ width: "100%" }}>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {item_group_guid
                      ? "Update Item Group"
                      : "Create Item Group"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name="item_group"
                    label="Item Group"
                    placeholder="Item Group"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  rowSpan={2}
                  style={{ width: "100%" }}
                >
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ width: "100%", display: "flex", alignItems: "end" }}
                >
                  <Field
                    wrapperClassName="w-100"
                    id={"isactive"}
                    name={"isactive"}
                    label={"Status"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={STATUSARRAY?.map((status: any) => {
                      return {
                        value: status.value,
                        label: status.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <ButtonForAll
                    label={item_group_guid ? "Update" : "Create"}
                    type="submit"
                    onClick={() => setSubmitAction("value")}
                    disabled={!formRenderProps.allowSubmit || formLoading}
                  />
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginLeft: 4 }}
                    onClick={() => navigate("/itemgroup")}
                  >
                    Cancel
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            {item_group_guid && (
              <ShadowCard style={{ padding: 12, marginTop: 10 }}>
                <GridLayout cols={[{ width: "100%" }]}>
                  <GridLayoutItem>
                    <Typography.h4>{"Margin Structure"}</Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem
                    className="bg-dark text-white fw-600"
                    style={{ width: "100%" }}
                  >
                    <GridLayout
                      style={{
                        marginRight: 70,
                        padding: "10px 0",
                      }}
                      gap={{ rows: 0, cols: 10 }}
                      cols={[
                        { width: "14%" },
                        { width: "14%" },
                        { width: "12%" },
                        { width: "12%" },
                        { width: "12%" },
                        { width: "12%" },
                        { width: "12%" },
                        { width: "12%" },
                      ]}
                    >
                      <GridLayoutItem className="ps-2">
                        From date
                      </GridLayoutItem>
                      <GridLayoutItem className="ps-2">To Date</GridLayoutItem>
                      <GridLayoutItem className="ps-2">
                        Conversion Unit
                      </GridLayoutItem>
                      <GridLayoutItem className="ps-2" colSpan={3}>
                        Agent Margin
                      </GridLayoutItem>
                      <GridLayoutItem className="ps-2" colSpan={2}>
                        Retailer Margin
                      </GridLayoutItem>
                    </GridLayout>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <GridLayout
                      style={{ marginRight: 70 }}
                      gap={{ rows: 0, cols: 10 }}
                      cols={[
                        { width: "14%" },
                        { width: "14%" },
                        { width: "12%" },
                        { width: "12%" },
                        { width: "12%" },
                        { width: "12%" },
                        { width: "12%" },
                        { width: "12%" },
                      ]}
                    >
                      <GridLayoutItem>
                        <Field
                          name="from_date"
                          format="dd/MM/yyyy"
                          component={FormDatePicker}
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          name="to_date"
                          format="dd/MM/yyyy"
                          component={FormDatePicker}
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          name={`conversion_unit`}
                          placeholder="Unit"
                          component={FormSelectionField}
                          options={[
                            { value: 1, label: "KG" },
                            { value: 2, label: "PKT" },
                          ]}
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          name={`agent_margin`}
                          placeholder="% / ₹"
                          component={FormSelectionField}
                          options={[
                            { value: 1, label: "%" },
                            { value: 2, label: "₹" },
                          ]}
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          name={`agent_marginvalue1`}
                          placeholder="Value 1"
                          component={FormTextField}
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          name={`agent_marginvalue2`}
                          placeholder="Value 2"
                          component={FormTextField}
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          name={`retailer_margin`}
                          placeholder="% / ₹"
                          component={FormSelectionField}
                          options={[
                            { value: 1, label: "%" },
                            { value: 2, label: "₹" },
                          ]}
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          name={`retailer_value`}
                          placeholder="Value 1"
                          component={FormTextField}
                        />
                      </GridLayoutItem>
                      <GridLayoutItem
                        colSpan={8}
                        style={{ textAlign: "end", marginTop: 20 }}
                      >
                        <ButtonForAll
                          label={
                            formRenderProps.valueGetter(
                              "item_group_margin_structure_id"
                            )
                              ? "Update"
                              : "Add"
                          }
                          disabled={
                            formRenderProps.valueGetter("from_date") &&
                            formRenderProps.valueGetter("to_date") &&
                            formRenderProps.valueGetter("conversion_unit")
                              ? // formRenderProps.valueGetter("agent_margin") &&
                                // formRenderProps.valueGetter("agent_marginvalue1") &&
                                // formRenderProps.valueGetter("agent_marginvalue2") &&
                                // formRenderProps.valueGetter("retailer_margin") &&
                                // formRenderProps.valueGetter("retailer_value")
                                false
                              : true
                          }
                          type="submit"
                          onClick={() => setSubmitAction("add")}
                          // loading={loading}
                        />
                        <Button
                          style={{ marginLeft: 4 }}
                          fillMode={"outline"}
                          themeColor={"primary"}
                          type="button"
                          onClick={() => {
                            handleClearMarginrate(formRenderProps);
                          }}
                        >
                          Clear
                        </Button>
                      </GridLayoutItem>
                    </GridLayout>
                  </GridLayoutItem>
                </GridLayout>
              </ShadowCard>
            )}
            {ItemGroupDetails &&
              ItemGroupDetails?.item_group_margin_structure &&
              ItemGroupDetails?.item_group_margin_structure?.length > 0 && (
                <ShadowCard style={{ padding: 12, marginTop: 10 }}>
                  <GridLayoutItem>
                    <Typography.h4 className="m-0">
                      {"Margin Structure List"}
                    </Typography.h4>
                  </GridLayoutItem>
                  <KendoGrid
                    style={{ marginTop: 14 }}
                    data={ItemGroupDetails?.item_group_margin_structure}
                    skip={page.skip}
                    take={page.take}
                    total={
                      ItemGroupDetails?.item_group_margin_structure?.length
                    }
                    pageable={{
                      buttonCount: 5,
                      pageSizes: [5, 10, 15, "All"],
                      pageSizeValue: pageSizeValue,
                      type: "input",
                    }}
                    onPageChange={pageChange}
                    cells={{
                      headerCell: HeaderCustomCell,
                      data: MyDataCustomCell,
                    }}
                  >
                    <Column field="from_date" title="From Date" />
                    <Column field="to_date" title="To Date" />
                    <Column
                      field="conversion_unit"
                      title="Conversion Unit"
                      cell={(props: any) => (
                        <td>
                          {props.dataItem?.conversion_unit === 1
                            ? "KG"
                            : props.dataItem?.conversion_unit === 2
                            ? "PKT"
                            : ""}
                        </td>
                      )}
                    />
                    <Column
                      field="agent_marginvalue1"
                      title="Agent Margin value 1"
                      cell={(props: any) => (
                        <td className="text-center">
                          {props.dataItem?.agent_marginvalue1
                            ? formatIndianNumber(
                                props.dataItem?.agent_marginvalue1
                              )
                            : 0}{" "}
                          {props.dataItem?.agent_marginvalue1 &&
                            (props.dataItem?.agent_margin === 1 ? "%" : "₹")}
                        </td>
                      )}
                    />
                    <Column
                      field="agent_marginvalue2"
                      title="Agent Margin Value 2"
                      cell={(props: any) => (
                        <td className="text-center">
                          {props.dataItem?.agent_marginvalue2
                            ? formatIndianNumber(
                                props.dataItem?.agent_marginvalue2
                              )
                            : 0}{" "}
                          {props.dataItem?.agent_marginvalue2 &&
                            (props.dataItem?.agent_margin === 1 ? "%" : "₹")}
                        </td>
                      )}
                    />
                    <Column
                      field="retailer_value"
                      title="Retailer Value"
                      cell={(props: any) => (
                        <td className="text-center">
                          {props.dataItem?.retailer_value
                            ? formatIndianNumber(props.dataItem?.retailer_value)
                            : 0}{" "}
                          {props.dataItem?.retailer_value &&
                            (props.dataItem?.retailer_margin === 1 ? "%" : "₹")}
                        </td>
                      )}
                    />
                    <Column
                      field="ID"
                      title="Actions"
                      cell={(props: any) =>
                        MyEditCommandCell(props, formRenderProps)
                      }
                      width={"110px"}
                      filterable={false}
                      locked={true}
                    />
                  </KendoGrid>
                </ShadowCard>
              )}
          </FormElement>
        )}
      />
    </>
  );
};

const DeleteMarginRateDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const item_group_guid = location.state?.item_group_guid;
  const ItemGroupMarginRateID = useAppSelector(
    (state) => state.itemgroup.ItemGroupMarginRateID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteItemGroupMarginRate(ID));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(getItemGroupById(item_group_guid));
        dispatch(closeDialog());
        dispatch(clearItemGroupMarginRateID());
      } else {
        dispatch(getItemGroupById(item_group_guid));
        dispatch(closeDialog());
        dispatch(clearItemGroupMarginRateID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteItemGroupMarginRate" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Margin rate"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this margin rate?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(ItemGroupMarginRateID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default CreateItemGroup;
