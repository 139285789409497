import React from "react";
import { PieChartOutlined, UserOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { GrTransaction } from "react-icons/gr";
import { TbReportSearch } from "react-icons/tb";
import { SiHiveBlockchain } from "react-icons/si";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Dashboard", "", <PieChartOutlined />),
  getItem("User Management", "usermanagement", <UserOutlined />, [
    getItem("Menu", "menu"),
    getItem("Rights", "rights"),
    getItem("User", "user"),
    getItem("User Type", "usertype"),
  ]),
  getItem("Masters", "masters", <SiHiveBlockchain />, [
    getItem("Employee", "employee"),
    getItem("Country", "country"),
    getItem("State", "state"),
    getItem("City", "city"),
    getItem("Designation", "designation"),
    getItem("Document", "document"),
    getItem("Unit", "unit"),
    getItem("Item Category", "itemcategory"),
    getItem("Item Group", "itemgroup"),
    getItem("Item", "item", "", [
      getItem("Raw Material Items", "rawmaterialitem"),
      getItem("Semi Finished Items", "semifinisheditem"),
      getItem("Finished Items", "finisheditem"),
      getItem("Packing Material Items", "packingmaterialitem"),
    ]),
    getItem("Machine", "machine"),
    getItem("GST", "gst"),
    getItem("Warehouse", "warehouse"),
    getItem("Account", "account"),
    getItem("Process", "process"),
    getItem("Department", "department"),
    getItem("Holiday", "holiday"),
    getItem("Leave", "leave"),
    getItem("Relation", "relation"),
    getItem("Profession Tax Slab", "professiontaxslab"),
    getItem("BOM", "bom"),
  ]),
  getItem("Transaction", "transaction", <GrTransaction />, [
    getItem("Purchase Order", "purchaseorder"),
    getItem("Inward", "inward"),
    getItem("Inward Return", "inwardreturn"),
    getItem("Product Cleaning", "productcleaning"),
    getItem("Product Milling", "productmilling"),
    getItem("Roasting", "roasting"),
    getItem("Production", "production"),
    getItem("Magnet Cleaning", "magnetcleaning"),
    getItem("Machine Cleaning", "machinecleaning"),
    getItem("Packing", "packing"),
    getItem("Inward Packing Material", "inwardpackingmaterial"),
    getItem("Outward Packing Material", "outwardpackingmaterial"),
    getItem("Stock Transfer", "stocktransfer"),
    getItem("Sales Order", "salesorder"),
    getItem("Sales Challan", "saleschallan"),
    getItem("Sales Invoice", "sales"),
    getItem("Visitor", "visitor"),
    getItem("Office Staff Out", "officestaffout"),
    // getItem("Distributor Opening Stock", "distributoropeningstock"),
    // getItem("Retailer Sales Order", "retailersalesorder"),
  ]),
  getItem("Reports", "reports", <TbReportSearch />, [
    getItem("Product Cleaning ", "productcleaningreportview"),
    getItem("Product Milling ", "productmillingreportview"),
    getItem("Roasting ", "roastingreportview"),
    getItem("Production ", "productionreportview"),
    getItem("Ghanti Cleaning ", "ghanticleaningreportview"),
    getItem("Metal Cleaning ", "metalcleaningreportview"),
    getItem("Inward Register ", "inwardregisterreportview"),
    getItem("Stock ", "stockreportview"),
    getItem(
      "Inward Packing Material Stock Detail ",
      "inwardpackingstockreportview"
    ),
    getItem(
      "Outward Packing Material Stock Detail ",
      "outwordpackingstockreportview"
    ),
    getItem("Packing Register ", "packingregisterreportview"),
    getItem("Packing Material Stock", "packingmaterialstockreportview"),
    getItem("Visitor ", "visitorreportview"),
    getItem("Office Staff Out ", "officestaffoutreportview"),
  ]),
];

interface HeaderProps {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClick: MenuProps["onClick"] = (e) => {
    navigate(e.key);
  };

  const userResponseString = localStorage.getItem("UserRightsAssign") || "[]";
  let userResponse: {
    menu_id: number;
    menu_key: string;
    menu_name: string;
    rights_id: string;
  }[];

  try {
    userResponse = JSON.parse(userResponseString);
  } catch (error) {
    userResponse = [];
  }

  const allowedKeys = new Set(userResponse.map((item) => item.menu_key));

  // function filterMenujItems(menuItems: MenuItem[]): MenuItem[] {
  //   return menuItems.reduce((filteredItems, item: any) => {
  //     if (allowedKeys.has(item.key)) {
  //       if (item.children) {
  //         const filteredChildren = filterMenuItems(item.children);
  //         filteredItems.push({ ...item, children: filteredChildren });
  //       } else {
  //         filteredItems.push(item);
  //       }
  //     } else if (item.children) {
  //       const filteredChildren = filterMenuItems(item.children);
  //       if (filteredChildren.length > 0) {
  //         filteredItems.push({ ...item, children: filteredChildren });
  //       }
  //     }
  //     return filteredItems;
  //   }, [] as MenuItem[]);
  // }
  function filterMenuItems(
    menuItems: MenuItem[],
    depth: number = 0
  ): MenuItem[] {
    return menuItems.reduce((filteredItems, item: any) => {
      // Check rights only for the first two layers (depth 0 and 1)
      if (depth < 2) {
        if (allowedKeys.has(item.key)) {
          if (item.children) {
            const filteredChildren = filterMenuItems(item.children, depth + 1);
            filteredItems.push({ ...item, children: filteredChildren });
          } else {
            filteredItems.push(item);
          }
        }
      } else if (depth >= 2) {
        filteredItems.push(item);
      }
      return filteredItems;
    }, [] as MenuItem[]);
  }

  let filteredItems = filterMenuItems(items);

  const hasDashboardAccess = userResponse.some(
    (item) => item.menu_key === "dashboard"
  );
  if (hasDashboardAccess) {
    filteredItems = [
      getItem("Dashboard", "", <PieChartOutlined />),
      ...filteredItems,
    ];
  }

  function mapToGetItemFormat(menuItems: MenuItem[]): MenuItem[] {
    return menuItems.map((item: any) =>
      getItem(
        item.label,
        item.key,
        item.icon,
        item.children ? mapToGetItemFormat(item.children) : undefined
      )
    );
  }

  const formattedItems = mapToGetItemFormat(filteredItems);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div
        className="drawer-logo"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h4 style={{ margin: "0", color: "white" }}>GM</h4>
      </div>
      <Menu
        className="sider-scroll"
        style={{
          overflow: "auto",
          height: "calc(100vh - 115px)",
        }}
        onClick={onClick}
        theme="dark"
        defaultSelectedKeys={[""]}
        selectedKeys={[location.pathname?.substring(1)?.split("/")[0]]}
        mode="vertical"
        // mode="inline"
        // items={items}
        items={formattedItems}
      />
    </Sider>
  );
};

export default Header;
