import React, { useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { setSODeleteIndex } from "./salesOrderSlice";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { IItem } from "../Item/itemModel";
import { useLocation } from "react-router-dom";
import { IUnit } from "../unit/unitModel";
import { IGst } from "../gst/gstModel";
import { getItemById } from "../Item/services/item.services";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";

const SOItemDetailsArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const sale_order_guid = location.state?.sale_order_guid;
  const InputRef = React.useRef<any>(null);

  const SODeleteIndex = useAppSelector(
    (state) => state.salesOrder.SODeleteIndex
  );

  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const UnitList = useAppSelector((state) => state.unit.UnitList);
  const GstList = useAppSelector((state) => state.gst.GstList);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("soItemDeleteDialog"));
    dispatch(setSODeleteIndex(index));
  };

  useEffect(() => {
    if (!sale_order_guid) {
      pushElementInarray();
    }
  }, []);

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setSODeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        item_id: null,
        mrp: null,
        quantity: null,
        unit_id: null,
        rate: null,
        gst_id: null,
        amount: null,
      },
    });

    setTimeout(() => {
      InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    }, 0);
  }, [fieldArrayRenderProps]);

  const handleProductChange = async (
    e: any,
    innerIndex: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    const sales_order_items =
      fieldArrayRenderProps.formRenderProps.valueGetter("sales_order_items");
    if (e?.value) {
      const payload = {
        id: e?.value,
      };
      const response: any = await dispatch(getItemById(payload));
      sales_order_items &&
        sales_order_items.length > 0 &&
        sales_order_items.map((item: any, index: number) => {
          if (innerIndex === index) {
            if (response.meta.requestStatus === "fulfilled") {
              fieldArrayRenderProps.formRenderProps.onChange(
                `sales_order_items.${index}.gst_id`,
                {
                  value: `${response.payload?.gst_id}--${response.payload?.gst}`,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `sales_order_items.${index}.unit_id`,
                {
                  value: response.payload?.unit_id,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `sales_order_items.${index}.mrp`,
                {
                  value: response.payload?.mrp,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `sales_order_items.${index}.rate`,
                {
                  value: response.payload?.sales_price,
                }
              );
            } else {
              fieldArrayRenderProps.formRenderProps.onChange(
                `sales_order_items.${index}.gst_id`,
                {
                  value: null,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `sales_order_items.${index}.unit_id`,
                {
                  value: null,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `sales_order_items.${index}.mrp`,
                {
                  value: "",
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `sales_order_items.${index}.rate`,
                {
                  value: "",
                }
              );
            }
          }
        });
    } else {
      sales_order_items &&
        sales_order_items.length > 0 &&
        sales_order_items.map((item: any, index: number) => {
          if (innerIndex === index) {
            fieldArrayRenderProps.formRenderProps.onChange(
              `sales_order_items.${index}.gst_id`,
              {
                value: null,
              }
            );
            fieldArrayRenderProps.formRenderProps.onChange(
              `sales_order_items.${index}.unit_id`,
              {
                value: null,
              }
            );
            fieldArrayRenderProps.formRenderProps.onChange(
              `sales_order_items.${index}.mrp`,
              {
                value: "",
              }
            );
            fieldArrayRenderProps.formRenderProps.onChange(
              `sales_order_items.${index}.rate`,
              {
                value: "",
              }
            );
          }
        });
    }
  };

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          className="bg-dark text-white fw-600"
          style={{ width: "100%" }}
        >
          <GridLayout
            style={{
              marginRight: 35,
              padding: 10,
            }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "25%" },
              { width: "10%" },
              { width: "10%" },
              { width: "15%" },
              { width: "10%" },
              { width: "15%" },
              { width: "10%" },
              { width: "5%" },
            ]}
          >
            <GridLayoutItem>Item</GridLayoutItem>
            <GridLayoutItem>MRP</GridLayoutItem>
            <GridLayoutItem>Quantity</GridLayoutItem>
            <GridLayoutItem>Unit</GridLayoutItem>
            <GridLayoutItem>Rate</GridLayoutItem>
            <GridLayoutItem>GST</GridLayoutItem>
            <GridLayoutItem>Amount</GridLayoutItem>
            <GridLayoutItem></GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
        {dialogName === "soItemDeleteDialog" && (
          <AppDialog>
            <>
              <Typography.h5>{"Delete Item"}</Typography.h5>
              <GridLayout>
                <GridLayoutItem>
                  <Typography.p>
                    Are you sure you want to delete this?
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <DialogCloseButton themeColor="error" />
                  <Button
                    fillMode={"solid"}
                    themeColor={"error"}
                    onClick={() => handleDeleteAction(SODeleteIndex)}
                    type="button"
                  >
                    Delete
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </>
          </AppDialog>
        )}
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map(
            (sales_order_items: any, index: number) => (
              <GridLayoutItem key={index} style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 35, padding: 10, position: "relative" }}
                  gap={{ rows: 10, cols: 5 }}
                  cols={[
                    { width: "25%" },
                    { width: "10%" },
                    { width: "10%" },
                    { width: "15%" },
                    { width: "10%" },
                    { width: "15%" },
                    { width: "10%" },
                    { width: "5%" },
                  ]}
                >
                  <GridLayoutItem ref={InputRef}>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`sales_order_items.${index}.item_id`}
                      placeholder="Product"
                      isAddNew={true}
                      addNewLink="item/create"
                      component={FormIncrementalSearch}
                      validator={requiredValidator}
                      onChange={(e: any) =>
                        handleProductChange(e, index, fieldArrayRenderProps)
                      }
                      fetchIncrementalData={(search: any) =>
                        fieldArrayRenderProps.handleItemSearchChange(
                          search,
                          `sales_order_items.${index}.item_options`,
                          fieldArrayRenderProps.formRenderProps
                        )
                      }
                      options={
                        fieldArrayRenderProps.value[index]?.item_options?.map(
                          (item: IItem) => {
                            return {
                              value: item.id,
                              label: item.product_name,
                            };
                          }
                        ) || []
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`sales_order_items.${index}.mrp`}
                      placeholder="0"
                      type="number"
                      minNumber="0"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`sales_order_items.${index}.quantity`}
                      placeholder="0"
                      min="0"
                      validator={requiredValidator}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`sales_order_items.${index}.unit_id`}
                      placeholder="Unit"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={UnitList?.map((unit: IUnit) => {
                        return {
                          value: unit?.id,
                          label: unit?.unit_code,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`sales_order_items.${index}.rate`}
                      placeholder="0"
                      min="0"
                      validator={requiredValidator}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`sales_order_items.${index}.gst_id`}
                      placeholder="GST (%)"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={GstList?.map((gst: IGst) => {
                        return {
                          value: `${gst?.id}--${gst?.gst}`,
                          label: gst?.gst_title,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`sales_order_items.${index}.amount`}
                      placeholder="0"
                      format="n2"
                      disabled={true}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      position: "sticky",
                      top: 0,
                      right: 0,
                      // zIndex: 10,
                    }}
                  >
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(index)}
                      disabled={fieldArrayRenderProps.value.length === 1}
                      themeColor={"error"}
                      size={"small"}
                      fillMode={"solid"}
                      type="button"
                      style={{
                        position: "absolute",
                        height: 32,
                        width: 32,
                        top: 0,
                        right: 0,
                      }}
                    >
                      <MdDelete
                        className="absolute-position"
                        style={{ fontSize: "16px" }}
                      />
                    </IconButton>
                  </GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
            )
          )}
      </GridLayout>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{ marginTop: 10, borderTop: "1px solid lightgray" }}
        >
          <GridLayout
            style={{ marginRight: 45, padding: 10 }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "25%" },
              { width: "10%" },
              { width: "10%" },
              { width: "15%" },
              { width: "10%" },
              { width: "15%" },
              { width: "10%" },
              { width: "5%" },
            ]}
          >
            <GridLayoutItem colSpan={2} className="d-flex align-items-end">
              <Button
                fillMode={"solid"}
                themeColor={"primary"}
                onClick={pushElementInarray}
                type="button"
              >
                Add Row
              </Button>
            </GridLayoutItem>
            {/* <GridLayoutItem
              colSpan={4}
              className="d-flex justify-content-end align-items-center fw-600"
            >
              Total
            </GridLayoutItem>
            <GridLayoutItem colSpan={2}>
              <Field
                wrapperClassName="right-alighned-field"
                wrapperStyle={{ margin: 0 }}
                name="taxable_amount"
                format="n2"
                component={FormNumericTextField}
                disabled="true"
              />
            </GridLayoutItem> */}
          </GridLayout>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default SOItemDetailsArray;
