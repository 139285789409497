import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { FiEdit } from "react-icons/fi";
import AlertBox from "../../components/common/AlertBox";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import { useNavigate } from "react-router-dom";
import { getAllOrdersByUserID } from "./services/order.services";
import moment from "moment";

const OrderHistory: React.FC = () => {
  const dispatch = useAppDispatch();
  const AccountID = useAppSelector(
    (state) => state.login.loginDetails.AccountID
  );

  useEffect(() => {
    if (AccountID) {
      dispatch(getAllOrdersByUserID(+AccountID));
    }
  }, [AccountID]);

  return (
    <>
      <GridLayout
        cols={[{ width: "100%" }]}
        className="container"
        style={{ minWidth: 800 }}
      >
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "12px 20px 14px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Order History
          </Typography.h4>
        </GridLayoutItem>
        <GridLayoutItem>
          <OrderHistoryGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const OrderHistoryGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();

  const OrderList = useAppSelector((state) => state.order.OrderList);
  const loading = useAppSelector((state) => state.order.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? OrderList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (id: any) => {
      navigate("/order/edit", { state: { id: id } });
    };
    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEdit(props.dataItem.id)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {OrderList && OrderList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(OrderList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : OrderList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={OrderList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="sales_order_no" title="Sales Order No." />
          <Column
            field="sales_date"
            title="Sales Date"
            cell={(props: any) => (
              <td>
                {props.dataItem?.sales_date
                  ? moment(props.dataItem?.sales_date, "YYYY-MM-DD").format(
                      "DD/MM/YYYY"
                    )
                  : ""}
              </td>
            )}
          />
          <Column field="total_amount" title="Amount" />
          <Column field="modify_order_no" title="Updated No." />
          <Column
            field="ID"
            title="Action"
            cell={MyEditCommandCell}
            width={"80px"}
            filterable={false}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default OrderHistory;
