import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { IRoasting, IRoastingInitial } from "./roastingModel";
import {
  createRoasting,
  deleteRoasting,
  getAllActiveRoastings,
  getAllRoasting,
  getRoastingByID,
  updateRoasting,
} from "./services/roasting.services";

const initialState: IRoastingInitial = {
  loading: false,
  error: "",
  RoastingID: null,
  RoastingList: [],
  RoastingDetail: {
    roasting_date: moment().toDate(),
    roasting_no: null,
    item_id: null,
    grn_no: null,
    no_of_workers: null,
    machine_id: null,
    start_time: "",
    end_time: "",
    total_qty: null,
    received_qty: null,
    process_loss_qty: null,
    checker_by: null,
    financial_year: null,
    // department_id: null,
    previous_process_id: null,
    process_id: null,
    remarks: "",
  },
};

const productCleaningSlice = createSlice({
  name: "roasting",
  initialState,
  reducers: {
    clearRoastingDetails: (state) => {
      state.RoastingDetail = initialState.RoastingDetail;
    },
    setRoastingID: (state, action) => {
      state.RoastingID = action.payload;
    },
    clearRoastingID: (state) => {
      state.RoastingID = initialState.RoastingID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRoasting.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllRoasting.fulfilled,
      (state, action: PayloadAction<IRoasting[]>) => {
        state.loading = false;
        state.RoastingList = action.payload || [];
      }
    );
    builder.addCase(getAllRoasting.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RoastingList = [];
    });
    builder.addCase(getAllActiveRoastings.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveRoastings.fulfilled,
      (state, action: PayloadAction<IRoasting[]>) => {
        state.loading = false;
        state.RoastingList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveRoastings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RoastingList = [];
    });
    builder.addCase(createRoasting.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createRoasting.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(createRoasting.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateRoasting.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateRoasting.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateRoasting.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteRoasting.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteRoasting.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteRoasting.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getRoastingByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getRoastingByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.RoastingDetail = action.payload;
    });
    builder.addCase(getRoastingByID.rejected, (state, action) => {
      state.loading = false;
      state.RoastingDetail = initialState.RoastingDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearRoastingDetails, clearRoastingID, setRoastingID } =
  productCleaningSlice.actions;
export default productCleaningSlice.reducer;
