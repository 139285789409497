import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import moment from "moment";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const getAllProductions = createAsyncThunk(
  "Production/FindAllProduction",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Production/FindAllProduction`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Product cleanings:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createProduction = createAsyncThunk(
  "Production/InsertProduction",
  async (ProductionData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Production/InsertProduction`,
        ProductionData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating production:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateProduction = createAsyncThunk(
  "Production/UpdateProduction",
  async (ProductionData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Production/UpdateProduction`,
        ProductionData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating production:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteProduction = createAsyncThunk(
  "Production/DeleteProduction",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Production/DeleteProduction`,
        { Production_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting production:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getProductionByID = createAsyncThunk(
  "Production/FindByIDProduction",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Production/FindByIDProduction`,
        { Production_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        production_no: result?.production_no,
        Production_guid: result?.Production_guid,
        start_date: result?.start_date
          ? moment(result?.start_date, "YYYY-MM-DD").toDate()
          : "",
        end_date: result?.end_date
          ? moment(result?.end_date, "YYYY-MM-DD").toDate()
          : "",
        lot_no: result?.lot_no,
        item_id: result?.item_id,
        item_options: result?.item_options || [], // need to change after backend change its just item_options
        product_name: result?.product_name,
        no_of_workers: result?.no_of_workers,
        start_time: result?.start_time
          ? moment(result?.start_time, "HH:mm:ss").toDate()
          : "",
        end_time: result?.end_time
          ? moment(result?.end_time, "HH:mm:ss").toDate()
          : "",
        roasting_duration: result?.roasting_duration,
        old_material_lot_no: result?.old_material_lot_no,
        total_quantity: result?.total_quantity,
        quantity: result?.quantity ? result?.quantity : 0,
        total_qty: result?.total_qty,
        received_qty: result?.received_qty ? result?.received_qty : 0,
        loss_qty: result?.loss_qty,
        supervisor_by: result?.supervisor_by,
        supervisor_name: result?.supervisor_name,
        remarks: result?.remarks,
        ProductionItemDetails: result?.ProductionItemDetails
          ? result?.ProductionItemDetails?.map((item: any) => {
              return {
                id: item?.id ? item?.id : null,
                production_item_details_guid: item?.production_item_details_guid
                  ? item?.production_item_details_guid
                  : "",
                process_id: item?.process_id ? item?.process_id : null,
                production_id: item?.production_id ? item?.production_id : null,
                item_id: item?.item_id ? item?.item_id : null,
                item_options: item?.item_options ? item?.item_options : [],
                product_name: item?.product_name ? item?.product_name : "",
                bom_qty: item?.bom_qty ? item?.bom_qty : null,
                grn_no: item?.grn_no ? item?.grn_no : null,
                stock_qty: item?.stock_qty ? item?.stock_qty : 0,
                used_qty: item?.used_qty ? item?.used_qty : null,
                isactive: item?.isactive ? item?.isactive : false,
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching production:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllBOMDetails = createAsyncThunk(
  "Production/FindAllBomDetails",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Production/FindAllBomDetails`,
        { item_id: ID }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching BOM Details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
