import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import {
  clearMetalCleaningReportList,
  setMetalCleaningReport,
} from "../reportsSlice";
import {
  Grid,
  GridColumn,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import { setCurrentPage, setPageLimit } from "../../pagination/paginationSlice";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { findAllActiveMachine } from "../../machine/services/machine.services";
import { IMachine } from "../../machine/machineModel";
import { getMetalCleaningReport } from "../services/reports.services";
import { Button } from "@progress/kendo-react-buttons";
import Logo from "../../../assets/Images/logo.png";
import { checkAcessRights } from "../../../_helper/helper";
import { PRINT_ACCESS } from "../../../_contstants/common";

const MetalCleaningReportForm: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const MachineList = useAppSelector((state) => state.machine.MachineList);
  const MetalCleaningReportList = useAppSelector(
    (state) => state.reports.MetalCleaningReportList
  );
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  useEffect(() => {
    dispatch(findAllActiveMachine());

    return () => {
      dispatch(clearMetalCleaningReportList());
      dispatch(setCurrentPage(0));
    };
  }, []);

  const handleSubmit = (values: any) => {
    const payload = {
      machine_no: values?.machine_no ? values?.machine_no : null,
      from_date: values?.from_date
        ? moment(values?.from_date).format("YYYY-MM-DD")
        : null,
      to_date: values?.to_date
        ? moment(values?.to_date).format("YYYY-MM-DD")
        : null,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(setMetalCleaningReport(payload));
    dispatch(getMetalCleaningReport(payload));
  };
  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 10 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[{ width: "40%" }, { width: "60%" }]}
                >
                  <GridLayoutItem colSpan={2}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Metal Cleaning Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem style={{ display: "flex", gap: 10 }}>
                    <Field
                      wrapperStyle={{ width: "100%" }}
                      name="from_date"
                      label="From Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                    />
                    <Field
                      wrapperStyle={{ width: "100%" }}
                      name="to_date"
                      label="To Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem></GridLayoutItem>
                  <GridLayoutItem
                    style={{ display: "flex", alignItems: "end", gap: 10 }}
                  >
                    <Field
                      wrapperClassName="w-100"
                      name="machine_no"
                      label="Machine No"
                      component={FormSelectionField}
                      options={MachineList?.map((machine: IMachine) => {
                        return {
                          value: machine?.id,
                          label: machine?.machine_name,
                        };
                      })}
                    />
                    <div style={{ marginBottom: 1 }}>
                      <ButtonWithLoading
                        label={"View"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          MetalCleaningReportList &&
          MetalCleaningReportList.length > 0 && (
            <ShadowCard style={{ marginTop: 10 }}>
              <MetalCleaningReportGridCommponent />
            </ShadowCard>
          )
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};

const MetalCleaningReportGridCommponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);

  const MetalCleaningReportList = useAppSelector(
    (state) => state.reports.MetalCleaningReportList
  );
  const MetalCleaningReportDetails = useAppSelector(
    (state) => state.reports.MetalCleaningReportDetails
  );
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const isMetalCleaningRef = React.useRef(true);

  useEffect(() => {
    if (!isMetalCleaningRef.current) {
      const payload = {
        machine_no: MetalCleaningReportDetails?.machine_no
          ? MetalCleaningReportDetails?.machine_no
          : null,
        from_date: MetalCleaningReportDetails?.from_date
          ? MetalCleaningReportDetails?.from_date
          : "",
        to_date: MetalCleaningReportDetails?.to_date
          ? MetalCleaningReportDetails?.to_date
          : "",
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      dispatch(getMetalCleaningReport(payload));
    } else {
      isMetalCleaningRef.current = false;
    }
  }, [currentPage, pageLimit]);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const printTable = (data: any[]) => {
    let printbuttons = document.querySelector(
      ".printbuttons"
    ) as HTMLElement | null;
    let report_container = document.querySelector(
      ".report-container"
    ) as HTMLElement | null;

    if (printbuttons) printbuttons.style.display = "none";
    if (report_container) report_container.style.padding = "20px 0";

    let tableRows = data.length;
    let maxRows = tableRows;
    let pageCount = Math.ceil(tableRows / maxRows);
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      //   content += `<div class="page-header">Page ${i + 1}</div>`;
      content +=
        '<table class="table table-bordered" style="font-size:10px;margin-bottom:30px;">';
      content += "<tr>";
      content += `<td rowSpan="4" style="width:16%" ><img src="${Logo}" width="100%" /></td>`;
      content +=
        '<td colSpan="4" style="text-align: center;font-size: 14px;font-weight: 600;color: gray;">Harihar Foods Pvt Ltd.</td>';
      content += "</tr>";
      content += "<tr>";
      content += '<td style="width:20%;">Document Name:</td>';
      content +=
        '<td colSpan="3">Daily machine & magnet cleaning & sieve integrity report.</td>';
      content += "</tr>";
      content += "<tr>";
      content += "<td>Document Number:</td>";
      content += "<td>PRD/F/08:</td>";
      content += "<td>Page</td>";
      content += `<td>${i + 1}</td>`;
      content += "</tr>";
      content += "<tr>";
      content += "<td>Date Of Issue:</td>";
      content += "<td>01/01/2020</td>";
      content += "<td>Version</td>";
      content += "<td>2.00</td>";
      content += "</tr>";

      content += "</table>";

      content += '<table class="table table-bordered" style="font-size:10px;">';
      content += '<thead style="background:lightgray;">';
      content += '<tr style="text-align:center">';
      content += "<th>SR. NO.</th>";
      content += "<th>DATE</th>";
      content += "<th>MACHINE NO.</th>";
      content += '<th style="width:22%;">DEPT.</th>';
      content += "<th>M/C CLEANING</th>";
      content += "<th>METAL QTY. IN GM.</th>";
      content += "<th>SIEVE CONDITION</th>";
      content += "<th>SUPERVISOR SIGN</th>";
      content += "<th>VERIFY BY</th>";
      content += "</tr>";
      content += "</thead>";
      content += "<tbody>";

      let startRow = i * maxRows;
      let endRow = Math.min((i + 1) * maxRows, tableRows);

      for (let j = startRow; j < endRow; j++) {
        content += "<tr>";
        content += `<td>${j + 1}</td>`;
        content += `<td>${
          data[j]?.magnet_cleaning_date
            ? moment(data[j]?.magnet_cleaning_date, "DD/MM/YYYY").format(
                "DD MMM YYYY"
              )
            : ""
        }</td>`;
        content += `<td>${data[j].machine_name}</td>`;
        content += `<td>${data[j].product_name}</td>`;
        content += `<td>${data[j].m_c_cleaning}</td>`;
        content += `<td>${data[j].metal_qty}</td>`;
        content += `<td>${data[j].sieve_condition}</td>`;
        content += `<td>${data[j].supervisor_name}</td>`;
        content += `<td>${data[j].verify_name}</td>`;
        content += "</tr>";
      }

      content += "</tbody>";
      content += "</table>";

      content +=
        '<table class="table table-bordered" style="font-size:11px; width:100%; margin:30px 0; text-align:center">';
      content += "<tr>";
      content += `<td style="width:33%">Prepared By: <br>Mr. GAURANG MEHTA</br></td>`;
      content += `<td style="width:34%">Document Status: </br>CONTROL COPY</td>`;
      content += `<td style="width:33%">Approved By: <br>Mr. PARAG SHAH</br> </td>`;
      content += "</tr>";
      content += "</table>";

      content += "</div>";
    }

    // Create a new window for the print preview
    let printWindow = window.open("", "_blank");
    if (!printWindow) {
      console.error("Failed to open print preview window.");
      return;
    }
    printWindow.document.write(`
      <html>
      <head>
        <title>Print Preview</title>
        <style>
          @page { margin: 1cm; }
          .print-page { page-break-after: always; }
          .table-bordered { width: 100%; border-collapse: collapse; }
          .table-bordered th, .table-bordered td { border: 1px solid black; padding: 8px; }
          body { font-family: Arial, sans-serif; }
        </style>
      </head>
      <body>
        ${content}
        <script>
          window.onload = function() {
            window.print();
          };
        </script>
      </body>
      </html>
    `);
    printWindow.document.close();

    if (printbuttons) printbuttons.style.display = "block";
    if (report_container) report_container.style.padding = "20px 10px";
  };

  return (
    <>
      {MetalCleaningReportList && MetalCleaningReportList.length ? (
        <GridLayout gap={{ rows: 10, cols: 10 }} cols={[{ width: "100%" }]}>
          <GridLayoutItem className="d-flex justify-content-between align-items-center">
            <Typography.h4 className="m-0">
              Metal Cleaning List{" "}
              {(MetalCleaningReportDetails?.from_date ||
                MetalCleaningReportDetails?.to_date) && (
                <span style={{ fontSize: 13 }}>
                  {"("}
                  {MetalCleaningReportDetails?.from_date
                    ? moment(
                        MetalCleaningReportDetails?.from_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {MetalCleaningReportDetails?.from_date &&
                    MetalCleaningReportDetails?.to_date &&
                    " - "}
                  {MetalCleaningReportDetails?.to_date
                    ? moment(
                        MetalCleaningReportDetails?.to_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {")"}
                </span>
              )}
            </Typography.h4>
            {isPrintAccess && (
              <Button
                style={{ marginRight: 5 }}
                type="button"
                fillMode={"solid"}
                themeColor={"primary"}
                onClick={() => printTable(MetalCleaningReportList)}
              >
                Print
              </Button>
            )}
          </GridLayoutItem>
          <GridLayoutItem>
            <Grid
              className="table-bordered"
              style={{ maxHeight: "calc(100vh - 152px)" }}
              filterable={true}
              filter={filter}
              data={
                filter
                  ? filterBy(MetalCleaningReportList, filter)
                  : MetalCleaningReportList
              }
              onFilterChange={handleFilterChange}
              total={
                MetalCleaningReportList[0]?.itemcount ||
                MetalCleaningReportList.length
              }
              skip={currentPage * pageLimit}
              take={pageLimit}
              pageable={{
                buttonCount: 5,
                pageSizes: [10, 50, 100, 300, 500],
                pageSizeValue: pageLimit,
                type: "input",
              }}
              onPageChange={pageChange}
              cells={{
                headerCell: HeaderCustomCell,
                data: MyDataCustomCell,
              }}
            >
              <GridColumn
                field="UniqueNo"
                title="Sr. No."
                width={80}
                filterable={false}
              />
              <GridColumn
                field="magnet_cleaning_date"
                title="Date"
                cell={(props: any) => (
                  <td>
                    {props.dataItem?.magnet_cleaning_date
                      ? moment(
                          props.dataItem?.magnet_cleaning_date,
                          "DD/MM/YYYY"
                        ).format("DD MMM YYYY")
                      : ""}
                  </td>
                )}
              />
              <GridColumn field="machine_name" title="Machine No" />
              <GridColumn field="product_name" title="Item Name" />
              <GridColumn field="m_c_cleaning" title="M/C Cleaning" />
              <GridColumn field="metal_qty" title="Metal Qty" />
              <GridColumn field="sieve_condition" title="Sieve Condition" />
              <GridColumn field="supervisor_name" title="Supervisor By" />
              <GridColumn field="verify_name" title="Verify By" />
            </Grid>
          </GridLayoutItem>
        </GridLayout>
      ) : (
        <AlertBox />
      )}
    </>
  );
};
export default MetalCleaningReportForm;
