import { Button } from "@progress/kendo-react-buttons";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  ItemActiveInactive,
  deleteItem,
  getAllItem,
} from "./services/item.services";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import {
  clearItemID,
  clearItemDetail,
  setItemID,
  clearItemList,
  setFilterProductName,
} from "./itemSlice";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import IconButton from "../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Switch, TextBox } from "@progress/kendo-react-inputs";
// import { LoadingPanel } from "../../components/layout/Loading";
// import AlertBox from "../../components/common/AlertBox";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import { checkAcessRights } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import { filterClearIcon } from "@progress/kendo-svg-icons";
import { LoadingPanel } from "../../components/layout/Loading";
import _debounce from "lodash/debounce";

const Item: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isAddAccess = checkAcessRights("/item", INSERT_ACCESS);

  useEffect(() => {
    return () => {
      dispatch(clearItemList());
      dispatch(closeDialog());
      dispatch(clearItemDetail());
      dispatch(setCurrentPage(0));
      dispatch(setFilterProductName(""));
    };
  }, []);

  const handleItemMasterCreate = () => {
    if (location.pathname === "/rawmaterialitem") {
      navigate("/rawmaterialitem/create");
    } else if (location.pathname === "/semifinisheditem") {
      navigate("/semifinisheditem/create");
    } else if (location.pathname === "/finisheditem") {
      navigate("/finisheditem/create");
    } else if (location.pathname === "/packingmaterialitem") {
      navigate("/packingmaterialitem/create");
    } else {
      navigate("/item/create");
    }
  };

  return (
    <>
      <DeleteItemMasterDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            {location.pathname === "/rawmaterialitem"
              ? "Raw Material"
              : location.pathname === "/semifinisheditem"
              ? "Semi Finished"
              : location.pathname === "/finisheditem"
              ? "Finished"
              : location.pathname === "/packingmaterialitem"
              ? "Packing Material"
              : ""}{" "}
            Item List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleItemMasterCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              Create{" "}
              {location.pathname === "/rawmaterialitem"
                ? "Raw Material"
                : location.pathname === "/semifinisheditem"
                ? "Semi Finished"
                : location.pathname === "/finisheditem"
                ? "Finished"
                : location.pathname === "/packingmaterialitem"
                ? "Packing Material"
                : ""}{" "}
              Item
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <ItemMasterGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const ItemMasterGridComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isEditAccess = checkAcessRights("/item", EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights("/item", DELETE_ACCESS);
  // const [filterValue, setFilterValue] = useState("");

  const ItemList = useAppSelector((state) => state.item.ItemList);
  const loading = useAppSelector((state) => state.item.loading);
  const FilterProductName = useAppSelector(
    (state) => state.item.FilterProductName
  );
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  useEffect(() => {
    const fetchItemList = async () => {
      const payload = {
        product_name: FilterProductName,
        item_type:
          location.pathname === "/rawmaterialitem"
            ? "Raw Material"
            : location.pathname === "/semifinisheditem"
            ? "Semi Finished"
            : location.pathname === "/finisheditem"
            ? "Finished"
            : location.pathname === "/packingmaterialitem"
            ? "Packing Material"
            : "",
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      await dispatch(getAllItem(payload));
    };
    fetchItemList();
  }, [currentPage, pageLimit]);

  useEffect(() => {
    dispatch(setCurrentPage(0));
    const fetchItemList = async () => {
      const payload = {
        product_name: FilterProductName,
        item_type:
          location.pathname === "/rawmaterialitem"
            ? "Raw Material"
            : location.pathname === "/semifinisheditem"
            ? "Semi Finished"
            : location.pathname === "/finisheditem"
            ? "Finished"
            : location.pathname === "/packingmaterialitem"
            ? "Packing Material"
            : "",
        limit: +pageLimit,
        skip: 0 * +pageLimit,
      };
      await dispatch(getAllItem(payload));
    };
    fetchItemList();
  }, [location.pathname]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(setCurrentPage(0));
  //   };
  // }, [location.pathname]);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditItem = (item_guid: any) => {
      if (location.pathname === "/rawmaterialitem") {
        navigate("/rawmaterialitem/edit", {
          state: { item_guid: item_guid, id: props.dataItem?.id },
        });
      } else if (location.pathname === "/semifinisheditem") {
        navigate("/semifinisheditem/edit", {
          state: { item_guid: item_guid, id: props.dataItem?.id },
        });
      } else if (location.pathname === "/finisheditem") {
        navigate("/finisheditem/edit", {
          state: { item_guid: item_guid, id: props.dataItem?.id },
        });
      } else if (location.pathname === "/packingmaterialitem") {
        navigate("/packingmaterialitem/edit", {
          state: { item_guid: item_guid, id: props.dataItem?.id },
        });
      } else {
        navigate("/item/edit", {
          state: { item_guid: item_guid, id: props.dataItem?.id },
        });
      }
    };

    const handleOpenDeleteDialog = (item_guid: any) => {
      dispatch(openDialog("deleteItem"));
      dispatch(setItemID(item_guid));
    };

    return (
      <td className="action-td">
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() => handleEditItem(props.dataItem.item_guid)}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.item_guid)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 5 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
    const [IsActive, setIsActive] = useState<boolean>(props.dataItem?.isactive);

    const handleActiveInActive = () => {
      setIsActive(!IsActive);
      const data = {
        item_guid: props.dataItem.item_guid,
        isactive: !IsActive,
      };
      dispatch(ItemActiveInactive(data)).then((response: any) => {
        const payload = {
          product_name: FilterProductName,
          item_type:
            location.pathname === "/rawmaterialitem"
              ? "Raw Material"
              : location.pathname === "/semifinisheditem"
              ? "Semi Finished"
              : location.pathname === "/finisheditem"
              ? "Finished"
              : location.pathname === "/packingmaterialitem"
              ? "Packing Material"
              : "",
          limit: +pageLimit,
          skip: +currentPage * +pageLimit,
        };
        if (response.payload?.data?.Status === 200) {
          dispatch(getAllItem(payload));
        } else {
          dispatch(getAllItem(payload));
        }
      });
    };

    return (
      <td style={{ textAlign: "center" }}>
        <Tooltip anchorElement="target" position="top" parentTitle={true}>
          <div title={`Set ${IsActive ? "Inactive" : "Active"}.`}>
            <Switch
              disabled={isEditAccess ? false : true}
              name="isactive"
              checked={IsActive}
              onChange={handleActiveInActive}
              size="small"
            />
          </div>
        </Tooltip>
      </td>
    );
  };
  const gridRef = React.useRef<any>(null);

  return (
    <>
      {/* {ItemList && ItemList.length ? ( */}
      {loading && <LoadingPanel gridRef={gridRef} />}
      <KendoGrid
        style={{ maxHeight: "calc(100vh - 152px)" }}
        filterable={true}
        filter={filter}
        data={(filter ? filterBy(ItemList, filter) : ItemList) || []}
        onFilterChange={handleFilterChange}
        total={ItemList[0]?.itemcount || ItemList?.length}
        skip={currentPage * pageLimit}
        take={pageLimit}
        pageable={{
          buttonCount: 5,
          pageSizes: [10, 50, 100, 300, 500],
          pageSizeValue: pageLimit,
          type: "input",
        }}
        onPageChange={pageChange}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field="product_name"
          title="Product"
          filterable
          filterCell={ProductNameFilterCell}
          cell={(props: any) => (
            <td>
              <div
                className={isEditAccess ? "hoverableName" : "normalName"}
                onClick={() => {
                  if (
                    isEditAccess &&
                    location.pathname === "/rawmaterialitem"
                  ) {
                    navigate("/rawmaterialitem/edit", {
                      state: { item_guid: props.dataItem?.item_guid },
                    });
                  } else if (
                    isEditAccess &&
                    location.pathname === "/semifinisheditem"
                  ) {
                    navigate("/semifinisheditem/edit", {
                      state: { item_guid: props.dataItem?.item_guid },
                    });
                  } else if (
                    isEditAccess &&
                    location.pathname === "/finisheditem"
                  ) {
                    navigate("/finisheditem/edit", {
                      state: { item_guid: props.dataItem?.item_guid },
                    });
                  } else if (
                    isEditAccess &&
                    location.pathname === "/packingmaterialitem"
                  ) {
                    navigate("/packingmaterialitem/edit", {
                      state: { item_guid: props.dataItem?.item_guid },
                    });
                  } else if (isEditAccess) {
                    navigate("/item/edit", {
                      state: { item_guid: props.dataItem?.item_guid },
                    });
                  }
                }}
              >
                {props.dataItem?.product_name}
              </div>
            </td>
          )}
        />
        <Column field="item_type" title="Item Type" />
        <Column field="unit_name" title="Unit" />
        <Column field="item_group" title="Item Group" />
        <Column field="item_category" title="Item Category" />
        <Column field="manage_stock" title="Manage Stock" />
        <Column field="mrp" title="MRP" />
        <Column field="gst_title" title="GST Title" />
        <Column
          field="isactive"
          title="Status"
          cell={MyActiveInActtiveCommandCell}
          width={"90px"}
          filterable={false}
          locked={true}
        />
        {(isEditAccess || isDeleteAccess) && (
          <Column
            field="item_guid"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
            locked={true}
          />
        )}
      </KendoGrid>
      {/* ) : (
        <AlertBox />
      )} */}
    </>
  );
};

const DeleteItemMasterDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const ItemID = useAppSelector((state) => state.item.ItemID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterProductName = useAppSelector(
    (state) => state.item.FilterProductName
  );

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteItem(ID));
      dispatch(setCurrentPage(0));
      const payload = {
        product_name: FilterProductName,
        item_type:
          location.pathname === "/rawmaterialitem"
            ? "Raw Material"
            : location.pathname === "/semifinisheditem"
            ? "Semi Finished"
            : location.pathname === "/finisheditem"
            ? "Finished"
            : location.pathname === "/packingmaterialitem"
            ? "Packing Material"
            : "",
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response.payload?.Data.Status === 200) {
        dispatch(getAllItem(payload));
        dispatch(closeDialog());
        dispatch(clearItemID());
      } else {
        dispatch(getAllItem(payload));
        dispatch(closeDialog());
        dispatch(clearItemID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteItem" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Item ?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Item?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => {
                    handleDeleteAction(ItemID);
                  }}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

const ProductNameFilterCell = (props: any) => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  let textBox: any;
  const [inputValue, setInputValue] = useState(props.value || "");

  const containsText = (value: any, filter: any) =>
    value && value.toLowerCase().includes(filter.toLowerCase());

  const onChange = (event: any) => {
    setInputValue(event.target.value);
    props.onChange({
      value: textBox.value,
      operator: containsText,
      syntheticEvent: event.syntheticEvent,
    });
  };

  const delayedApiCall = React.useCallback(
    _debounce((product_name: string) => {
      dispatch(setCurrentPage(0));
      dispatch(setFilterProductName(product_name));
      const payload = {
        product_name,
        item_type:
          location.pathname === "/rawmaterialitem"
            ? "Raw Material"
            : location.pathname === "/semifinisheditem"
            ? "Semi Finished"
            : location.pathname === "/finisheditem"
            ? "Finished"
            : location.pathname === "/packingmaterialitem"
            ? "Packing Material"
            : "",
        limit: +pageLimit,
        skip: 0 * +pageLimit,
      };

      dispatch(getAllItem(payload));
    }, 300),
    [dispatch, pageLimit]
  );
  useEffect(() => {
    delayedApiCall(inputValue);
    return () => {
      delayedApiCall.cancel();
    };
  }, [inputValue, delayedApiCall]);

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    });
    dispatch(setFilterProductName(""));
    const payload = {
      product_name: "",
      item_type:
        location.pathname === "/rawmaterialitem"
          ? "Raw Material"
          : location.pathname === "/semifinisheditem"
          ? "Semi Finished"
          : location.pathname === "/finisheditem"
          ? "Finished"
          : location.pathname === "/packingmaterialitem"
          ? "Packing Material"
          : "",
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(getAllItem(payload));
  };

  const value = props.value || null;
  return (
    <div className="d-flex">
      <TextBox
        value={value || ""}
        ref={(input) => {
          textBox = input;
        }}
        onChange={onChange}
      />
      <Button
        svgIcon={filterClearIcon}
        title="Clear"
        disabled={!value}
        onClick={onClearButtonClick}
        type="button"
        style={{
          marginLeft: "8px",
        }}
      ></Button>
    </div>
  );
};
export default Item;
