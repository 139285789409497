import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import {
  Avatar,
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  activeInActiveUsers,
  deleteUser,
  getAllUsers,
} from "./services/user.services";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import IconButton from "../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { clearUserID, setUserID } from "./userSlice";
import { Switch } from "@progress/kendo-react-inputs";
import AlertBox from "../../components/common/AlertBox";
import { Tooltip } from "@progress/kendo-react-tooltip";
// import {
//   DELETE_ACCESS,
//   EDIT_ACCESS,
//   INSERT_ACCESS,
// } from "../../_contstants/common";
// import { checkAcessRights } from "../../_helper/helper";

const User: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const loading = useAppSelector((state) => state.user.loading);

  // const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(closeDialog());
  }, []);

  const handleUserCreate = () => {
    navigate("/user/create");
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <DeleteUserDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            User List
          </Typography.h4>
          {/* {isAddAccess && ( */}
          <Button
            onClick={handleUserCreate}
            fillMode={"solid"}
            themeColor={"primary"}
          >
            Create User
          </Button>
          {/* )} */}
        </GridLayoutItem>
        <GridLayoutItem>
          <UserGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const UserGridComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const UserList = useAppSelector((state) => state.user.UserList);
  // const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  // const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();
  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? UserList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditUser = (user_guid: any) => {
      navigate("/user/edit", { state: { user_guid: user_guid } });
    };
    const handleOpenDeleteDialog = (user_guid: any) => {
      dispatch(openDialog("deleteUser"));
      dispatch(setUserID(user_guid));
    };
    return (
      <td className="action-td">
        {/* {isEditAccess && ( */}
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEditUser(props.dataItem.user_guid)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        {/* )}
        {isDeleteAccess && ( */}
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem.user_guid)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
        {/* )} */}
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
    const [IsActive, setIsActive] = useState<boolean>(props.dataItem.isactive);

    const handleActiveInActive = () => {
      setIsActive(!IsActive);
      const data = {
        user_guid: props.dataItem.user_guid,
        isactive: !IsActive,
      };
      dispatch(activeInActiveUsers(data)).then((response: any) => {
        if (response.payload?.data?.Status === 200) {
          dispatch(getAllUsers());
        } else {
          dispatch(getAllUsers());
        }
      });
    };

    return (
      <td style={{ textAlign: "center" }}>
        <Tooltip anchorElement="target" position="top" parentTitle={true}>
          <div title={`Set ${IsActive ? "Inactive" : "Active"}.`}>
            <Switch
              // disabled={isEditAccess ? false : true}
              name="isactive"
              checked={IsActive}
              onChange={handleActiveInActive}
              size="small"
            />
          </div>
        </Tooltip>
      </td>
    );
  };

  return (
    <>
      {UserList && UserList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(UserList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : UserList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={UserList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="UserImage"
            title="Image"
            width="80px"
            filterable={false}
            cell={(props: any) => {
              const firstName = props.dataItem.first_name
                ? props.dataItem.first_name?.charAt(0)?.toUpperCase()
                : "";
              const lastName = props.dataItem.last_name
                ? props.dataItem.last_name?.charAt(0)?.toUpperCase()
                : "";
              return (
                <td>
                  <Avatar
                    style={{
                      width: "50px",
                      height: "50px",
                      backgroundColor: props.dataItem.UserImage
                        ? "transparent"
                        : "#303F9F",
                    }}
                  >
                    {props.dataItem.UserImage ? (
                      <img
                        src={props.dataItem.UserImage}
                        alt={`${firstName + lastName}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      `${firstName + lastName}`
                    )}
                  </Avatar>
                </td>
              );
            }}
          />
          <Column
            field="first_name"
            title="Name"
            cell={(props: any) => (
              <td>
                {/* {isEditAccess ? ( */}
                <div
                  className="hoverableName"
                  onClick={() =>
                    navigate("/user/edit", {
                      state: { user_guid: props.dataItem?.user_guid },
                    })
                  }
                >
                  {props.dataItem.first_name} {props.dataItem.last_name}
                </div>
                {/* ) : (
                  <div className="normalName">
                    {props.dataItem.first_name} {props.dataItem.last_name}
                  </div>
                )} */}
              </td>
            )}
          />
          <Column field="emailid" title="Email ID" />
          <Column field="mobileno" title="Mobile No" />
          <Column
            field="id"
            title="Status"
            cell={MyActiveInActtiveCommandCell}
            width={"90px"}
            filterable={false}
            locked={true}
          />
          {/* {(isDeleteAccess || isEditAccess) && ( */}
          <Column
            field="ID"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
            locked={true}
          />
          {/* )} */}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteUserDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const UserID = useAppSelector((state) => state.user.UserID);
  const handleDeleteAction = async (user_guid: any) => {
    if (user_guid) {
      const response = await dispatch(deleteUser(user_guid));
      if (response.payload.Data.Status === 200) {
        dispatch(getAllUsers());
        dispatch(closeDialog());
        dispatch(clearUserID());
      } else {
        dispatch(getAllUsers());
        dispatch(closeDialog());
        dispatch(clearUserID());
      }
    }
  };
  return (
    <>
      {dialogName === "deleteUser" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete User?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this User?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(UserID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default User;
