import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISales, ISalesInitialState } from "./salesModel";
import {
  createSales,
  deleteSales,
  getAllSales,
  getSalesByID,
  getSalesPrint,
  updateSales,
} from "./services/sales.services";
import moment from "moment";

const initialState: ISalesInitialState = {
  loading: false,
  error: "",
  SalesStateForGST: null,
  SalesDeleteIndex: -1,
  SalesID: null,
  SalesList: [],
  SalesDetail: {
    sales_challan_id: null,
    invoice_no: "",
    invoice_date: moment().toDate(),
    vendor_id: null,
    terms_day: null,
    due_date: "",
    sales_ledger_id: null,
    total_amount: null,
    total_gst: null,
    gst: null,
    cgst: null,
    sgst: null,
    igst: null,
    vehicle_no: "",
    transport_name: "",
    delivery_slip_no: "",
    cartoon: null,
    bag: null,
    sales_items: [],
  },
  SalesPrintDetail: {},
};

const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    clearSalesDetails: (state) => {
      state.SalesDetail = initialState.SalesDetail;
    },
    setSalesID: (state, action) => {
      state.SalesID = action.payload;
    },
    clearSalesID: (state) => {
      state.SalesID = initialState.SalesID;
    },
    setSalesDeleteIndex: (state, action) => {
      state.SalesDeleteIndex = action.payload;
    },
    setSalesStateForGST: (state, action) => {
      state.SalesStateForGST = action.payload;
    },
    clearSalesStateForGST: (state) => {
      state.SalesStateForGST = initialState.SalesStateForGST;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSales.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllSales.fulfilled,
      (state, action: PayloadAction<ISales[]>) => {
        state.loading = false;
        state.SalesList = action.payload || [];
      }
    );
    builder.addCase(getAllSales.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SalesList = [];
    });

    builder.addCase(createSales.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createSales.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createSales.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateSales.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateSales.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateSales.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteSales.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteSales.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteSales.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getSalesByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getSalesByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.SalesDetail = action.payload;
    });
    builder.addCase(getSalesByID.rejected, (state, action) => {
      state.loading = false;
      state.SalesDetail = initialState.SalesDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getSalesPrint.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getSalesPrint.fulfilled, (state, action: any) => {
      state.loading = false;
      state.SalesPrintDetail = action.payload;
    });
    builder.addCase(getSalesPrint.rejected, (state, action) => {
      state.loading = false;
      state.SalesPrintDetail = initialState.SalesPrintDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearSalesDetails,
  clearSalesID,
  setSalesID,
  setSalesDeleteIndex,
  setSalesStateForGST,
  clearSalesStateForGST,
} = salesSlice.actions;
export default salesSlice.reducer;
