import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
// import FormImageUpload from "../../components/formFields/FormImageUpload";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import FormCheckbox from "../../components/formFields/FormCheckbox";
import { createUser, getUserByID, updateUser } from "./services/user.services";
import { clearUserDetails } from "./userSlice";
import ShadowCard from "../../components/common/ShadowCard";
import FormMultiSelectionFiled from "../../components/formFields/FormMultiSelectionFiled";
import { IRights } from "../rights/rightsModel";
import {
  getAllActiveMenuSubMenuList,
  getAllActiveMenus,
} from "../menu/services/menu.services";
import { getAllActiveRights } from "../rights/services/rights.services";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import {
  getAllActiveUserTypes,
  getUserTypeByID,
} from "../userType/services/userType.services";
import { IUserType } from "../userType/userTypeModel";
import { STATUSARRAY } from "../../_contstants/common";
import FormPasswordField from "../../components/formFields/FormPasswordField";

const CreateUser: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user_guid = location.state?.user_guid;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();

  const [formKey, setFormKey] = React.useState(1);

  const loading = useAppSelector((state) => state.user.loading);
  const UserDetails = useAppSelector((state) => state.user.UserDetails);
  const MenuSubMenuList = useAppSelector((state) => state.menu.MenuSubMenuList);
  const MenuList = useAppSelector((state) => state.menu.MenuList);
  const RightsList = useAppSelector((state) => state.rights.RightsList);
  const UserTypeList = useAppSelector((state) => state.userType.UserTypeList);

  useEffect(() => {
    if (user_guid) {
      dispatch(getUserByID(user_guid));
    }
  }, [user_guid]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [UserDetails]);

  useEffect(() => {
    dispatch(getAllActiveMenuSubMenuList());
    dispatch(getAllActiveMenus());
    dispatch(getAllActiveRights());
    dispatch(getAllActiveUserTypes());

    return () => {
      dispatch(clearUserDetails());
    };
  }, []);

  const handleMenuChange = (
    formRenderProps: FormRenderProps,
    menuName: string,
    isMenuChecked: boolean
  ) => {
    MenuSubMenuList &&
      MenuSubMenuList.length > 0 &&
      MenuSubMenuList?.forEach((menu: any) => {
        if (menu?.id === menuName) {
          if (isMenuChecked === false) {
            formRenderProps.onChange(`${menu?.id}Rights`, {
              value: null,
            });
          }

          menu?.MenuSubList?.forEach((submenu: any) => {
            formRenderProps.onChange(`${submenu?.id}`, {
              value: isMenuChecked,
            });
            if (isMenuChecked === false) {
              formRenderProps.onChange(`${submenu?.id}Rights`, {
                value: null,
              });
            }
          });
        }
      });
  };

  const handleSubMenuChange = (
    formRenderProps: FormRenderProps,
    subMenuName: string,
    isMenuChecked: boolean
  ) => {
    MenuSubMenuList &&
      MenuSubMenuList.length > 0 &&
      MenuSubMenuList?.map((menu: any) => {
        menu?.MenuSubList?.map((submenu: any) => {
          if (submenu?.id === subMenuName) {
            if (isMenuChecked === false) {
              formRenderProps.onChange(`${submenu?.id}Rights`, {
                value: null,
              });
            }
            if (submenu?.id && submenu?.under_menu_id) {
              formRenderProps.onChange(`${submenu?.under_menu_id}`, {
                value: true,
              });
            }
          }
        });
      });
  };

  const handleUserTypeChange = async (
    e: any,
    formRenderProps: FormRenderProps
  ) => {
    MenuList.map((e: any) => {
      formRenderProps.onChange(`${e.id}`, { value: false });
      formRenderProps.onChange(`${e.id}Rights`, { value: null });
    });
    if (e?.value) {
      const payload = {
        id: e?.value,
      };
      const res = await dispatch(getUserTypeByID(payload));
      if (res.meta.requestStatus === "fulfilled") {
        for (const key in res?.payload) {
          formRenderProps.onChange(`${key}`, { value: res?.payload[key] });
        }
      } else {
        MenuList.map((e: any) => {
          formRenderProps.onChange(`${e.id}`, { value: false });
          formRenderProps.onChange(`${e.id}Rights`, { value: null });
        });
      }
    }
  };

  const handleSubmit = async (values: any) => {
    interface ResultItem {
      menu_id: number;
      rights_id: string;
    }

    const result: ResultItem[] = Object.entries(values).reduce(
      (acc: ResultItem[], [key, value]) => {
        if (key.endsWith("Rights")) {
          const menuID: number = parseInt(key.replace("Rights", ""), 10);
          if (value) {
            const rightsID: string =
              Array.isArray(value) && value.length > 0 ? value.join(", ") : "";
            acc.push({ menu_id: menuID, rights_id: rightsID });
          }
        } else if (value === true) {
          const menuID: number = parseInt(key, 10);
          if (!values[`${menuID}Rights`] && !isNaN(menuID)) {
            acc.push({ menu_id: menuID, rights_id: "" });
          }
        }
        return acc;
      },
      []
    );

    if (user_guid) {
      const editPayload = {
        user_guid: user_guid,
        first_name: values.first_name ? values.first_name : "",
        last_name: values.last_name ? values.last_name : "",
        mobileno: values.mobileno ? values.mobileno?.toString() : "",
        alternate_mobileno: values.alternate_mobileno
          ? values.alternate_mobileno?.toString()
          : "",
        emailid: values.emailid ? values.emailid : "",
        alternate_emailid: values.alternate_emailid
          ? values.alternate_emailid
          : "",
        // password: values.password ? values.password : "",
        isactive: values.isactive === 1,
        usertypeid: values?.usertypeid ? values?.usertypeid : null,
        UserRightsAssign: result || [],
      };
      const response = await dispatch(updateUser(editPayload));
      if (response?.meta?.requestStatus === "fulfilled") {
        navigate(-1);
      }
    } else {
      const insertPayload = {
        first_name: values.first_name ? values.first_name : "",
        last_name: values.last_name ? values.last_name : "",
        mobileno: values.mobileno ? values.mobileno?.toString() : "",
        alternate_mobileno: values.alternate_mobileno
          ? values.alternate_mobileno?.toString()
          : "",
        emailid: values.emailid ? values.emailid : "",
        alternate_emailid: values.alternate_emailid
          ? values.alternate_emailid
          : "",
        password: values.password ? values.password : "",
        isactive: values.isactive === 1,
        usertypeid: values?.usertypeid ? values?.usertypeid : null,
        UserRightsAssign: result || [],
      };
      const response = await dispatch(createUser(insertPayload));
      if (response?.meta?.requestStatus === "fulfilled") {
        navigate(-1);
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={UserDetails}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {user_guid ? "Update User" : "Create User"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="first_name"
                    maxLength="100"
                    label="First Name"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="last_name"
                    maxLength="100"
                    label="Last Name"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="mobileno"
                    type="number"
                    max="10"
                    label="Mobile No"
                    validator={requiredValidator}
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="alternate_mobileno"
                    type="number"
                    max="10"
                    label="Alternate Mobile No "
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="emailid"
                    type="text"
                    label="Email"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="alternate_emailid"
                    type="text"
                    label="Alternate Email"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                {!user_guid && (
                  <GridLayoutItem>
                    <Field
                      name="password"
                      type="Password"
                      label="Password"
                      component={FormPasswordField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="usertypeid"
                    label="User Type"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    onChange={(e: any) =>
                      handleUserTypeChange(e, formRenderProps)
                    }
                    options={UserTypeList?.map((usertype: IUserType) => {
                      return {
                        value: usertype?.id,
                        label: usertype?.user_type,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="isactive"
                    label="Status"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={STATUSARRAY?.map((status: any) => {
                      return {
                        value: status?.value,
                        label: status?.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem>
                  <Field
                    name="UserImage"
                    type="file"
                    accept="image/*"
                    label="User Image"
                    component={FormImageUpload}
                  />
                </GridLayoutItem> */}
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>Rights</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  {MenuSubMenuList &&
                    MenuSubMenuList.length > 0 &&
                    MenuSubMenuList?.map((menu: any) => {
                      return (
                        <>
                          <div
                            style={{
                              display: "flex",
                              width: "50%",
                              justifyContent: "space-between",
                              alignItems: "center",
                              minHeight: 45,
                            }}
                          >
                            <Field
                              key={menu?.id}
                              name={`${menu?.id}`}
                              label={menu?.menu_name}
                              component={FormCheckbox}
                              onChange={() =>
                                handleMenuChange(
                                  formRenderProps,
                                  menu?.id,
                                  formRenderProps.valueGetter(`${menu?.id}`)
                                )
                              }
                            />
                            {formRenderProps.valueGetter(`${menu?.id}`) ===
                              true && (
                              <Field
                                wrapperStyle={{ width: 300 }}
                                name={`${menu?.id}Rights`}
                                size="small"
                                isSelectAll={true}
                                // isAddNew={true}
                                // addNewLink={"/rights"}
                                component={FormMultiSelectionFiled}
                                options={RightsList?.map((right: IRights) => {
                                  return {
                                    value: right?.id,
                                    label: right?.name,
                                  };
                                })}
                              />
                            )}
                          </div>
                          {menu &&
                            menu?.MenuSubList &&
                            menu?.MenuSubList.length > 0 &&
                            menu?.MenuSubList.map((submenu: any) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    width: "60%",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    minHeight: 45,
                                  }}
                                >
                                  <Field
                                    wrapperStyle={{ marginLeft: "15%" }}
                                    key={submenu?.id}
                                    name={`${submenu?.id}`}
                                    label={submenu?.menu_name}
                                    component={FormCheckbox}
                                    onChange={() =>
                                      handleSubMenuChange(
                                        formRenderProps,
                                        submenu?.id,
                                        formRenderProps.valueGetter(
                                          `${submenu?.id}`
                                        )
                                      )
                                    }
                                  />
                                  {formRenderProps.valueGetter(
                                    `${submenu?.id}`
                                  ) === true && (
                                    <Field
                                      wrapperStyle={{ width: 300 }}
                                      // key={submenu?.id}
                                      name={`${submenu?.id}Rights`}
                                      isSelectAll={true}
                                      size="small"
                                      component={FormMultiSelectionFiled}
                                      options={RightsList?.map(
                                        (right: IRights) => {
                                          return {
                                            value: right?.id,
                                            label: right?.name,
                                          };
                                        }
                                      )}
                                    />
                                  )}
                                </div>
                              );
                            })}
                        </>
                      );
                    })}
                </GridLayoutItem>

                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <ButtonWithLoading
                    label={user_guid ? "Update" : "Create"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginLeft: 4 }}
                    onClick={() => navigate("/user")}
                  >
                    Cancel
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateUser;
