export const checkAcessRights = (route: string, rightID: string) => {
  const userResponseString = localStorage.getItem("UserRightsAssign") || "[]";
  let userResponse: {
    menu_id: number;
    menu_key: string;
    menu_name: string;
    rights_id: string;
  }[];

  try {
    userResponse = JSON.parse(userResponseString);
  } catch (error) {
    userResponse = [];
  }

  return userResponse
    ?.find((e: any) => e.menu_key === route?.substring(1))
    ?.rights_id?.includes(rightID);
};

export const hasRights = (menu_key: string) => {
  const userResponseString = localStorage.getItem("UserRightsAssign") || "[]";
  let userResponse: {
    menu_id: number;
    menu_key: string;
    menu_name: string;
    rights_id: string;
  }[];

  try {
    userResponse = JSON.parse(userResponseString);
  } catch (error) {
    userResponse = [];
  }

  const menu = userResponse.find((item) => item.menu_key === menu_key);
  return menu && menu.rights_id;
};

export const getLocalStorageItem = (Name: string) => {
  return localStorage.getItem(Name);
};

export function convertToWords(num: number) {
  const ones = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const teens = [
    "",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "Ten",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const units = ["", "Thousand", "Lakh", "Crore"];

  function oneTwoDigitWords(n: number) {
    if (n < 10) {
      return ones[n];
    } else if (n >= 11 && n < 20) {
      return teens[n - 10];
    } else if (n % 10 === 0) {
      return tens[Math.floor(n / 10)];
    } else {
      return tens[Math.floor(n / 10)] + " " + ones[n % 10];
    }
  }

  function twoDigitWords(n: number) {
    if (n < 20) {
      return oneTwoDigitWords(n);
    } else {
      return (
        tens[Math.floor(n / 10)] + (n % 10 !== 0 ? " " + ones[n % 10] : "")
      );
    }
  }

  function threeDigitWords(n: number) {
    if (n < 100) {
      return twoDigitWords(n);
    } else {
      return (
        ones[Math.floor(n / 100)] +
        " Hundred" +
        (n % 100 !== 0 ? " " + twoDigitWords(n % 100) : "")
      );
    }
  }

  function integerToWords(n: number) {
    if (n === 0) return "Zero";
    const chunks = [];
    while (n > 0) {
      chunks.push(n % 100);
      n = Math.floor(n / 100);
    }
    let result = "";
    const chunkCount = chunks.length;
    for (let i = chunkCount - 1; i >= 0; i--) {
      if (chunks[i] !== 0) {
        if (i > 0) {
          result +=
            threeDigitWords(chunks[i]) + " " + (units[i] ? units[i] + " " : "");
        } else {
          result += threeDigitWords(chunks[i]);
        }
      }
    }
    return result.trim();
  }

  function indianNumberingSystem(n: number) {
    let result = "";
    let crore = Math.floor(n / 10000000);
    n = n % 10000000;
    let lakh = Math.floor(n / 100000);
    n = n % 100000;
    let thousand = Math.floor(n / 1000);
    n = n % 1000;
    let hundred = Math.floor(n / 100);
    let rest = n % 100;

    if (crore > 0) {
      result += integerToWords(crore) + " Crore ";
    }
    if (lakh > 0) {
      result += integerToWords(lakh) + " Lakh ";
    }
    if (thousand > 0) {
      result += integerToWords(thousand) + " Thousand ";
    }
    if (hundred > 0) {
      result += integerToWords(hundred) + " Hundred ";
    }
    if (rest > 0) {
      result += integerToWords(rest);
    }

    return result.trim();
  }

  const parts = num.toString().split(".");
  const integerPart = parseInt(parts[0], 10);
  const decimalPart = parts.length > 1 ? parseInt(parts[1], 10) : 0;

  let result = indianNumberingSystem(integerPart) + " Rupees";

  if (decimalPart > 0) {
    result += " And " + indianNumberingSystem(decimalPart) + " Paise";
  }

  return result + " Only";
}

// If amount has decimal then fix 2 numbers after decimal
export function formatNumber(num: any) {
  if (Number.isInteger(num)) {
    return num?.toString();
  } else {
    return num?.toFixed(2);
  }
}

export function formatIndianNumber(number: number): string {
  if (number === null || number === undefined) return "";
  let [integerPart, decimalPart] = number.toFixed(2).split(".");
  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);

  if (otherDigits !== "") {
    lastThreeDigits = "," + lastThreeDigits;
  }

  let formattedOtherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  return formattedOtherDigits + lastThreeDigits + "." + decimalPart;
}

export function formatIndianNumberForQty(number: number) {
  if (number === null || number === undefined) return "";
  let [integerPart, decimalPart] = number.toFixed(3).split(".");
  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);

  if (otherDigits !== "") {
    lastThreeDigits = "," + lastThreeDigits;
  }

  let formattedOtherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  return formattedOtherDigits + lastThreeDigits + "." + decimalPart;
}

export function getYearsArray(
  startYear: number,
  endYear: number
): { label: string; value: string }[] {
  const yearsArray = [];
  for (let year = startYear; year <= endYear; year++) {
    yearsArray.push({ label: year.toString(), value: year.toString() });
  }
  return yearsArray;
}
