import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { convertToWords, formatIndianNumber } from "../../_helper/helper";
import { getSalesChallanPrint } from "./services/salesChallan.services";

const SalesChallanPrint = () => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const sales_challan_guid = params?.sales_challan_guid;
    const SalesChallanPrintDetail = useAppSelector(
        (state) => state.salesChallan.SalesChallanPrintDetail
    );
    console.log("SalesChallanPrintDetail", SalesChallanPrintDetail)

    useEffect(() => {
        if (sales_challan_guid) {
            const handleOrderPrint = async () => {
                const res = await dispatch(getSalesChallanPrint(sales_challan_guid));
                if (res.meta.requestStatus === "fulfilled") {
                    window.print();
                }
            };
            handleOrderPrint();
        }
    }, [sales_challan_guid]);

    let totalAmount = 0;
    let totalTaxableAmount = 0;

    SalesChallanPrintDetail?.sales_challan_items &&
        SalesChallanPrintDetail?.sales_challan_items.length > 0 &&
        SalesChallanPrintDetail?.sales_challan_items?.map(
            (item: any) => (totalAmount += item?.amount)
        );

    SalesChallanPrintDetail?.gst_details &&
        SalesChallanPrintDetail?.gst_details?.length > 0 &&
        SalesChallanPrintDetail?.gst_details?.map((gstamount: any) => {
            const totaltax =
                gstamount?.total_taxable_amount &&
                gstamount?.gst &&
                (gstamount?.total_taxable_amount * gstamount?.gst) / 100;
            totalTaxableAmount += totaltax;
        });

    return (
        <div className="p-4 sales-print-page">
            <h6 className="text-center fw-600">Sales Challan</h6>
            <p className="text-center">
                <b>Harihar Foods Pvt. Ltd.</b>
                <br />
                A/16, Mohan Estate, opp. Anupan Cinema, Khokhra, Ahmedabad <br />
                Tel(Off) 079-22934263, 22935292 <br />
                FSSAI No: 10012021000359 <br />
                GSTIN/UIN: 24AAACH5100R1ZN <br />
                State: Gujarat, Code: 24 <br />
                CIN: U15499GJ1994PTC021520 <br />
                Email: marketinf@shreeganeshmasala.com
            </p>
            <table className="table table-bordered w-100 sales-print-table m-0">
                <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
                    <td colSpan={2} rowSpan={2} className="w-50">
                        <p
                            className="m-0 fw-bold"
                            style={{ padding: "2px 8px", fontSize: 13 }}
                        >
                            {SalesChallanPrintDetail?.account_name}
                        </p>
                        <div className="pt-0" style={{ minHeight: 60 }}>
                            {SalesChallanPrintDetail?.address1}{" "}
                            {SalesChallanPrintDetail?.address1 && <br />}
                            {SalesChallanPrintDetail?.address2}{" "}
                            {SalesChallanPrintDetail?.address2 && <br />}
                            GSTIN/UIN: {SalesChallanPrintDetail?.gst_no} <br />
                            PAN/IT No.: {SalesChallanPrintDetail?.pan_no}
                            State: {SalesChallanPrintDetail?.state_name} Code:{" "}
                            {SalesChallanPrintDetail?.gst_state_code}
                        </div>
                    </td>
                    <td className="w-25" style={{ height: 40 }}>
                        Challan No.
                        <br />
                        <span className="fw-600 p-0">{SalesChallanPrintDetail?.challan_no}</span>
                    </td>
                    <td className="w-25">
                        Dated
                        <br />
                        <span className="fw-600 p-0">{SalesChallanPrintDetail?.challan_date}</span>
                    </td>
                </tr>
                <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
                    <td className="w-25">
                        Dispatched through
                        <br />
                        <span className="fw-600 p-0">{SalesChallanPrintDetail?.transport_name}</span>
                    </td>
                    <td className="w-25">
                        Destination
                        <br />
                        <span className="fw-600 p-0"></span>
                    </td>
                </tr>
            </table>
            <table
                cellSpacing={1}
                cellPadding={2}
                className="table w-100  sales-print-table mb-0 b-none"
            >
                <tr className="text-center fw-600" style={{ fontSize: 12 }}>
                    <th rowSpan={2} style={{ width: "5%" }}>
                        Sr No.
                    </th>
                    <th rowSpan={2} style={{ width: "45%" }}>
                        Description Of Goods
                    </th>
                    <th rowSpan={2} style={{ width: "8%" }}>
                        HSN/SAC
                    </th>
                    <th rowSpan={2} style={{ width: "10%" }}>
                        MRP
                    </th>
                    <th colSpan={2} style={{ width: "10%" }}>
                        Quantity
                    </th>
                    <th rowSpan={2} style={{ width: "5%" }}>
                        Per
                    </th>
                    <th rowSpan={2} style={{ width: "7%" }}>
                        Rate
                    </th>
                    <th rowSpan={2} style={{ width: "10%" }}>
                        Amount
                    </th>
                </tr>
                <tr className="text-center fw-600" style={{ fontSize: 12 }}>
                    <th>Shipped</th>
                    <th>Billed</th>
                </tr>
                {SalesChallanPrintDetail?.sales_challan_items &&
                    SalesChallanPrintDetail?.sales_challan_items.length > 0 &&
                    SalesChallanPrintDetail?.sales_challan_items?.map((item: any, index: number) => (
                        <>
                            <tr>
                                <td className="text-center">{index + 1}</td>
                                <td>{item?.product_name}</td>
                                <td>{item?.hsn_sac_code}</td>
                                <td className="text-end">{item?.mrp || 0}</td>
                                <td className="text-end">{item?.shipped_quantity}</td>
                                <td className="text-end">{item?.billed_quantity}</td>
                                <td>{item?.unit_name}</td>
                                <td className=" text-end">{formatIndianNumber(item?.rate)}</td>
                                <td className=" text-end">
                                    {formatIndianNumber(item?.amount)}
                                </td>
                            </tr>
                        </>
                    ))}
                <tr>
                    <td colSpan={8}></td>
                    <td className="text-end fw-600" style={{ fontSize: 12 }}>
                        {formatIndianNumber(totalAmount)}
                    </td>
                </tr>
                {SalesChallanPrintDetail?.gst_details &&
                    SalesChallanPrintDetail?.gst_details?.length > 0 &&
                    SalesChallanPrintDetail?.gst_details?.map((gstdetails: any) =>
                        SalesChallanPrintDetail?.state_name === "GUJARAT" ? (
                            <>
                                <tr>
                                    <td></td>
                                    <td className=" text-end">CGST {gstdetails?.gst / 2}%</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className=" text-end">
                                        {(gstdetails?.gst / 2)?.toFixed(2)}
                                    </td>
                                    <td>%</td>
                                    <td className=" text-end">
                                        {gstdetails?.total_taxable_amount &&
                                            gstdetails?.gst &&
                                            formatIndianNumber(
                                                (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                                                100 /
                                                2
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className=" text-end">SGST {gstdetails?.gst / 2}%</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className=" text-end">
                                        {(gstdetails?.gst / 2)?.toFixed(2)}
                                    </td>
                                    <td>%</td>
                                    <td className=" text-end">
                                        {gstdetails?.total_taxable_amount &&
                                            gstdetails?.gst &&
                                            formatIndianNumber(
                                                (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                                                100 /
                                                2
                                            )}
                                    </td>
                                </tr>
                            </>
                        ) : (
                            <tr>
                                <td></td>
                                <td className=" text-end">IGST {gstdetails?.gst}%</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className=" text-end">{gstdetails?.gst?.toFixed(2)}</td>
                                <td>%</td>
                                <td className=" text-end">
                                    {gstdetails?.total_taxable_amount &&
                                        gstdetails?.gst &&
                                        formatIndianNumber(
                                            (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                                        )}
                                </td>
                            </tr>
                        )
                    )}
                <tr style={{ fontSize: 12 }}>
                    <td colSpan={8} className=" text-end fw-600">
                        {" "}
                        Total Invoice Amount:
                    </td>
                    <td className="text-end fw-600">
                        {formatIndianNumber(Math.round(+totalAmount + +totalTaxableAmount))}
                    </td>
                </tr>
                <tr className="b-none">
                    <td colSpan={8} className="b-none">
                        <span className="d-block p-0" style={{ fontSize: 8 }}>
                            Amount Chargeable (in words)
                        </span>
                        <span
                            className="d-block fw-600 p-0"
                            style={{ fontSize: 12, textTransform: "uppercase" }}
                        >
                            {convertToWords(Math.round(+totalAmount + +totalTaxableAmount))}
                        </span>
                    </td>
                    <td className="text-end b-none">E & O.E</td>
                </tr>
            </table>
            <table
                cellSpacing={1}
                cellPadding={2}
                className="table table-bordered w-100  sales-print-table mb-0 b-none"
                style={{ pageBreakInside: "avoid" }}
            >
                {SalesChallanPrintDetail?.state_name === "GUJARAT" ? (
                    <>
                        <tr className="text-center fw-600" style={{ fontSize: 13 }}>
                            <th rowSpan={2}>Taxable Value</th>
                            <th colSpan={2}>Central Tax</th>
                            <th colSpan={2}>State Tax</th>
                            <th rowSpan={2} colSpan={2}>
                                Total Tax Amount
                            </th>
                        </tr>
                        <tr className="text-center" style={{ fontSize: 13 }}>
                            <th>Rate</th>
                            <th>Amount</th>
                            <th>Rate</th>
                            <th>Amount</th>
                        </tr>
                    </>
                ) : (
                    <>
                        <tr className="text-center" style={{ fontSize: 13 }}>
                            <th rowSpan={2}>Taxable Value</th>
                            <th colSpan={4}>Integrated Tax</th>
                            <th rowSpan={2} colSpan={2}>
                                Total Tax Amount
                            </th>
                        </tr>
                        <tr className="text-center" style={{ fontSize: 13 }}>
                            <th>Rate</th>
                            <th colSpan={3}>Amount</th>
                        </tr>
                    </>
                )}
                {SalesChallanPrintDetail?.gst_details &&
                    SalesChallanPrintDetail?.gst_details?.length > 0 &&
                    SalesChallanPrintDetail?.gst_details?.map((gstdetails: any) =>
                        SalesChallanPrintDetail?.state_name === "GUJARAT" ? (
                            <tr className="text-end">
                                <td>{formatIndianNumber(gstdetails?.total_taxable_amount)}</td>
                                <td>{formatIndianNumber(gstdetails?.gst / 2)} %</td>
                                <td className=" ">
                                    {gstdetails?.total_taxable_amount &&
                                        gstdetails?.gst &&
                                        formatIndianNumber(
                                            (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                                            100 /
                                            2
                                        )}
                                </td>
                                <td>{formatIndianNumber(gstdetails?.gst / 2)} %</td>
                                <td>
                                    {gstdetails?.total_taxable_amount &&
                                        gstdetails?.gst &&
                                        formatIndianNumber(
                                            (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                                            100 /
                                            2
                                        )}
                                </td>
                                <td colSpan={2}>
                                    {gstdetails?.total_taxable_amount &&
                                        gstdetails?.gst &&
                                        formatIndianNumber(
                                            (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                                        )}
                                </td>
                            </tr>
                        ) : (
                            <tr className="text-end">
                                <td>{formatIndianNumber(gstdetails?.total_taxable_amount)}</td>
                                <td>{formatIndianNumber(gstdetails?.gst)} %</td>
                                <td colSpan={3}>
                                    {gstdetails?.total_taxable_amount &&
                                        gstdetails?.gst &&
                                        formatIndianNumber(
                                            (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                                        )}
                                </td>
                                <td colSpan={2}>
                                    {gstdetails?.total_taxable_amount &&
                                        gstdetails?.gst &&
                                        formatIndianNumber(
                                            (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                                        )}
                                </td>
                            </tr>
                        )
                    )}
                {SalesChallanPrintDetail?.state_name === "GUJARAT" ? (
                    <tr className="text-end fw-600" style={{ fontSize: 13 }}>
                        <td>{formatIndianNumber(totalAmount)}</td>
                        <td></td>
                        <td>{formatIndianNumber(totalTaxableAmount / 2)}</td>
                        <td></td>
                        <td>{formatIndianNumber(totalTaxableAmount / 2)}</td>
                        <td colSpan={2}>{formatIndianNumber(totalTaxableAmount)}</td>
                    </tr>
                ) : (
                    <tr className="text-end fw-600" style={{ fontSize: 13 }}>
                        <td>{formatIndianNumber(totalAmount)}</td>
                        <td></td>
                        <td colSpan={3}>{formatIndianNumber(totalTaxableAmount)}</td>
                        <td colSpan={2}>{formatIndianNumber(totalTaxableAmount)}</td>
                    </tr>
                )}
                <tr style={{ borderBottom: "none" }}>
                    <td colSpan={7} style={{ textAlign: "end", border: "none" }}>
                        <span className="d-block p-0" style={{ fontSize: 8 }}>
                            Tax Amount (in words)
                        </span>
                        <span
                            className="d-block fw-600 p-0"
                            style={{ fontSize: 12, textTransform: "uppercase" }}
                        >
                            {convertToWords(+totalTaxableAmount?.toFixed(2))}
                        </span>
                    </td>
                </tr>
            </table>
            <table
                cellSpacing={1}
                cellPadding={2}
                style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}
                className="table table-bordered w-100 mb-0"
            >
                <tr>
                    <td className="w-50" rowSpan={2}>
                        Company's PAN : <span className="fw-600 p-0">AAACH5100R</span>{" "}
                        <br />
                        CIN NO : <span className="fw-600 p-0">
                            U15499GJ1994PTC021520
                        </span>{" "}
                        <br />
                        <br />
                        <u>Declaration:</u>
                        <ol className="ms-1 mb-0">
                            <li>
                                We declare that this invoice shows the actual price of the goods
                                described and that all particulars are true and correct.
                            </li>
                            <li>Goods once sold will not be taken back.</li>
                            <li>
                                Interest @18% will be levied on outstanding after 30 days of
                                invoice date.
                            </li>
                            <li>Cheque return charges will be Rs 250/-.</li>
                            <li>FSSAI Licence No. 10012021000359</li>
                            <li>Subject to Ahmedabad Jurisdiction</li>
                        </ol>
                    </td>
                    <td className="w-50 p-0">
                        <div>
                            <b>Company's Bank Details</b> <br />
                            <table className="table bank-details-table">
                                <tr>
                                    <td>Bank Name</td>
                                    <td className="fw-600">: Kotak Mahindra Bank</td>
                                </tr>
                                <tr>
                                    <td>A/c No.</td>
                                    <td className="fw-600">: 2411684374</td>
                                </tr>
                                <tr>
                                    <td>Branch & IFS Code</td>
                                    <td className="fw-600">: SATELITE BRANCH & KKBK0000810</td>
                                </tr>
                            </table>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            height: 100,
                            borderTop: "1px double rgba(0, 0, 0, 0.5) ",
                            borderLeft: "1px solid rgba(0, 0, 0, 0.5) ",
                            textAlign: "end",
                        }}
                    >
                        <p className="p-0 mb-5 fw-600">for Harihar Foods Pvt. Ltd.</p>
                        <p className="m-0 p-0">Authorised Signatory</p>
                    </td>
                </tr>
            </table>
        </div>
    );
};

export default SalesChallanPrint;
