import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import InwardItemDetailsArray from "./InwardItemDetailsArray";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import FormDatePicker from "../../components/formFields/FormDateField";
import { clearInwardDetails } from "./inwardSlice";
import { getAllAccountIncremental } from "../account/services/account.services";
import { getAllItemIncremental } from "../Item/services/item.services";
import { findAllActiveUnit } from "../unit/services/unit.services";
import {
  createInward,
  getInwardByID,
  updateInward,
} from "./services/inward.services";
import ButtonForAll from "../../components/common/Button";
import {
  getAllPurchaseOrders,
  getPurchaseNoPurchaseOrderByID,
} from "../purchaseorder/services/purchaseOrder.services";
import FormTimePicker from "../../components/formFields/FormTimeField";
import { findAllActiveWarehouse } from "../warehouse/services/warehouse.services";
import moment from "moment";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import { clearItemList } from "../Item/itemSlice";
import { CONDITION } from "../../_contstants/common";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const QtychangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const inward_items = formRenderProps.valueGetter("inward_items");

  useEffect(() => {
    if (inward_items && inward_items.length > 0) {
      inward_items?.map((item: any, index: number) => {
        const weight = item?.weight || 0;
        const rate = item?.rate || 0;
        formRenderProps.onChange(`inward_items.${index}.total`, {
          value: +weight * +rate,
        });
      });
    }
  }, [
    inward_items.map((item: any) => item?.weight).join("-"),
    inward_items.map((item: any) => item?.rate).join("-"),
  ]);

  let totalamount = 0;

  useEffect(() => {
    if (inward_items && inward_items.length > 0) {
      inward_items?.map((item: any) => {
        totalamount += +item?.total || 0;
      });
    }
    formRenderProps.onChange("total_amount", {
      value: totalamount,
    });
  }, [inward_items.map((item: any) => item?.total).join("-")]);

  return null;
};

// const PONoChangeWatcher: React.FC<FormChangeWatcherProps> = ({
//   formRenderProps,
// }) => {
//   const dispatch = useAppDispatch();

//   const po_no = formRenderProps.valueGetter("po_no");

//   const handlePONoChange = async (
//   ) => {
//     if (po_no) {
//       const payload = {
//         po_no: po_no,
//         financial_account_year: 202425,
//       };
//       const response = await dispatch(getPurchaseNoPurchaseOrderByID(payload));
//       if (response.meta.requestStatus === "fulfilled") {
//         formRenderProps.onChange("vendor_id", {
//           value: response.payload?.vendor_id,
//         });
//         const inwardItems =
//           response.payload?.purchase_order_items &&
//           response.payload?.purchase_order_items.length > 0 &&
//           response.payload?.purchase_order_items?.map((item: any) => {
//             return {
//               item_id: item?.item_id,
//               weight: item?.qty,
//               rate: item?.rate,
//               unit_id: item?.unit_id,
//               cold_storage_id: item?.cold_storage_id,
//             };
//           });
//         formRenderProps.onChange("inward_items", {
//           value: inwardItems || [],
//         });
//       } else {
//         formRenderProps.onChange("vendor_id", {
//           value: null,
//         });
//         formRenderProps.onChange("inward_items", {
//           value: [],
//         });
//       }
//     }
//   };

//   useEffect(() => {
//     handlePONoChange();
//   }, [po_no]);

//   return null;
// };

const CreateInward: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const inward_guid = location.state?.inward_guid;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.inward.loading);
  const InwardDetail = useAppSelector((state) => state.inward.InwardDetail);
  const PurchaseOrderList = useAppSelector(
    (state) => state.purchaseOrder.PurchaseOrderList
  );
  const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [InwardDetail]);

  useEffect(() => {
    if (inward_guid) {
      const payload = {
        inward_guid: inward_guid,
      };
      dispatch(getInwardByID(payload));
    }
  }, [inward_guid]);

  useEffect(() => {
    const payload = {
      account_name: "",
      gst_no: "",
      financial_account_year: 202425,
      limit: 5000,
      skip: 0,
    };

    dispatch(findAllActiveUnit());
    dispatch(findAllActiveWarehouse());
    dispatch(getAllPurchaseOrders(payload));

    return () => {
      dispatch(clearInwardDetails());
      dispatch(clearItemList());
    };
  }, []);

  const handlePONoChange = async (
    PONo: any,
    formRenderProps: FormRenderProps
  ) => {
    if (PONo) {
      const payload = {
        po_no: PONo,
        financial_account_year: 202425,
      };
      const response = await dispatch(getPurchaseNoPurchaseOrderByID(payload));
      if (response.meta.requestStatus === "fulfilled") {
        formRenderProps.onChange("vendor_id", {
          value: response.payload?.vendor_id,
        });
        formRenderProps.onChange("vendor_options", {
          value: response.payload?.vendor_options,
        });
        formRenderProps.onChange("po_date", {
          value: response.payload?.po_date,
        });
        const inwardItems =
          response.payload?.purchase_order_items &&
          response.payload?.purchase_order_items.length > 0 &&
          response.payload?.purchase_order_items?.map((item: any) => {
            return {
              item_id: item?.item_id,
              item_options: item?.item_options,
              weight: item?.qty,
              rate: item?.rate,
              unit_id: item?.unit_id,
              cold_storage_id: item?.cold_storage_id,
              total: item?.qty * item?.rate,
            };
          });
        formRenderProps.onChange("inward_items", {
          value: inwardItems || [],
        });
      } else {
        formRenderProps.onChange("vendor_id", {
          value: null,
        });
        formRenderProps.onChange("vendor_options", {
          value: [],
        });
        formRenderProps.onChange("po_date", {
          value: "",
        });
        formRenderProps.onChange("inward_items", {
          value: [],
        });
      }
    }
  };

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (inward_guid) {
      try {
        const updatePayload = {
          inward_guid: InwardDetail?.inward_guid,
          po_no: values?.po_no ? +values?.po_no : null,
          po_date: values?.po_date
            ? moment(values?.po_date).format("YYYY-MM-DD")
            : "",
          inward_date: values?.inward_date
            ? moment(values?.inward_date).format("YYYY-MM-DD")
            : "",
          bill_no: values?.bill_no ? values?.bill_no : "",
          vendor_id: values?.vendor_id ? +values?.vendor_id : null,
          vehicle_no: values?.vehicle_no ? values?.vehicle_no : "",
          remarks: values?.remarks ? values?.remarks : "",
          chalan_no: values?.chalan_no ? values?.chalan_no : "",
          bill_date: values?.bill_date
            ? moment(values?.bill_date).format("YYYY-MM-DD")
            : "",
          vehicle_in_time: values?.vehicle_in_time
            ? moment(values?.vehicle_in_time).format("HH:mm:ss")
            : "",
          vehicle_out_time: values?.vehicle_out_time
            ? moment(values?.vehicle_out_time).format("HH:mm:ss")
            : "",
          financial_year: 202425,
          vehicle_condition: values?.vehicle_condition
            ? values?.vehicle_condition
            : "",
          region: values?.region ? values?.region : "",
          inward_items: values?.inward_items
            ? values?.inward_items?.map((inwarditem: any) => {
                return {
                  id: inwarditem?.id ? +inwarditem?.id : 0,
                  item_id: inwarditem?.item_id ? +inwarditem?.item_id : null,
                  dagina: inwarditem?.dagina ? +inwarditem?.dagina : null,
                  weight: inwarditem?.weight ? +inwarditem?.weight : 0,
                  rate: inwarditem?.rate ? +inwarditem?.rate : null,
                  total: inwarditem?.total ? +inwarditem?.total : null,
                  cold_storage_id: inwarditem?.cold_storage_id
                    ? +inwarditem?.cold_storage_id
                    : null,
                  receipt_no: inwarditem?.receipt_no
                    ? inwarditem?.receipt_no
                    : "",
                  unit_id: inwarditem?.unit_id ? +inwarditem?.unit_id : null,
                };
              })
            : [],
        };
        const response = await dispatch(updateInward(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/inward");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const insertPayload = {
          po_no: values?.po_no ? +values?.po_no : null,
          po_date: values?.po_date
            ? moment(values?.po_date).format("YYYY-MM-DD")
            : "",
          inward_date: values?.inward_date
            ? moment(values?.inward_date).format("YYYY-MM-DD")
            : "",
          bill_no: values?.bill_no ? values?.bill_no : "",
          vendor_id: values?.vendor_id ? +values?.vendor_id : null,
          vehicle_no: values?.vehicle_no ? values?.vehicle_no : "",
          remarks: values?.remarks ? values?.remarks : "",
          chalan_no: values?.chalan_no ? values?.chalan_no : "",
          bill_date: values?.bill_date
            ? moment(values?.bill_date).format("YYYY-MM-DD")
            : "",
          vehicle_in_time: values?.vehicle_in_time
            ? moment(values?.vehicle_in_time).format("HH:mm:ss")
            : "",
          vehicle_out_time: values?.vehicle_out_time
            ? moment(values?.vehicle_out_time).format("HH:mm:ss")
            : "",
          vehicle_condition: values?.vehicle_condition
            ? values?.vehicle_condition
            : "",
          region: values?.region ? values?.region : "",
          financial_year: 202425,
          inward_items: values?.inward_items
            ? values?.inward_items?.map((inwarditem: any) => {
                return {
                  item_id: inwarditem?.item_id ? +inwarditem?.item_id : null,
                  dagina: inwarditem?.dagina ? +inwarditem?.dagina : null,
                  weight: inwarditem?.weight ? +inwarditem?.weight : 0,
                  rate: inwarditem?.rate ? +inwarditem?.rate : null,
                  total: inwarditem?.total ? +inwarditem?.total : null,
                  cold_storage_id: inwarditem?.cold_storage_id
                    ? +inwarditem?.cold_storage_id
                    : null,
                  receipt_no: inwarditem?.receipt_no
                    ? inwarditem?.receipt_no
                    : "",
                  unit_id: inwarditem?.unit_id ? +inwarditem?.unit_id : null,
                };
              })
            : [],
        };
        const response = await dispatch(createInward(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleInwardNoChange = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Enter" || ev.key === "Tab") {
      ev.preventDefault();
      if ((ev.target as HTMLInputElement).value) {
        const payload = {
          inward_no: (ev.target as HTMLInputElement).value,
          financial_year: 202425,
        };
        dispatch(getInwardByID(payload));
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={InwardDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {inward_guid ? "Update Inward" : "Create Inward"}
                  </Typography.h4>
                </GridLayoutItem>
                {inward_guid && (
                  <GridLayoutItem>
                    <Field
                      name="inward_no"
                      onKeyDown={handleInwardNoChange}
                      label="Inward No"
                      placeholder="Inward No"
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                )}
                {inward_guid && <GridLayoutItem colSpan={3}></GridLayoutItem>}
                <GridLayoutItem>
                  <Field
                    name="po_no"
                    label="Purchase Order No"
                    placeholder="Purchase Order No"
                    disabled={inward_guid ? true : false}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={PurchaseOrderList?.map((po: any) => {
                      return {
                        value: po?.po_no,
                        label: `${po?.po_no} - ${po?.account_name}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem className="position-relative">
                  <ButtonForAll
                    label={"View"}
                    disabled={inward_guid ? true : false}
                    style={{ position: "absolute", top: 35 }}
                    onClick={() =>
                      handlePONoChange(
                        formRenderProps.valueGetter("po_no"),
                        formRenderProps
                      )
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="inward_date"
                    label="Inward Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="po_date"
                    label="PO Date"
                    format="dd/MM/yyyy"
                    disabled={true}
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vendor_id"
                    label="Vendor"
                    placeholder="Party Name"
                    isAddNew={true}
                    addNewLink="account/create"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    fetchIncrementalData={(search: any) =>
                      handleVendorSearchChange(
                        search,
                        `vendor_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("vendor_options")
                        ?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item.account_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="chalan_no"
                    label="Chalan No"
                    placeholder="Chalan No"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="bill_no"
                    label="Bill No."
                    placeholder="Bill No."
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="bill_date"
                    label="Bill Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vehicle_in_time"
                    label="Vehicle In Time"
                    component={FormTimePicker}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vehicle_out_time"
                    label="Vehicle Out Time"
                    component={FormTimePicker}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2} rowSpan={3}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vehicle_no"
                    label="Vehicle No."
                    placeholder="GJ01AA1111"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vehicle_condition"
                    label="Vehicle Condition"
                    placeholder="Vehicle Condition"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={CONDITION?.map((condition: any) => {
                      return {
                        value: condition?.value,
                        label: condition?.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                {formRenderProps.valueGetter("vehicle_condition") ===
                  "NOT OK" && (
                  <GridLayoutItem colSpan={2}>
                    <Field
                      name="region"
                      label="Reason"
                      placeholder="Reason"
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                )}
                <QtychangeWatcher formRenderProps={formRenderProps} />
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                gap={{ rows: 0, cols: 10 }}
                cols={[{ width: "100%" }]}
              >
                <GridLayoutItem>
                  <Typography.h4>Item Details</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem style={{ borderBottom: "1px solid lightgray" }}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    handleItemSearchChange={handleItemSearchChange}
                    component={InwardItemDetailsArray}
                    name="inward_items"
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 15,
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={inward_guid ? "Update" : "Create"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={() => navigate("/inward")}
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateInward;
