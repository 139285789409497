import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IItem } from "../itemModel";

export const getAllItem = createAsyncThunk(
  "Item/FindAllItem",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/FindAllItem`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Item:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createItem = createAsyncThunk(
  "Item/InsertItem",
  async (Item: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/InsertItem`,
        Item
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Item:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateItem = createAsyncThunk(
  "Item/UpdateItem",
  async (Item: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/UpdateItem`,
        Item
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Item:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getItemById = createAsyncThunk(
  "Item/FindByIDItem",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/FindByIDItem`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        item_guid: result.item_guid,
        item_type: result.item_type,
        product_name: result.product_name,
        description: result.description,
        conversion_unit: 1,
        item_group_id: result.item_group_id,
        item_group_marginstructure_id: result.item_group_marginstructure_id,
        item_category_id: result.item_category_id,
        unit_id: result.unit_id,
        gst_id: result.gst_id,
        gst: result.gst,
        manage_stock: +result.manage_stock,
        product_code: result.product_code,
        negative_qty_allowed: result.negative_qty_allowed,
        low_stock_warning: result.low_stock_warning,
        show_item_in_purchase: result.show_item_in_purchase,
        show_item_in_sales: result.show_item_in_sales,
        mrp: result.mrp,
        purchase_price: result.purchase_price,
        sales_price: result.sales_price,
        hsn_sac_code: result.hsn_sac_code,
        markup: result?.markup,
        min_markup: result?.min_markup,
        sequence_no: result?.sequence_no,
        expiry_days: result?.expiry_days,
        weight_number: result?.weight_number,
        isactive: result.isactive === true ? 1 : 2,
        ItemDeatails: result?.ItemDeatails
          ? result?.ItemDeatails?.map((item: any) => {
              return {
                id: item?.id ? +item?.id : null,
                item_details_guid: item?.item_details_guid
                  ? +item?.item_details_guid
                  : null,
                item_id: item?.item_id ? +item?.item_id : null,
                product_name: item?.product_name ? item?.product_name : "",
                grn_no: item?.grn_no ? +item?.grn_no : null,
                item_qty: item?.item_qty ? +item?.item_qty : null,
                warehouse_id: item?.warehouse_id ? +item?.warehouse_id : null,
                warehouse_name: item?.warehouse_name
                  ? item?.warehouse_name
                  : "",
              };
            })
          : [],
        item_mrp_details: result?.item_mrp_details
          ? result?.item_mrp_details?.map((mrp: any) => {
              return {
                id: mrp?.id ? +mrp?.id : null,
                item_mrp_guid: mrp?.item_mrp_guid ? mrp?.item_mrp_guid : "",
                item_id: mrp?.item_id ? mrp?.item_id : null,
                from_date: mrp?.from_date ? mrp?.from_date : "",
                to_date: mrp?.to_date ? mrp?.to_date : "",
                mrp: mrp?.mrp ? mrp?.mrp : null,
              };
            })
          : [],
        item_margin_structure: result.item_margin_structure
          ? result.item_margin_structure?.map((margin: any) => {
              return {
                id: margin?.id ? margin?.id : null,
                item_margin_structure_guid: margin?.item_margin_structure_guid
                  ? margin?.item_margin_structure_guid
                  : "",
                item_id: margin?.item_id ? margin?.item_id : null,
                item_group_marginstructure_id:
                  margin?.item_group_marginstructure_id
                    ? margin?.item_group_marginstructure_id
                    : null,
                item_group_id: margin?.item_group_id
                  ? margin?.item_group_id
                  : null,
                from_date: margin?.from_date ? margin?.from_date : "",
                to_date: margin?.to_date ? margin?.to_date : "",
                conversion_unit: margin?.conversion_unit
                  ? margin?.conversion_unit
                  : null,
                agent_margin: margin?.agent_margin
                  ? margin?.agent_margin
                  : null,
                agent_marginvalue1: margin?.agent_marginvalue1
                  ? margin?.agent_marginvalue1
                  : null,
                agent_marginvalue2: margin?.agent_marginvalue2
                  ? margin?.agent_marginvalue2
                  : null,
                retailer_margin: margin?.retailer_margin
                  ? margin?.retailer_margin
                  : null,
                retailer_value: margin?.retailer_value
                  ? margin?.retailer_value
                  : null,
              };
            })
          : [],
        item_margin_rate: result.item_margin_rate
          ? result.item_margin_rate?.map((margin: any) => {
              return {
                id: margin?.id ? margin?.id : null,
                from_date: margin?.from_date ? margin?.from_date : "",
                to_date: margin?.to_date ? margin?.to_date : "",
                item_id: margin?.item_id ? margin?.item_id : null,
                margin_structure_id: margin?.margin_structure_id
                  ? margin?.margin_structure_id
                  : null,
                mrp: margin?.mrp ? margin?.mrp : null,
                retail_margin: margin?.retail_margin
                  ? margin?.retail_margin
                  : null,
                retail_rate_include_gst: margin?.retail_rate_include_gst
                  ? margin?.retail_rate_include_gst
                  : null,
                retail_sgst: margin?.retail_sgst ? margin?.retail_sgst : null,
                retail_cgst: margin?.retail_cgst ? margin?.retail_cgst : null,
                retail_rate_exclude_gst: margin?.retail_rate_exclude_gst
                  ? margin?.retail_rate_exclude_gst
                  : null,
                distributor_rate_include_gst:
                  margin?.distributor_rate_include_gst
                    ? margin?.distributor_rate_include_gst
                    : null,
                distributor_sgst: margin?.distributor_sgst
                  ? margin?.distributor_sgst
                  : null,
                distributor_cgst: margin?.distributor_cgst
                  ? margin?.distributor_cgst
                  : null,
                distributor_rate: margin?.distributor_rate
                  ? margin?.distributor_rate
                  : null,
              };
            })
          : [],
      } as IItem;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Item:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteItem = createAsyncThunk(
  "Item/DeleteItem",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/DeleteItem`,
        { item_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Item:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveItem = createAsyncThunk(
  "Item/FindAllActiveItem",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Item/FindAllActiveItem`
      );
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Item:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ItemActiveInactive = createAsyncThunk(
  "Item/ActiveInActiveItem",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/ActiveInActiveItem`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Item:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllItemIncremental = createAsyncThunk(
  "Item/FindAllItemIncremental",
  async ({
    search,
    item_group_id,
  }: {
    search: any;
    item_group_id?: number;
  }) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/FindAllItemIncremental`,
        { product_name: search, item_group_id: item_group_id }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Item:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createItemMarginRate = createAsyncThunk(
  "Item/InsertItemMarignStructure",
  async (marginRateData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/InsertItemMarignStructure`,
        marginRateData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error inserting margin rate:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateItemMarginRate = createAsyncThunk(
  "Item/UpdateItemMarignStructure",
  async (marginRateData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/UpdateItemMarignStructure`,
        marginRateData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating margin rate:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteItemMarginRate = createAsyncThunk(
  "Item/DeleteItemMarginStructure",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/DeleteItemMarginStructure`,
        {
          item_margin_structure_id: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting margin rate:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createItemMrp = createAsyncThunk(
  "Item/InsertItemMrp",
  async (marginRateData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/InsertItemMrp`,
        marginRateData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error inserting mrp:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateItemMrp = createAsyncThunk(
  "Item/UpdateItemMrp",
  async (marginRateData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/UpdateItemMrp`,
        marginRateData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating mrp:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
