import { Field, FormRenderProps } from "@progress/kendo-react-form";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
  GridCellProps,
} from "@progress/kendo-react-grid";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import { Button } from "@progress/kendo-react-buttons";
import FormDatePicker from "../../components/formFields/FormDateField";
import ButtonForAll from "../../components/common/Button";
import { formatIndianNumber } from "../../_helper/helper";
import { useAppSelector } from "../../app/hooks";
import React from "react";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import IconButton from "../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import moment from "moment";

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const MRPDetailsArray = ({
  formRenderProps,
  setSubmitAction,
}: {
  formRenderProps: FormRenderProps;
  setSubmitAction: any;
}) => {
  const ItemDetail = useAppSelector((state) => state.item.ItemDetail);

  const [mrpPage, setMRPPage] = React.useState<PageState>(initialDataState);
  const [ratePage, setRatePage] = React.useState<PageState>(initialDataState);
  const [mrpPageSizeValue, setMRPPageSizeValue] = React.useState<
    number | string | undefined
  >();
  const [ratePageSizeValue, setRatePageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChangeForMRP = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? ItemDetail?.item_mrp_details?.length || event.page.take
        : event.page.take;

    if (targetEvent.value) {
      setMRPPageSizeValue(targetEvent.value);
    }
    setMRPPage({
      ...event.page,
      take,
    });
  };

  const pageChangeForRate = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? ItemDetail?.item_margin_rate?.length || event.page.take
        : event.page.take;

    if (targetEvent.value) {
      setRatePageSizeValue(targetEvent.value);
    }
    setRatePage({
      ...event.page,
      take,
    });
  };

  const handleClearMrp = (formRenderProps: FormRenderProps) => {
    formRenderProps.onChange("item_mrp_guid", {
      value: null,
    });
    formRenderProps.onChange("start_date", {
      value: "",
    });
    formRenderProps.onChange("mrpamount", {
      value: "",
    });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 12 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const MyEditMRPCommandCell = (
    props: GridCellProps,
    formRenderProps: FormRenderProps
  ) => {
    const handleEdit = (item: any) => {
      formRenderProps.onChange("item_mrp_guid", {
        value: item?.item_mrp_guid,
      });
      formRenderProps.onChange("start_date", {
        value: item?.from_date
          ? moment(item?.from_date, "DD/MM/YYYY").toDate()
          : "",
      });
      formRenderProps.onChange("end_date", {
        value: item?.to_date
          ? moment(item?.to_date, "DD/MM/YYYY").toDate()
          : "",
      });
      formRenderProps.onChange("mrpamount", {
        value: item?.mrp || 0,
      });
    };

    return (
      <td
        className={props.className}
        style={{
          ...props.style,
          textAlign: "center",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEdit(props.dataItem)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
      </td>
    );
  };

  return (
    <>
      <Card style={{ padding: 10, border: "1px solid goldenrod" }}>
        <GridLayout cols={[{ width: "100%" }]}>
          <GridLayoutItem>
            <Typography.h4 className="text-dark">{"MRP Details"}</Typography.h4>
          </GridLayoutItem>
          <GridLayoutItem
            className="bg-dark text-white fw-600"
            style={{ width: "100%" }}
          >
            <GridLayout
              style={{
                marginRight: 20,
                padding: "10px 0",
              }}
              gap={{ rows: 0, cols: "2%" }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "29.34%" },
              ]}
            >
              <GridLayoutItem className="ps-2">From date</GridLayoutItem>
              <GridLayoutItem className="ps-2">MRP</GridLayoutItem>
              <GridLayoutItem className="ps-2">Actions</GridLayoutItem>
            </GridLayout>
          </GridLayoutItem>
          <GridLayoutItem>
            <GridLayout
              gap={{ rows: 0, cols: "2%" }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "29.34%" },
              ]}
            >
              <GridLayoutItem>
                <Field
                  name="start_date"
                  format="dd/MM/yyyy"
                  component={FormDatePicker}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="w-100 right-alighned-field"
                  name={`mrpamount`}
                  placeholder="0"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ textAlign: "end", marginTop: 16 }}>
                <ButtonForAll
                  label={
                    formRenderProps.valueGetter("item_mrp_guid")
                      ? "Update"
                      : "Add"
                  }
                  disabled={
                    formRenderProps.valueGetter("start_date") &&
                    formRenderProps.valueGetter("mrpamount")
                      ? false
                      : true
                  }
                  type="submit"
                  onClick={() => setSubmitAction("mrp")}
                />
                <Button
                  style={{ marginLeft: 4 }}
                  fillMode={"outline"}
                  themeColor={"primary"}
                  type="button"
                  onClick={() => {
                    handleClearMrp(formRenderProps);
                  }}
                >
                  Clear
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </GridLayoutItem>
        </GridLayout>
      </Card>
      {ItemDetail &&
        ItemDetail?.item_mrp_details &&
        ItemDetail?.item_mrp_details?.length > 0 && (
          <>
            <GridLayoutItem
              style={{
                marginTop: 30,
              }}
            >
              <Typography.h4 className="m-0 text-dark">
                {"MRP Rate List"}
              </Typography.h4>
              {/* <ButtonForAll
                label={showMRP ? "Hide" : "Add"}
                type="button"
                onClick={() => setShowMRP(!showMRP)}
              /> */}
            </GridLayoutItem>
            <KendoGrid
              style={{ marginTop: 14 }}
              data={
                ItemDetail?.item_mrp_details
                  ? ItemDetail?.item_mrp_details?.slice(
                      mrpPage.skip,
                      mrpPage.take + mrpPage.skip
                    )
                  : []
              }
              skip={mrpPage.skip}
              take={mrpPage.take}
              total={ItemDetail?.item_mrp_details?.length}
              pageable={{
                buttonCount: 5,
                pageSizes: [5, 10, 15, "All"],
                pageSizeValue: mrpPageSizeValue,
                type: "input",
              }}
              onPageChange={pageChangeForMRP}
              cells={{
                headerCell: HeaderCustomCell,
                data: MyDataCustomCell,
              }}
            >
              <Column field="from_date" title="From Date" />
              <Column field="to_date" title="To Date" />
              <Column
                field="mrp"
                title="MRP"
                cell={(props: any) => (
                  <td style={{ textAlign: "end" }}>
                    {formatIndianNumber(+props.dataItem?.mrp)}
                  </td>
                )}
              />
              <Column
                field="ID"
                title="Actions"
                cell={(props: any) =>
                  MyEditMRPCommandCell(props, formRenderProps)
                }
                width={"110px"}
                filterable={false}
                locked={true}
              />
            </KendoGrid>
          </>
        )}

      {ItemDetail &&
        ItemDetail?.item_margin_rate &&
        ItemDetail?.item_margin_rate?.length > 0 && (
          <>
            <GridLayoutItem
              style={{
                marginTop: 30,
              }}
            >
              <Typography.h4 className="m-0 text-dark">
                {"Rate List"}
              </Typography.h4>
            </GridLayoutItem>
            <KendoGrid
              style={{ marginTop: 14 }}
              data={
                ItemDetail?.item_margin_rate
                  ? ItemDetail?.item_margin_rate?.slice(
                      ratePage.skip,
                      ratePage.take + ratePage.skip
                    )
                  : []
              }
              skip={ratePage.skip}
              take={ratePage.take}
              total={ItemDetail?.item_margin_rate?.length}
              pageable={{
                buttonCount: 5,
                pageSizes: [5, 10, 15, "All"],
                pageSizeValue: ratePageSizeValue,
                type: "input",
              }}
              onPageChange={pageChangeForRate}
              cells={{
                headerCell: HeaderCustomCell,
                data: MyDataCustomCell,
              }}
            >
              <Column field="from_date" title="From Date" width={150} />
              <Column field="to_date" title="To Date" width={150} />
              <Column
                field="mrp"
                title="MRP"
                width={150}
                cell={(props: any) => (
                  <td style={{ textAlign: "end" }}>
                    {formatIndianNumber(+props.dataItem?.mrp)}
                  </td>
                )}
              />
              <Column
                field="retail_margin"
                title="Retail Margin"
                width={150}
                cell={(props: any) => (
                  <td style={{ textAlign: "end" }}>
                    {formatIndianNumber(+props.dataItem?.retail_margin)}
                  </td>
                )}
              />
              <Column
                field="retail_rate_include_gst"
                title="Retail Rate Include GST"
                width={210}
                cell={(props: any) => (
                  <td style={{ textAlign: "end" }}>
                    {formatIndianNumber(
                      +props.dataItem?.retail_rate_include_gst
                    )}
                  </td>
                )}
              />
              <Column
                field="retail_cgst"
                title="Retail CGST"
                width={150}
                cell={(props: any) => (
                  <td style={{ textAlign: "end" }}>
                    {formatIndianNumber(+props.dataItem?.retail_cgst)}
                  </td>
                )}
              />
              <Column
                field="retail_sgst"
                title="Retail SGST"
                width={150}
                cell={(props: any) => (
                  <td style={{ textAlign: "end" }}>
                    {formatIndianNumber(+props.dataItem?.retail_sgst)}
                  </td>
                )}
              />
              <Column
                field="retail_rate_exclude_gst"
                title="Retail Rate Exclude GST"
                width={200}
                cell={(props: any) => (
                  <td style={{ textAlign: "end" }}>
                    {formatIndianNumber(
                      +props.dataItem?.retail_rate_exclude_gst
                    )}
                  </td>
                )}
              />
              <Column
                field="distributor_rate_include_gst"
                title="Distributor Rate Include GST"
                width={250}
                cell={(props: any) => (
                  <td style={{ textAlign: "end" }}>
                    {formatIndianNumber(
                      +props.dataItem?.distributor_rate_include_gst
                    )}
                  </td>
                )}
              />
              <Column
                field="distributor_cgst"
                title="Distributor CGST"
                width={150}
                cell={(props: any) => (
                  <td style={{ textAlign: "end" }}>
                    {formatIndianNumber(+props.dataItem?.distributor_cgst)}
                  </td>
                )}
              />
              <Column
                field="distributor_sgst"
                title="Distributor SGST"
                width={150}
                cell={(props: any) => (
                  <td style={{ textAlign: "end" }}>
                    {formatIndianNumber(+props.dataItem?.distributor_sgst)}
                  </td>
                )}
              />
              <Column
                field="distributor_rate"
                title="Distributor Rate"
                width={150}
                cell={(props: any) => (
                  <td style={{ textAlign: "end" }}>
                    {formatIndianNumber(+props.dataItem?.distributor_rate)}
                  </td>
                )}
              />
            </KendoGrid>
          </>
        )}
    </>
  );
};

export default MRPDetailsArray;
