// import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../components/layout/Layout";
import LogIn from "../features/login/Login";
import Country from "../features/country/Country";
import User from "../features/user/User";
import CreateUser from "../features/user/CreateUser";
import UserType from "../features/userType/UserType";
import Menu from "../features/menu/Menu";
import Rights from "../features/rights/Rights";
import State from "../features/state/State";
import City from "../features/city/City";
import CreateUserType from "../features/userType/CreateUserType";
import Dashboard from "../features/dashboard/Dashboard";
import PurchaseOrder from "../features/purchaseorder/PurchaseOrder";
import CreatePurchaseOrder from "../features/purchaseorder/CreatePurchaseOrder";
import ItemCategory from "../features/Item Category/ItemCategory";
import Gst from "../features/gst/Gst";
import ItemGroup from "../features/ItemGroup/ItemGroup";
import Unit from "../features/unit/Unit";
import Machine from "../features/machine/Machine";
import Item from "../features/Item/Item";
import CreateItem from "../features/Item/CreateItem";
import WareHouse from "../features/warehouse/WareHouse";
import Inward from "../features/inward/Inward";
import CreateInward from "../features/inward/CreateInward";
import InwardReturn from "../features/inwardreturn/InwardReturn";
import CreateInwardReturn from "../features/inwardreturn/CreateInwardReturn";
import MagnetCleaning from "../features/magnetcleaning/MagnetCleaning";
import CreateMagnetCleaning from "../features/magnetcleaning/CreateMagnetCleaning";
import MachineCleaning from "../features/machinecleaning/MachineCleaning";
import CreateMachineCleaning from "../features/machinecleaning/CreateMachineCleaning";
import Account from "../features/account/Account";
import CreateAccount from "../features/account/CreateAccount";
import Process from "../features/process/Process";
import ProductCleaning from "../features/productcleaning/ProductCleaning";
import CreateProductCleaning from "../features/productcleaning/CreateProductCleaning";
import Department from "../features/department/Department";
import Bom from "../features/bom/Bom";
import CreateBom from "../features/bom/CreateBom";
import ProductMilling from "../features/productmilling/ProductMilling";
import CreateProductMilling from "../features/productmilling/CreateProductMilling";
import Roasting from "../features/roasting/Roasting";
import CreateRoasting from "../features/roasting/CreateRoasting";
import Production from "../features/production/Production";
import CreateProduction from "../features/production/CreateProduction";
import ProductMillingReportForm from "../features/reports/reportsform/ProductMillingReportForm";
import ProductionReportForm from "../features/reports/reportsform/ProductionReportForm";
import GhantiCleaningReportForm from "../features/reports/reportsform/GhantiCleaningReportForm";
import MetalCleaningReportForm from "../features/reports/reportsform/MetalCleaningReportForm";
import ProductCleaningReportForm from "../features/reports/reportsform/ProductCleaningReportForm";
import RoastingReportForm from "../features/reports/reportsform/RoastingReportForm";
import InwardPackingMaterial from "../features/inwardpacking/InwardPackingMaterial";
import CreateInwardPackingMaterial from "../features/inwardpacking/CreateInwardPackingMaterial";
import OutwardPackingMaterial from "../features/outwardpacking/OutwardPackingMaterial";
import CreateOutwardPackingMaterial from "../features/outwardpacking/CreateOutwardPackingMaterial";
import StockTransfer from "../features/stocktransfer/StockTransfer";
import CreateStockTransfer from "../features/stocktransfer/CreateStockTransfer";
import InwardPackingMaterialStockForm from "../features/reports/reportsform/InwardPakingMaterialStockForm";
import OutwardPackingMaterialStockForm from "../features/reports/reportsform/OutwardPakingMaterialStockForm";
import PrivateRoute from "./PrivateRoute";
import InwardRegisterReportForm from "../features/reports/reportsform/InwardRegisterReportForm";
import Packing from "../features/packing/Packing";
import CreatePacking from "../features/packing/CreatePacking";
import StockReportForm from "../features/reports/reportsform/StockReportForm";
import PackingRegisterReport from "../features/reports/reportsform/PackingRegisterReport";
import PackingMaterialStockReport from "../features/reports/reportsform/PackingMaterialStockReport";
import SalesOrder from "../features/salesorder/SalesOrder";
import CreateSalesOrder from "../features/salesorder/CreateSalesOrder";
import SalesOrderPrint from "../features/salesorder/SalesOrderPrint";
import OrderForm from "../features/placeorder/OrderForm";
import Visitor from "../features/visitor/Visitor";
import CreateVisitor from "../features/visitor/CreateVisitor";
import OfficeStaffOut from "../features/officestaffout/OfficeStaffOut";
import CreateOfficeStaffOut from "../features/officestaffout/CreateOfficeStaffOut";
import VisitorReportForm from "../features/reports/reportsform/VisitorReportForm";
import OfficeStaffOutReportForm from "../features/reports/reportsform/OfficeStaffOutReportForm";
import OrderHistory from "../features/placeorder/OrderHistory";
import Sales from "../features/sales/Sales";
import CreateSales from "../features/sales/CreateSales";
import SalesPrint from "../features/sales/SalesPrint";
import AccountLayout from "../components/layout/AccountLayout";
import AccountDashboard from "../features/dashboard/AccountDashboard";
import { useAppSelector } from "../app/hooks";
import CreateItemGroup from "../features/ItemGroup/CreateItemGroup";
import VisitorPrint from "../features/visitor/VisitorPrint";
import SalesChallan from "../features/saleschallan/SalesChallan";
import CreateSalesChallan from "../features/saleschallan/CreateSalesChallan";
import GatePassPrint from "../features/sales/GatePassPrint";
import EnvelopePrint from "../features/sales/EnvelopePrint";
import SalesChallanPrint from "../features/saleschallan/SalesChallanPrint";
import Employee from "../features/employee/Employee";
import CreateEmployee from "../features/employee/CreateEmployee";
import Designation from "../features/designation/Designation";
import Document from "../features/document/Document";
import Relation from "../features/relation/Relation";
import Holiday from "../features/holiday/Holiday";
import Leave from "../features/leave/Leave";
import CreateProfessionTaxSlab from "../features/ProfessionTaxSlab/CreateProfessionTaxSlab";
import DistributorOpeningStock from "../features/distributoropeningstock/DistributorOpeningStock";
import CreateDistributorOpeningStock from "../features/distributoropeningstock/CreateDistributorOpeningStock";
import Retailer from "../features/retailer/Retailer";
import CreateRetailer from "../features/retailer/CreateRetailer";
import RetailerSalesOrder from "../features/retailersalesorder/RetailerSalesOrder";
import CreateRetailerSalesOrder from "../features/retailersalesorder/CreateRetailerSalesOrder";

const MainRouter = () => {
  const AccountIDString = useAppSelector(
    (state) => state.login.loginDetails.AccountID
  );
  const AccountID = parseInt(AccountIDString);

  return (
    <Routes>
      <Route path="login" element={<LogIn />} />
      <Route
        path="salesorderprint/:sale_order_guid"
        element={<SalesOrderPrint />}
      />
      <Route path="salesprint/:sale_guid" element={<SalesPrint />} />
      <Route path="sales/gatepass/:sale_guid" element={<GatePassPrint />} />
      <Route path="sales/envelope/:sale_guid" element={<EnvelopePrint />} />
      <Route path="visitorprint/:visitor_guid" element={<VisitorPrint />} />
      <Route
        path="saleschallanprint/:sales_challan_guid"
        element={<SalesChallanPrint />}
      />
      <Route
        path="/"
        element={
          <PrivateRoute>
            {AccountID ? <AccountLayout /> : <Layout />}
          </PrivateRoute>
        }
      >
        <Route
          index={true}
          element={AccountID ? <AccountDashboard /> : <Dashboard />}
        />
        <Route path="order" element={<OrderForm />} />
        <Route path="order/edit" element={<OrderForm />} />
        <Route path="orderhistory" element={<OrderHistory />} />
        <Route path="user" element={<User />} />
        <Route path="user/create" element={<CreateUser />} />
        <Route path="user/edit" element={<CreateUser />} />
        <Route path="employee" element={<Employee />} />
        <Route path="employee/create" element={<CreateEmployee />} />
        <Route path="employee/edit" element={<CreateEmployee />} />
        <Route path="usertype" element={<UserType />} />
        <Route path="usertype/create" element={<CreateUserType />} />
        <Route path="usertype/edit" element={<CreateUserType />} />
        <Route path="menu" element={<Menu />} />
        <Route path="rights" element={<Rights />} />
        <Route path="country" element={<Country />} />
        <Route path="state" element={<State />} />
        <Route path="city" element={<City />} />
        <Route path="designation" element={<Designation />} />
        <Route path="document" element={<Document />} />
        <Route path="relation" element={<Relation />} />
        <Route path="holiday" element={<Holiday />} />
        <Route path="leave" element={<Leave />} />
        <Route path="professiontaxslab" element={<CreateProfessionTaxSlab />} />
        <Route path="purchaseorder" element={<PurchaseOrder />} />
        <Route path="purchaseorder/create" element={<CreatePurchaseOrder />} />
        <Route path="purchaseorder/edit" element={<CreatePurchaseOrder />} />
        <Route path="inward" element={<Inward />} />
        <Route path="inward/create" element={<CreateInward />} />
        <Route path="inward/edit" element={<CreateInward />} />
        <Route path="inwardreturn" element={<InwardReturn />} />
        <Route path="inwardreturn/create" element={<CreateInwardReturn />} />
        <Route path="inwardreturn/edit" element={<CreateInwardReturn />} />
        <Route path="productcleaning" element={<ProductCleaning />} />
        <Route
          path="productcleaning/create"
          element={<CreateProductCleaning />}
        />
        <Route
          path="productcleaning/edit"
          element={<CreateProductCleaning />}
        />
        <Route path="production" element={<Production />} />
        <Route path="production/create" element={<CreateProduction />} />
        <Route path="production/edit" element={<CreateProduction />} />
        <Route path="itemcategory" element={<ItemCategory />} />
        <Route path="gst" element={<Gst />} />
        <Route path="itemgroup" element={<ItemGroup />} />
        <Route path="itemgroup/create" element={<CreateItemGroup />} />
        <Route path="itemgroup/edit" element={<CreateItemGroup />} />
        <Route path="unit" element={<Unit />} />
        <Route path="machine" element={<Machine />} />
        <Route path="item" element={<Item />} />
        <Route path="item/create" element={<CreateItem />} />
        <Route path="item/edit" element={<CreateItem />} />
        <Route path="rawmaterialitem" element={<Item />} />
        <Route path="rawmaterialitem/create" element={<CreateItem />} />
        <Route path="rawmaterialitem/edit" element={<CreateItem />} />
        <Route path="semifinisheditem" element={<Item />} />
        <Route path="semifinisheditem/create" element={<CreateItem />} />
        <Route path="semifinisheditem/edit" element={<CreateItem />} />
        <Route path="finisheditem" element={<Item />} />
        <Route path="finisheditem/create" element={<CreateItem />} />
        <Route path="finisheditem/edit" element={<CreateItem />} />
        <Route path="packingmaterialitem" element={<Item />} />
        <Route path="packingmaterialitem/create" element={<CreateItem />} />
        <Route path="packingmaterialitem/edit" element={<CreateItem />} />
        <Route path="warehouse" element={<WareHouse />} />
        <Route path="magnetcleaning" element={<MagnetCleaning />} />
        <Route
          path="magnetcleaning/create"
          element={<CreateMagnetCleaning />}
        />
        <Route path="magnetcleaning/edit" element={<CreateMagnetCleaning />} />
        <Route path="machinecleaning" element={<MachineCleaning />} />
        <Route
          path="machinecleaning/create"
          element={<CreateMachineCleaning />}
        />
        <Route
          path="machinecleaning/edit"
          element={<CreateMachineCleaning />}
        />
        <Route path="account" element={<Account />} />
        <Route path="account/create" element={<CreateAccount />} />
        <Route path="account/edit" element={<CreateAccount />} />
        <Route path="process" element={<Process />} />
        <Route path="department" element={<Department />} />
        <Route path="bom" element={<Bom />} />
        <Route path="bom/create" element={<CreateBom />} />
        <Route path="bom/edit" element={<CreateBom />} />
        <Route path="productmilling" element={<ProductMilling />} />
        <Route
          path="productmilling/create"
          element={<CreateProductMilling />}
        />
        <Route path="productmilling/edit" element={<CreateProductMilling />} />
        <Route path="roasting" element={<Roasting />} />
        <Route path="roasting/create" element={<CreateRoasting />} />
        <Route path="roasting/edit" element={<CreateRoasting />} />
        <Route
          path="inwardpackingmaterial"
          element={<InwardPackingMaterial />}
        />
        <Route
          path="inwardpackingmaterial/create"
          element={<CreateInwardPackingMaterial />}
        />
        <Route
          path="inwardpackingmaterial/edit"
          element={<CreateInwardPackingMaterial />}
        />
        <Route
          path="outwardpackingmaterial"
          element={<OutwardPackingMaterial />}
        />
        <Route
          path="outwardpackingmaterial/create"
          element={<CreateOutwardPackingMaterial />}
        />
        <Route
          path="outwardpackingmaterial/edit"
          element={<CreateOutwardPackingMaterial />}
        />
        <Route path="stocktransfer" element={<StockTransfer />} />
        <Route path="stocktransfer/create" element={<CreateStockTransfer />} />
        <Route path="stocktransfer/edit" element={<CreateStockTransfer />} />
        <Route path="packing" element={<Packing />} />
        <Route path="packing/create" element={<CreatePacking />} />
        <Route path="packing/edit" element={<CreatePacking />} />
        <Route
          path="productmillingreportview"
          element={<ProductMillingReportForm />}
        />
        <Route path="productionreportview" element={<ProductionReportForm />} />
        <Route
          path="ghanticleaningreportview"
          element={<GhantiCleaningReportForm />}
        />
        <Route
          path="metalcleaningreportview"
          element={<MetalCleaningReportForm />}
        />
        <Route
          path="productcleaningreportview"
          element={<ProductCleaningReportForm />}
        />
        <Route
          path="inwardpackingstockreportview"
          element={<InwardPackingMaterialStockForm />}
        />
        <Route
          path="outwordpackingstockreportview"
          element={<OutwardPackingMaterialStockForm />}
        />
        <Route
          path="inwardregisterreportview"
          element={<InwardRegisterReportForm />}
        />
        <Route path="stockreportview" element={<StockReportForm />} />
        <Route
          path="packingregisterreportview"
          element={<PackingRegisterReport />}
        />
        <Route
          path="packingmaterialstockreportview"
          element={<PackingMaterialStockReport />}
        />
        <Route path="visitorreportview" element={<VisitorReportForm />} />
        <Route
          path="officestaffoutreportview"
          element={<OfficeStaffOutReportForm />}
        />
        <Route path="roastingreportview" element={<RoastingReportForm />} />
        <Route path="salesorder" element={<SalesOrder />} />
        <Route path="salesorder/create" element={<CreateSalesOrder />} />
        <Route path="salesorder/edit" element={<CreateSalesOrder />} />
        <Route path="sales" element={<Sales />} />
        <Route path="sales/create" element={<CreateSales />} />
        <Route path="sales/edit" element={<CreateSales />} />
        <Route path="visitor" element={<Visitor />} />
        <Route path="visitor/create" element={<CreateVisitor />} />
        <Route path="visitor/edit" element={<CreateVisitor />} />
        <Route path="officestaffout" element={<OfficeStaffOut />} />
        <Route
          path="officestaffout/create"
          element={<CreateOfficeStaffOut />}
        />
        <Route path="officestaffout/edit" element={<CreateOfficeStaffOut />} />
        <Route path="saleschallan" element={<SalesChallan />} />
        <Route path="saleschallan/create" element={<CreateSalesChallan />} />
        <Route path="saleschallan/edit" element={<CreateSalesChallan />} />
        <Route
          path="distributoropeningstock"
          element={<DistributorOpeningStock />}
        />
        <Route
          path="distributoropeningstock/create"
          element={<CreateDistributorOpeningStock />}
        />
        <Route
          path="distributoropeningstock/edit"
          element={<CreateDistributorOpeningStock />}
        />
        <Route path="retailersalesorder" element={<RetailerSalesOrder />} />
        <Route
          path="retailersalesorder/create"
          element={<CreateRetailerSalesOrder />}
        />
        <Route
          path="retailersalesorder/edit"
          element={<CreateRetailerSalesOrder />}
        />
        <Route path="retailer" element={<Retailer />} />
        <Route path="retailer/create" element={<CreateRetailer />} />
        <Route path="retailer/edit" element={<CreateRetailer />} />
        <Route path="*" element={<h1>404</h1>} />
      </Route>
    </Routes>
  );
};

export default MainRouter;
