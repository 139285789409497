import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/Images/logo.png";
import AccountUserProfile from "./AccountUserProfile";

const AccountLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div id="wrapper">
      <div
        className="bg-dark"
        style={{ overflowX: "scroll", scrollbarWidth: "none" }}
      >
        <nav className="container account-home" role="navigation">
          <div className="row">
            <div className="col-3 d-flex justify-content-center align-items-center">
              <img
                src={Logo}
                alt=""
                height={45}
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/")}
              />
            </div>
            <div className="col-5">
              <ul className="account-home-menu">
                <li
                  className={
                    location.pathname?.split("/")[1] === "order" ? "active" : ""
                  }
                  onClick={() => {
                    navigate("/order");
                  }}
                >
                  Place Order
                </li>
                <li
                  className={
                    location.pathname?.split("/")[1] === "orderhistory"
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    navigate("/orderhistory");
                  }}
                >
                  Order History
                </li>
              </ul>
            </div>
            <div className="col-4 d-flex justify-content-end">
              <AccountUserProfile />
            </div>
          </div>
        </nav>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-12 p-0"
            style={{ overflow: "scroll", scrollbarWidth: "none" }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountLayout;
