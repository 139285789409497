import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormTextField from "../../../components/formFields/FormTextField";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import {
  getAccountStatustoryByUserID,
  updateAccountStatustory,
} from "../services/employee.services";
import {
  clearAccountAndStatutoryDetails,
  decreaseActiveStep,
  increaseActiveStep,
} from "../employeeSlice";
import ShadowCard from "../../../components/common/ShadowCard";
import FormDatePicker from "../../../components/formFields/FormDateField";
import { Button } from "@progress/kendo-react-buttons";
// import FormImageUpload from "../../../components/formFields/FormImageUpload";
import { LoadingPanel } from "../../../components/layout/Loading";
import moment from "moment";
import FormCheckbox from "../../../components/formFields/FormCheckbox";

const AccountAndStatutory: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const gridRef = useRef<any>(null);
  const EmployeeID = location.state?.EmployeeID;
  const EmployeeGUID = location.state?.EmployeeGUID;

  const formloading = useAppSelector((state) => state.employee.formloading);
  const AccountAndStatutoryDetails = useAppSelector(
    (state) => state.employee.AccountAndStatutoryDetails
  );

  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    return () => {
      dispatch(clearAccountAndStatutoryDetails());
    };
  }, []);

  useEffect(() => {
    if (EmployeeGUID) {
      dispatch(getAccountStatustoryByUserID(EmployeeGUID));
    }
  }, [EmployeeGUID]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [AccountAndStatutoryDetails]);
  const handleSubmit = async (values: any) => {
    const formdata = {
      user_guid: EmployeeGUID,
      bank_name: values?.bank_name,
      account_no: values?.account_no,
      ifsc_code: values?.ifsc_code,
      bank_branch: values?.bank_branch,
      pf_no: values?.pf_no,
      pf_deduction: values?.pf_deduction,
      pf_join_date: values?.pf_join_date
        ? moment(values?.pf_join_date).format("YYYY-MM-DD")
        : null,
      uan_no: values?.uan_no,
      est_no: values?.est_no,
      esi_join_date: values?.esi_join_date
        ? moment(values?.esi_join_date).format("YYYY-MM-DD")
        : null,
      aadharcard: values?.aadharcard,
      pancard: values?.pancard,
      passport_no: values?.passport_no,
      isactive: values?.isactive === 1,
    };
    try {
      if (EmployeeGUID) {
        const response = await dispatch(updateAccountStatustory(formdata));
        // console.log("response", response);
        if (response?.payload?.Status === 201) {
          navigate("/employee/edit", {
            state: {
              EmployeeGUID: EmployeeGUID,
              EmployeeID: EmployeeID,
            },
          });
          dispatch(increaseActiveStep());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };
  if (formloading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <Form
      onSubmit={handleSubmit}
      key={formKey}
      initialValues={AccountAndStatutoryDetails}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 10 }}>
            <GridLayout
              style={{ marginRight: 30 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
              ]}
            >
              <GridLayoutItem colSpan={4}>
                <Typography.h4>{"Bank Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="bank_name"
                  label="Bank Name"
                  placeholder="i.e. State Bank Of India"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="account_no"
                  label="Account No."
                  placeholder="Account No."
                  type="number"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="ifsc_code"
                  label="IFSC Code"
                  placeholder={"i.e. IDFC100025"}
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="bank_branch"
                  label="Branch Name"
                  placeholder={"i.e. Kalupur"}
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
          <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
              ]}
            >
              <GridLayoutItem colSpan={4}>
                <Typography.h4>{"PF Account Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem className="d-flex align-items-start">
                <Field
                  name="pf_deduction"
                  label="PF Deduction"
                  placeholder="PF Deduction"
                  component={FormCheckbox}
                  size="large"
                  labelClassName={"fw-bold"}
                  // validator={requiredValidator}
                />
              </GridLayoutItem>
              {formRenderProps.valueGetter("pf_deduction") && (
                <>
                  <GridLayoutItem>
                    <Field
                      name="pf_no"
                      label="PF No."
                      placeholder="i.e.DL/CPM/1234567/0001234"
                      component={FormTextField}
                      validator={requiredValidator}
                      disabled={!formRenderProps.valueGetter("pf_deduction")}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="pf_join_date"
                      label="PF Join Date"
                      format={"dd/MM/yyyy"}
                      maxDate={moment().toDate()}
                      validator={requiredValidator}
                      component={FormDatePicker}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="uan_no"
                      label="UAN No."
                      placeholder="i.e.100123456789"
                      type="number"
                      max={12}
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                </>
              )}
            </GridLayout>
          </ShadowCard>
          <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem colSpan={3}>
                <Typography.h4>{"ESI Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="est_no"
                  label="ESI No."
                  placeholder="i.e.1234567890"
                  component={FormTextField}
                  max={10}
                  type="number"
                  // validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="esi_join_date"
                  label="ESI Join Date"
                  format={"dd/MM/yyyy"}
                  maxDate={moment().toDate()}
                  component={FormDatePicker}
                />
              </GridLayoutItem>
              <GridLayoutItem></GridLayoutItem>
              <GridLayoutItem colSpan={3}>
                <Typography.h4>{"Other Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="pancard"
                  label="PAN No."
                  placeholder="i.e.GYYPK5487P"
                  component={FormTextField}
                  //  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="aadharcard"
                  label="AADHAR No."
                  placeholder="i.e.712846157845"
                  component={FormTextField}
                  type="number"
                  max={12}
                  // validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="passport_no"
                  label="PASSPORT No."
                  placeholder="i.e. A1234567"
                  component={FormTextField}
                  // validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem
                colSpan={3}
                style={{
                  textAlign: "end",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <Button
                  type="button"
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 4 }}
                  onClick={() => dispatch(decreaseActiveStep())}
                >
                  Previous
                </Button>
                <Button
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 4 }}
                  onClick={() => navigate("/employee")}
                >
                  Cancel
                </Button>
                <ButtonWithLoading
                  label={"Save & Next"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || formloading}
                  loading={formloading}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
        </FormElement>
      )}
    />
  );
};

export default AccountAndStatutory;
