import React, { useState } from "react";
import "./Layout.scss";
import { Outlet } from "react-router-dom";
import MainHeader from "./Header";
import { Layout as AntLayout, theme } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import IconButton from "../common/IconButton";
import UserProfile from "./UserProfile";

const Layout: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { Header, Content } = AntLayout;
  const [collapsed, setCollapsed] = useState(false);
  return (
    <AntLayout style={{ minHeight: "100vh" }}>
      <MainHeader collapsed={collapsed} setCollapsed={setCollapsed} />
      <AntLayout
        style={{
          overflow: "auto",
          height: "100vh",
        }}
      >
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "sticky",
            top: 0,
            right: 0,
            zIndex: 10,
          }}
        >
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            style={{
              fontSize: "26px",
              width: 64,
              height: 64,
            }}
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </IconButton>
          <UserProfile />
        </Header>
        <Content
          style={{
            margin: "16px",
          }}
        >
          <Outlet />
        </Content>
      </AntLayout>
    </AntLayout>
  );
};

export default Layout;

// import "./Layout.scss";
// import { useEffect } from "react";
// import { Link, Outlet } from "react-router-dom";
// import { BiTransfer, BiWorld } from "react-icons/bi";
// import {
//   FaBuilding,
//   FaUsers,
//   FaWarehouse,
//   FaGreaterThan,
//   FaLessThan,
// } from "react-icons/fa";
// import { CiMenuBurger } from "react-icons/ci";
// import UserProfile from "./UserProfile";
// import { ImCross } from "react-icons/im";
// import { FaCircleUser } from "react-icons/fa6";
// import { SiHiveBlockchain } from "react-icons/si";
// import { GrTransaction, GrVirtualMachine } from "react-icons/gr";
// import {
//   MdCleaningServices,
//   MdDashboard,
//   MdOutlineAllInbox,
// } from "react-icons/md";
// import {
//   HiDocumentCheck,
//   HiDocumentMinus,
//   HiDocumentPlus,
//   HiDocumentText,
//   HiMiniClipboardDocumentCheck,
//   HiMiniDocumentArrowDown,
//   HiMiniDocumentArrowUp,
// } from "react-icons/hi2";
// import {
//   TbHexagonLetterU,
//   TbReportSearch,
//   TbHexagonLetterC,
//   TbHexagonLetterM,
//   TbHexagonLetterR,
//   TbHexagonLetterP,
// } from "react-icons/tb";
// import {
//   LuMenu,
//   LuPackageCheck,
//   LuPackageMinus,
//   LuPackagePlus,
// } from "react-icons/lu";
// import { RiAccountPinBoxFill } from "react-icons/ri";
// import { HiReceiptTax } from "react-icons/hi";
// import { BsBoundingBox } from "react-icons/bs";
// import { VscServerProcess } from "react-icons/vsc";
// import { AiFillSetting } from "react-icons/ai";
// import { TiUserAdd } from "react-icons/ti";

// const Layout = () => {
//   const toggleMenu = () => {
//     document.querySelectorAll(".navbar-nav > li").forEach((element) => {
//       element.addEventListener("click", function (this: HTMLElement) {
//         document.querySelector(".page-contect")?.classList.remove("in");
//         document.querySelectorAll(".navbar-nav > li").forEach((li) => {
//           li.classList.remove("active");
//           const popup = document.querySelector(
//             ".navbar-nav > li.active .subnav-content"
//           );
//           if (popup) {
//             const popupElement = popup as HTMLElement;
//             popupElement.classList.remove("d-block");
//             popupElement.classList.add("d-none");
//           }
//         });
//         this.classList.add("active");
//         const popup = document.querySelector(
//           ".navbar-nav > li.active .subnav-content"
//         );
//         if (popup) {
//           const popupElement = popup as HTMLElement;
//           popupElement.classList.remove("d-none");
//           popupElement.classList.add("d-block");
//         }
//       });
//     });
//   };

//   useEffect(() => {
//     toggleMenu();
//   }, []);

//   const toggleSubMenu = () => {
//     const popup = document.querySelector(
//       ".navbar-nav > li.active .subnav-content"
//     );
//     if (popup) {
//       const popupElement = popup as HTMLElement;
//       popupElement.classList.remove("d-block");
//       popupElement.classList.add("d-none");
//     }

//     document.querySelectorAll(".navbar-nav > li").forEach((li) => {
//       li.classList.remove("active");
//     });
//   };

//   const toggleNav = () => {
//     const sidenav = document.querySelector(".side-nav") as any;
//     const mbottom = document.querySelector(".m-bottom") as any;
//     const gmnavbarheader = document.querySelector(".gmnavbar-header") as any;
//     const pagewrapper = document.querySelector(".page-wrapper") as any;
//     const pagecontect = document.querySelectorAll(".page-contect") as any;
//     const menuName = document.querySelectorAll(".menu-name") as any;
//     const greaterThan = document.querySelector(".greaterthan") as any;
//     const lessThan = document.querySelector(".lessthan") as any;

//     const isOpen = sidenav.style.width === "210px";
//     const isHeaderOpen = gmnavbarheader.style.width === "210px";
//     sidenav.style.width = isOpen ? "60px" : "210px";
//     mbottom.style.width = isOpen ? "60px" : "210px";
//     pagewrapper.style.marginLeft = isOpen ? "60px" : "210px";
//     gmnavbarheader.style.width = isHeaderOpen ? "60px" : "210px";
//     pagecontect.forEach((element: any) => {
//       if (isOpen) {
//         element.style.left = "60px";
//       } else {
//         element.style.left = "210px";
//       }
//     });
//     menuName.forEach((element: any) => {
//       if (isOpen) {
//         element.style.transition = "0.5s";
//         element.classList.remove("d-inline");
//         element.classList.add("d-none");
//       } else {
//         element.style.transition = "0.5s";
//         element.classList.remove("d-none");
//         element.classList.add("d-inline");
//       }
//     });
//     if (isOpen) {
//       lessThan.classList.remove("d-block");
//       lessThan.classList.add("d-none");
//       greaterThan.classList.remove("d-none");
//       greaterThan.classList.add("d-block");
//     } else {
//       lessThan.classList.remove("d-none");
//       lessThan.classList.add("d-block");
//       greaterThan.classList.remove("d-block");
//       greaterThan.classList.add("d-none");
//     }
//     // }
//   };

//   return (
//     <div id="wrapper">
//       <nav className="gmnavbar" role="navigation">
//         <div className="gmnavbar-header" style={{ width: 210 }}>
//           <button type="button" className="navbar-toggle">
//             <CiMenuBurger />
//           </button>
//           <h3 className="m-0">GM</h3>
//         </div>
//         <div className="d-flex align-items-center">
//           <UserProfile />
//         </div>
//       </nav>
//       <div className="collapse navbar-collapse navbar-ex1-collapse show">
//         <ul
//           className="nav navbar-nav side-nav"
//           style={{ zIndex: 10, scrollbarWidth: "none", width: 210 }}
//         >
//           <li className="subnav" onClick={toggleMenu}>
//             <Link to="/" className="collapsed">
//               <MdDashboard style={{ flex: "none" }} />
//               <span className="menu-name">Dashboard</span>
//             </Link>
//           </li>
//           <li className="subnav" onClick={toggleMenu}>
//             <Link to="javascript:void(0)" className="collapsed">
//               <FaCircleUser style={{ flex: "none" }} />
//               <span className="menu-name">User Management</span>
//             </Link>
//             <div className="subnav-content page-contect">
//               <div className="container-fluid">
//                 <div className="container-title">
//                   <h2>User - Quick Menu</h2>
//                   <button className="model-close" onClick={toggleSubMenu}>
//                     <ImCross />
//                   </button>
//                 </div>
//                 <div className="container-information ">
//                   <div className="fw-600">Role Rights:</div>
//                   <div className="container-top-block pt-3">
//                     <Link
//                       to="/menu"
//                       className="info-block"
//                       onClick={toggleSubMenu}
//                     >
//                       <LuMenu className="submenu-icon" />
//                       <span>Menu</span>
//                     </Link>
//                     <Link
//                       to="/rights"
//                       className="info-block"
//                       onClick={toggleSubMenu}
//                     >
//                       <AiFillSetting className="submenu-icon" />
//                       <span>Rights </span>
//                     </Link>
//                   </div>
//                   <div className="pt-3 fw-600">User:</div>
//                   <div className="container-top-block  pt-3">
//                     <Link
//                       to="/user"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <TiUserAdd className="submenu-icon" />
//                       <span>User</span>
//                     </Link>

//                     <Link
//                       to="/usertype"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <FaUsers className="submenu-icon" />
//                       <span>User Type</span>
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </li>
//           <li className="subnav" onClick={toggleMenu}>
//             <Link to="javascript:void(0)" className="collapsed">
//               <SiHiveBlockchain style={{ flex: "none" }} />
//               <span className="menu-name">Masters</span>
//             </Link>
//             <div className="subnav-content page-contect">
//               <div className="container-fluid">
//                 <div className="container-title">
//                   <h2>Masters - Quick Menu</h2>
//                   <button className="model-close" onClick={toggleSubMenu}>
//                     <ImCross />
//                   </button>
//                 </div>
//                 <div className="container-information ">
//                   <div className="fw-600">Places:</div>
//                   <div className="container-top-block pt-3">
//                     <Link
//                       to="/country"
//                       className="info-block"
//                       onClick={toggleSubMenu}
//                     >
//                       <BiWorld className="submenu-icon" />
//                       <span>Country</span>
//                     </Link>
//                     <Link
//                       to="/state"
//                       className="info-block"
//                       onClick={toggleSubMenu}
//                     >
//                       <BiWorld className="submenu-icon" />
//                       <span>State </span>
//                     </Link>
//                     <Link
//                       to="/city"
//                       className="info-block"
//                       onClick={toggleSubMenu}
//                     >
//                       <BiWorld className="submenu-icon" />
//                       <span>City</span>
//                     </Link>
//                   </div>
//                   <div className="pt-3 fw-600">Item:</div>
//                   <div className="container-top-block  pt-3">
//                     <Link
//                       to="/item"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <MdOutlineAllInbox className="submenu-icon" />
//                       <span>Item</span>
//                     </Link>

//                     <Link
//                       to="/itemcategory"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <MdOutlineAllInbox className="submenu-icon" />
//                       <span>Item Category</span>
//                     </Link>
//                     <Link
//                       to="/itemgroup"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <MdOutlineAllInbox className="submenu-icon" />
//                       <span>Item Group</span>
//                     </Link>
//                     <Link
//                       to="/unit"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <TbHexagonLetterU className="submenu-icon" />
//                       <span>Unit</span>
//                     </Link>
//                   </div>
//                   <div className="pt-3 fw-600">Other:</div>
//                   <div className="container-top-block  pt-3">
//                     <Link
//                       to="/account"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <RiAccountPinBoxFill className="submenu-icon" />
//                       <span>Account</span>
//                     </Link>
//                     <Link
//                       to="/machine"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <GrVirtualMachine className="submenu-icon" />
//                       <span>Machine</span>
//                     </Link>
//                     <Link
//                       to="/department"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <FaBuilding className="submenu-icon" />
//                       <span>Department</span>
//                     </Link>
//                     <Link
//                       to="/warehouse"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <FaWarehouse className="submenu-icon" />
//                       <span>Warehouse</span>
//                     </Link>
//                     <Link
//                       to="/gst"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <HiReceiptTax className="submenu-icon" />
//                       <span>GST</span>
//                     </Link>
//                     <Link
//                       to="/bom"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <BsBoundingBox className="submenu-icon" />
//                       <span>BOM</span>
//                     </Link>
//                     <Link
//                       to="/process"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <VscServerProcess className="submenu-icon" />
//                       <span>Process</span>
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </li>
//           <li className="subnav" onClick={toggleMenu}>
//             <Link to="javascript:void(0)" className="collapsed">
//               <GrTransaction style={{ flex: "none" }} />
//               <span className="menu-name">Transactions</span>
//             </Link>
//             <div className="subnav-content page-contect">
//               <div className="container-fluid">
//                 <div className="container-title">
//                   <h2>Transactions - Quick Menu</h2>
//                   <button className="model-close" onClick={toggleSubMenu}>
//                     <ImCross />
//                   </button>
//                 </div>
//                 <div className="container-information ">
//                   <div className="fw-600">Transaction:</div>
//                   <div className="container-top-block  pt-3">
//                     <Link
//                       to="/purchaseorder"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <HiMiniDocumentArrowDown className="submenu-icon" />
//                       <span>Purchase Order</span>
//                     </Link>
//                     <Link
//                       to="/inward"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <HiMiniDocumentArrowDown className="submenu-icon" />
//                       <span>Inward</span>
//                     </Link>
//                     <Link
//                       to="/inwardreturn"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <HiMiniDocumentArrowUp className="submenu-icon" />
//                       <span>Inward Return</span>
//                     </Link>
//                   </div>
//                   <div className="pt-3 fw-600">Process:</div>
//                   <div className="container-top-block pt-3">
//                     <Link
//                       to="/productcleaning"
//                       className="info-block"
//                       onClick={toggleSubMenu}
//                     >
//                       <TbHexagonLetterC className="submenu-icon" />
//                       <span>Product Cleaning</span>
//                     </Link>
//                     <Link
//                       to="/productmilling"
//                       className="info-block"
//                       onClick={toggleSubMenu}
//                     >
//                       <TbHexagonLetterM className="submenu-icon" />
//                       <span>Product Milling</span>
//                     </Link>
//                     <Link
//                       to="/roasting"
//                       className="info-block"
//                       onClick={toggleSubMenu}
//                     >
//                       <TbHexagonLetterR className="submenu-icon" />
//                       <span>Roasting</span>
//                     </Link>
//                     <Link
//                       to="/production"
//                       className="info-block"
//                       onClick={toggleSubMenu}
//                     >
//                       <TbHexagonLetterP className="submenu-icon" />
//                       <span>Production</span>
//                     </Link>
//                   </div>
//                   <div className="pt-3 fw-600">Cleaning:</div>
//                   <div className="container-top-block  pt-3">
//                     <Link
//                       to="/magnetcleaning"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <MdCleaningServices className="submenu-icon" />
//                       <span>Magnet Cleaning</span>
//                     </Link>
//                     <Link
//                       to="/machinecleaning"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <MdCleaningServices className="submenu-icon" />
//                       <span>Machine Cleaning</span>
//                     </Link>
//                   </div>
//                   <div className="pt-3 fw-600">Packing & Stock:</div>
//                   <div className="container-top-block  pt-3">
//                     <Link
//                       to="/packing"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <LuPackageCheck className="submenu-icon" />
//                       <span>Packing</span>
//                     </Link>
//                     <Link
//                       to="/inwardpackingmaterial"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <LuPackagePlus className="submenu-icon" />
//                       <span>Inward Packing Material</span>
//                     </Link>
//                     <Link
//                       to="/outwardpackingmaterial"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <LuPackageMinus className="submenu-icon" />
//                       <span>Outward Packing Material</span>
//                     </Link>
//                     <Link
//                       to="/stocktransfer"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <BiTransfer className="submenu-icon" />
//                       <span>Stock Transfer</span>
//                     </Link>
//                   </div>
//                   <div className="container-top-block  pt-3">
//                     <Link
//                       to="/salesorder"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <HiMiniDocumentArrowUp className="submenu-icon" />
//                       <span>Sales Order</span>
//                     </Link>
//                     <Link
//                       to="/sales"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <HiMiniDocumentArrowUp className="submenu-icon" />
//                       <span>Sales</span>
//                     </Link>
//                   </div>
//                   <div className="pt-3 fw-600">Visitor & Staff:</div>
//                   <div className="container-top-block  pt-3">
//                     <Link
//                       to="/visitor"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <HiMiniDocumentArrowDown className="submenu-icon" />
//                       <span>Visitor</span>
//                     </Link>
//                     <Link
//                       to="/officestaffout"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <HiMiniDocumentArrowUp className="submenu-icon" />
//                       <span>Office Staff Out</span>
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </li>
//           <li className="subnav" onClick={toggleMenu}>
//             <Link to="javascript:void(0)" className="collapsed">
//               <TbReportSearch style={{ flex: "none" }} />
//               <span className="menu-name">Reports</span>
//             </Link>
//             <div className="subnav-content page-contect">
//               <div className="container-fluid">
//                 <div className="container-title">
//                   <h2>Reports - Quick Menu</h2>
//                   <button className="model-close" onClick={toggleSubMenu}>
//                     <ImCross />
//                   </button>
//                 </div>
//                 <div className="container-information ">
//                   <div className="fw-600">Process Reports:</div>
//                   <div className="container-top-block pt-3">
//                     <Link
//                       to="/productcleaningreportview"
//                       className="info-block"
//                       onClick={toggleSubMenu}
//                     >
//                       <HiDocumentCheck className="submenu-icon" />
//                       <span>Product Cleaning</span>
//                     </Link>
//                     <Link
//                       to="/productmillingreportview"
//                       className="info-block"
//                       onClick={toggleSubMenu}
//                     >
//                       <HiDocumentCheck className="submenu-icon" />
//                       <span>Product Milling</span>
//                     </Link>
//                     <Link
//                       to="/roastingreportview"
//                       className="info-block"
//                       onClick={toggleSubMenu}
//                     >
//                       <HiDocumentCheck className="submenu-icon" />
//                       <span>Roasting</span>
//                     </Link>
//                     <Link
//                       to="/productionreportview"
//                       className="info-block"
//                       onClick={toggleSubMenu}
//                     >
//                       <HiDocumentCheck className="submenu-icon" />
//                       <span>Production</span>
//                     </Link>
//                   </div>
//                   <div className="pt-3 fw-600">Cleaning Reports:</div>
//                   <div className="container-top-block  pt-3">
//                     <Link
//                       to="/ghanticleaningreportview"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <HiMiniClipboardDocumentCheck className="submenu-icon" />
//                       <span>Ghanti Cleaning</span>
//                     </Link>
//                     <Link
//                       to="/metalcleaningreportview"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <HiMiniClipboardDocumentCheck className="submenu-icon" />
//                       <span>Metal Cleaning</span>
//                     </Link>
//                   </div>
//                   <div className="pt-3 fw-600">Stock & Packing Reports:</div>
//                   <div className="container-top-block  pt-3">
//                     <Link
//                       to="/inwardregisterreportview"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <HiDocumentPlus className="submenu-icon" />
//                       <span>Inward Register</span>
//                     </Link>
//                     <Link
//                       to="/stockreportview"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <HiDocumentText className="submenu-icon" />
//                       <span>Stock</span>
//                     </Link>
//                     <Link
//                       to="/inwardpackingstockreportview"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <HiDocumentPlus className="submenu-icon" />
//                       <span>Inward Packing Material Stock Detail</span>
//                     </Link>
//                     <Link
//                       to="/outwordpackingstockreportview"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <HiDocumentMinus className="submenu-icon" />
//                       <span>Outward Packing Material Stock Detail</span>
//                     </Link>
//                     <Link
//                       to="/packingregisterreportview"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <HiDocumentMinus className="submenu-icon" />
//                       <span>Packing Register</span>
//                     </Link>
//                     <Link
//                       to="/packingmaterialstockreportview"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <BiTransfer className="submenu-icon" />
//                       <span>Packing Material Stock</span>
//                     </Link>
//                     <Link
//                       to="/visitorreportview"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <BiTransfer className="submenu-icon" />
//                       <span>Visitor</span>
//                     </Link>
//                     <Link
//                       to="/officestaffoutreportview"
//                       className="info-block "
//                       onClick={toggleSubMenu}
//                     >
//                       <BiTransfer className="submenu-icon" />
//                       <span>Office Staff Out</span>
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </li>
//           <div className="m-bottom" onClick={toggleNav}>
//             <a href="javascript:void(0)" className="m-arrow">
//               <FaGreaterThan className="greaterthan d-none" />
//               <FaLessThan className="lessthan" />
//             </a>
//           </div>
//         </ul>
//       </div>
//       <div className="all-page">
//         <div className="page-wrapper">
//           <div className="page-information ">
//             <Outlet />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Layout;
