import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IPurchaseOrder,
  IPurchaseOrderInitialState,
} from "./purchaseOrderModel";
import {
  createPurchaseOrder,
  deletePurchaseOrder,
  getAllPurchaseOrders,
  getPurchaseNoPurchaseOrderByID,
  getPurchaseOrderByID,
  updatePurchaseOrder,
} from "./services/purchaseOrder.services";
import moment from "moment";

const initialState: IPurchaseOrderInitialState = {
  loading: false,
  error: "",
  stateForGST: null,
  PODeleteIndex: -1,
  PurchaseOrderID: null,
  PurchaseOrderList: [],
  PurchaseOrderDetail: {
    po_no_string: "",
    po_date: moment().toDate(),
    delivery_date: "",
    quotation_no: null,
    quotation_ref: "",
    vendor_id: null,
    mobile_no: "",
    email_id: "",
    remarks: "",
    taxable_amount: null,
    gst: null,
    sgst: null,
    cgst: null,
    igst: null,
    sub_total: null,
    round_off: null,
    ground_total: null,
    financial_account_year: null,
    freight: "",
    p_and_f_charge: "",
    payment_terms: "",
    mode_of_transport: "",
    purchase_order_items: [],
    isactive: true,
  },
};

const purchaseOrderSlice = createSlice({
  name: "purchaseOrder",
  initialState,
  reducers: {
    clearPurchaseOrderDetails: (state) => {
      state.PurchaseOrderDetail = initialState.PurchaseOrderDetail;
    },
    setPurchaseOrderID: (state, action) => {
      state.PurchaseOrderID = action.payload;
    },
    clearPurchaseOrderID: (state) => {
      state.PurchaseOrderID = initialState.PurchaseOrderID;
    },
    setPODeleteIndex: (state, action) => {
      state.PODeleteIndex = action.payload;
    },
    setStateForGST: (state, action) => {
      state.stateForGST = action.payload;
    },
    clearStateForGST: (state) => {
      state.stateForGST = initialState.stateForGST;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPurchaseOrders.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllPurchaseOrders.fulfilled,
      (state, action: PayloadAction<IPurchaseOrder[]>) => {
        state.loading = false;
        state.PurchaseOrderList = action.payload || [];
      }
    );
    builder.addCase(getAllPurchaseOrders.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PurchaseOrderList = [];
    });
    builder.addCase(createPurchaseOrder.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createPurchaseOrder.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createPurchaseOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updatePurchaseOrder.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updatePurchaseOrder.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePurchaseOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deletePurchaseOrder.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deletePurchaseOrder.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePurchaseOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getPurchaseOrderByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getPurchaseOrderByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.PurchaseOrderDetail = action.payload;
    });
    builder.addCase(getPurchaseOrderByID.rejected, (state, action) => {
      state.loading = false;
      state.PurchaseOrderDetail = initialState.PurchaseOrderDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getPurchaseNoPurchaseOrderByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getPurchaseNoPurchaseOrderByID.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.PurchaseOrderDetail = action.payload;
      }
    );
    builder.addCase(
      getPurchaseNoPurchaseOrderByID.rejected,
      (state, action) => {
        state.loading = false;
        state.PurchaseOrderDetail = initialState.PurchaseOrderDetail;
        state.error = action.error.message || "Something went wrong";
      }
    );
  },
});

export const {
  clearPurchaseOrderDetails,
  clearPurchaseOrderID,
  setPurchaseOrderID,
  setPODeleteIndex,
  setStateForGST,
  clearStateForGST,
} = purchaseOrderSlice.actions;
export default purchaseOrderSlice.reducer;
