import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { IGRNList, IInwardReturnState } from "./inwardReturnModel";
import {
  createInwardReturn,
  deleteInwardReturn,
  getAllInwardGRN,
  getAllInwardReturns,
  getAllItemByInwardNo,
  getGRNByItemID,
  getInwardReturnByID,
  updateInwardReturn,
} from "./services/inwardReturn.services";

const initialState: IInwardReturnState = {
  loading: false,
  error: "",
  InwardReturnDeleteIndex: -1,
  InwardReturnID: null,
  InwardReturnList: [],
  GRNList: [],
  ItemList: [],
  InwardReturnDetail: {
    inward_return_guid: "",
    inward_return_no: null,
    inward_return_date: moment().toDate(),
    vendor_id: null,
    bill_no: "",
    vehicle_no: "",
    chalan_no: "",
    financial_year: null,
    remarks: "",
    inward_return_items: [],
  },
};

const inwardReturnSlice = createSlice({
  name: "inwardReturn",
  initialState,
  reducers: {
    clearInwardReturnDetails: (state) => {
      state.InwardReturnDetail = initialState.InwardReturnDetail;
    },
    setInwardReturnID: (state, action) => {
      state.InwardReturnID = action.payload;
    },
    clearItemList: (state) => {
      state.ItemList = initialState.ItemList;
    },
    clearInwardReturnID: (state) => {
      state.InwardReturnID = initialState.InwardReturnID;
    },
    setInwardReturnDeleteIndex: (state, action) => {
      state.InwardReturnDeleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGRNByItemID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getGRNByItemID.fulfilled,
      (state, action: PayloadAction<IGRNList[]>) => {
        state.loading = false;
        state.GRNList = action.payload || [];
      }
    );
    builder.addCase(getGRNByItemID.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.GRNList = [];
    });
    builder.addCase(getAllInwardGRN.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllInwardGRN.fulfilled,
      (state, action: PayloadAction<IGRNList[]>) => {
        state.loading = false;
        state.GRNList = action.payload || [];
      }
    );
    builder.addCase(getAllInwardGRN.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.GRNList = [];
    });
    builder.addCase(getAllInwardReturns.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllInwardReturns.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.InwardReturnList = action.payload || [];
      }
    );
    builder.addCase(getAllInwardReturns.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.InwardReturnList = [];
    });
    builder.addCase(createInwardReturn.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createInwardReturn.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createInwardReturn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateInwardReturn.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateInwardReturn.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateInwardReturn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteInwardReturn.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteInwardReturn.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteInwardReturn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getInwardReturnByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getInwardReturnByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.InwardReturnDetail = action.payload;
    });
    builder.addCase(getInwardReturnByID.rejected, (state, action) => {
      state.loading = false;
      state.InwardReturnDetail = initialState.InwardReturnDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getAllItemByInwardNo.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllItemByInwardNo.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.ItemList = action.payload || [];
      }
    );
    builder.addCase(getAllItemByInwardNo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ItemList = [];
    });
  },
});

export const {
  clearInwardReturnDetails,
  clearInwardReturnID,
  setInwardReturnID,
  setInwardReturnDeleteIndex,
  clearItemList,
} = inwardReturnSlice.actions;
export default inwardReturnSlice.reducer;
