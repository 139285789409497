import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast } from "../../../components/toast/Toasts";

export const getProductMillingReport = createAsyncThunk(
  "Reports/FindAllProductMillingReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllProductMillingReport`,
        formData
      );
      const result = response.data?.Data;
      return result.map((milling: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: milling?.id,
          itemcount: milling?.itemcount,
          totalpages: milling?.totalpages,
          milling_date: milling?.milling_date,
          milling_no: milling?.milling_no,
          item_id: milling?.item_id,
          product_name: milling?.product_name,
          grn_no: milling?.grn_no,
          no_of_workers: milling?.no_of_workers,
          machine_id: milling?.machine_id,
          machine_name: milling?.machine_name,
          start_time: milling?.start_time,
          end_time: milling?.end_time,
          total_qty: milling?.total_qty,
          received_qty: milling?.received_qty,
          production_loss_qty: milling?.production_loss_qty,
          loss_percentage: milling?.loss_percentage,
          lot_no: milling?.lot_no,
          katta: milling?.katta,
          checker_by: milling?.checker_by,
          checker_name: milling?.checker_name,
          department_id: milling?.department_id,
          department_name: milling?.department_name,
          process_id: milling?.process_id,
          process_name: milling?.process_name,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Product milling report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getProductionReport = createAsyncThunk(
  "Reports/FindAllProductionReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllProductionReport`,
        formData
      );
      const result = response.data?.Data;
      return result.map((production: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: production?.id,
          itemcount: production?.itemcount,
          totalpages: production?.totalpages,
          Production_guid: production?.Production_guid,
          start_date: production?.start_date,
          end_date: production?.end_date,
          lot_no: production?.lot_no,
          item_id: production?.item_id,
          product_name: production?.product_name,
          no_of_workers: production?.no_of_workers,
          roasting_duration: production?.roasting_duration,
          old_material_lot_no: production?.old_material_lot_no,
          total_quantity: production?.total_quantity,
          quantity: production?.quantity,
          total_qty: production?.total_qty,
          received_qty: production?.received_qty,
          loss_qty: production?.loss_qty,
          loss_percentage: production?.loss_percentage,
          supervisor_by: production?.supervisor_by,
          supervisor_name: production?.supervisor_name,
          remarks: production?.remarks,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Production report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getGhantiCleaningReport = createAsyncThunk(
  "Reports/FindAllMachineCleaningReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllMachineCleaningReport`,
        formData
      );
      const result = response.data?.Data;
      return result.map((ghanti: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: ghanti?.id,
          itemcount: ghanti?.itemcount,
          totalpages: ghanti?.totalpages,
          cleaning_no: ghanti?.cleaning_no,
          machine_no: ghanti?.machine_no,
          machine_name: ghanti?.machine_name,
          product_name: ghanti?.product_name,
          grinding_lot_no: ghanti?.grinding_lot_no,
          production_qty: ghanti?.production_qty,
          maintenance_time: ghanti?.maintenance_time,
          machine_cleaning_date: ghanti?.machine_cleaning_date,
          qc_by: ghanti?.qc_by,
          qc_name: ghanti?.qc_name,
          production_by: ghanti?.production_by,
          production_name: ghanti?.production_name,
          remarks: ghanti?.remarks,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Ghanti cleaning report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getMetalCleaningReport = createAsyncThunk(
  "Reports/FindAllMagnetCleaningReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllMagnetCleaningReport`,
        formData
      );
      const result = response.data?.Data;
      return result.map((metal: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: metal?.id,
          itemcount: metal?.itemcount,
          totalpages: metal?.totalpages,
          cleaning_no: metal?.cleaning_no,
          machine_no: metal?.machine_no,
          machine_name: metal?.machine_name,
          product_name: metal?.product_name,
          metal_qty: metal?.metal_qty,
          m_c_cleaning: metal?.m_c_cleaning,
          sieve_condition: metal?.sieve_condition,
          magnet_cleaning_date: metal?.magnet_cleaning_date,
          supervisor_by: metal?.supervisor_by,
          supervisor_name: metal?.supervisor_name,
          verify_by: metal?.verify_by,
          verify_name: metal?.verify_name,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Metal cleaning report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getProductCleaningReport = createAsyncThunk(
  "Reports/FindAllProductCleaningReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllProductCleaningReport`,
        formData
      );
      const result = response.data?.Data;
      return result.map((cleaning: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: cleaning?.id,
          itemcount: cleaning?.itemcount,
          totalpages: cleaning?.totalpages,
          cleaning_date: cleaning?.cleaning_date,
          cleaning_no: cleaning?.cleaning_no,
          item_id: cleaning?.item_id,
          product_name: cleaning?.product_name,
          grn_no: cleaning?.grn_no,
          no_of_workers: cleaning?.no_of_workers,
          machine_id: cleaning?.machine_id,
          machine_name: cleaning?.machine_name,
          start_time: cleaning?.start_time,
          end_time: cleaning?.end_time,
          total_qty: cleaning?.total_qty,
          received_qty: cleaning?.received_qty,
          rejection_qty: cleaning?.rejection_qty,
          loss_percentage: cleaning?.loss_percentage,
          cleaning_checker_by: cleaning?.cleaning_checker_by,
          cleaning_checker_name: cleaning?.cleaning_checker_name,
          department_id: cleaning?.department_id,
          department_name: cleaning?.department_name,
          process_id: cleaning?.process_id,
          process_name: cleaning?.process_name,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Product cleaning report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getRoastingReport = createAsyncThunk(
  "Reports/FindAllRoastingReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllRoastingReport`,
        formData
      );
      const result = response.data?.Data;
      return result.map((roasting: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: roasting?.id,
          itemcount: roasting?.itemcount,
          totalpages: roasting?.totalpages,
          roasting_guid: roasting?.roasting_guid,
          roasting_date: roasting?.roasting_date,
          // lot_no: roasting?.lot_no,
          item_id: roasting?.item_id,
          product_name: roasting?.product_name,
          grn_no: roasting?.grn_no,
          no_of_workers: roasting?.no_of_workers,
          machine_id: roasting?.machine_id,
          machine_name: roasting?.machine_name,
          start_time: roasting?.start_time,
          end_time: roasting?.end_time,
          total_qty: roasting?.total_qty,
          // roasting_duration: roasting?.roasting_duration,
          // old_material_lot_no: roasting?.old_material_lot_no,
          received_qty: roasting?.received_qty,
          process_loss_qty: roasting?.process_loss_qty,
          loss_percentage: roasting?.loss_percentage,
          department_name: roasting?.department_name,
          checker_name: roasting?.checker_name,
          // supervisor_name: roasting?.supervisor_name,
          remarks: roasting?.remarks,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Roasting report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const getInwardPackingMaterialStockReport = createAsyncThunk(
  "Reports/FindAllInwardPackingMaterialReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllInwardPackingMaterialReport`,
        formData
      );
      const result = response.data?.Data;
      return result.map((results: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: results?.id,
          itemcount: results?.itemcount,
          totalpages: results?.totalpages,
          inward_packing_no: results?.inward_packing_no,
          inward_packing_material_date: results?.inward_packing_material_date,
          item_id: results?.item_id,
          product_name: results?.product_name,
          inward_item_qty: results?.inward_item_qty,
          unit_id: results?.unit_id,
          unit_name: results?.unit_name,
          roll: results?.roll,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error(
        "Error fetching inward packing material stock report:",
        error
      );
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getOutwardPackingMaterialStockReport = createAsyncThunk(
  "Reports/FindAllOutwardPackingMaterialReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllOutwardPackingMaterialReport`,
        formData
      );
      const result = response.data?.Data;
      return result.map((results: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: results?.id,
          itemcount: results?.itemcount,
          totalpages: results?.totalpages,
          outward_packing_no: results?.outward_packing_no,
          outward_packing_material_date: results?.outward_packing_material_date,
          item_id: results?.item_id,
          product_name: results?.product_name,
          outward_item_qty: results?.outward_item_qty,
          unit_id: results?.unit_id,
          unit_name: results?.unit_name,
          roll: results?.roll,
          outward_type: results?.outward_type,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error(
        "Error fetching outward packing material stock report:",
        error
      );
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getInwardRegisterReport = createAsyncThunk(
  "Inward/FindByRegisterPrintInward",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Inward/FindByRegisterPrintInward`,
        formData
      );
      const result = response.data?.Data;
      return result.map((results: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: results?.id,
          itemcount: results?.itemcount,
          inward_guid: results?.inward_guid,
          inward_no: results?.inward_no,
          po_no: results?.po_no,
          inward_date: results?.inward_date,
          bill_no: results?.bill_no,
          vendor_id: results?.vendor_id,
          account_name: results?.account_name,
          vehicle_no: results?.vehicle_no,
          remarks: results?.remarks,
          chalan_no: results?.chalan_no,
          bill_date: results?.bill_date,
          vehicle_in_time: results?.vehicle_in_time,
          vehicle_out_time: results?.vehicle_out_time,
          financial_year: results?.financial_year,
          grn_no: results?.grn_no,
          inward_id: results?.inward_id,
          item_id: results?.item_id,
          item_name: results?.item_name,
          dagina: results?.dagina,
          weight: results?.weight,
          rate: results?.rate,
          total: results?.total,
          cold_storage_id: results?.cold_storage_id,
          warehouse_name: results?.warehouse_name,
          receipt_no: results?.receipt_no,
          unit_id: results?.unit_id,
          unit_name: results?.unit_name,
          po_date: results?.po_date,
          vehicle_condition: results?.vehicle_condition,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching inward register:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getItemStockReport = createAsyncThunk(
  "Inward/FindAllItemStock",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Inward/FindAllItemStock`,
        formData
      );
      const result = response.data?.Data;
      return result.map((results: any, index: number) => {
        return {
          UniqueNo: index + 1,
          item_id: results?.item_id,
          product_name: results?.product_name,
          grn_no: results?.grn_no,
          process_id: results?.process_id,
          process_name: results?.process_name,
          unit_id: results?.unit_id,
          unit_name: results?.unit_name,
          warhouse_id: results?.warhouse_id,
          warehouse_name: results?.warehouse_name,
          actual_stock: results?.actual_stock,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching item stock:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPackingRegisterReport = createAsyncThunk(
  "Reports/FindAllPackingReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllPackingReport`,
        formData
      );
      const result = response.data?.Data;
      return result.map((packing: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: packing?.id,
          itemcount: packing?.itemcount,
          totalpages: packing?.totalpages,
          packing_guid: packing?.packing_guid,
          packing_no: packing?.packing_no,
          packing_date: packing?.packing_date,
          raw_item: packing?.raw_item,
          raw_item_name: packing?.raw_item_name,
          lot_no: packing?.lot_no,
          packing_item: packing?.packing_item,
          packing_item_name: packing?.packing_item_name,
          batch_no: packing?.batch_no,
          taken_qty: packing?.taken_qty,
          used_qty: packing?.used_qty,
          wastage_qty: packing?.wastage_qty,
          loss_percentage: packing?.loss_percentage,
          ready_qty_in_nos: packing?.ready_qty_in_nos,
          mfg_date: packing?.mfg_date,
          exp_date: packing?.exp_date,
          remarks: packing?.remarks,
          verify_by: packing?.verify_by,
          financial_year: packing?.financial_year,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching packing register:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPackingMaterialStockReport = createAsyncThunk(
  "Reports/FindAllPackingMaterialstockReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllPackingMaterialstockReport`,
        formData
      );
      const result = response.data?.Data;
      return result?.map((packing: any, index: number) => {
        return {
          UniqueNo: index + 1,
          item_id: packing?.item_id,
          product_name: packing?.product_name,
          item_group_id: packing?.item_group_id,
          item_group_name: packing?.item_group_name,
          unit_id: packing?.unit_id,
          unit_name: packing?.unit_name,
          actual_qty: packing?.actual_qty,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching packing material stock report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getVisitorReport = createAsyncThunk(
  "Reports/FindAllVisitorReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllVisitorReport`,
        formData
      );
      const result = response.data?.Data;
      return result?.map((visitor: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: visitor?.id,
          visitor_guid: visitor?.visitor_guid,
          visitor_date: visitor?.visitor_date,
          visitor_no: visitor?.visitor_no,
          user_id: visitor?.user_id,
          user_name: visitor?.user_name,
          name: visitor?.name,
          mobile_no: visitor?.mobile_no,
          email_id: visitor?.email_id,
          id_proof_no: visitor?.id_proof_no,
          from_company: visitor?.from_company,
          reason: visitor?.reason,
          in_time: visitor?.in_time,
          out_time: visitor?.out_time,
          financial_year: visitor?.financial_year,
          createdby: visitor?.createdby,
          createdby_name: visitor?.createdby_name,
          isactive: visitor?.isactive,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching visitor report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getOfficeStaffOutReport = createAsyncThunk(
  "Reports/FindAllStaffOutReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllStaffOutReport`,
        formData
      );
      const result = response.data?.Data;
      return result?.map((staffout: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: staffout?.id,
          itemcount: staffout?.itemcount,
          totalpages: staffout?.totalpages,
          staff_out_guid: staffout?.staff_out_guid,
          staff_out_date: staffout?.staff_out_date,
          user_id: staffout?.user_id,
          user_name: staffout?.user_name,
          staff_out_no: staffout?.staff_out_no,
          reason_for_out: staffout?.reason_for_out,
          approved_by: staffout?.approved_by,
          out_time: staffout?.out_time,
          in_time: staffout?.in_time,
          financial_year: staffout?.financial_year,
          createdby: staffout?.createdby,
          createdby_name: staffout?.createdby_name,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching office staff out report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
