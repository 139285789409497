import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormTextField from "../../components/formFields/FormTextField";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ShadowCard from "../../components/common/ShadowCard";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { LoadingPanel } from "../../components/layout/Loading";
import { clearProductCleaningDetails } from "./productCleaningSlice";
import {
  createProductCleaning,
  getProductCleaningByID,
  updateProductCleaning,
} from "./services/productCleaning.services";
// import { IItem } from "../Item/itemModel";
import { getGRNByItemID } from "../inwardreturn/services/inwardReturn.services";
import { IMachine } from "../machine/machineModel";
import FormTimePicker from "../../components/formFields/FormTimeField";
import { findAllActiveMachine } from "../machine/services/machine.services";
import { findAllActiveDepartment } from "../department/services/department.services";
import { IProcess } from "../process/processModel";
import { findAllActiveProcess } from "../process/services/process.services";
import moment from "moment";
import { IUser } from "../user/userModel";
import { getAllActiveUsers } from "../user/services/user.services";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { getCurrentStock } from "../stocktransfer/services/stockTransfer.services";
import { clearItemList } from "../Item/itemSlice";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import { getAllItemIncremental } from "../Item/services/item.services";
import { getLocalStorageItem } from "../../_helper/helper";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const QtychangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const total_qty = formRenderProps.valueGetter("total_qty");
  const received_qty = formRenderProps.valueGetter("received_qty");
  const rejection_qty = formRenderProps.valueGetter("rejection_qty");

  useEffect(() => {
    formRenderProps.onChange("rejection_qty", {
      value: total_qty - received_qty,
    });
  }, [total_qty, received_qty]);

  if (rejection_qty < 0) {
    formRenderProps.onChange("rejection_qty", {
      value: 0,
    });
  }

  return null;
};

const ItemChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const item_id = formRenderProps.valueGetter("item_id");
  const location = useLocation();
  const product_cleaning_guid = location.state?.product_cleaning_guid;
  const isItemRef = React.useRef(true);

  useEffect(() => {
    const fetchGRNOptions = async () => {
      if (product_cleaning_guid) {
        if (!isItemRef.current) {
          formRenderProps.onChange("grn_no", {
            value: null,
          });
        } else {
          isItemRef.current = false;
        }
      } else {
        formRenderProps.onChange("grn_no", {
          value: null,
        });
      }
      if (item_id) {
        const response: any = await dispatch(getGRNByItemID(item_id));
        if (response && response.payload) {
          const result = response.payload
            .filter((grn: any) => grn?.item_id === item_id)
            .map((grnno: any) => ({
              value: `${grnno?.grn_no}`,
              label: `${grnno?.grn_no}`,
            }));

          formRenderProps.onChange("grnNoOptions", {
            value: result?.length > 0 && result,
          });
        } else {
          formRenderProps.onChange("grnNoOptions", { value: [] });
          formRenderProps.onChange("grn_no", {
            value: null,
          });
        }
      } else {
        formRenderProps.onChange("grnNoOptions", { value: [] });
        formRenderProps.onChange("grn_no", {
          value: null,
        });
      }
    };

    fetchGRNOptions();
  }, [item_id]);

  return null;
};

const GRNChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const product_cleaning_guid = location.state?.product_cleaning_guid;
  const item_id = formRenderProps.valueGetter("item_id");
  const grn_no = formRenderProps.valueGetter("grn_no");
  const current_stock = formRenderProps.valueGetter("current_stock");

  useEffect(() => {
    if (grn_no) {
      const fetchStock = async (grn_no: any) => {
        const payload = {
          item_id: item_id ? item_id : null,
          grn_no: grn_no ? grn_no : "",
          process_id: 1,
          warehouse_id: 1,
        };
        const res = await dispatch(getCurrentStock(payload));
        formRenderProps.onChange("current_stock", {
          value: res?.payload?.actual_stock || 0,
        });
      };

      fetchStock(grn_no);
    } else {
      formRenderProps.onChange("current_stock", {
        value: null,
      });
    }
  }, [grn_no]);

  useEffect(() => {
    if (
      (current_stock === null || current_stock < 1) &&
      !product_cleaning_guid
    ) {
      formRenderProps.onChange("total_qty", {
        value: 0,
      });
      formRenderProps.onChange("received_qty", {
        value: 0,
      });
    }
  }, [current_stock]);

  return null;
};

const UserChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const UserID = getLocalStorageItem("UserID");
  const product_cleaning_guid = location.state?.product_cleaning_guid;

  useEffect(() => {
    if (!product_cleaning_guid) {
      formRenderProps.onChange("cleaning_checker_by", {
        value: UserID ? +UserID : null,
      });
    }
  }, [UserID]);

  return null;
};

const CreateProductCleaning: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const product_cleaning_guid = location.state?.product_cleaning_guid;
  const loading = useAppSelector((state) => state.productCleaning.loading);
  const itemLoading = useAppSelector((state) => state.item.loading);
  const ProductCleaningDetail = useAppSelector(
    (state) => state.productCleaning.ProductCleaningDetail
  );
  // const ItemList = useAppSelector((state) => state.item.ItemList);
  const MachineList = useAppSelector((state) => state.machine.MachineList);
  const ProcessList = useAppSelector((state) => state.process.ProcessList);
  const UserList = useAppSelector((state) => state.user.UserList);
  const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [ProductCleaningDetail]);

  useEffect(() => {
    dispatch(findAllActiveMachine());
    dispatch(findAllActiveDepartment());
    dispatch(findAllActiveProcess());
    dispatch(getAllActiveUsers());

    return () => {
      dispatch(clearProductCleaningDetails());
      dispatch(clearItemList());
    };
  }, []);

  useEffect(() => {
    if (product_cleaning_guid) {
      const payload = {
        product_cleaning_guid: product_cleaning_guid,
      };
      dispatch(getProductCleaningByID(payload));
    }
  }, [product_cleaning_guid]);

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (product_cleaning_guid) {
      try {
        const updatePayload = {
          product_cleaning_guid: ProductCleaningDetail?.product_cleaning_guid,
          cleaning_date: values?.cleaning_date
            ? moment(values?.cleaning_date).format("YYYY-MM-DD")
            : "",
          cleaning_no: values?.cleaning_no ? +values?.cleaning_no : null,
          item_id: values?.item_id ? +values?.item_id : null,
          grn_no: values?.grn_no ? values?.grn_no : "",
          no_of_workers: values?.no_of_workers ? +values?.no_of_workers : null,
          machine_id: values?.machine_id ? +values?.machine_id : null,
          start_time: values?.start_time
            ? moment(values?.start_time).format("HH:mm:ss")
            : "",
          end_time: values?.end_time
            ? moment(values?.end_time).format("HH:mm:ss")
            : "",
          total_qty: values?.total_qty ? +values?.total_qty : 0,
          received_qty: values?.received_qty ? +values?.received_qty : 0,
          rejection_qty: values?.rejection_qty ? +values?.rejection_qty : 0,
          cleaning_checker_by: values?.cleaning_checker_by
            ? +values?.cleaning_checker_by
            : null,
          financial_year: 202425,
          // department_id: values?.department_id ? +values?.department_id : null,
          process_id: values?.process_id ? +values?.process_id : null,
          remarks: values?.remarks ? values?.remarks : "",
        };
        const response = await dispatch(updateProductCleaning(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/productcleaning");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const insertPayload = {
          cleaning_date: values?.cleaning_date
            ? moment(values?.cleaning_date).format("YYYY-MM-DD")
            : "",
          cleaning_no: values?.cleaning_no ? +values?.cleaning_no : null,
          item_id: values?.item_id ? +values?.item_id : null,
          grn_no: values?.grn_no ? values?.grn_no : "",
          no_of_workers: values?.no_of_workers ? +values?.no_of_workers : null,
          machine_id: values?.machine_id ? +values?.machine_id : null,
          start_time: values?.start_time
            ? moment(values?.start_time).format("HH:mm:ss")
            : "",
          end_time: values?.end_time
            ? moment(values?.end_time).format("HH:mm:ss")
            : "",
          total_qty: values?.total_qty ? +values?.total_qty : 0,
          received_qty: values?.received_qty ? +values?.received_qty : 0,
          rejection_qty: values?.rejection_qty ? +values?.rejection_qty : 0,
          cleaning_checker_by: values?.cleaning_checker_by
            ? +values?.cleaning_checker_by
            : null,
          financial_year: 202425,
          // department_id: values?.department_id ? +values?.department_id : null,
          process_id: values?.process_id ? +values?.process_id : null,
          remarks: values?.remarks ? values?.remarks : "",
        };
        const response = await dispatch(createProductCleaning(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleProductCleaningNoChange = (
    ev: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (ev.key === "Enter" || ev.key === "Tab") {
      ev.preventDefault();
      if ((ev.target as HTMLInputElement).value) {
        const payload = {
          cleaning_no: (ev.target as HTMLInputElement).value,
          financial_year: 202425,
        };
        dispatch(getProductCleaningByID(payload));
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={ProductCleaningDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {product_cleaning_guid
                      ? "Update Product Cleaning"
                      : "Create Product Cleaning"}
                  </Typography.h4>
                </GridLayoutItem>
                {product_cleaning_guid && (
                  <GridLayoutItem>
                    <Field
                      // wrapperClassName="w-50"
                      name="cleaning_no"
                      onKeyDown={handleProductCleaningNoChange}
                      label="Product Cleaning No"
                      placeholder="Product Cleaning No"
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                )}
                {product_cleaning_guid && (
                  <GridLayoutItem colSpan={3}></GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="cleaning_date"
                    label="Cleaning Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="item_id"
                    label="Product Name"
                    placeholder="Product Name"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    loading={itemLoading}
                    fetchIncrementalData={(search: any) =>
                      handleItemSearchChange(
                        search,
                        `item_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("item_options")
                        ?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item.product_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem
                // style={{ display: "flex", gap: 10, alignItems: "start" }}
                >
                  <Field
                    // wrapperStyle={{ width: "50%" }}
                    name="grn_no"
                    label="GRN No"
                    placeholder="GRN No"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    // options={grnOptions || []}
                    options={formRenderProps.valueGetter("grnNoOptions") || []}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="stock-label center-alighned-field d-flex flex-wrap justify-content-center"
                    wrapperStyle={{ color: "red" }}
                    name="current_stock"
                    label="Current Stock"
                    disabled={true}
                    placeholder="0"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="no_of_workers"
                    label="No Of Workers"
                    type="number"
                    placeholder="0"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="machine_id"
                    label="Machine No"
                    placeholder="Machine No"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={MachineList?.map((machine: IMachine) => {
                      return {
                        value: machine?.id,
                        label: machine?.machine_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                //  style={{ display: "flex", gap: 10 }}
                >
                  <Field
                    wrapperStyle={{ width: "100%" }}
                    name="start_time"
                    label="Start Time"
                    component={FormTimePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperStyle={{ width: "100%" }}
                    name="end_time"
                    label="End Time"
                    component={FormTimePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem></GridLayoutItem> */}
                <GridLayoutItem
                  colSpan={2}
                  style={{ display: "flex", gap: 10 }}
                >
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="total_qty"
                    label="Total Qty (KG)"
                    placeholder="0"
                    min={0}
                    max={
                      product_cleaning_guid
                        ? ProductCleaningDetail?.total_qty +
                          formRenderProps.valueGetter("current_stock")
                        : formRenderProps.valueGetter("current_stock")
                    }
                    component={FormNumericTextField}
                    validator={requiredValidator}
                  />
                  {/* </GridLayoutItem>
                <GridLayoutItem> */}
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="received_qty"
                    label="Received Qty"
                    placeholder="0"
                    min={0}
                    max={formRenderProps.valueGetter("total_qty")}
                    component={FormNumericTextField}
                    validator={requiredValidator}
                  />
                  {/* </GridLayoutItem>
                <GridLayoutItem> */}
                  <Field
                    wrapperClassName="w-100 center-alighned-field red-highlight-field d-flex flex-column align-items-center"
                    name="rejection_qty"
                    label="Rejection Qty"
                    placeholder="0"
                    disabled={true}
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem>
                  <Field
                    name="department_id"
                    label="Material Issued to Department"
                    placeholder="Department Name"
                    component={FormSelectionField}
                    options={DepartmentList?.map((department: IDepartment) => {
                      return {
                        value: department?.id,
                        label: department?.department_name,
                      };
                    })}
                  />
                </GridLayoutItem> */}

                <GridLayoutItem>
                  <Field
                    name="process_id"
                    label="Material Issued to Department"
                    placeholder="Material Issued to Department"
                    component={FormSelectionField}
                    // validator={requiredValidator}
                    options={ProcessList?.map((process: IProcess) => {
                      return {
                        value: process?.id,
                        label: process?.process_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="cleaning_checker_by"
                    label="Cleaning Checker By"
                    placeholder="Checker Name"
                    component={FormSelectionField}
                    options={UserList?.map((user: IUser) => {
                      return {
                        value: user?.id,
                        label: `${user?.first_name} ${user?.last_name}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem></GridLayoutItem> */}
                <GridLayoutItem
                  // colSpan={4}
                  style={{
                    // position: "relative",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    // marginTop: 10,
                  }}
                >
                  <div
                  // style={{ position: "absolute", right: 0, top: 36 }}
                  >
                    <ButtonWithLoading
                      label={product_cleaning_guid ? "Update" : "Create"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={() => navigate("/productcleaning")}
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <QtychangeWatcher formRenderProps={formRenderProps} />
            <ItemChangeWatcher formRenderProps={formRenderProps} />
            <GRNChangeWatcher formRenderProps={formRenderProps} />
            <UserChangeWatcher formRenderProps={formRenderProps} />
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateProductCleaning;
