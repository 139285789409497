import { Button } from "@progress/kendo-react-buttons";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import AppDialog from "../../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setAttachmentDeleteIndex } from "../employeeSlice";
import { ErrorToast } from "../../../components/toast/Toasts";
import { Ripple } from "@progress/kendo-react-ripple";
import { FaRegEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { IDocument } from "../../document/documentModel";
import { useLocation } from "react-router-dom";
import {
  deleteEmployeeAttachment,
  getDocumentAttachmentByUserID,
  updateEmployeeAttachment,
  uploadEmployeeAttachment,
} from "../services/employee.services";
import FormAttachmentUpload from "../../../components/formFields/FormAttachmentUpload";
import { requiredValidator } from "../../../components/formFields/CommonValidator";

const DocumentAttachmentsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const AttachmentDeleteIndex = useAppSelector(
    (state) => state.employee.AttachmentDeleteIndex
  );
  const location = useLocation();
  const EmployeeID = location.state?.EmployeeID;
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const DocumentList = useAppSelector((state) => state.document?.DocumentList);

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        document_id: null,
        document: "",
      },
    });
  }, [fieldArrayRenderProps]);
  const handleImageSave = async (index: number) => {
    const attachmentGUID =
      fieldArrayRenderProps?.value[index]?.employee_document_guid;
    const attachment = fieldArrayRenderProps?.value[index]?.document;
    const documentTypeID = fieldArrayRenderProps?.value[index]?.document_id;
    try {
      if (attachmentGUID) {
        const files = new FormData();
        let binaryDoc;
        if (
          attachment &&
          typeof attachment !== "string" &&
          attachment.length > 0
        ) {
          binaryDoc = attachment[0]?.getRawFile();
        }
        files.append("id", attachmentGUID);
        files.append("document_id", documentTypeID);
        files.append("employee_id", EmployeeID);
        files.append("document", binaryDoc || "");
        const res = await dispatch(updateEmployeeAttachment(files));
        if (res.payload.Status === 200) {
          dispatch(getDocumentAttachmentByUserID(EmployeeID));
        }
      } else {
        const files = new FormData();
        if (
          attachment &&
          typeof attachment !== "string" &&
          attachment.length > 0
        ) {
          const binaryDoc = attachment[0]?.getRawFile();
          files.append("document_id", documentTypeID);
          files.append("employee_id", EmployeeID);
          files.append("document", binaryDoc);
          const res = await dispatch(uploadEmployeeAttachment(files));
          if (res.payload.Status === 200) {
            dispatch(getDocumentAttachmentByUserID(EmployeeID));
          }
        }
      }
    } catch (error: any) {
      // Error handling
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error saving Attchment:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  };

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("ImageDeleteDialog"));
    dispatch(setAttachmentDeleteIndex(index));
  };

  const handleOpenImage = (url: string) => {
    if (url && typeof url === "string") {
      window.open(url);
    }
  };
  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index]?.employee_document_guid) {
        try {
          const response = await dispatch(
            deleteEmployeeAttachment(
              fieldArrayRenderProps?.value[index]?.employee_document_guid
            )
          );
          if (response?.meta?.requestStatus === "fulfilled") {
            fieldArrayRenderProps?.onRemove({ index: index });
            dispatch(closeDialog());
            dispatch(setAttachmentDeleteIndex(-1));
            //  SuccessToast(response.payload.message || "Success");
          }
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Attachment:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setAttachmentDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  return (
    <GridLayout gap={{ rows: 10, cols: 10 }}>
      {/* {fieldArrayRenderProps?.value?.length < 1 ? pushElementInarray() : null} */}
      {dialogName === "ImageDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Document"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Documnet?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Ripple>
                  <Button
                    style={{ marginRight: 4 }}
                    fillMode={"outline"}
                    themeColor={"error"}
                    onClick={() => {
                      dispatch(closeDialog());
                      dispatch(setAttachmentDeleteIndex(-1));
                    }}
                  >
                    Cancel
                  </Button>
                </Ripple>
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(AttachmentDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((document: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card className="fieldarray-card">
              <GridLayout
                style={{
                  marginRight: 60,
                  alignItems: "center",
                  marginLeft: 20,
                  padding: 10,
                }}
                gap={{ rows: 10, cols: 10 }}
                cols={[{ width: "20%" }, { width: "60%" }, { width: "20%" }]}
              >
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name={`EmployeeAttachments.${index}.document_id`}
                    label="Document Type"
                    component={FormSelectionField}
                    placeholder="Select Document Type"
                     validator={requiredValidator}
                    options={DocumentList?.map((doc: IDocument) => {
                      return {
                        value: doc?.id,
                        label: doc?.document_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ display: "flex" }}>
                  <Field
                    imageWidth={60}
                    imageHeight={60}
                    name={`EmployeeAttachments.${index}.document`}
                    label="Document"
                    type="file"
                    // accept="image/*"
                    component={FormAttachmentUpload}
                  />
                </GridLayoutItem>

                <GridLayoutItem>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "end",
                      marginTop: "35px",
                    }}
                  >
                    <Button
                      style={{ padding: 5 }}
                      type="button"
                      fillMode={"solid"}
                      themeColor={"primary"}
                      onClick={() => handleImageSave(index)}
                    >
                      {!fieldArrayRenderProps.value[index]?.id
                        ? "Save"
                        : "Update"}
                    </Button>
                    <Button
                      style={{
                        marginLeft: 5,
                        position: "relative",
                        width: 30,
                        height: 32,
                      }}
                      onClick={() => handleOpenDeleteDialog(index)}
                      //  disabled={fieldArrayRenderProps.value.length === 1}
                      fillMode={"solid"}
                      themeColor={"error"}
                      type="button"
                      size={"small"}
                    >
                      <MdDelete className="absolute-position" />
                    </Button>
                    <Button
                      style={{
                        marginLeft: 5,
                        position: "relative",
                        width: 30,
                        height: 32,
                      }}
                      onClick={() =>
                        handleOpenImage(
                          fieldArrayRenderProps.value[index]?.document
                        )
                      }
                      disabled={!fieldArrayRenderProps.value[index]?.id}
                      fillMode={"solid"}
                      themeColor={"primary"}
                      type="button"
                      size={"small"}
                    >
                      <FaRegEye className="absolute-position" />
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
      <GridLayoutItem style={{ marginBottom: 10, textAlign: "left" }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
          disabled={
            fieldArrayRenderProps.value?.filter(
              (e: any) => typeof e.attachments === "object"
            )?.length > 0
          }
        >
          Add Document
        </Button>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default DocumentAttachmentsArray;
